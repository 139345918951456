import React from 'react';
import i18n from 'i18next';
import moment from 'moment';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';
import IntakeCalculator from 'utils/nutrition/fmfIntakeCalculator.js';

const intakeCalculator = new IntakeCalculator();

export default class MeasurementCard extends React.Component {
    constructor(props) {
        super(props);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _calculateFatPercentageViaSkinFoldMeasurement(client, measurement) {
        const fatPercentage = intakeCalculator.calculateFatPercentage(client, measurement);
        if (!fatPercentage) {
            return "?";
        }

        return parseInt(fatPercentage);
    }

    _isUserTypeClient() {
        const user = this.context.currentUser;
        const userType = user && user.identityToken && user.identityToken.user_type;
        return userType === 'Client';
    }

    _deleteMeasurement() {
        if(window.confirm(i18n.t('measurements.confirmDelete'))) {
            fmfApiClient.deleteClientMeasurement(this.cancelSource.token, this.props.client.id, this.props.measurement.id)
                .then(result => {
                    this.props.onRefresh();
                }, error => {
                    Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                });
        }
    }

    _renderDate(measurement) {
        let n = new Date();
        let createdAt = '';
        n.setDate(n.getDate()-1);
        let createdAtMoment = moment(measurement.createdAt);
        if(createdAtMoment.isBefore(n)) {
            createdAt = createdAtMoment.format('dddd D MMMM YYYY');
        } else {
            createdAt = createdAtMoment.fromNow();
        }
        return createdAt
    }

    _renderWeight(measurement) {
        const weight = measurement.currentWeight;
        if (weight) {
            return (<span>{i18n.t('formFields.currentWeight')}: { weight }kg</span>);
        }
    }

    _renderFatPercentage(client, measurement) {
        let fatPercentage = null;
        switch (measurement.skinFoldMeasurementMethod) {
            case 0:
                fatPercentage = measurement.fatPercentage;
                break;
            case 1:
            case 2:
                fatPercentage = this._calculateFatPercentageViaSkinFoldMeasurement(client, measurement);
                break;
        }

        if (fatPercentage) {
            return (<span>{i18n.t('measurements.fatPercentage')}: {fatPercentage}%</span>);
        }
    }

    _renderStats(client, measurement) {
        const weight = this._renderWeight(measurement);
        const fatPercentage = this._renderFatPercentage(client, measurement);

        return (
            <p>
                {weight}
                {weight && fatPercentage ? <span> | </span> : null}
                {fatPercentage}
            </p>
        );
    }

    render() {
        const client = this.props.client;
        const measurement = this.props.measurement;

        return (
            <div onClick={() => this.props.onClick(measurement)} className={"card white measurement" + (this.props.clickable ? " clickable" : "")}>
                <div className="card-content">
                    <div className="row card-measurement-row">
                        <div className="col s1">
                            <i className="material-icons tiny card-icon">insert_chart</i>
                        </div>
                        <div className="col s11">
                            <div className="measurementsDetails">
                                <span className="date">{this._renderDate(measurement)}</span>
                                {!this._isUserTypeClient() &&
                                    <span className="itemTools">
                                        <a className="cursor" onClick={(e) => { e.stopPropagation(); this._deleteMeasurement(); }}>
                                            <i className="material-icons">delete</i>
                                        </a>
                                    </span>
                                }
                                <br />
                            </div>
                            {this._renderStats(client, measurement)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

MeasurementCard.contextType = UserContext