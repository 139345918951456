import React from 'react';

export default function UpgradeState({children}) {
    return (
        <div id="page">
            <div className="container inlineModal">
                <div className="row">
                    <div className="col s12 m8 offset-m2 l6 offset-l3">
                        <div className="modalContentContainer upgrade-state">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}