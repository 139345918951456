import jsonwebtoken from 'jsonwebtoken';
import { Subject } from 'rxjs';
import cloneDeep from 'lodash.clonedeep';
import fmfApiClient from 'utils/fmf-api-client.js';
import settings from 'utils/settings.js';

const localStorageAccessTokenKey = 'Cariphy.accessToken';
const localStorageRefreshTokenKey = 'Cariphy.refreshToken';

// Old deprecated local storage keys.
const localStorageUserIdKey = 'Cariphy.userId';
const localStorageIdentityTokenKey = 'Cariphy.identityToken';

const userSessionChangedSubject = new Subject();
const userLoggedOutSubject = new Subject();

let userSession;

const getAccessToken = () => localStorage.getItem(localStorageAccessTokenKey);
const getRefreshToken = () => localStorage.getItem(localStorageRefreshTokenKey);
const getCode = () => new URLSearchParams(window.location.hash.slice(1)).get('code');
const getUserSession = () => cloneDeep(userSession);
const setUserSession = (newUserSession) => {
    userSession = newUserSession;
    userSessionChangedSubject.next(newUserSession);
}

const onInit = async () => {
    // Clean old local storage values
    localStorage.removeItem(localStorageUserIdKey);
    localStorage.removeItem(localStorageIdentityTokenKey);

    // Clean remaining access token because we still have to log in.
    localStorage.removeItem(localStorageAccessTokenKey);

    const code = getCode();
    if (code) {
        // WORKAROUND: Try/catch gives the following error, so we used the `Promise.catch()` method:
        // `imports/api/auth-service.js: Cannot read property 'type' of null`
        return await loginWithCode(code).catch(logout);
    }
    if (userSession) return userSession;
    const refreshToken = getRefreshToken();
    if (refreshToken) {
        // WORKAROUND: Try/catch gives the following error, so we used the `Promise.catch()` method:
        // `imports/api/auth-service.js: Cannot read property 'type' of null`
        return await loginWithRefreshToken(refreshToken).catch(logout);
    }
}

const onUserChanged = onChanged => userSessionChangedSubject.subscribe(onChanged);
const onUserChangedUnsubscribe = subscription => userSessionChangedSubject.unsubscribe(subscription);
const onUserLoggedOut = onLoggedOut => userLoggedOutSubject.subscribe(onLoggedOut);
const onUserLoggedOutUnsubscribe = subscription => userLoggedOutSubject.unsubscribe(subscription);

const loginWithUserCredentials = (username, password) => {
    const promise = fmfApiClient.getTokenByUserCredentials(username, password);
    return handleLogin(promise);
};

const loginWithRefreshToken = refreshToken => {
    const promise = fmfApiClient.getTokenByRefreshToken(refreshToken);
    return handleLogin(promise);
};

const loginWithCode = code => {
    const promise = fmfApiClient.getTokenByCode(code);
    return handleLogin(promise);
};

const logout = () => {
    userSession = null;
    localStorage.removeItem(localStorageRefreshTokenKey);
    localStorage.removeItem(localStorageAccessTokenKey);
    userSessionChangedSubject.next(null);
    userLoggedOutSubject.next();
}

const handleLogin = (promise) => {
    return promise.then(response => {
        localStorage.setItem(localStorageAccessTokenKey, response.access_token);
        localStorage.setItem(localStorageRefreshTokenKey, response.refresh_token);

        return new Promise(resolve => {
            // Wrap in another promise, so errors are not returned to the invoker.
            Promise.all([
                fmfApiClient.getUserProfile(),
                fmfApiClient.getOrganization(),
                fmfApiClient.getOrganizationLicense()
            ]).then(values => {
                const [ profile, organization, license ] = values;
                const identity = jsonwebtoken.verify(response.id_token, settings.FMF_API_IDTOKEN_PUBLICKEY);
                userSession = {
                    userId: identity.user_id,
                    identityToken: identity,
                    profile: profile,
                    organization: organization,
                    license: license
                };
                userSessionChangedSubject.next(userSession);
                resolve();
            });
        })
    });
}

const authService = {
    getAccessToken,
    getRefreshToken,
    getUserSession,
    setUserSession,

    onInit,
    onUserChanged,
    onUserChangedUnsubscribe,
    onUserLoggedOut,
    onUserLoggedOutUnsubscribe,

    loginWithUserCredentials,
    loginWithRefreshToken,
    logout
};
export default authService;