import React from 'react';
import { Link } from 'react-router-dom';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';

import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import Pagination from 'components/components/pagination/pagination.jsx';
import TrainingTemplateCard from 'components/components/trainingTemplates/trainingTemplateCard.jsx';

export default class trainingTemplates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoader: true,
            templates: [],
            nrOfPages: 0,
            currentPage: 0,
            pageSize: 15
        };

        this.cancelSource = fmfApiClient.createCancelSource();

        this._getTemplates(this.props.cms);
    }

    _setCurrentPage(newPageNr) {
        this.setState({
            currentPage: newPageNr
        },function() {
            this.getCustomers();
        }.bind(this));
    }

    _getTemplates(isCms) {
        const endpoint = isCms ? 'getTrainingTemplates' : 'getCustomTrainingTemplates';
        fmfApiClient[endpoint](this.cancelSource.token, "", [], [], [], [], this.state.currentPage, this.state.pageSize)
            .then(result => {
                this.setState({
                    templates: result.items,
                    nrOfPages: result.totalPages,
                    currentPage: result.page,
                    showLoader: false
                });
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // eslint-disable-next-line eqeqeq
        if(this.props.cms != nextProps.cms){
            this._getTemplates(nextProps.cms);
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    render() {
        const { currentUser } = this.context;

        return (
            <div id="page">
                <div className="pageheader">
                    <div className="container">
                        <div className="row">
                            {this.props.cms ?
                            <div className="col s12">
                                <h1>CMS - Training Templates</h1><br />
                                <div className="extraInfo">
                                    FitMasterFreddy Training Templates
                                </div>
                            </div>
                            :
                            <div className="col s12">
                                <h1>{ currentUser? currentUser.identityToken.organization_displayname:'' }</h1><br />
                                <div className="extraInfo">
                                    Opgeslagen templates
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                { this.state.showLoader ? <FPLoader /> :
                    <div className="container">
                        <div className="customers">
                            { this.props.cms ?
                            <div className="actions">
                                <Link to="/cms/training-templates/add" className="btn-floating btn-large waves-effect waves-light">
                                    <i className="material-icons">add</i>
                                </Link>
                            </div>
                            :
                            <br />
                            }
                            <div className="row">
                                { this.state.templates.map((template) => (
                                    <div className="col s12 m6 l3" key={'template'+template.id}>
                                        <TrainingTemplateCard cms={this.props.cms} template={template} />
                                    </div>
                                )) }
                            </div>
                            <div className="row">
                                <div className="col s12 center-align">
                                    <Pagination currentPage={this.state.currentPage}
                                        pages={this.state.nrOfPages}
                                        onPageChange={this._setCurrentPage}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}


trainingTemplates.contextType = UserContext