import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import InvoiceRule from 'components/components/profile/invoiceRule.jsx';

export default class InvoiceOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invoicesLoaded: false,
            invoices: null
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        fmfApiClient.getInvoices(this.cancelSource.token)
            .then(result => {
                this.setState({
                    invoicesLoaded: true,
                    invoices: result.items
                });
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    renderInvoices() {
        return (
            <div className="invoiceItems">
                { this.state.invoices.length > 0 ? this.state.invoices.map((invoice) => (
                    <InvoiceRule invoiceData={invoice} key={invoice.invoiceNumber.toString()} />
                )) : <strong><Trans>subscriptionPage.noPayments</Trans></strong> }

            </div>
        );
    }

    renderLoader() {
        return (
            <FPLoader fixed="relative" />
        );
    }

    render() {
        return (
            <div className="container">
                <h4 className="smallGreyTitle"><Trans>subscriptionPage.invoicesTitle</Trans></h4>
                <hr />
                { this.state.invoicesLoaded ? this.renderInvoices() : this.renderLoader() }
            </div>
        );
    }
}


InvoiceOverview.contextType = UserContext