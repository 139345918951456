import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import history from 'history.js';
import ScheduleCard from './scheduleCard';

export default class TrainingScheduleCard extends Component {
    constructor(props) {
        super(props);
        this._handleCardClick = this._handleCardClick.bind(this)
    }

    _renderDescription(trainingSchedule) {
        let description = ''
        if (trainingSchedule.goalName) {
            description = trainingSchedule.goalName + " in ";
        }

        return description + trainingSchedule.weeks + " weken";
    }

    _handleCardClick(event) {
        history.push('/clienten/' + this.props.clientId + '/trainingsschema/' + this.props.trainingSchedule.id);
    }

    render() {
        const trainingSchedule = this.props.trainingSchedule;

        const handleCardClick = this._handleCardClick;
        const icon = "assignment";
        const title = (<Trans>trainingSchedule.trainingschedule</Trans>);

        return (
            <ScheduleCard schedule={trainingSchedule}
                handleCardClick={handleCardClick}
                icon={icon}
                title={title}
                description={this._renderDescription(trainingSchedule)}
            />
    );
 }
}

TrainingScheduleCard.propTypes = {
    clientId: PropTypes.string.isRequired,
    trainingSchedule: PropTypes.object.isRequired,
};