import React from 'react';

import Dashboard from 'components/components/dashboard';

export default (props) => (
    <Dashboard {...props} renderHeader={(dashboardProps) => <Header {...props} {...dashboardProps} />} />
);

const Header = ({clients, searchTerm, showLoader}) => {
    if (showLoader) return null;

    return (
        <>
            {clients.length > 0
                ? <h1>{ clients.length } zoekresultaten voor: { searchTerm }</h1>
                : <h1>Geen resultaten</h1>
            }
        </>
    );
};