import React, { useState, useContext, useRef } from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { SketchPicker } from 'react-color';
import fmfApiClient from 'utils/fmf-api-client';
import ImageCropper from 'components/components/imageCropper/imageCropper';
import CircleLoader from 'components/components/circleLoader/circleLoader';
import { ThemeContext, DefaultTheme } from 'contexts';

export default () => {
    const theme = useContext(ThemeContext);
    const [ primaryColor, setPrimaryColor ] = useState(theme.primaryColor);
    const [ previewUrl, setPreviewUrl ] = useState(theme.logoUrl);
    const [ { fileName, fileData }, setFile ] = useState({});
    const [ isBusy, setIsBusy ] = useState(false);

    const imageCropper = useRef();

    const reset = () => {
        setPrimaryColor(DefaultTheme.primaryColor);
        setPreviewUrl(DefaultTheme.logoUrl);
        setFile({});
        imageCropper.current.handleCancel();
    }

    const submit = (e) => {
        e.preventDefault();
        const cancelSource = fmfApiClient.createCancelSource();
        const submitPrimaryColor = primaryColor === DefaultTheme.primaryColor ? null : primaryColor;

        setIsBusy(true);
        fmfApiClient.setStyling(cancelSource.token, submitPrimaryColor, fileName, fileData)
            .catch(error => {
                Materialize.toast(i18n.t('errors.general_error'), window.toastDuration);
                throw error;
            }).finally(() => setIsBusy(false));
    }

    return (
        <div className="subscriptionContainer">
            <br />
            <div className="container">
                <div className="row">
                    <div className="col s12 m9 l7">
                        <form id="setStyleForm" onSubmit={submit}>
                            <div className="row noMarginBottom">
                                <div className="col s12">
                                    <h4 className="smallGreyTitle"><Trans>changeStyling.modalTitle</Trans></h4>
                                    <br />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 m6">
                                    <h4 className="small">{i18n.t('changeStyling.primaryColor')}</h4>
                                    <SketchPicker disableAlpha={true} color={primaryColor} onChangeComplete={color => setPrimaryColor(color.hex)} />
                                </div>
                                <div className="col s12 m6">
                                    <h4 className="small">{i18n.t('changeStyling.logo')}</h4>
                                    <ImageCropper
                                        ref={imageCropper}
                                        saveText={i18n.t('generalActions.choose')}
                                        width={300}
                                        height={72}
                                        border={5}
                                        scaleMin={0.2}
                                        preview={previewUrl}
                                        onSave={(cancelToken, fileName, fileData) => {
                                            setFile({ fileName, fileData });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 right-align">
                                    <button className="waves-effect waves-light btn noShadow" type="button" onClick={reset}>
                                        <Trans>generalActions.reset</Trans>
                                    </button>
                                    <button disabled={isBusy} className="waves-effect waves-light btn noShadow" type="submit">
                                        <Trans>generalActions.save</Trans>
                                    </button>
                                    <div className={!isBusy ? 'hide' : ''} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}>
                                        <CircleLoader />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};