import React from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts.jsx';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import InvoiceOverview from 'components/components/profile/invoiceOverview.jsx';
import history from 'history.js';

export default class ProfileSubscription extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nextLicense: null
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        this._getNextLicense();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _getNextLicense() {
        fmfApiClient.getLicenses(this.cancelSource.token)
            .then(result => {
                const currentLicense = this.context.currentUser.license;
                for (let i = 0; i < result.length; i++) {
                    const license = result[i];
                    // We want to suggest the license with the next price increase, not the most expensive one.
                    if (license.priceExclVat > currentLicense.priceExclVat) {
                        this.setState({nextLicense: license});
                        return;
                    }
                }
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });
    }

    render() {
        const currentUser = this.context.currentUser;
        const license = currentUser.license;
        const maxNrOfClients = license.maxClients || 0;
        const organization = currentUser.organization;

        return (
            <div className="subscriptionContainer">
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col s12 m4">
                            <h4 className="smallGreyTitle"><Trans>subscriptionPage.subscriptionTitle</Trans></h4>
                            <SubscriptionCard
                                subscriptionName={license.name}
                                maxNrOfClients={maxNrOfClients}
                                upgradeName={this.state.nextLicense && this.state.nextLicense.name}
                            />
                        </div>
                        <div className="col s12 m4">
                            <h4 className="smallGreyTitle"><Trans>subscriptionPage.paymentMethod</Trans></h4>
                            <LicenseCard license={license} />
                        </div>
                        <div className="col s12 m4">
                            <h4 className="smallGreyTitle"><Trans>subscriptionPage.invoiceInfo</Trans></h4>
                            <InvoiceCard organization={organization} />
                        </div>
                    </div>
                </div>
                <InvoiceOverview />
            </div>
        )
    }
}

ProfileSubscription.contextType = UserContext

function SubscriptionCard({subscriptionName, maxNrOfClients, upgradeName}) {
    return (
        <div className="card bordered infoCard">
            <div className="card-content">
                <span className="card-title">
                    {i18n.t('subscriptionPage.subscriptionType', {subscription: subscriptionName})}
                </span>
                <span className="card-subtitle">
                    {maxNrOfClients > 0 ?
                        i18n.t('subscriptionPage.maxClients', {nrOfClients: maxNrOfClients}) :
                        i18n.t('subscriptionPage.unlimitedClients')}
                </span>
                <div className="row noMarginBottom">
                    <div className="col s7">
                        {upgradeName && <UpgradeButton upgradeName={upgradeName} /> }
                    </div>
                </div>
            </div>
        </div>
    );
}

function UpgradeButton({upgradeName}) {
    return (
        <a className="waves-effect waves-light btn noShadow blue white-text" onClick={() => history.push('/profile/upgrade')}>
            {i18n.t('subscriptionPage.upgradeTo', {upgradeName: upgradeName})}
        </a>
    );
}

function LicenseCard({license}) {
    return (
        <div className="card bordered infoCard">
            {license.priceExclVat > 0 ?
                <div className="card-content">
                    <span className="card-title">
                        iDeal <img src="/images/ideal-icon.png" width="32" className="inlineImage" />
                    </span>
                    <span className="card-subtitle">
                        <Trans>subscriptionPage.paymentMethodDescriptionMonthlyDirectDebit</Trans>
                    </span>
                    <div className="row noMarginBottom">
                        <div className="col s12">
                            <span className="upgradePrice">
                                <span className="valuta">€</span>
                                <span className="price">
                                    {license.priceExclVat === 0 ?
                                        '0,-' :
                                        license.priceExclVat + ',-'
                                    }
                                </span>
                            </span>
                        </div>
                    </div>
                </div> :
                <div className="card-content">
                    <span className="card-title">Geen betaling nodig</span>
                    <div className="row noMarginBottom">
                        <div className="col s12">
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

function InvoiceCard({organization}) {
    return (
        <div className="card bordered infoCard">
            <div className="card-content">
                <span className="card-title small"><Trans>subscriptionPage.invoiceAddress</Trans></span>
                <span className="card-subtitle truncate">
                    {
                        (organization.organizationName ? organization.organizationName + ', ' : '') +
                        (organization.street ? organization.street + ', ' : '') +
                        (organization.houseNumber ? organization.houseNumber + ', ' : '') +
                        (organization.postcode ? organization.postcode + ', ' : '') +
                        (organization.city ? organization.city + ', ' : '')
                    }
                </span>
                <div className="row noMarginBottom">
                    <div className="col s12">
                        <Link className="waves-effect waves-light btn noShadow" to="/profile/account">
                            <Trans>generalActions.edit</Trans>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}