import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import history from 'history.js';
import ScheduleCard from './scheduleCard';

export default class NutritionScheduleCard extends Component {
    constructor(props) {
        super(props);
        this._handleCardClick = this._handleCardClick.bind(this)
    }

    _handleCardClick(event) {
        history.push('/clienten/' + this.props.clientId + '/voedingsschema/' + this.props.nutritionSchedule.id);
    }

    render() {
        const nutritionSchedule = this.props.nutritionSchedule;

        const handleCardClick = this._handleCardClick;
        const icon = "local_dining";
        const title = (<Trans>nutritionSchedule.nutritionSchedule</Trans>);

        return (
            <ScheduleCard schedule={nutritionSchedule}
                handleCardClick={handleCardClick}
                icon={icon}
                title={title}
            />
    );
 }
}

NutritionScheduleCard.propTypes = {
    clientId: PropTypes.string.isRequired,
    nutritionSchedule: PropTypes.object.isRequired
};