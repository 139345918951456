import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import moment from 'moment';

import fmfApiClient from 'utils/fmf-api-client.js';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import DailyAdviceOverView from './dailyAdviceOverView.jsx';
import { MergeShoppingList } from 'utils/nutrition/mergeShoppingList.js';

const mergeShoppingList = new MergeShoppingList();

class nutritionScheduleWeekPrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nutritionSchedule: null
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        let htmlTag = document.getElementsByTagName('html')[0];
        htmlTag.setAttribute("moznomarginboxes", "");
        htmlTag.setAttribute("mozdisallowselectionprint", "");

        this._getNutritionSchedule();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _calculateWeek() {
        return this.props.week || (this.state.nutritionSchedule && this.state.nutritionSchedule.currentWeek) || 1;
    }

    _getNutritionSchedule() {
        fmfApiClient.getClientNutritionSchedule(this.cancelSource.token, this.props.clientId, this.props.nutritionScheduleId)
            .then(result => {
                this.setState({nutritionSchedule: result});
            }
        );
    }

    _getNutritionScheduleDates() {
        let startDate = moment(this.state.nutritionSchedule.startDate).format('D MMMM \'YY');
        let endDate = moment(startDate, 'D MMMM \'YY');
        endDate = endDate.add(this.state.nutritionSchedule.weeks, 'weeks').add('-1','days').format('D MMMM \'YY');

        return startDate + ' - ' + endDate;
    }

    _getNutritionScheduleDatesForWeek(weekno) {
        let startDate = moment(this.state.nutritionSchedule.startDate);
        let endDate = moment(startDate);
        endDate = endDate.add(weekno+1, 'weeks').add('-1','days').format('D MMMM \'YY');

        startDate = startDate.add(weekno, 'weeks').format('D MMMM \'YY');

        return startDate + ' - ' + endDate;
    }

    _shoppingList(week, shoppingList) {
        const secondHalfIndex = Math.ceil(shoppingList.length / 2);
        const firstHalf = shoppingList.slice(0, secondHalfIndex);
        const secondHalf = shoppingList.slice(secondHalfIndex);

        return (
            <div>
                <h5><Trans>nutritionSchedule.shoppingList</Trans> <Trans>nutritionSchedule.week</Trans> {week}</h5>
                <div className="shoppinglist-container row">
                    {this._shoppingListPart(firstHalf)}
                    {this._shoppingListPart(secondHalf)}
                </div>
            </div>
        );
    }

    _shoppingListPart(listPart) {
        return (
            <div className="col s6">
                {listPart && listPart.map((shoppingListCat, catIndex) => {
                    return shoppingListCat != null && shoppingListCat[1] != null && shoppingListCat[1].length > 0 && (
                        <div className="cat-row" key={"shoppinglistitem-" + catIndex}>
                            <div className="row shoppingListItem-row">
                                <div className="col s12 cat">
                                    {shoppingListCat[0]}
                                </div>
                            </div>
                            {shoppingListCat[1].map((mealNutrition, index) => {
                                const nutrition = mealNutrition.nutrition;
                                return (
                                    <div className="row shoppingListItem-row" key={"shoppinglistitem-" + catIndex + "-" + index}>
                                        <div className="col s8">{nutrition.name}</div>
                                        <div className="col s4">
                                            {mealNutrition.quantity}
                                            &nbsp;
                                            {mealNutrition.quantity > 1 ?
                                                nutrition.unitNamePlural :
                                                nutrition.unitName
                                            }
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    _dailyAdvice(week, dayInWeek, dailyAdvice, dayInWeekNumber) {
        let startDate = moment(this.state.nutritionSchedule.startDate);
        let startDateFormatted = startDate.add(dayInWeekNumber - 1,'day').add(week - 1, 'weeks').format('D MMMM \'YY');
        const weeklyAdviceId = this.state.nutritionSchedule.weeklyAdvices[week - 1].id;
        const ids =  dailyAdvice.dailyAdviceMeals.map(dailyAdviceMeal => dailyAdviceMeal.id);
        const mealSubstitutesForThisDailyAdvice = this.state.nutritionSchedule.mealSubstitutes.filter(
            // eslint-disable-next-line eqeqeq
            o => ids.indexOf(o.dailyAdviceMealId) >= 0 && o.weeklyAdviceId == weeklyAdviceId
        );

        return (
            <DailyAdviceOverView key={"DailyAdviceOverView" + dailyAdvice.id}
                dailyAdvice={dailyAdvice} dayInWeek={dayInWeek}
                mealSubstitutes={mealSubstitutesForThisDailyAdvice}
                dateFormatted={startDateFormatted}
                printMode
            />
        );
    }

    _weekDetailAdvice(week) {
        const dayAdvices = this.state.nutritionSchedule.weeklyAdvices[week - 1].dailyAdvices;

        return (
            <div>
                {this._dailyAdvice(week, "monday", dayAdvices[0], 1)}
                {this._dailyAdvice(week, "tuesday", dayAdvices[1], 2)}
                {this._dailyAdvice(week, "wednesday", dayAdvices[2], 3)}
                {this._dailyAdvice(week, "thursday", dayAdvices[3], 4)}
                {this._dailyAdvice(week, "friday", dayAdvices[4], 5)}
                {this._dailyAdvice(week, "saturday", dayAdvices[5], 6)}
                {this._dailyAdvice(week, "sunday", dayAdvices[6], 7)}
            </div>
        );
    }


    render() {
        const week = this._calculateWeek();

        let shoppingList;
        if (this.state.nutritionSchedule) {
            shoppingList = mergeShoppingList.mergeShoppingListFromNutritionScheduleWeek(this.state.nutritionSchedule, week);
        }

        return (
            <div>
                {this.state.nutritionSchedule &&
                    <div className="center-align noprint">
                        <br />
                        <Link className="btn"
                        to={'/clienten/' + this.props.clientId + '/voedingsschema/' + this.state.nutritionSchedule.id + '?week=' + week}>
                            <Trans>generalActions.backToOverview</Trans>
                        </Link>
                        <a onClick={window.print} className="btn"><Trans>generalActions.print</Trans></a>
                        <br />
                        <br />
                    </div>
                }
                <div className="printPage-box nutritionScheduleWeek-print">
                    <div id="page" className="nutritionScheduleWeek">
                    {!this.state.nutritionSchedule ?
                        <FPLoader fixed="fixed" /> :
                        <div>
                            <div className="container">
                                <div className="row">
                                    <div className="col s12">
                                        <div className="userInfo variableName">{this._getNutritionScheduleDatesForWeek(week - 1)}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12">
                                        {this._weekDetailAdvice(week)}
                                    </div>
                                    <div className="col s12 shoppinglist">
                                        {this._shoppingList(week, shoppingList)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        )
    }
}

export default nutritionScheduleWeekPrint;

nutritionScheduleWeekPrint.propTypes = {
    clientId             : PropTypes.string.isRequired,
    nutritionScheduleId  : PropTypes.string.isRequired,
    week                 : PropTypes.string
};