import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

class AddNutritionScheduleErrorMax extends React.Component {

    render() {
        return (
            <div id="addNutritionScheduleErrorMax" className="modal error">
                <div className="modal-content">
                    <h5><Trans>addNutritionScheduleErrorMax.title</Trans></h5>
                    <p>
                        <Trans>addNutritionScheduleErrorMax.description</Trans>
                        <br />
                        <Link to="/profile/upgrade" className="modal-close">
                            <Trans>addNutritionScheduleErrorMax.button</Trans>
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}

export default AddNutritionScheduleErrorMax;