import React from 'react';
import { UserContext } from 'contexts.jsx';
import history from 'history.js';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import UpgradeState from 'components/components/upgradeState'

export default class UpgradePaymentCheck extends React.Component {
    constructor(props) {
        super(props);
        this.timeout = null;

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        this.checkPayment();
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
        this.cancelSource.cancel();
    }

    checkPayment() {
        fmfApiClient.getPayment(this.cancelSource.token, this.props.paymentId)
            .then(result => {
                let payment = result;
                if (payment && payment.status === 0) {
                    this.timeout = setTimeout(() => this.checkPayment(), 2000);
                } else if (payment && payment.status === 1) {
                    fmfApiClient.onChangeLicenseComplete(this.cancelSource.token)
                        .then(result => {
                            history.push('/profile/upgrade-afgerond');
                        });
                } else {
                    history.push("/profile/upgrade-mislukt");
                }
            }, error => {
                history.push("/profile/upgrade-mislukt");
                return Promise.reject(error);
            });
    }

    render() {
        return (
            <UpgradeState>
                <h4><Trans>subscriptionPage.accountPaymentCheckTitle</Trans></h4>
                <p><Trans>subscriptionPage.accountPaymentCheckText</Trans></p>
                <FPLoader/>
            </UpgradeState>
        )
    }
}

UpgradePaymentCheck.contextType = UserContext