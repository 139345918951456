import React from 'react';
import i18n from 'i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';

class MealSubstitutionView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchNutrition: '',
            substitions: [],
            showLoader: false
        };

        this.typeTimeout = null;
        this.searchIndex = 0;

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((this.props.meal && this.props.meal.id) !== (nextProps.meal && nextProps.meal.id)) {
            this.setState({
                searchNutrition: "",
                substitions: []
            });
        }
    }

    componentDidMount() {
        if (this.props.meal) {
            this._getSubstitionMeals();
        }
        $('#mealSubstitutions').modal();
    }

    componentDidUpdate(prevProps) {
        if ((this.props.meal && this.props.meal.id) !== (prevProps.meal && prevProps.meal.id)) {
            this._getSubstitionMeals();
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _getSubstitionMeals() {
        let currentSearchIndex = ++this.searchIndex;
        this.setState(
            {showLoader: true},
            () => {
                fmfApiClient.getSubstitionMeals(
                    this.cancelSource.token,
                    this.state.searchNutrition,
                    Math.floor(this.props.meal.kcal),
                    this.props.meal.mealTypeId
                ).then(result => {
                    // This call gets executed multiple times (when typing)
                    // We only want to update the UI when a result arrives for the last call.
                    if (this.searchIndex !== currentSearchIndex) {
                        return;
                    }

                    this.setState({
                        showLoader: false,
                        substitions: result.items
                    });
                }, error => {
                    // This call gets executed multiple times (when typing)
                    // We only want to update the UI when a result arrives for the last call.
                    if (this.searchIndex !== currentSearchIndex) {
                        return;
                    }

                    Materialize.toast(i18n.t('errors.' + error.error.toString()), window.toastDuration);
                    this._close();
                    this.setState({showLoader: false});
                });
            }
        );
    }

    _onSubmit(event) {
        event.preventDefault();
    }

    _changeSearchNutritionHandler(event) {
        this.setState({
            searchNutrition: event.target.value,
            substitions: []
        }, () => {
            clearTimeout(this.typeTimeout);
            this.typeTimeout = setTimeout(() => this._getSubstitionMeals(), 500);
        });
    }

    _close() {
        $(".modal-overlay").trigger("click");
    }

    _selectSubstituteMeal(substituteMeal) {
        let substituteMealDTO = {
            "dailyAdviceMealId": this.props.dailyAdviceMeal.id,
            "substituteMealId": substituteMeal.id,
            "weeklyAdviceId": this.props.weeklyAdviceId
        };

        fmfApiClient.substituteMeal(
            this.cancelSource.token,
            this.props.clientId,
            this.props.nutritionScheduleId,
            substituteMealDTO
        ).then(result => {
            this._close();

            if (this.props.refreshNutrititonSchedule) {
                this.props.refreshNutrititonSchedule();
            }
        });
    }

    render() {
        return (
            <div id="mealSubstitutions" className="modal wide">
                {this.props.dailyAdviceMeal && this.props.meal &&
                    <div className="mealDetailnfo" key={"mealSubstitutions" + this.props.dailyAdviceMealId}>
                        <div className="col l12 s12 mealDetailinfo-container">
                            <div className="mealDetailnfo-header">
                                <h2>{this.props.meal.name}</h2>
                                <div className="userInfo variableName">{this.props.meal.mealTypeName} - {Math.floor(this.props.meal.kcal)} kcal</div>
                                <p id="substition-warning">{i18n.t('nutritionSchedule.substitutionWarning') }</p>
                                <form id="mealSubstitutionsForm" onSubmit={this._onSubmit} noValidate>
                                    <div className="row">
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="searchNutrition" type="text"
                                                name="searchNutrition"
                                                autoComplete="off"
                                                value={this.state.searchNutrition}
                                                onChange={(e) => this._changeSearchNutritionHandler(e)}
                                                required data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <label htmlFor="searchNutrition">Zoek voedingsmiddel</label>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className="mealDetail-body-container">
                                <div className="substituteMeals-container">
                                    {this.state.showLoader ?
                                        <div className="circle-loader-wrapper">
                                            <CircleLoader />
                                        </div> :
                                        // eslint-disable-next-line eqeqeq
                                        !this.state.substitions || this.state.substitions.length == 0 ?
                                            <p>Je zoekopdracht heeft geen resultaten</p> :
                                            this.state.substitions.map((meal, index) => {
                                                // eslint-disable-next-line eqeqeq
                                                if (meal.id != this.props.meal.id) {
                                                    return (
                                                        <div key={"mealsubstitute"+index} className="row substituteMeal">
                                                            {meal.name} - {Math.floor(meal.kcal)} Kcal
                                                            <a
                                                                onClick={() => this._selectSubstituteMeal(meal)}
                                                                className="btn-floating btn waves-effect waves-light right">
                                                                <i className="material-icons">add</i>
                                                            </a>
                                                        </div>
                                                    );
                                                }
                                                return;
                                            })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <a className="closeButton" onClick={this._close}><i className="material-icons">close</i></a>
            </div>
        )
    }
}

export default MealSubstitutionView;