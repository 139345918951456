import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import moment from 'moment';
import accounting from 'accounting';

class InvoiceRule extends React.Component {
    isPaid(status) {
        return status === 1;
    }

    render() {
        let invoiceDate = moment(this.props.invoiceData.invoiceDate).format('D-M-YYYY');
        return (
            <div className="row rowItem">
                <div className="col s4 m2">
                    <span className="grey-text">{ invoiceDate }</span>
                </div>
                <div className="col s5 m5">
                    <strong>{ this.props.invoiceData.licenseName }</strong>
                </div>
                <div className="col s3 m1 right-align-on-medium">
                    { accounting.formatMoney(this.props.invoiceData.amountInclVat)}
                </div>
                <div className="col s6 m2 right-align-on-medium">
                    { this.isPaid(this.props.invoiceData.payment.status) ?
                        <span className="green-text"><Trans>subscriptionPage.completed</Trans></span> :
                        <span className="red-text"><Trans>subscriptionPage.notCompleted</Trans></span>
                    }
                </div>
                <div className="col s6 m2 right-align">
                    <Link to={'/profile/factuur/' + this.props.invoiceData.invoiceNumber} className="blue-text">
                        <Trans>subscriptionPage.viewInvoice</Trans>
                    </Link>
                </div>
            </div>
        )
    }
}


export default InvoiceRule;