/* eslint-disable */
// WORKAROUND: Until adding .eslintignore is supported, we need to disable eslint on a per-file base.
// See: https://github.com/facebook/create-react-app/issues/7339

$.fn.autogrow = function(options) {
    $(this).each(function() {

        var $this       = $(this),
            minHeight   = $this.height(),
            lineHeight  = $this.css('lineHeight');

        $(this).css('height','hidden');

        var duplicate = $('<div></div>').css({
            position:   'absolute',
            top:        -10000,
            left:       -10000,
            width:      $(this).width(),
            fontSize:   $this.css('fontSize'),
            fontFamily: $this.css('fontFamily'),
            lineHeight: $this.css('lineHeight'),
            resize:     'none',
            'word-wrap':'break-word',
            'white-space':'pre-wrap'
        }).appendTo(document.body);

        var update = function() {

            var val = this.value.replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/&/g, '&amp;')
                .replace(/\n/g, '<br/>');

            duplicate.html(val);
            duplicate.css('width', $(this).width());
            $(this).css('height', Math.max(duplicate.height() + 40, minHeight));
        }

        $(this).change(update).keyup(update).keydown(update);

        update.apply(this);

    });
    return this;
}