import React, { useContext } from 'react';
import { Router, Route, Redirect } from 'react-router-dom';
import { UserContext } from './contexts.jsx';
import authService from 'utils/auth-service.js';

import history from './history';
import TrackPageView from './trackPageView';

// IMPORT Layouts
import {MainLayout} from 'components/layouts/mainLayout.jsx';
import {LoginLayout} from 'components/layouts/loginLayout.jsx';
import {UpgradeLayout} from 'components/layouts/upgradeLayout.jsx';
import {ProfileLayout} from 'components/layouts/profileLayout.jsx';

// IMPORT COMPONENTS
import Content from 'components/components/content.jsx';
import SearchOverview from 'components/components/searchOverview/searchOverview.jsx';
import ClientDashboard from 'components/components/clientDashboard/clientDashboard.jsx';
import TrainingTemplates from 'components/components/trainingTemplates/trainingTemplates.jsx';
import UpgradePage from 'components/components/profile/upgradePage.jsx';
import UpgradePaymentCheck from 'components/components/profile/upgradePaymentCheck.jsx';
import UpgradeFinished from 'components/components/profile/upgradeFinished.jsx';
import UpgradeFailed from 'components/components/profile/upgradeFailed.jsx';
import ProfileSubscription from 'components/components/profile/profileSubscription.jsx';
import Styling from 'components/components/profile/styling.jsx';
import ProfileEdit from 'components/components/profile/profileEdit.jsx';
import Users from 'components/components/profile/users.jsx';
import AccountEdit from 'components/components/profile/accountEdit.jsx';
import Invoice from 'components/components/invoice/invoice.jsx';
import AddTrainingScheduleLayout from 'components/components/trainingSchedule/addTrainingScheduleLayout.jsx';
import TrainingSchedulePrintLayout from 'components/components/trainingSchedule/trainingSchedulePrint.jsx';
import EditTrainingScheduleLayout from 'components/components/trainingSchedule/editTrainingScheduleLayout.jsx';
import EditTrainingTemplateLayout from 'components/components/trainingTemplates/editTrainingTemplateLayout.jsx';
import TrainingScheduleDetail from 'components/components/trainingSchedule/trainingScheduleDetail.jsx';
import NutritionSchedule from 'components/components/nutritionSchedule/nutritionSchedule.jsx';
import NutritionSchedulePrintLayout from 'components/components/nutritionSchedule/nutritionScheduleWeekPrint.jsx';
import { ControlPlusClientProxy } from 'components/components/controlPlus';

// Registration and login
import LoginForm from 'components/forms/login/loginform.jsx';
import RegisterForm from 'components/forms/register/registerForm.jsx';
import ResetPass from 'components/forms/resetPass/resetPass.jsx';
import SetPass from 'components/forms/setPass/setPass.jsx';
import ActivationSuccesfull from 'components/forms/activation/activationSuccesfull.jsx';
import ActivationError from 'components/forms/activation/activationError.jsx';
import Activate from 'components/forms/activation/activate.jsx';

import { hasLicenseExpired, pathCanBeAccessedWithExpiredLicense } from 'utils/licenseUtils';
import LicenseExpired from 'components/components/licenseExpired';

// We use `exact` for all of our routes, since it's a better default.
// This is because without exact the first route would match when we have subroutes.
// Example: <Route path="/page" /> and <Route path="/page/subpage" />.
// Without exact the first route would trigger when browsing `/page/subpage`, instead
// of always the 2nd route.
//
// The react-router team will probably change `exact` to be true by default in version 5.
// But for now, we put `exact` in all of our routes.
//
// See: https://github.com/ReactTraining/react-router/issues/4958

export default function AppRouter() {
    return (
        <Router history={history}>
            <TrackPageView />
            <PublicRoutes />
            <SecureRoutes />
        </Router>
    );
}

const PublicRoutes = () => {
    return (
        <>
            <Route exact path="/" render={() => {
                return <Redirect push to="/dashboard" />
            }}/>
            <Route exact path="/login" render={({match, location}) => {
                return (
                    <>
                        <RedirectWhenLoggedIn location={location} />
                        <RouteWithLoginLayout exact path={match.path} component={LoginForm} />
                    </>
                );
            }} />
            <Route exact path="/uitloggen" component={Logout} />
            <RouteWithLoginLayout exact path="/registreer" component={RegisterForm} />
            <RouteWithLoginLayout exact path="/controlplus" render={() => <RegisterForm controlPlus />} />
            <RouteWithLoginLayout exact path="/resetPass" render={({location}) => {
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams.has('token') && queryParams.get('token').replace(/\s/g, '+');
                const email = queryParams.get('email');
                return (
                    <ResetPass token={token} email={email} />
                );
            }} />
            <RouteWithLoginLayout exact path="/setPass" render={({location}) => {
                const queryParams = new URLSearchParams(location.search);
                const cToken = queryParams.has("cToken") && queryParams.get('cToken').replace(/\s/g, '+');
                const pToken = queryParams.has('pToken') && queryParams.get('pToken').replace(/\s/g, '+');
                const email = queryParams.get('email');
                return <SetPass cToken={cToken} pToken={pToken} email={email} />
            }} />
            <RouteWithLoginLayout exact path="/registreer/voltooid" component={ActivationSuccesfull} />
            <RouteWithLoginLayout exact path="/registreer/mislukt" component={ActivationError} />
            <Route exact path="/activate" render={({location}) => {
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams.has("token") && queryParams.get("token").replace(/\s/g, '+');
                const email = queryParams.get("email");
                return <Activate token={token} email={email} />
            }} />
        </>
    );
}

const SecureRoutes = () => {
    return (
        <>
            <SecureRouteWithMainLayout exact path="/dashboard" component={Dashboard} />
            <SecureRouteWithMainLayout exact path="/zoeken" render={({location}) => {
                const queryParams = new URLSearchParams(location.search);
                const searchTerm = queryParams.get('q');
                return <SearchOverview searchTerm={searchTerm} />
            }} />

            <SecureRouteWithMainLayout exact path="/clienten/:clientId" render={({match}) => (
                <ClientDashboard clientId={match.params.clientId} />
            )} />
            <SecureRoute exact path="/controlplus/client/:controlPlusId" render={({match}) => (
                <ControlPlusClientProxy controlPlusId={match.params.controlPlusId} />
            )} />

            <SecureRoute exact path="/clienten/:clientId/trainingsschema/toevoegen/:nrOfWeeks/:startDate" render={({match}) => (
                <AddTrainingScheduleLayout clientId={match.params.clientId} nrOfWeeks={match.params.nrOfWeeks} startDate={match.params.startDate} />
            )} />
            <SecureRouteWithMainLayout exact path="/clienten/:clientId/trainingsschema/:trainingSchedule" render={({match}) => (
                <TrainingScheduleDetail clientId={match.params.clientId} trainingScheduleId={match.params.trainingSchedule} />
            )} />
            <SecureRoute exact path="/clienten/:clientId/trainingsschema/:trainingSchedule/print" render={({match}) => (
                <TrainingSchedulePrintLayout clientId={match.params.clientId} trainingScheduleId={match.params.trainingSchedule} />
            )} />
            <SecureRoute exact path="/clienten/:clientId/trainingsschema/:trainingSchedule/bewerken" render={({match}) => (
                <EditTrainingScheduleLayout clientId={match.params.clientId} trainingScheduleId={match.params.trainingSchedule} />
            )} />

            <SecureRouteWithMainLayout exact path="/clienten/:clientId/voedingsschema/:nutritionSchedule" render={({location, match}) => {
                const queryParams = new URLSearchParams(location.search);
                const week = queryParams.get('week');
                return <NutritionSchedule clientId={match.params.clientId} nutritionScheduleId={match.params.nutritionSchedule} week={week} />
            }} />
            <SecureRoute exact path="/clienten/:clientId/voedingsschema/:nutritionSchedule/print" render={({location, match}) => {
                const queryParams = new URLSearchParams(location.search);
                const week = queryParams.get('week');
                return <NutritionSchedulePrintLayout clientId={match.params.clientId} nutritionScheduleId={match.params.nutritionSchedule} week={week} />
            }} />

            {/* Redirect old week url for backwards compatibility */}
            <SecureRoute exact path="/clienten/:clientId/voedingsschema/:nutritionSchedule/week/:week" render={({match}) => {
                return <Redirect to={"/clienten/" + match.params.clientId + "/voedingsschema/" + match.params.nutritionSchedule + "?week=" + match.params.week} />
            }} />
            <SecureRoute exact path="/clienten/:clientId/voedingsschema/:nutritionSchedule/week/:week/print" render={({match}) => {
                return <Redirect to={"/clienten/" + match.params.clientId + "/voedingsschema/" + match.params.nutritionSchedule + "/print?week=" + match.params.week} />
            }} />

            <SecureRouteWithMainLayout exact path="/mijn-templates" component={TrainingTemplates} />
            <SecureRoute exact path="/mijn-templates/:trainingTemplateId" render={({match}) => (
                <EditTrainingTemplateLayout trainingTemplateId={match.params.trainingTemplateId} />
            )} />

            <SecureRoute exact path="/cms" render={() => <Redirect push to="/cms/training-templates" />} />
            <SecureRouteWithMainLayout exact path="/cms/training-templates" render={() => (
                <TrainingTemplates cms={true} />
            )} />
            <SecureRoute exact path="/cms/training-templates/add" render={() => (
                <EditTrainingTemplateLayout cms={true} addTrainingTemplate={true} />
            )} />
            <SecureRoute exact path="/cms/training-templates/edit/:trainingSchedule" render={({match}) => (
                <EditTrainingTemplateLayout cms={true} trainingTemplateId={match.params.trainingSchedule} />
            )} />

            <SecureRouteWithProfileLayout exact path="/profile" component={ProfileEdit} />
            <SecureRouteWithProfileLayout exact path="/profile/users" component={Users} />
            <SecureRouteWithProfileLayout exact path="/profile/Account" component={AccountEdit} />
            <SecureRouteWithProfileLayout exact path="/profile/abonnement" component={ProfileSubscription} />
            <SecureRouteWithProfileLayout exact path="/profile/styling" component={Styling} />

            <SecureRoute exact path="/profile/factuur/:invoiceNumber" render={({match}) => (
                <Invoice invoiceNumber={match.params.invoiceNumber} />
            )} />

            <SecureRouteWithUpgradeLayout exact path="/profile/upgrade" component={UpgradePage} />
            <SecureRouteWithUpgradeLayout exact path="/profile/upgrade-afgerond" component={UpgradeFinished} />
            <SecureRouteWithUpgradeLayout exact path="/profile/upgrade-mislukt" component={UpgradeFailed} />
            <SecureRouteWithUpgradeLayout exact path="/licentie-verlopen" component={LicenseExpired} />

            {/* Mollie redirects to this route  after a payment has been made.
            Url is set in (backend) API project settings (appsettings.json). */}
            <SecureRouteWithUpgradeLayout exact path="/profile/upgrade-betaling-controle/:paymentId" render={({match}) => (
                <UpgradePaymentCheck paymentId={match.params.paymentId}/>
            )} />
        </>
    );
}

const RouteWithLoginLayout = (props) => {
    const {component: Component, render, ...otherProps} = props;
    return (
        <Route {...otherProps} render={(routeProps) => (
            <LoginLayout {...routeProps}>
                <Route {...props} />
            </LoginLayout>
        )} />
    );
}

const SecureRouteWithMainLayout = (props) => {
    const {component: Component, render, ...otherProps} = props;
    return (
        <SecureRoute {...otherProps} render={(routeProps) => (
            <MainLayout {...routeProps}>
                <SecureRoute {...props} />
            </MainLayout>
        )} />
    );
}

const SecureRouteWithProfileLayout = (props) => {
    const {component: Component, render, ...otherProps} = props;
    return (
        <SecureRoute {...otherProps} render={(routeProps) => (
            <ProfileLayout {...routeProps}>
                <SecureRoute {...props} />
            </ProfileLayout>
        )} />
    );
}

const SecureRouteWithUpgradeLayout = (props) => {
    const {component: Component, render, ...otherProps} = props;
    return (
        <SecureRoute {...otherProps} render={(routeProps) => (
            <UpgradeLayout {...routeProps}>
                <SecureRoute {...props} />
            </UpgradeLayout>
        )} />
    );
}

const SecureRoute = (props) => {
    const {component: Component, render, ...otherProps} = props;
    const {currentUser} = useContext(UserContext);
    return (
        <Route {...otherProps} render={({location}) => {
            if (!currentUser) {
                return <Redirect to={"/login#" + location.pathname + location.search} />;
            }

            if (hasLicenseExpired(currentUser) && !pathCanBeAccessedWithExpiredLicense(location.pathname)) {
                return <Redirect push to="/licentie-verlopen" />;
            }

            return <Route {...props} />;
        }} />
    );
}

const RedirectWhenLoggedIn = ({location}) => {
    const {currentUser} = useContext(UserContext);
    if (currentUser) {
        const hash = location.hash && location.hash.substr(1);
        if (hash) {
            return <Redirect push to={hash} />
        }

        return <Redirect push to="/dashboard" />
    }

    return null;
};

const Dashboard = () => {
    const { currentUser } = useContext(UserContext);
    const idToken = currentUser.identityToken;
    const userType = idToken && idToken.user_type;

    // UserType was introduced in an update,
    // so it can still be empty.
    // We should assume it is a trainer in that case.
    if (!userType || userType === 'Trainer') {
        return (
            <Content />
        );
    }

    if (userType === 'Client') {
        let clientId = idToken.client_id;
        return (
            <ClientDashboard clientId={clientId} />
        );
    }

    authService.logout();
    return null;
}

const Logout = () => {
    authService.logout();
    return <Redirect to='/login' />;
}