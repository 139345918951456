import React, { useState, useEffect, useContext } from 'react';
import tinycolor from 'tinycolor2';
import authService from 'utils/auth-service.js';

export const UserContext = React.createContext({currentUser: null});
export const UserProvider = ({children}) => {
    const [user, setUser] = useState(authService.getUserSession());
    useEffect(() => {
        const subscription = authService.onUserChanged(user => setUser(user));
        return () => authService.onUserChangedUnsubscribe(subscription);
    }, []);

    return (
        <UserContext.Provider value={{currentUser: user}}>
            {children}
        </UserContext.Provider>
    );
};

export const ThemeContext = React.createContext({primaryColor: null, logoUrl: null});
export const ThemeProvider = ({children}) => {
    const { currentUser: user } = useContext(UserContext);

    let primaryColor = user && user.identityToken && user.identityToken.style_primary_color_hexrgb;
    if (!primaryColor) primaryColor = DefaultTheme.primaryColor;

    let logoUrl = user && user.identityToken && user.identityToken.style_logo_url;
    if (!logoUrl) logoUrl = DefaultTheme.logoUrl;

    // Set primary color in CSS.
    useEffect(() => {
        document.documentElement.style.setProperty('--primary-color', primaryColor);
        document.documentElement.style.setProperty('--primary-color-light-4', tinycolor(primaryColor).lighten(4));
        document.documentElement.style.setProperty('--primary-color-light-15', tinycolor(primaryColor).lighten(15));
        document.documentElement.style.setProperty('--primary-color-dark-10', tinycolor(primaryColor).darken(10));
        document.documentElement.style.setProperty('--primary-color-dark-15', tinycolor(primaryColor).darken(15));
    }, [primaryColor]);

    return (
        <ThemeContext.Provider value={{primaryColor: primaryColor, logoUrl: logoUrl}}>
            {children}
        </ThemeContext.Provider>
    )
}

export const DefaultTheme = {
    primaryColor: '#DCE442',
    logoUrl: '/images/logo_groen.svg'
}