import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';

class ResetPass extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            username: '',
            password: '',
            checkPassword: '',
            errorMessage: '',
            showError: 'hide',
            showSuccess: 'showForm',
            showForm: '',
            buttonDisabled: '',
            showLoading: 'hide'
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    componentDidMount() {
        let token = this.props.token;
        let email = this.props.email;

        if(token && email) {
            this.setState({
                token: token,
                username: email,
            });
        } else {
            this.setState({
                password: '',
                checkPassword: '',
                errorMessage: i18n.t('errors.credentials_invalid'),
                buttonDisabled: '',
                showLoading: 'hide',
                showError: '',
                showForm: 'hide'
            });
        }

        $("#registerForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            rules: {
                password: {
                    required: true,
                    minlength: 6,
                    pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/
                },
                checkPassword: {
                    equalTo: "#password"
                }
            },
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
        $('.tooltipped').tooltip();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        fmfApiClient.resetPassword(this.cancelSource.token, this.state.username, this.state.token, this.state.password)
            .then(result => {
                this.setState({
                    password: '',
                    checkPassword: '',
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: 'showSuccess'
                });
            }, error => {
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    render() {
        return (
            <div id="registerFormContainer" className={this.state.showSuccess}>
                <div className="container">
                <form id="registerForm" noValidate onSubmit={this._submitForm}>
                    <div className="loginForm">
                        <div className="row">
                            <div className=" col s10 offset-s1 m8 offset-m2">

                                {/* ERROR MESSAGE */}
                                <div className={this.state.showError}>
                                    <div className="row">
                                        <div className='col s12'>
                                            <div id="card-alert" className="card red">
                                                <div className="card-content white-text">
                                                    <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.showForm}>
                                    <div className="row">
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="password" type="password"
                                                name="password"
                                                onChange={this._changeHandler}
                                                data-msg={ i18n.t('errors.required_password') }
                                                required
                                            />
                                            <label htmlFor="password">{ i18n.t('formFields.password') }</label>
                                            <a className="info tooltipped" data-position="bottom" data-delay="50" data-tooltip={ i18n.t('register.passwordRequirements') }><i className="material-icons">info</i></a>
                                        </div>
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="checkPassword" type="password"
                                                name="checkPassword"
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.password_not_equal') }
                                            />
                                            <label htmlFor="checkPassword">{ i18n.t('formFields.confirmPassword') }</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col s12 m6'>
                                            <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>forgotPass.button</Trans></button>
                                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                                <CircleLoader/>
                                            </div>

                                            <p><Trans components={[<Link to='/login' />]}>forgotPass.alreadyGotAAcount</Trans></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="registerSuccessNotice">
                    <div className="row">
                        <div className=" col s10 offset-s1 m8 offset-m2">
                            <br />
                            <h4><Trans>forgotPass.successTitle</Trans></h4>
                            <p><Trans components={[<Link to='/login' />]}>forgotPass.successIntro</Trans></p>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

export default ResetPass;