import React from 'react';
import i18n from 'i18next'
import fmfApiClient from 'utils/fmf-api-client.js';
import GoalRangeSlider from 'components/components/goalRangeSlider/goalRangeSlider.jsx';

export default class GoalModalPart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            goals: [],
            id: '',
            comment: '',
            variables: []
        };

        this._onGoalSelection = this._onGoalSelection.bind(this);
        this._onCommentChange = this._onCommentChange.bind(this);
        this._onSliderChange = this._onSliderChange.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentGoal && nextProps.currentGoal !== this.props.currentGoal) {
            const currentGoal = nextProps.currentGoal;
            this.setState({
                id: currentGoal.id,
                comment: currentGoal.comment,
                variables: currentGoal.variables
            })
            Materialize.updateTextFields();
        }
    }

    componentDidMount() {
        this._getGoals();
        $('#addGoal .tooltipped').tooltip({delay: 300});
        if (this.state.variables && this.state.variables.length > 0) {
            $('.collapsible').collapsible();
        }
    }

    componentDidUpdate(_, prevState) {
        if ((!prevState.variables || prevState.variables.length === 0)
            && this.state.variables && this.state.variables.length > 0) {
            $('.collapsible').collapsible();
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _onError(message) {
        this.props.onError(message);
    }

    _onGoalChanging() {
        this.props.onGoalChanging();
    }

    _onGoalChange() {
        let variables = [];
        for (let variable of this.state.variables)
        {
            if (variable.start && variable.end) {
                variables.push(variable);
            }
        }

        let goal = {
            id: this.state.id,
            comment: this.state.comment,
            variables: variables
        };

        this.props.onGoalChange(goal);
    }

    _onGoalSelection(event) {
        const goalId = event.target.value;
        // eslint-disable-next-line eqeqeq
        if (goalId == '') return;
        if (goalId === this.state.id) return;

        // Trigger the combobox to change immediately, because the API call might take some time to complete.
        this.setState({ id: goalId }, () => {
            this._onGoalChanging();
            fmfApiClient.getGoal(this.cancelSource.token, goalId)
                .then(result => {
                    this.setState({ variables: result.variables }, this._onGoalChange);
                }, error => {
                    this._onError(i18n.t('errors.' + error.error));
                });
        });
    }

    _onCommentChange(event) {
        const comment = event.target.value;
        if (comment === this.state.comment) return;

        this.setState({ comment: comment }, this._onGoalChange);
    }

    _onSliderChange(variableId, start, end) {
        // eslint-disable-next-line eqeqeq
        let i = this.state.variables.findIndex(v => v.id == variableId);
        let variable = this.state.variables[i];

        if (start === variable.start && end === variable.end) return;

        this.setState((state) => ({
            variables: Object.assign(
                [...state.variables], {
                    [i]: {
                        ...variable,
                        start: start,
                        end: end
                    }
                }
            )
        }), this._onGoalChange);
    }

    _getGoals () {
        fmfApiClient.getGoals(this.cancelSource.token)
            .then(result => {
                this.setState({ goals: result });
            }, error => {
                this._onError(i18n.t('errors.' + error.error));
            });
    }

    render() {
        return (
            <div className="goalModalPart">
                <div className="row noMarginBottom">
                    <div className='input-field col s12'>
                        <select name="id"
                            className="browser-default"
                            value={this.state.id}
                            onChange={this._onGoalSelection}
                            data-msg={ i18n.t('errors.required_field') }
                            required
                        >
                            <option value="" disabled>{ i18n.t('addGoal.chooseGoal') }</option>
                            {this.state.goals.map(goal => (
                                <option key={goal.id} value={goal.id}>{goal.name}</option>
                            ))}
                        </select>
                        <div>
                            <a className="info tooltipped" data-position="bottom" data-tooltip={ i18n.t('addGoal.goalInfo') }>{ i18n.t('addGoal.howDoesItWork') }</a>
                        </div>
                    </div>
                </div>
                <div className="row noMarginBottom">
                    <div className='input-field col s12'>
                        <input type="text"
                            name="comment"
                            value={this.state.comment}
                            onChange={this._onCommentChange}
                        />
                        <label htmlFor="lastName">{ i18n.t('formFields.goalComment') }</label>
                    </div>
                </div>
                <div className="row noMarginBottom">
                    <div className='col s12'>
                        {this.state.variables.length > 0 &&
                            <ul className="collapsible">
                                <li>
                                    <div className="collapsible-header">
                                        <span><strong>{ i18n.t('addGoal.variablesTitle') }</strong></span>
                                    </div>
                                    <div className="collapsible-body">
                                        {this.state.variables.map(variable => (
                                            <GoalRangeSlider key={variable.id} variable={variable} onChange={this._onSliderChange} />
                                        ))}
                                        <span className="variableName">{ i18n.t('addGoal.changeGoals') }</span>
                                    </div>
                                </li>
                            </ul>
                        }
                    </div>
                </div>
            </div>
        );
    }
}