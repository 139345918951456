import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

import { UserContext } from 'contexts.jsx';
import Dashboard from './dashboard';

export default (props) => (
    <Dashboard {...props} renderHeader={(dashboardProps) => <Header {...props} {...dashboardProps} />} />
);

const Header = ({nrOfActiveClients, maxNrOfClients, clientsStatus}) => {
    const { currentUser}  = useContext(UserContext);

    let noticeTranslationKey;
    switch (clientsStatus) {
        case 'notice':
            noticeTranslationKey = 'userDashboard.almostMaxedOutClientsNotice';
            break;
        case 'maxedOut':
            noticeTranslationKey = 'userDashboard.maxedOutClientsNotice';
            break;
        default:
            noticeTranslationKey = '';
    }

    return (
        <>
            <h1>{ currentUser ? currentUser.identityToken.organization_displayname : '' }</h1>
            <div className="extraInfo">
                {/* eslint-disable-next-line eqeqeq */}
                { nrOfActiveClients != '' ?
                    <span className="specTag" data-status={clientsStatus}>
                        {
                            nrOfActiveClients +
                            (maxNrOfClients > 0 ? ' / ' + maxNrOfClients :  '') +
                            ' ' + i18n.t('userDashboard.clients')
                        }
                        &nbsp;
                        {clientsStatus && clientsStatus !== 'good' &&
                            <>
                                <a className="info tooltipped" data-position="bottom" data-delay="50"
                                data-tooltip={ i18n.t(noticeTranslationKey) }>
                                    <i className="material-icons">info</i>
                                </a>
                                &nbsp;
                                <Link to="/profile/upgrade">
                                    { i18n.t('subscriptionPage.upgrade') }
                                </Link>
                            </>
                        }
                    </span>
                    : ''
                }
            </div>
        </>
    );
};