import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

class AddTrainingScheduleError extends React.Component {

    render() {
        return (
            <div id="addTrainingScheduleError" className="modal error">
                <div className="modal-content">
                    <h5><Trans>addTrainingScheduleError.title</Trans></h5>
                    <p>
                        <Trans>addTrainingScheduleError.description</Trans>
                        <br />
                        <Link to="/profile/upgrade" className="modal-close">
                            <Trans>addTrainingScheduleError.button</Trans>
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}

export default AddTrainingScheduleError;