import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

class AddItemError extends React.Component {

    render() {
        return (
            <div id="addItemError" className="modal error">
                <div className="modal-content">
                    <h5><Trans>addClient.itemErrorTitle</Trans></h5>
                    <p>
                        <Link to="/profile/upgrade" className="modal-close">
                            <Trans>addClient.itemErrorLink</Trans>
                        </Link>
                        &nbsp;<Trans>addClient.itemErrorRest</Trans>
                    </p>

                </div>
            </div>
        );
    }
}

export default AddItemError;