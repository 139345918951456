import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

class maxClientsError extends React.Component {

    render() {
        return (
            <div id="maxClientsError" className="modal error">
                <div className="modal-content">
                    <h5><Trans>addClient.maxClientsErrorTitle</Trans></h5>
                    <p>
                        <Link to="/profile/upgrade" className="modal-close">
                            <Trans>addClient.maxClientsErrorLink</Trans>
                        </Link>
                        &nbsp;<Trans>addClient.maxClientsErrorDescription</Trans>
                    </p>
                </div>
            </div>
        );
    }
}

export default maxClientsError;