import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts.jsx';
import { Trans } from 'react-i18next';
import UpgradeState from 'components/components/upgradeState'
import { hasLicenseExpired } from 'utils/licenseUtils';

export default function UpgradeFailed() {
    const { currentUser } = useContext(UserContext);
    return (
        <UpgradeState>
            <h4><Trans>subscriptionPage.accountPaymentFailedTitle</Trans></h4>
            <p><Trans>subscriptionPage.accountPaymentFailedText</Trans></p>
            <div>
                <Link to="/profile/upgrade" className="btn">
                    <Trans>subscriptionPage.upgradeAgain</Trans>
                </Link>
                { !hasLicenseExpired(currentUser) &&
                    <Link to="/dashboard" className="btn">
                        <Trans>generalActions.backToDashboard</Trans>
                    </Link>
                }
            </div>
        </UpgradeState>
    );
}