import React from 'react';
import history from 'history.js';
import i18n from 'i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import { Trans } from 'react-i18next';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import ListContainer from 'components/components/sortableList/listContainer.jsx';
import { SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';

class TrainingTemplateExercises extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            exercises: [],
            goalId: null,
            template: null,
            templateName: null,
            showLoading: 'hide',
            buttonDisabled: ''
        };

        this._cancel = this._cancel.bind(this);
        this._delete = this._delete.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this._deleteExercise = this._deleteExercise.bind(this);
        this._saveSchedule = this._saveSchedule.bind(this);
        this.setNameAndGoal = this.setNameAndGoal.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            exercises: nextProps.exercises,
            goalId: nextProps.goal,
            template: nextProps.template,
            templateName: nextProps.template ? nextProps.template.name : null
        }, function() {
            let exerciseArray = [];
            let  arrayLength = this.state.exercises.length;

            for (let i = 0; i < arrayLength; i++) {
                let exercise = this.state.exercises[i];
                exercise.supportsOneRepetitionMax = false;
                exerciseArray.push(exercise);
            }

            this.setState({
                exercises: exerciseArray
            });
        });
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    setNameAndGoal (templateName,goalId) {
        this.setState({
            templateName: templateName,
            goalId: goalId
        });
    }

    addExercise(exercise) {
        let exerciseArray = [];
        exerciseArray = this.state.exercises;

        exercise.supportsOneRepetitionMax = false;

        exerciseArray.push(exercise);
        this.setState({
            exercises: exerciseArray
        }, function() {
            Materialize.toast(i18n.t('trainingTemplate.exerciseAdded'), window.toastDuration);
        });
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState({
            exercises: arrayMove(this.state.exercises, oldIndex, newIndex),
        });
    }

    _deleteExercise(index) {
        let exerciseArray = [];
        exerciseArray = this.state.exercises;

        exerciseArray.splice(index, 1);

        this.setState({
            exercises: exerciseArray
        }, function() {
            Materialize.toast(i18n.t('trainingTemplate.exerciseDeleted'), window.toastDuration);
        });
    }

    _saveSchedule() {
        if(this.state.exercises.length > 0 && this.state.goalId != null) {

            this.setState({
                showLoading: '',
                buttonDisabled: 'disabled'
            });

            let templateExerciseIds = [];

            Object.keys(this.state.exercises).forEach(function(key,index) {
                let exercise = this.state.exercises[index];
                templateExerciseIds.push(exercise.id);
            }.bind(this));

            let trainingScheduleTemplate = {
                name: this.state.templateName,
                goalId: this.state.goalId,
                exercises: templateExerciseIds
            }

            let result;

            if (this.props.cms) {
                if (this.props.addTrainingTemplate) {
                    result = fmfApiClient.createTrainingTemplate(this.cancelSource.token, trainingScheduleTemplate);
                } else {
                    result = fmfApiClient.updateTrainingTemplate(this.cancelSource.token, this.state.template.id, trainingScheduleTemplate);
                }
            } else {
                result = fmfApiClient.updateCustomTrainingTemplate(this.cancelSource.token, this.state.template.id, trainingScheduleTemplate);
            }

            result.then(result => {
                Materialize.toast(i18n.t('trainingTemplate.saveSuccesful'), window.toastDuration);
                this._navigateToOverviewPage();
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                this.setState({
                    showLoading: 'hide',
                    buttonDisabled: ''
                });
            });
        } else {
            alert(i18n.t('trainingSchedule.addExercisesAndGoal'));
        }
    }

    _openMobileExercises(event) {
        event.preventDefault();
        let target = event.currentTarget;
        let sideContainer = $(target).parents('.greyContainer');
        sideContainer.addClass('open');
    }

    _closeMobileExercises(event) {
        event.preventDefault();
        let target = event.currentTarget;
        let sideContainer = $(target).parents('.greyContainer');
        sideContainer.removeClass('open');
    }

    _cancel(event) {
        event.preventDefault();
        if(window.confirm(i18n.t('trainingSchedule.cancelAdd'))){
            this._navigateToOverviewPage();
        }
    }

    _delete(event) {
        event.preventDefault();

        if(window.confirm(i18n.t('trainingSchedule.deleteConfirm'))){
            this.setState({
                showLoading: '',
                buttonDisabled: 'disabled'
            });

            const endpoint = this.props.cms ? 'deleteTrainingTemplate' : 'deleteCustomTrainingTemplate';
            fmfApiClient[endpoint](this.cancelSource.token, this.props.template.id)
                .then(result => {
                    Materialize.toast(i18n.t('trainingSchedule.deleteTrainingTemplateSuccesful'), window.toastDuration);
                    this._navigateToOverviewPage();
                }, error => {
                    this.setState({
                        showLoading: 'hide',
                        buttonDisabled: ''
                    });
                });
        }
    }

    _navigateToOverviewPage() {
        const overviewPath = this.props.cms ? '/cms/training-templates' : '/mijn-templates';
        history.push(overviewPath);
    }

    render() {
        const SortableList = SortableContainer(ListContainer, {withRef: true});

        return (
            <div className="scheduleExerciseContainer">
                <div className="row noMarginBottom">
                    <div className="col s12">
                        <div className="hide-on-small-only">
                            <h6 className="profileName">Template oefeningen</h6>
                        </div>
                        <div className="row noMarginBottom hide-on-med-and-up">
                            <div className="col s4">
                            </div>
                            <div className="col s8 right-align">
                                <a className="cursor menuLink view" onClick={this._openMobileExercises}>
                                    <Trans>trainingTemplate.viewExercises</Trans> <i className="material-icons">toc</i>
                                </a>
                                <a className="cursor menuLink close" onClick={this._closeMobileExercises}>
                                    <i className="material-icons">close</i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row noMarginBottom">
                    <div className="col s12">
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <div className="exerciseListContainer">
                            { this.state.exercises.length > 0 ?
                                <SortableList ref={(instance) => {this.SortableList = instance;}}
                                    onSetOneRepetitionMax={this._setOneRepetitionMax}
                                    onDeleteExercise={this._deleteExercise}
                                    items={this.state.exercises}
                                    onSortEnd={this.onSortEnd}
                                    lockAxis="y"
                                    helperClass="fmfSortable"
                                    useDragHandle={true} />
                                :
                                <ul className="exercisesList">
                                    <li>
                                        <div className="exerciseListItem empty">
                                            <div className="itemTitle">
                                                <Trans>trainingTemplate.addExerciseToStart</Trans>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            }
                            { this.state.exercises.length > 0 ?
                            <a className="btn" disabled={this.state.buttonDisabled} onClick={this._saveSchedule}>
                                <Trans>generalActions.save</Trans>
                            </a>
                            : null }
                            <a disabled={this.state.buttonDisabled} onClick={this._cancel} className="btn grey">
                                <Trans>generalActions.cancel</Trans>
                            </a>
                            <br />
                            <br />
                            { this.state.exercises.length > 0 ?
                            <a disabled={this.state.buttonDisabled} onClick={this._delete} className="btn outline alert">
                                <Trans>generalActions.delete</Trans>
                            </a>
                            : null }
                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}>
                                <CircleLoader/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TrainingTemplateExercises;