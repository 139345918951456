import React from 'react';
import { Trans } from 'react-i18next';
import ChangeLicense from 'components/components/changeLicense';

export default function UpgradePage() {
    return (
        <ChangeLicense
            title={<Trans>subscriptionPage.upgradeTitle</Trans>}
            subtitle={<Trans>subscriptionPage.upgradeSubtitle</Trans>}
        />
    )
}