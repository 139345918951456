import axios from 'axios';

const settings = {};
const loadSettings = () => {
    return Promise.all([
        axios.get('/settings.json').then(response => {
            if (!response.data || response.data._type !== 'settings') {
                return Promise.reject(new Error('Response didn\'t have `_type` property set to `settings`.'));
            }
            return response.data;
        }),
        axios.get('/settings.' + process.env.NODE_ENV + '.json').then(response => {
            if (!response.data || response.data._type !== 'settings') {
                // Environment settings file is optional, so swallow invalid responses.
                return;
            }
            return response.data;
        }).catch(error => {
            if (error.response && error.response.status === 404) {
                // File is optional, so swallow 404 status.
                return;
            }
            return Promise.reject(error);
        })
    ]).then(values => {
        const [mainSettings, envSettings] = values;
        Object.assign(settings, mainSettings);
        Object.assign(settings, envSettings);
    });
}

export default settings;
export { loadSettings };