
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import debugKey from './debugKey';
import getAuthenticatedUserTags from './getAuthenticatedUserTags';
import logToConsoleInDebugTelemetryProcessor from './logToConsoleInDebugTelemetryProcessor';

// Based on: https://github.com/Azure-Samples/application-insights-react-demo/blob/94739018fe95333449ed62dd5bd4f326925c77da/src/TelemetryService.js
// Might not be necessary anymore in the feature, depending on the answers to this issue: https://github.com/microsoft/ApplicationInsights-JS/issues/1045
class TelemetryService {
    constructor() {
        this.reactPlugin = new ReactPlugin();
    }

    initialize(instrumentationKey, history) {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey || debugKey,

                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: { history: history }
                }
            }
        });
        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer(e => e.tags.push(getAuthenticatedUserTags()));
        appInsights.addTelemetryInitializer(logToConsoleInDebugTelemetryProcessor);

        // Log unhandled promise rejections in AppInsights.
        // See: https://github.com/microsoft/ApplicationInsights-JS/issues/1013
        window.addEventListener("unhandledrejection", event => {
            appInsights.trackException({exception: event.reason});
        });

        this.appInsights = appInsights;
    }
}

export const ai = new TelemetryService();