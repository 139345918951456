import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import { LoaderLayout } from 'components/layouts/loaderLayout.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';

export default function Activate({email, token}) {
    const [redirectTo, setRedirectTo] = useState(null);

    useEffect(() => {
        const cancelSource = fmfApiClient.createCancelSource();
        fmfApiClient.confirmEmail(cancelSource.token, email, token)
            .then(result => {
                setRedirectTo('/registreer/voltooid');
            }, error => {
                setRedirectTo('/registreer/mislukt');
            });
        return () => cancelSource.cancel();
    }, [email, token]);

    return (
        redirectTo === null ?
            <LoaderLayout /> :
            <Redirect push to={redirectTo} />
    );
}