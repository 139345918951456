import React from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts.jsx';
import history from 'history.js';
import i18n from 'i18next';
import SidenavContainer from 'components/components/sidenav/sidenav.jsx';
import ChangePasswordModal from 'components/forms/modals/changePassword.jsx';
import { ThemeContext } from 'contexts';

export default class Navbar extends React.Component {
    constructor(props) {
        super(props);

        //IS THE SIDENAV ALREADY INITIALIZED
        this.state = {
            sideNavInitiated: false,
            searchTerm: new URLSearchParams(history.location.search).get('q') || ''
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);
        this._triggerSearch = this._triggerSearch.bind(this);
    }

    handleClick() {
        //HANDLE CLICK TO OPEN SIDEMENU ON TOUCH DEVICES
        let searchContainer = $('.searchContainer');
        searchContainer.toggleClass('active');
        if (searchContainer.hasClass('active')) {
            setTimeout(function () {
                searchContainer.find('input').focus();
            }, 200);
        }
    }

    _isUserTypeClient(user) {
        const userType = user && user.identityToken && user.identityToken.user_type;
        return userType === 'Client';
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _triggerSearch (event) {
        // eslint-disable-next-line eqeqeq
        if(history.location.pathname === '/zoeken' && this.state.searchTerm.length > 2) {
            this._navigateSearch();
        }
    }

    _onSubmit (event) {
        event.preventDefault();
        this._navigateSearch();
    }

    _navigateSearch() {
        const search = new URLSearchParams();
        search.append('q', this.state.searchTerm);
        history.push({
            pathname: '/zoeken',
            search: search.toString()
        });
    }

    userItem() {
        const { currentUser } = this.context;

        return (
            <span className="userName">
            <img
                src={currentUser.profile.imageUrl}
                className="profilePic circle"/><span className="hide-on-med-and-down">{ currentUser.identityToken.fullname }</span></span>
        );
    }

    render() {
        const { transparent } = this.props;
        const { currentUser } = this.context;
        const isUserTypeClient = this._isUserTypeClient(currentUser);
        return (
            <div className={transparent?'transparent':''}>
                <SidenavContainer/>
                <nav className="navbar" role="navigation">
                    <div className="nav-wrapper container">
                        <Link id="logo-container" to="/dashboard" className="brand-logo">
                            <ThemeContext.Consumer>
                                {({logoUrl}) => (
                                    <img src={logoUrl} height="72" />
                                )}
                            </ThemeContext.Consumer>
                        </Link>
                        { !isUserTypeClient ?
                            <div className="searchContainer">
                                <form id="registerForm" onSubmit={this._onSubmit}>
                                    <div className="input-field">
                                        <i className="material-icons prefieldIcon">search</i>
                                        <input
                                            id="searchTerm" type="search"
                                            name="searchTerm"
                                            value={this.state.searchTerm}
                                            onChange={this._changeHandler}
                                            onKeyUp={this._triggerSearch}
                                            placeholder={ i18n.t('navbar.searchPlaceholder') } />
                                    </div>
                                </form>
                            </div> :
                            null }
                        <ul id="nav-mobile" className="right">
                            <li className="hide-on-large-only"><a onClick={this.handleClick}><i className="material-icons left">search</i></a></li>
                            <li>
                                <a className="button-collapse show-on-large" data-activates="slide-out">
                                    {
                                    currentUser ?
                                        this.userItem() :
                                        ''
                                    }</a>
                            </li>
                        </ul>
                    </div>
                </nav>
                <ChangePasswordModal />
            </div>
        )
    }
}

Navbar.contextType = UserContext