import React from 'react';
import Navbar from 'components/components/navbar/navbar.jsx';
import ProfileHeader from 'components/components/profile/profileHeader.jsx';
import ProfileMenu from 'components/components/profile/profileMenu.jsx';

export const ProfileLayout = ({children}) => (
    <div>
        <Navbar />
        <div id="page">
            <div className="pageheader noPaddingBottom">
                <ProfileHeader />
                <ProfileMenu />
            </div>
        </div>
        {children}
    </div>
);