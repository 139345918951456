import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';

export default class AccountEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayName: '',
            organizationName: '',
            postcode: '',
            houseNumber: '',
            street: '',
            city: '',
            country: 'Nederland',
            phoneNumber: '',
            email: '',
            organizationContactName: '',
            vatin: '',
            website: '',

            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'};

        this.picker = null;

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _setStartValues (result) {

        this.setState({
            displayName: result.displayName?result.displayName:'',
            organizationName: result.organizationName?result.organizationName:'',
            postcode: result.postcode?result.postcode:'',
            houseNumber: result.houseNumber?result.houseNumber:'',
            street: result.street?result.street:'',
            city: result.city?result.city:'',
            phoneNumber: result.phoneNumber?result.phoneNumber:'',
            email: result.email?result.email:'',
            organizationContactName: result.organizationContactName?result.organizationContactName:'',
            vatin: result.vatin?result.vatin:'',
            website: result.website?result.website:''
        });
        Materialize.updateTextFields();
    }

    componentDidMount() {
        fmfApiClient.getOrganization(this.cancelSource.token)
            .then((result, error) => {
                if(error) {
                    Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                } else {
                    this._setStartValues(result);
                }
            });

        $("#accountForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
    }

    componentWillUnmount(){
        this.cancelSource.cancel();
        this.unmounted = true;
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        // eslint-disable-next-line eqeqeq
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            username: $('#username')[0].value,
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        let account = {
            displayName: this.state.displayName,
            organizationName: this.state.organizationName,
            postcode: this.state.postcode,
            houseNumber: this.state.houseNumber,
            street: this.state.street,
            country: this.state.country,
            city: this.state.city,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            organizationContactName: this.state.organizationContactName,
            vatin: this.state.vatin,
            website: this.state.website
        };

        Object.keys(account).forEach(function(key,index) {
            if(account[key] === '') {
                account[key] = null;
            }
        });

        fmfApiClient.updateOrganization(account)
            .then(result => {
                if (this.unmounted) return;
                this.setState({
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: ''
                });

                Materialize.toast(i18n.t('changeAccount.successText'), window.toastDuration);
            }, error => {
                if (this.unmounted) return;
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    render() {
        return (
            <div className="editContainer">
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col s12 m9 l7">
                            <form id="accountForm" noValidate onSubmit={this._submitForm}>
                                <div className="row noMarginBottom">
                                    <div className="col s12">
                                        <h4 className="smallGreyTitle"><Trans>changeAccount.modalTitle</Trans></h4>
                                        <br />
                                        {/* ERROR MESSAGE */}
                                        <div className={this.state.showError}>
                                            <div id="card-alert" className="card red">
                                                <div className="card-content white-text">
                                                    <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="organizationName" type="text"
                                            name="organizationName"
                                            value={this.state.organizationName}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                        />
                                        <label htmlFor="organizationName" >{ i18n.t('formFields.company') }</label>
                                    </div>
                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="organizationContactName" type="text"
                                            name="organizationContactName"
                                            value={this.state.organizationContactName}
                                            onChange={this._changeHandler}
                                        />
                                        <label htmlFor="organizationContactName" >{ i18n.t('formFields.organizationContactName') }</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="postcode" type="text"
                                            name="postcode"
                                            value={this.state.postcode}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                        />
                                        <label htmlFor="postcode" >{ i18n.t('formFields.postcode') }</label>
                                    </div>

                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="houseNumber" type="text"
                                            name="houseNumber"
                                            value={this.state.houseNumber}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                        />
                                        <label htmlFor="houseNumber" >{ i18n.t('formFields.houseNumber') }</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="street" type="text"
                                            name="street"
                                            value={this.state.street}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                        />
                                        <label htmlFor="street" >{ i18n.t('formFields.street') }</label>
                                    </div>

                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="city" type="text"
                                            name="city"
                                            value={this.state.city}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                        />
                                        <label htmlFor="city" >{ i18n.t('formFields.city') }</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="phoneNumber" type="text"
                                            name="phoneNumber"
                                            value={this.state.phoneNumber}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                        />
                                        <label htmlFor="phoneNumber" >{ i18n.t('formFields.phoneNumber') }</label>
                                    </div>

                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="email" type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                            className="validate"
                                        />
                                        <label htmlFor="email" >{ i18n.t('formFields.organizationEmail') }</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="vatin" type="text"
                                            name="vatin"
                                            value={this.state.vatin}
                                            onChange={this._changeHandler}
                                        />
                                        <label htmlFor="vatin" >{ i18n.t('formFields.vatin') }</label>
                                    </div>

                                    <div className='input-field col s12 m6'>
                                        <input
                                            id="website" type="text"
                                            name="website"
                                            value={this.state.website}
                                            onChange={this._changeHandler}
                                        />
                                        <label htmlFor="website" >{ i18n.t('formFields.website') }</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 right-align">
                                        <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>generalActions.save</Trans></button>
                                        <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                            <CircleLoader/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


AccountEdit.contextType = UserContext
