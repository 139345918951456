import PropTypes from 'prop-types';
import React from 'react';

class PageItem extends React.Component {

    constructor(props) {
        super(props);

        this._setCurrentPage = this._setCurrentPage.bind(this);
    }

    _setCurrentPage() {
        if (typeof this.props.onPageChange === 'function') {
            this.props.onPageChange(this.props.pageNr);
        }
    }

    render() {
        return (
            <li className={this.props.isActive?"active":"waves-effect"}>
                <a onClick={this._setCurrentPage} className="cursor">{ this.props.pageNr + 1 }</a>
            </li>
        )
    }
}

export default PageItem;

PageItem.propTypes = {
    pageNr          : PropTypes.number.isRequired,
    onPageChange    : PropTypes.func.isRequired,
    isActive        : PropTypes.bool.isRequired
};