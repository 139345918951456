import React from 'react';
import i18n from 'i18next';
import AvatarEditor from 'react-avatar-editor';
import fmfApiClient from 'utils/fmf-api-client.js';
import { ai } from 'utils/appInsights';

class imageCropper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preview: null,
            image: "",
            scale: 1,
            showCropper: 'image',
            rotation: 0,
            fileName: '',
            fileType: ''
        };

        this.handleFileSelect = this.handleFileSelect.bind(this);
        this.handleScale = this.handleScale.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSetCrop = this.handleSetCrop.bind(this);
        this.setEditorRef = this.setEditorRef.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
        this.unmounted = true;
    }

    handleScale(e) {
        const scale = parseFloat(e.target.value);
        this.setState({ scale });
    }

    getRotation(exifOrientation){
        switch(exifOrientation){
            case 3:
                // 180° rotate left
                return 180;
            case 5:
                // vertical flip + 90 rotate right
                return 90;
            case 6:
                // 90° rotate right
                return 90;
            case 7:
                // horizontal flip + 90 rotate right
                return 270;
            case 8:
                // 90° rotate left
                return 270;
            default:
                return 0;
        }
    }

    base64ToArrayBuffer(base64) {
        // REASON: Need to test if we fix this.
        // eslint-disable-next-line no-useless-escape
        base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '');
        let binaryString = atob(base64);
        let len = binaryString.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    getExifOrientation(arrayBuffer){
        let view = new DataView(arrayBuffer);
        // eslint-disable-next-line eqeqeq
        if (view.getUint16(0, false) != 0xFFD8) return -2;
        let length = view.byteLength, offset = 2;
        while (offset < length) {
            let marker = view.getUint16(offset, false);
            offset += 2;
            // eslint-disable-next-line eqeqeq
            if (marker == 0xFFE1) {
                // eslint-disable-next-line eqeqeq
                if (view.getUint32(offset += 2, false) != 0x45786966) return -1;
                // eslint-disable-next-line eqeqeq
                let little = view.getUint16(offset += 6, false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                let tags = view.getUint16(offset, little);
                offset += 2;
                for (let i = 0; i < tags; i++)
                // eslint-disable-next-line eqeqeq
                if (view.getUint16(offset + (i * 12), little) == 0x0112)
                    return view.getUint16(offset + (i * 12) + 8, little);
            }
            // eslint-disable-next-line eqeqeq
            else if ((marker & 0xFF00) != 0xFF00) break;
            else offset += view.getUint16(offset, false);
        }
        return -1;
    }

    handleFileSelect(event) {
        let fileInput = event.target;
        if (fileInput.files && fileInput.files[0]) {
            const file = fileInput.files[0];
            let reader = new FileReader();

            reader.onload = (e) => {
                let buffer = this.base64ToArrayBuffer(e.target.result);
                let exifOrientation = this.getExifOrientation(buffer);
                let rotation = this.getRotation(exifOrientation);

                this.setState({
                    image: e.target.result,
                    rotation: rotation,
                    showCropper: 'cropper',
                    fileName: file.name,
                    fileType: file.type
                }, () => {
                    // Reject file if it is not supported by canvas
                    // Check based on: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toDataURL
                    const { fileType } = this.state;
                    if (fileType !== 'image/png') {
                        const dataUrl = this.editor.canvas.toDataURL(fileType);
                        if (dataUrl.startsWith('data:image/png')) {
                            this.setState({
                                image: '',
                                showCropper: 'image'
                            });
                        }
                    }
                });
            }

            reader.readAsDataURL(file);
        }
    }

    setEditorRef(editor) {
        if (editor) this.editor = editor
    }

    getCanvasBlob(canvas, mimeType, qualityArgument) {
        return new Promise(resolve => {
            canvas.toBlob(blob => resolve(blob), mimeType, qualityArgument);
        })
    }

    handleSetCrop() {
        const { fileName, fileType } = this.state;
        const imageScaledToCanvas = this.editor.getImageScaledToCanvas();
        const imageDataURL = imageScaledToCanvas.toDataURL(fileType);
        this.getCanvasBlob(imageScaledToCanvas, fileType).then(fileData => {
            Promise.resolve(this.props.onSave(this.cancelSource.token, fileName, fileData))
                .then(result => {
                    if (this.unmounted) return;
                    this.setState({
                        preview: imageDataURL,
                        showCropper: 'image'
                    });

                    if (typeof this.props.onChange === 'function') {
                        this.props.onChange(result.url);
                    }
                    $(".fileInputAvatar").val("");
                }).catch(error => {
                    ai.appInsights.trackException({exception: error});
                    Materialize.toast('De afbeelding kon niet worden opgeslagen probeer het later nogmaals.', window.toastDuration);
                });
        });
    }

    handleCancel() {
        this.setState({
            image: '',
            showCropper: 'image',
            preview: null
        });
        $(".fileInputAvatar").val("");
    }

    render() {
        let {preview} = this.props;

        return (
            <div className={"fmfCropper "+this.state.showCropper}>

                <div className="cropperContainer">
                    {/*
                    Current version of `react-avatar-editor` (11.0.7) still calls `componentWillReceiveProps` internally.
                    This has already been fixed, but until an update has been released we will still get that warning in dev mode.
                    */}
                    <AvatarEditor
                        ref={this.setEditorRef}
                        image={this.state.image}
                        width={this.props.width || 300}
                        height={this.props.height || 300}
                        border={this.props.border !== undefined ? this.props.border : 50}
                        color={[220, 228, 66, 0.8]} // RGBA
                        scale={this.state.scale}
                        rotate={this.state.rotation}
                    />
                    <input
                        name="scale"
                        type="range"
                        onChange={this.handleScale}
                        min={this.props.scaleMin || 1}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                    />
                    <button onClick={this.handleCancel} className="waves-effect waves-light btn small grey noShadow" type="button">
                        {i18n.t('generalActions.close')}
                    </button>
                    <button onClick={this.handleSetCrop} className="waves-effect waves-light btn small pull-right noShadow accept" type="button">
                        {this.props.saveText || i18n.t('generalActions.save')}
                    </button>
                </div>

                <div className="imageContainer">
                    <img src={this.state.preview ? this.state.preview : preview} />
                    {
                        !this.props.controlPlusLink ?
                        <div className="file-field input-field">
                            <div className="waves-effect waves-light btn small noShadow">
                                <span>{i18n.t('generalActions.change')}</span>
                                <input type="file" className="fileInputAvatar" onChange={this.handleFileSelect} />
                            </div>
                            <div className="file-path-wrapper">
                                <input className="file-path validate" type="hidden" />
                            </div>
                        </div> :
                        null
                    }
                </div>
            </div>
        );
    }
}

export default imageCropper;