import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import ResendActivationModal from 'components/forms/modals/resendActivation.jsx';
import ForgotPasswordModal from 'components/forms/modals/forgotPassword.jsx';
import authService from 'utils/auth-service.js';

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {username: '', password: '', errorMessage: '', showError: 'hide', showResendActivation: 'hide', buttonDisabled: '', showLoading: 'hide'};

        this._onSubmit = this._onSubmit.bind(this)
        this._changeUsername = this._changeUsername.bind(this)
        this._changePassword = this._changePassword.bind(this)
        this._forgotPassword = this._forgotPassword.bind(this)
        this._resendActivation = this._resendActivation.bind(this)
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    _changeUsername (event) {
        this.setState({
            username: event.target.value,
        });
    }

    _changePassword (event) {
        this.setState({
            password: event.target.value,
        });
    }

    _onSubmit (event) {
        event.preventDefault();

        if (!this.state.username || !this.state.password) {
            return;
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        authService.loginWithUserCredentials(this.state.username, this.state.password)
            .catch(error => {
                if (this.unmounted) return;

                let errorMessage = i18n.t('errors.' + (
                    error.error === 'client_inactive' ?
                    'account_client_inactive' :
                    error.error.toString()
                ));

                if (!errorMessage) {
                    errorMessage = i18n.t('errors.general_error');
                }

                this.setState({
                    password: '',
                    errorMessage: errorMessage,
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
                if(error.error === 'account_unconfirmed') {
                    this.setState({
                        showResendActivation: ''
                    });
                }
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    _forgotPassword() {
        $('#forgotPassword').modal({
            ready: function (modal, trigger) { // Callback for Modal open. Modal and trigger parameters available.
                $('#resendActivation input#username').focus();
                this._raModal.changeUsername(this.state.username);
                this._fpModal.changeUsername(this.state.username);
            }.bind(this)
        });
        $('#forgotPassword').modal('open');
    }

    _resendActivation() {
        $('#resendActivation').modal();
        $('#resendActivation').modal('open');
    }

    render() {
        return (
            <div id="loginFormContainer">
                <form id="loginForm" onSubmit={this._onSubmit}>
                    <div className="container loginForm">
                        <div className="row">
                            <div className={this.state.showError}>
                                <div className='input-field col s10 offset-s1 m8 offset-m2'>
                                    <div id="card-alert" className="card red">
                                        <div className="card-content white-text">
                                            <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                            <p className={this.state.showResendActivation}><br /><a className="cursor" onClick={this._resendActivation}><Trans>login.resendActivationTrigger</Trans></a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-field col s10 offset-s1 m8 offset-m2">
                                <input
                                    id="email" type="email"
                                    value={this.state.username}
                                    onChange={this._changeUsername}
                                    className="validate" />
                                <label htmlFor="email" data-error={ i18n.t('errors.no_valid_email') }>{ i18n.t('formFields.email') }</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s10 offset-s1 m8 offset-m2">
                                <input id="password" type="password"
                                       onChange={this._changePassword}
                                       value={this.state.password}
                                       className="validate" />
                                <label htmlFor="password">{ i18n.t('formFields.password') }</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s10 offset-s1 m5 offset-m2">
                                <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit">{ i18n.t('login.button') }</button>
                                <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                    <CircleLoader/>
                                </div>

                                <p>
                                    <Trans components={[<Link to='/registreer' />]}>login.noAccount</Trans>
                                </p>
                            </div>
                            <div className="input-field col s10 offset-s1 m3 right-align hide-on-small-only">
                                <a onClick={this._forgotPassword} href="#">{ i18n.t('forgotPassword.button') }</a>
                            </div>
                            <div className="input-field col s10 offset-s1 m3 hide-on-med-and-up">
                                <a onClick={this._forgotPassword} href="#">{ i18n.t('forgotPassword.button') }</a>
                            </div>
                        </div>
                    </div>
                </form>
                <ForgotPasswordModal ref={(fpModal) => { this._fpModal = fpModal; }} email={this.state.username} />
                <ResendActivationModal ref={(raModal) => { this._raModal = raModal; }} email={this.state.username} />
            </div>
        );
    }
}

export default LoginForm;