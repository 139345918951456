import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import moment from 'moment';
import accounting from 'accounting';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';
import history from 'history.js';
import { ThemeContext } from 'contexts';

class invoice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            invoice: null,
            invoiceNumber: props.invoiceNumber
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        fmfApiClient.getInvoiceByInvoiceNumber(this.cancelSource.token, this.state.invoiceNumber)
            .then(result => {
                this.setState({
                    invoice: result
                });
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                history.push('/profile/abonnement');
            });

        // Removes default print header and footer for Firefox.
        // See: https://stackoverflow.com/a/28604550
        // Other browsers can accompish this with css.
        let htmlTag = document.getElementsByTagName('html')[0];
        htmlTag.setAttribute("moznomarginboxes", "");
        htmlTag.setAttribute("mozdisallowselectionprint", "");
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    renderInvoice() {
        const { logoUrl } = this.context;
        let invoiceDate = moment(this.state.invoice.invoiceDate).format('D-M-YYYY');
        return (
            <div>
                <div className="invoice-box">
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                        <tr className="top">
                            <td colSpan="2">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="title">
                                            <img src={logoUrl} width="300" height="72" />
                                        </td>

                                        <td>
                                            <Trans>invoice.invoiceNumber</Trans>: {this.state.invoice.invoiceNumber}<br />
                                            <Trans>invoice.invoiceDate</Trans>: {invoiceDate}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr className="information">
                            <td colSpan="2">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td>
                                            {this.state.invoice.customerName?<span>{this.state.invoice.customerName}<br /></span>:null}
                                            {this.state.invoice.customerContactName?<span><Trans>invoice.to</Trans> {this.state.invoice.customerContactName}<br /></span>:null}
                                            {this.state.invoice.customerStreet && this.state.invoice.customerHouseNumber ?<span>{this.state.invoice.customerStreet} {this.state.invoice.customerHouseNumber}<br /></span>:null}
                                            {this.state.invoice.customerPostCode && this.state.invoice.customerCity ?<span>{this.state.invoice.customerPostCode} {this.state.invoice.customerCity}<br /></span>:null}
                                        </td>

                                        <td>
                                            <Trans>invoice.FmFAddress</Trans>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>

                        <tr className="heading">
                            <td>
                                <Trans>invoice.Item</Trans>
                            </td>

                            <td>
                                <Trans>invoice.price</Trans>
                            </td>
                        </tr>

                        <tr className="item">
                            <td>
                                {this.state.invoice.payment.description}
                            </td>

                            <td>
                                {accounting.formatMoney(this.state.invoice.amountExclVat)}
                            </td>
                        </tr>

                        <tr className="item ">
                            <td>
                            </td>

                            <td>
                                <Trans>invoice.vat</Trans> ({ this.state.invoice.vatPercentage }%): {accounting.formatMoney(this.state.invoice.amountVat)}
                            </td>
                        </tr>

                        <tr className="total">
                            <td></td>

                            <td>
                                <strong><Trans>invoice.total</Trans></strong>: {accounting.formatMoney(this.state.invoice.amountInclVat)}
                            </td>
                        </tr>
                        <tr className="heading">
                            <td>
                                <Trans>invoice.paymentMethod</Trans>
                            </td>

                            <td>
                            </td>
                        </tr>

                        <tr className="details">
                            <td colSpan="2">
                                iDeal maandelijkse automatische incasso
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="center-align noprint">
                    <br />
                    <Link to="/profile/abonnement" className="btn"><Trans>generalActions.backToOverview</Trans></Link>
                    <a onClick={window.print} className="btn"><Trans>generalActions.print</Trans></a>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="invoiceContainer">
                {this.state.invoice != null? this.renderInvoice():<FPLoader fixed="fixed" />}

            </div>
        );
    }
}
invoice.contextType = ThemeContext;

export default invoice;