import React from 'react';
import { Trans } from 'react-i18next';

class linkedClientsError extends React.Component {
    render() {
        return (
            <div id="linkedClientsError" className="modal error">
                <div className="modal-content">
                    <h5><Trans>addClient.linkedClientsErrorTitle</Trans></h5>
                    <p><Trans>addClient.linkedClientsErrorDescription</Trans></p>
                </div>
            </div>
        );
    }
}

export default linkedClientsError;