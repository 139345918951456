import React from 'react';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import { ThemeContext } from 'contexts';

class FPLoader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fixed: this.props.fixed
        };
    }

    render() {
        const { logoUrl } = this.context;
        return (
            <div className="loaderContainer" data-fixed={this.state.fixed}>
                <div className="loader">
                    <CircleLoader/>
                    <img src={logoUrl} className="loaderLogo" />
                </div>
            </div>
        );
    }
}
FPLoader.contextType = ThemeContext;

export default FPLoader;