import React from 'react';
import i18n from 'i18next';

import { UserContext } from 'contexts';
import history from 'history.js';
import CustomerCard from 'components/components/customerCard/customercard.jsx';
import AddClientModal from 'components/forms/modals/addClient.jsx';
import MaxClientsErrorModal from 'components/forms/modals/maxClientsError.jsx';
import LinkedClientsErrorModal from 'components/forms/modals/linkedClientsError.jsx';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import Pagination from 'components/components/pagination/pagination.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';
import image from 'utils/image.js';

let timeout = null;

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            showLoader: true,
            nrOfActiveClients: '',
            maxNrOfClients: '',
            clientsStatus: '',
            nrOfPages: 0,
            currentPage: 0,
            pageSize: 15
        };

        this._addClient = this._addClient.bind(this);
        this._setCurrentPage = this._setCurrentPage.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidUpdate(prevProps) {
        if(this.state.nrOfActiveClients !== '' && this.state.maxNrOfClients !== '') {
            let clientsStatus = 'good';

            // eslint-disable-next-line eqeqeq
            if(this.state.maxNrOfClients != 0) {
                if(this.state.nrOfActiveClients >= this.state.maxNrOfClients) {
                   clientsStatus = 'maxedOut';
                } else if (this.state.nrOfActiveClients >= (this.state.maxNrOfClients-4)) {
                   clientsStatus = 'notice';
                }
            }

            // eslint-disable-next-line eqeqeq
            if(clientsStatus != this.state.clientsStatus) {
                this.setState({
                    clientsStatus: clientsStatus
                }, () => $('.specTag .tooltipped').tooltip({delay: 50}));
            }

        }

        // eslint-disable-next-line eqeqeq
        if(this.props.searchTerm != prevProps.searchTerm) {
            clearTimeout(timeout);
            timeout = setTimeout(() => this._getCustomers(), 500);
        }
    }

    componentDidMount() {
        this._getCustomers();

        fmfApiClient.countClients(this.cancelSource.token, true)
            .then(result => {
                this.setState({
                    nrOfActiveClients: result
                });
            });

        fmfApiClient.getOrganizationLicense(this.cancelSource.token)
            .then(result => {
                // eslint-disable-next-line eqeqeq
                if(result.maxClients != undefined) {
                    this.setState({
                        maxNrOfClients: result.maxClients
                    });
                } else {
                    this.setState({
                        maxNrOfClients: 0
                    });
                }
            });

        $('.dropdown-button').dropdown({
                inDuration: 300,
                outDuration: 225,
                constrain_width: false,
                gutter: 0, // Spacing from edge
                belowOrigin: true, // Displays dropdown below the button
                alignment: 'left' // Displays dropdown with edge aligned to the left of button
            }
        );

        $('select').material_select();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _setCurrentPage(newPageNr) {
        this.setState({
            currentPage: newPageNr
        },function() {
            this._getCustomers();
        }.bind(this));
    }

    _getCustomers() {
        if (!this.props.searchTerm) history.push('/dashboard');
        fmfApiClient.getClients(this.cancelSource.token, this.props.searchTerm, this.state.currentPage, this.state.pageSize, image.resize(160, 160))
            .then(res => {
                this.setState({
                    clients: res.items,
                    nrOfPages: res.totalPages,
                    currentPage: res.page,
                    showLoader: false
                });
            });
    }

    _addClient() {
        if(this._isLinked()) {
            $('#linkedClientsError').modal();
            $('#linkedClientsError').modal('open');
        // eslint-disable-next-line eqeqeq
        } else if(this.state.clientsStatus == 'maxedOut' ) {
            $('#maxClientsError').modal();
            $('#maxClientsError').modal('open');
        // eslint-disable-next-line eqeqeq
        } else if (this.state.clientsStatus != '' && this.state.clientsStatus != 'maxedOut' ) {
            $('#addClient').modal();
            $('#addClient').modal('open');
        } else {
            Materialize.toast(i18n.t('addClient.notPossibleYet'), window.toastDuration);
        }
    }

    _isLinked() {
        return this.context.currentUser.identityToken.hasOwnProperty('control_plus_link');
    }

    render() {
        return (
            <div id="page">
                <div className="pageheader">
                    <div className="container">
                        <div className="row">
                            <div className="col s12">
                                {this.props.renderHeader(this.state)}
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.showLoader ? <FPLoader /> :
                    <div className="container">
                        <div className="customers">
                            <div className="actions">
                                <a onClick={this._addClient} className="btn-floating btn-large waves-effect waves-light">
                                    <i className="material-icons">add</i>
                                </a>
                            </div>
                            <div className="row">
                                { this.state.clients.map((client) => (
                                        <div className="col s12 m6 l3" key={'client' + client.id}>
                                            <CustomerCard client={client} />
                                        </div>
                                    )) }
                                <div className="col s12 m6 l3">
                                    <a onClick={this._addClient} className="addLink">
                                        <span className="btn-floating btn-large"><i className="material-icons">add</i></span>
                                        <span className="caption">Maak een nieuwe cliënt aan</span>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12 center-align">
                                    <Pagination pages={this.state.nrOfPages}
                                        currentPage={this.state.currentPage}
                                        onPageChange={this._setCurrentPage}/>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <AddClientModal />
                <MaxClientsErrorModal />
                <LinkedClientsErrorModal />
            </div>
        )
    }
}

Dashboard.contextType = UserContext