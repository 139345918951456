import React from 'react';
import { Link } from 'react-router-dom';
import fmfApiClient from 'utils/fmf-api-client.js';
import { Trans } from 'react-i18next';
import moment from 'moment';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import image from 'utils/image.js';
import ExerciseDetailCard from 'components/components/trainingSchedule/exerciseDetailCardPrint.jsx';
import { ThemeContext } from 'contexts';

class trainingSchedulePrint extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            trainingSchedule: null,
            currentClient: null
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillMount() {
        this.getClient();
        this.getTrainingSchedule();
    }

    componentDidMount() {
        // Removes default print header and footer for Firefox.
        // See: https://stackoverflow.com/a/28604550
        // Other browsers can accompish this with css.
        let htmlTag = document.getElementsByTagName('html')[0];
        htmlTag.setAttribute("moznomarginboxes", "");
        htmlTag.setAttribute("mozdisallowselectionprint", "");
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    getClient() {
        fmfApiClient.getClient(this.cancelSource.token, this.props.clientId, image.resize(160, 160))
            .then(result => {
                this.setState({
                    currentClient: result
                });
            });
    }

    getTrainingSchedule() {
        fmfApiClient.getClientTrainingSchedule(this.cancelSource.token, this.props.clientId, this.props.trainingScheduleId)
            .then(result => {
                this.setState({
                    trainingSchedule: result
                });
            });
    }

    getTrainingScheduleDates() {
        let startDate = moment(this.state.trainingSchedule.startDate).format('D MMMM \'YY');
        let endDate = moment(startDate, 'D MMMM \'YY');
        endDate = endDate.add(this.state.trainingSchedule.weeks, 'weeks').format('D MMMM \'YY');

        return startDate + ' - ' + endDate ;
    }

    renderSchedule() {
        const { logoUrl } = this.context;
        return (
            <div>
                <div className="center-align noprint">
                    <br />
                    <Link className="btn" to={'/clienten/'+this.state.currentClient.id+'/trainingsschema/'+this.state.trainingSchedule.id}>
                        <Trans>generalActions.backToOverview</Trans>
                    </Link>
                    <a onClick={window.print} className="btn">
                        <Trans>generalActions.print</Trans>
                    </a>
                    <br />
                    <br />
                </div>
                <div className="printPage-box">
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                        <tr className="top">
                            <td colSpan="2">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="title">
                                            <img src={logoUrl} width="300" height="72" />
                                            <div className="row noMarginBottom">
                                                <div className="col s2">
                                                    <img className="profilePic" src={this.state.currentClient.imageUrl}/>
                                                </div>
                                                <div className="col s10">
                                                    <h6><b>{this.state.currentClient.name}</b></h6>
                                                    {this.state.trainingSchedule.goal?<div className="userInfo variableName">{this.state.trainingSchedule.goal.name}</div>:null}
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="row noMarginBottom">
                                                <div className="col s12">
                                                    <h4><Trans>trainingSchedule.trainingschedule</Trans><br /> {this.state.trainingSchedule.weeks} <Trans>formFields.weeks</Trans></h4>
                                                    <div className="userInfo variableName">{ this.getTrainingScheduleDates() }</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                { this.state.trainingSchedule.scheduleExercises.map((exercise) => (
                                    <ExerciseDetailCard key={exercise.id} exercise={exercise} trainingSchedule={this.state.trainingSchedule} currentClient={this.state.currentClient} onShowExercise={this._showExerciseInfo} />
                                )) }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        )
    }

    render() {
        return (
            <div className="printPageContainer">
                {this.state.trainingSchedule != null && this.state.currentClient != null ? this.renderSchedule():<FPLoader fixed="fixed" />}

            </div>

        )
    }
}
trainingSchedulePrint.contextType = ThemeContext;

export default trainingSchedulePrint;