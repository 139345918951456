import React from 'react';
import { Trans } from 'react-i18next';

class ExerciseDetailCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oneRepetitionMax: 0,
            exercise: null,
            variables: null
        };
    }

    _getExerciseTags() {
        let exerciseTags = [];
        exerciseTags = exerciseTags.concat(this.props.exercise.exercise.equipment, this.props.exercise.exercise.regions);

        return exerciseTags.join(' | ');
    }

    UNSAFE_componentWillMount(){
        // eslint-disable-next-line eqeqeq
        if(this.state.exercise == null) {
            this.setState({
                exercise: this.props.exercise,
                variables: this.props.exercise.variables,
                formVariables: this.props.exercise.variables
            });
        }
    }

    _getExerciseSliderImage(images) {
        let currentGender = this.props.currentClient.gender;
        let backgroundImage = null;

        if(images.length > 0){
            backgroundImage = images[0].url;
            for(let i = 0; i < images.length; i ++) {
                let imageObject = images[i];
                // eslint-disable-next-line eqeqeq
                if(imageObject.gender == currentGender) {
                    backgroundImage = imageObject.url;
                }
            }
        }

        return backgroundImage;
    }


    _fillWeeks() {
        let emptyWeeks = [];
        for (let i=this.state.exercise.variables[0].weekValues.length; i < 10; i++) {
            emptyWeeks.push(<div className="col s1" key={"emptyValue"+i}><div className="tableValue empty"></div></div>);
        }
        return emptyWeeks;
    }

    _renderVariablesTable(variables) {
        return (
            <div className="row exerciseDataTable">
                { variables.map((variable, index) => (
                    <div className="col s12" key={'variable-'+index}>
                        <div className="row collapsed" data-variable="sets">
                            <div className="col s2">
                                <div className="tableValue variableName dataLeft">
                                    { variable.name }
                                </div>
                            </div>
                            { variable.weekValues.map((value, valueIndex) => (
                                <div className="col s1" data-week={value.week} key={'variableValue-'+index+'-'+valueIndex}>
                                    <div className="tableValue">
                                        { value.value }
                                    </div>
                                </div>
                            ))}
                            {this._fillWeeks()}
                        </div>
                    </div>
                ))}


                <div className="col s12">
                    <div className="row collapsed weeks" data-variable="weeks">
                        <div className="col s2">
                            <div className="tableValue variableName dataLeft">
                                Week
                            </div>
                        </div>
                        { this.state.exercise.variables[0].weekValues.map((value, index) => (
                            <div className="col s1" key={value.week+"-week"}>
                                <div className="tableValue variableName">
                                    {value.week}
                                </div>
                            </div>
                        ))}
                        {this._fillWeeks()}
                    </div>
                </div>
            </div>);
    }

    render() {
        return (
            <div className="printMargin">
                <div className="detailCard print" >
                    <div className="row noMarginBottom collapsed">
                        <div className="col s12">
                            <div className="row noMarginBottom">
                                <div className="col s6">
                                    <div className="infoContainer print">
                                        <h5><b>{this.state.exercise.exercise.name}</b></h5>
                                        <span className="variableName">{this._getExerciseTags()}</span>
                                    </div>
                                    <div className="dataContainer print">
                                        {this._renderVariablesTable(this.state.variables)}
                                    </div>
                                </div>
                                { this.state.exercise.exercise.steps.map((step, index) => (
                                    <div className="col s2 printColumn" key={index}>
                                        <img src={this._getExerciseSliderImage(step.images)} className="printImage"  />
                                        <p><b><Trans>trainingSchedule.step</Trans> { step.index }</b>: { step.description }</p>
                                    </div>
                                )) }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExerciseDetailCard;