import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import ExerciseDetailCardChangeVariablesModal from 'components/components/trainingSchedule/exerciseDetailCardChangeVariablesModal.jsx';


export default class ExerciseDetailCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oneRepetitionMax: 0,
            oneRepetitionMaxFormValue: 0,
            exercise: null,
            variables: null,
            formVariables: null,
            hideLoader: 'hideLoader',
            showLoading: 'hide',
            buttonDisabled: null
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillMount() {
        // eslint-disable-next-line eqeqeq
        if(this.state.exercise == null) {
            this.setState({
                exercise: this.props.exercise,
                variables: this.props.exercise.variables,
            });
        }
    }

    componentDidMount() {
        this._activateSlider();
        this.setState({
            oneRepetitionMax: this.props.exercise.oneRepetitionMax
        });
        $('.detailCard .tooltipped').tooltip({delay: 1500});
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _isUserTypeClient() {
        const user = this.context.currentUser;
        const userType = user && user.identityToken && user.identityToken.user_type;
        return userType === 'Client';
    }

    _changeHandler(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    _getExerciseTags() {
        let exerciseTags = [];
        exerciseTags = exerciseTags.concat(this.props.exercise.exercise.equipment, this.props.exercise.exercise.regions);

        return exerciseTags.join(' | ');
    }

    _activateSlider() {
        $('#exerciseId'+this.props.exercise.id+' .exerciseSlider').slick({
            dots: true,
            arrows: false,
            customPaging: (slider, i) => {
                return $('<button type="button" />').html((i + 1));
            },
        });
    }

    _getExerciseSliderImage(images) {
        let currentGender = this.props.currentClient.gender;
        let backgroundImage = null;

        if(images.length > 0){
            backgroundImage = images[0].url;
            for(let i = 0; i < images.length; i ++) {
                let imageObject = images[i];
                // eslint-disable-next-line eqeqeq
                if(imageObject.gender == currentGender) {
                    backgroundImage = imageObject.url;
                }
            }
        }

        return backgroundImage;
    }

    _showExercise() {
        if (typeof this.props.onShowExercise === 'function') {
            this.props.onShowExercise(this.state.exercise.exercise.id);
        }
    }

    _openOneRepetitionMaxSetterCheck(event) {
        event.preventDefault();

        if(this.state.exercise.calculatedVariables) {
            this._openOneRepetitionMaxSetter(event);
        } else if (window.confirm(i18n.t('trainingSchedule.removeManualVariables'))) {
            this._openOneRepetitionMaxSetter(event);
        }
    }

    _openOneRepetitionMaxSetter(event) {
        this.setState({
            oneRepetitionMaxFormValue: this.state.oneRepetitionMax ? this.state.oneRepetitionMax : 0
        });
        let target = event.currentTarget;
        let infoContainer = $(target).parent('.oneRMContainer');
        infoContainer.toggleClass('openSetter');

        let setterInput = infoContainer.find('input[name=oneRepetitionMaxFormValue]');
        // eslint-disable-next-line eqeqeq
        if(setterInput.length == 1 && infoContainer.hasClass('openSetter')) {
            setterInput.focus();
        }
    }

    _closeOneRepetitionMaxSetter(event) {
        event.preventDefault();
        let target = event.currentTarget;
        let infoContainer = $(target).parents('.oneRMContainer');
        infoContainer.removeClass('openSetter');
    }

    _setOneRepetitionMax(event) {
        event.preventDefault();

        let target = event.currentTarget;
        let infoContainer = $(target).parents('.oneRMContainer');
        infoContainer.removeClass('openSetter');

        this.setState({
            hideLoader: null
        });

        fmfApiClient.updateClientTrainingScheduleExerciseOneRepetitionMax(
            this.cancelSource.token,
            this.props.currentClient.id,
            this.props.trainingSchedule.id,
            this.state.exercise.id,
            this.state.oneRepetitionMaxFormValue
        ).then(result => {
            this.setState({
                hideLoader: 'hideLoader',
                exercise: result,
                oneRepetitionMax: result.oneRepetitionMax,
                variables: result.variables,
                formVariables: result.variables
            }, () => {
                Materialize.toast(i18n.t('trainingSchedule.1RMChanged'), window.toastDuration);
            });
        }, error => {
            Materialize.toast(i18n.t('errors.' + error.error.toString()), window.toastDuration);
        });
    }

    _showChangeExercise() {
        $('#changeExercise'+this.state.exercise.id).modal();
        $('#changeExercise'+this.state.exercise.id).modal('open');
    }

    _fillWeeks() {
        let emptyWeeks = [];
        for (let i = this.state.exercise.variables[0].weekValues.length; i < 10; i++) {
            emptyWeeks.push(<div className="col s12 m1 hide-on-small-only" key={"emptyValue"+i}><div className="tableValue empty"></div></div>);
        }
        return emptyWeeks;
    }

    _renderVariablesTable(variables, withInputs = false) {
        return (
            <div className="row exerciseDataTable">
                {variables.map((variable, index) => (
                    <div className="col s3 m12" key={'variable'+withInputs+'-'+index}>
                        <div className="row collapsed" data-variable="sets">
                            <div className="col s12 m2">
                                <div className="tableValue variableName dataLeft">
                                    {variable.name}
                                </div>
                            </div>
                            {variable.weekValues.map((value, valueIndex) => (
                                <div className="col s12 m1" data-week={value.week}
                                    key={'variableValue' + (withInputs ? 'input' : 'value') + '-' + index + '-' + valueIndex}>
                                    <div className="tableValue">
                                        {withInputs ?
                                            <input
                                                id={"value-"+index+'-'+valueIndex} type="text"
                                                name={"value-"+index+'-'+valueIndex}
                                                data-variable={index}
                                                data-variable-value={valueIndex}
                                                value={this.state.formVariables[index].weekValues[valueIndex].value}
                                                onChange={this._changeVariableHandler}
                                                required data-msg={i18n.t('errors.required_field')}
                                            /> :
                                            value.value
                                        }
                                    </div>
                                </div>
                            ))}
                            {this._fillWeeks()}
                        </div>
                    </div>
                ))}


                <div className="col s3 m12 hide-on-small-only">
                    <div className="row collapsed weeks" data-variable="weeks">
                        <div className="col s12 m2">
                            <div className="tableValue variableName dataLeft">
                                Week
                            </div>
                        </div>
                        {this.state.exercise.variables[0].weekValues.map((value, index) => (
                            <div className="col s12 m1" key={value.week+"-week"}>
                                <div className="tableValue variableName">
                                    {value.week}
                                </div>
                            </div>
                        ))}
                        {this._fillWeeks()}
                    </div>
                </div>
            </div>);
    }

    _variablesSaved(exercise) {
        this.setState({
            exercise: exercise,
            oneRepetitionMax: exercise.oneRepetitionMax,
            variables: exercise.variables,
            formVariables: exercise.variables
        }, () => {
            Materialize.toast(i18n.t('trainingSchedule.manualUpdate'), window.toastDuration);
            $(".modal-overlay").trigger("click");
        });
    }

    render() {
        return (
            <div>
                <div className="detailCard" id={"exerciseId" + this.state.exercise.id}>
                    <div className="row noMarginBottom">
                        <div className="col s12 m3">
                            <div className="sliderContainer hide-on-small-only">
                                <div className="exerciseSlider">
                                    {this.state.exercise.exercise.steps.map((step, index) => (
                                        <div className="slide" key={index}>
                                            <img src={this._getExerciseSliderImage(step.images)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="imageRow hide-on-med-and-up">
                                {this.state.exercise.exercise.steps.map((step, index) => (
                                    <img src={this._getExerciseSliderImage(step.images)} key={index} />
                                ))}
                            </div>
                        </div>
                        <div className="col s12 m9">
                            <div className="row noMarginBottom">
                                <div className="col s12 m7">
                                    <div className="infoContainer">
                                        <h5>{this.state.exercise.exercise.name}</h5>
                                        <span className="variableName">{this._getExerciseTags()}</span>
                                    </div>
                                </div>
                                <div className="col s12 m5">
                                    <div className={this.state.hideLoader + " buttonContainer"}>
                                        {!this._isUserTypeClient() &&
                                            <React.Fragment>
                                                <CircleLoader/>
                                                <a className="btn grey cursor noShadow" onClick={() => this._showChangeExercise()}>
                                                    <i className="material-icons">mode_edit</i>
                                                </a>
                                            </React.Fragment>
                                        }
                                        <a className="btn grey cursor noShadow" onClick={() => this._showExercise()}>
                                            <i className="material-icons">info_outline</i>
                                        </a>
                                        {!this._isUserTypeClient() && this.state.exercise.exercise.supportsOneRepetitionMax &&
                                            <div className="oneRMContainer">
                                                <a className="btn grey cursor noShadow" onClick={e => this._openOneRepetitionMaxSetterCheck(e)}>
                                                    <span className="info tooltipped" data-position="bottom"
                                                        data-tooltip={i18n.t('trainingSchedule.1rmInfo')}>
                                                        1RM
                                                    </span>
                                                </a>
                                                <div className="oneRMFormContainer">
                                                    <button type="button" className="waves-effect waves-light btn noShadow grey"
                                                        onClick={e => this._closeOneRepetitionMaxSetter(e)}>
                                                        <i className="material-icons">close</i>
                                                    </button>
                                                    <span className="infoName"><Trans>trainingSchedule.1RM</Trans></span>
                                                    <input type="text"
                                                        id="oneRepetitionMaxFormValue"
                                                        name="oneRepetitionMaxFormValue"
                                                        value={this.state.oneRepetitionMaxFormValue}
                                                        onChange={e => this._changeHandler(e)} />
                                                    <button type="button" className="waves-effect waves-light btn noShadow"
                                                        onClick={e => this._setOneRepetitionMax(e)}>
                                                        <i className="material-icons">done</i>
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="dataContainer">
                                {this._renderVariablesTable(this.state.variables)}
                            </div>
                        </div>
                    </div>
                </div>
                <ExerciseDetailCardChangeVariablesModal onSaveVariables={exercise => this._variablesSaved(exercise)}
                    exercise={this.state.exercise} trainingSchedule={this.props.trainingSchedule}
                    currentClient={this.props.currentClient}
                />
            </div>
        )
    }
}

ExerciseDetailCard.contextType = UserContext