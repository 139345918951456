export default class latestNutritionPicker {
    // Measurements are ordered with most recent one on top.

    getLatestMeasurementOrNull(measurements) {
        if (measurements) {
            return measurements[0];
        }
    }

    getLatestMeasurementWithWeightOrNull(measurements) {
        if (measurements) {
            return measurements.find(m => m.currentWeight > 0);
        }
    }

    getLatestMeasurementWithFatPercentageOrNull(measurements) {
        if (measurements) {
            return measurements.find(m => m.fatPercentage > 0 || m.skinFoldMeasurementMethod > 0);
        }
    }

    getLatestMeasurementWithLengthOrNull(measurements) {
        if (measurements) {
            return measurements.find(m => m.currentLength > 0);
        }
    }
}