import React from 'react';
import history from 'history.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';

export default class AddClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            gender: '0',
            phoneNumber: '',
            email: '',
            birthdate: '',
            serverBirthdate: '',

            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'
        };

        this.picker = null;

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _setDate (setDate) {
        if(setDate.select) {
            this.setState({ serverBirthdate: new Date(setDate.select).toLocalISOString() });
            this.setState({ birthdate: new Date(setDate.select).toInversedShortDateString() });
        }
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    componentDidMount() {
        let picker = $('#addClientForm .datepicker').pickadate({
            selectYears: 70,
            today: '',
            selectMonths: true,
            format: 'dd-mm-yyyy',
            max: new Date(),
            closeOnSelect: true,
            onSet: function(timeSet) {
                this._setDate(timeSet);
            }.bind(this)
        });
        this.picker = picker.pickadate('picker');


        $("#addClientForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        // eslint-disable-next-line eqeqeq
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        let client = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            gender: this.state.gender,
            birthDate: this.state.serverBirthdate,
            isActive: true
        };

        //SET empty string values to null
        Object.keys(client).forEach(function(key,index) {
            if(client[key] === '') {
                client[key] = null;
            }
        });

        fmfApiClient.createClient(this.cancelSource.token, client)
            .then(result => {
                this.setState({
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: ''
                });

                $(".modal-overlay").trigger("click");
                history.push('/clienten/' + result.id);
                Materialize.toast(i18n.t('addClient.successText'), window.toastDuration);
            }, error => {
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    render() {
        return (
            <div id="addClient" className="modal">
                <form id="addClientForm" noValidate onSubmit={this._submitForm}>
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12">
                            <h4><Trans>addClient.modalTitle</Trans></h4>
                            <div className={this.state.showError}>
                                <div id="card-alert" className="card red">
                                    <div className="card-content white-text">
                                        <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className='input-field col s12'>
                            <input
                                id="firstName" type="text"
                                name="firstName"
                                value={this.state.firstName}
                                onChange={this._changeHandler}
                                required data-msg={ i18n.t('errors.required_field') } />
                            <label htmlFor="firstName" >{ i18n.t('formFields.firstname') }</label>
                        </div>
                    </div>
                        <div className="row">
                            <div className='input-field col s12'>
                                <input
                                    id="lastName" type="text"
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this._changeHandler}
                                    data-msg={ i18n.t('errors.required_field') }
                                    required
                                />
                                <label htmlFor="lastName">{ i18n.t('formFields.lastname') }</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className='input-field col s12'>
                                <input
                                    id="email" type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this._changeHandler}
                                    data-msg={ i18n.t('errors.no_valid_email') } />
                                <label htmlFor="firstName" >{ i18n.t('formFields.email') }</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className='input-field col s12'>
                                <input
                                    id="phoneNumber" type="text"
                                    name="phoneNumber"
                                    value={this.state.phoneNumber}
                                    onChange={this._changeHandler}
                                />
                                <label htmlFor="phoneNumber">{ i18n.t('formFields.phoneNumber') }</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m12 input-field">
                                <select id="gender"
                                        name="gender"
                                        className="browser-default"
                                        value={this.state.gender}
                                        onChange={this._changeHandler}
                                         >
                                    <option value="" disabled>{ i18n.t('formFields.chooseGender') }</option>
                                    <option value="1">{ i18n.t('formFields.male') }</option>
                                    <option value="2">{ i18n.t('formFields.female') }</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m12 input-field">
                                <input type="text"
                                       id="birthdate"
                                       name="birthdate"
                                       value={this.state.birthdate}
                                       onChange={this._changeHandler}
                                       className="datepicker" />
                                <label htmlFor="birthdate">{ i18n.t('formFields.birthdate') }</label>
                            </div>
                        </div>

                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12">
                            <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>addClient.button</Trans></button>
                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                <CircleLoader/>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}