import React from 'react';
import i18n from 'i18next';
import accounting from 'accounting';

class UpgradeBlock extends React.Component {
    _isCurrentLicense() {
        return (this.props.license.id === this.props.currentLicense.id);
    }

    _isUpgrade() {
        return (this.props.license.priceExclVat > this.props.currentLicense.priceExclVat);
    }

    _licenseSelected() {
        this.props.onLicenseSelected(this.props.license);
    }

    render() {
        let upgradeText = this._isUpgrade() ? 'upgradeTo' : 'downgradeTo';

        return (
            <div className="col s12 m6 l3 upgradeCard-container">
                <div className={"upgradeCard" + (this._isCurrentLicense() ? " active" : "")}>
                    <h4 className="upgradeTitle">
                        { this.props.license.name }
                    </h4>
                    <ul className="checkedList">
                        <li dangerouslySetInnerHTML={{__html: this.props.license.maxClients ?
                            this.props.license.maxClients === 1 ?
                                i18n.t('licenseBlocks.maxClient') :
                                i18n.t('licenseBlocks.maxClients', {nrOfClients: this.props.license.maxClients})
                            :
                            i18n.t('licenseBlocks.unlimitedClients')
                        }}>
                        </li>
                        <li dangerouslySetInnerHTML={{__html: this.props.license.maxTrainingSchedulesPerClient ?
                            this.props.license.maxTrainingSchedulesPerClient === 1 ?
                                i18n.t('licenseBlocks.maxTrainingSchedulePerClient') :
                                i18n.t('licenseBlocks.maxTrainingSchedulesPerClient', {maxTrainingSchedulesPerClient: this.props.license.maxTrainingSchedulesPerClient})
                            :
                            i18n.t('licenseBlocks.unlimitedTrainingSchemes')
                        }}>
                        </li>
                        <li dangerouslySetInnerHTML={{__html: this.props.license.maxNutritionSchedulesPerClient ?
                            this.props.license.maxNutritionSchedulesPerClient === 1 ?
                                i18n.t('licenseBlocks.maxNutritionSchedulePerClient') :
                                i18n.t('licenseBlocks.maxNutritionSchedulesPerClient', {maxNutritionSchedulesPerClient: this.props.license.maxNutritionSchedulesPerClient})
                            :
                            i18n.t('licenseBlocks.unlimitedNutritionSchemes')
                        }}>
                        </li>
                        { this.props.license.clientConnectAllowed ?
                            <li dangerouslySetInnerHTML={{__html: i18n.t('licenseBlocks.clientApp')}}></li> :
                            <br />
                        }
                    </ul>
                    <span className="upgradePrice center-align">
                        <span className="valuta">€</span>
                        <span className="price">
                            {/* eslint-disable-next-line eqeqeq */}
                            {this.props.license.priceExclVat == 0 ?
                                '0,-' :
                                this.props.license.priceExclVat + ',-'
                            }
                        </span>
                    </span>
                    <div className="center-align">
                        <span className="variableName">
                            {this.props.license.priceInclVat > 0 ?
                                <small>{ accounting.formatMoney(this.props.license.priceInclVat) } incl. { this.props.license.vatPercentage }% BTW</small> :
                                <small>&nbsp;</small>
                            }
                        </span>
                    </div>
                    <div className="upgradeButtonContainer">
                        { this._isCurrentLicense() ?
                            <a className="btn disabled noShadow">
                                {i18n.t('licenseBlocks.currentLicense')}
                            </a> :
                            <a className={"btn blue white-text noShadow" + (!this.props.isEnabled ? " disabled" : "")} onClick={() => this._licenseSelected()}>
                                {i18n.t('subscriptionPage.' + upgradeText, {upgradeName: this.props.license.name })}
                            </a>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default UpgradeBlock;