import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';

class ExerciseDetailCardChangesVariablesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            exercise: null,
            variables: null,
            formVariables: null,
            showLoading: 'hide',
            buttonDisabled: null
        };

        this._changeVariableHandler = this._changeVariableHandler.bind(this);
        this._onSubmit = this._onSubmit.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _changeVariableHandler(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const variable = target.getAttribute('data-variable');
        const variableValue = target.getAttribute('data-variable-value');

        if(variable != null && variableValue != null) {
            if (isNaN(value)) return;
            let parsedValue = parseInt(value); // We don't support decimals, so parse decimals to integers.
            if (isNaN(parsedValue)) parsedValue = ""; // "" (empty) translated to NaN. Since we want the user to be able to clear the input, translate it back to "".
            let variables = this.state.formVariables;
            variables[variable].weekValues[variableValue].value = parsedValue;

            this.setState({
                formVariables: variables
            });
        }
    }

    _getExerciseTags() {
        let exerciseTags = [];
        exerciseTags = exerciseTags.concat(this.props.exercise.exercise.equipment, this.props.exercise.exercise.regions);

        return exerciseTags.join(' | ');
    }

    UNSAFE_componentWillMount(){
        let variables = this.props.exercise.variables;
        let formVariables = this.props.exercise.variables;

        this.setState({
            exercise: this.props.exercise,
            variables: variables,
            formVariables: formVariables
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        let variables = nextProps.exercise.variables;
        let formVariables = nextProps.exercise.variables;

        this.setState({
            exercise: nextProps.exercise,
            variables: variables,
            formVariables: formVariables
        });
    }

    componentDidMount() {
        this.setState({
            oneRepetitionMax: this.props.exercise.oneRepetitionMax
        });

        $("#changeExerciseForm"+this.state.exercise.id).validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _onSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            buttonDisabled: 'disabled',
            showLoading: '',
        });

        const newState = {
            showLoading: 'hide',
            buttonDisabled: null,
        };

        fmfApiClient.updateClientTrainingScheduleExercise(
            this.cancelSource.token,
            this.props.currentClient.id,
            this.props.trainingSchedule.id,
            this.state.exercise.id,
            this.state.formVariables
        ).then(result => {
            this.setState(newState, function() {
                if (typeof this.props.onSaveVariables === 'function') {
                    this.props.onSaveVariables(result);
                }
            }.bind(this));
        }, error => {
            Materialize.toast(i18n.t('errors.' + error.error.toString()), window.toastDuration);
            this.setState(newState);
        });
    }

    _fillWeeks() {
        let emptyWeeks = [];
        for (let i=this.state.exercise.variables[0].weekValues.length; i < 10; i++) {
            emptyWeeks.push(<div className="col s12 m1 hide-on-small-only" key={"emptyValue"+i}><div className="tableValue empty"></div></div>);
        }
        return emptyWeeks;
    }

    _renderVariablesTable(variables, withInputs = false) {
        return (
            <div className="row exerciseDataTable">
                { variables.map((variable, index) => (
                    <div className="col s3 m12" key={'variable'+withInputs+'-'+index}>
                        <div className="row collapsed" data-variable="sets">
                            <div className="col s12 m2">
                                <div className="tableValue variableName dataLeft">
                                    { variable.name }
                                </div>
                            </div>
                            { variable.weekValues.map((value, valueIndex) => (
                                <div className="col s12 m1" data-week={value.week} key={'variableValue'+(withInputs?'input':'value')+'-'+index+'-'+valueIndex}>
                                    <div className="tableValue">
                                        { withInputs ?
                                            <input
                                                id={"value-"+index+'-'+valueIndex} type="number"
                                                name={"value-"+index+'-'+valueIndex}
                                                data-variable={index}
                                                data-variable-value={valueIndex}
                                                value={this.state.formVariables[index].weekValues[valueIndex].value}
                                                onChange={this._changeVariableHandler}
                                                required data-msg="" />
                                            :
                                            value.value
                                        }
                                    </div>
                                </div>
                            ))}
                            {this._fillWeeks()}
                        </div>
                    </div>
                ))}


                <div className="col s3 m12 hide-on-small-only">
                    <div className="row collapsed weeks" data-variable="weeks">
                        <div className="col s12 m2">
                            <div className="tableValue variableName dataLeft">
                                Week
                            </div>
                        </div>
                        { this.state.exercise.variables[0].weekValues.map((value, index) => (
                            <div className="col s12 m1" key={value.week+"-week"}>
                                <div className="tableValue variableName">
                                    {value.week}
                                </div>
                            </div>
                        ))}
                        {this._fillWeeks()}
                    </div>
                </div>
            </div>);
    }

    _submitForm(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div id={"changeExercise"+this.state.exercise.id} className="modal changeExercise">
                <form id={"changeExerciseForm"+this.state.exercise.id} onSubmit={this._submitForm}>
                    <div className="modal-content">
                        <h5>{this.state.exercise.exercise.name}<br />
                            <small><span className="variableName">{this._getExerciseTags()}</span>
                                {this.state.exercise.exercise.supportsOneRepetitionMax
                                    ?
                                    <span className="variableName"> | <Trans>trainingSchedule.1RM</Trans>: {this.state.exercise.oneRepetitionMax} <Trans>trainingSchedule.kg</Trans></span>
                                    :
                                    null
                                }
                            </small>
                        </h5>
                        <small><p><Trans>trainingSchedule.changeExplenation</Trans></p></small><br />
                        { this._renderVariablesTable(this.state.formVariables,true) }
                        <br />
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col s12">
                                <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>generalActions.save</Trans></button>
                                <button className="waves-effect waves-light btn noShadow grey modal-close" type="button"><Trans>changeAccount.cancelButton</Trans></button>
                                <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                    <CircleLoader/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

export default ExerciseDetailCardChangesVariablesModal;