import React from 'react';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';

class AddNote extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            body: '',
            currentUser: null,

            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    componentDidMount() {
        $('textarea.autogrow').autogrow();

        $("#addNoteForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function() {
                this._onSubmit();
            }.bind(this)
        });
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _onSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        let note = {
            body: this.state.body
        };

        fmfApiClient.createClientNote(this.cancelSource.token, this.props.clientId, note)
            .then(result => {
                this.setState({
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: '',
                    body: ''
                });

                if (typeof this.props.onChange === 'function') {
                    this.props.onChange();
                }

                $(".modal-overlay").trigger("click");
                Materialize.toast(i18n.t('notes.addNoteSucces'), window.toastDuration);
            }, error => {
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    _submitForm(event) {
        event.preventDefault();
    }

    render() {
        return (
            <div id="addNote" className="modal">
                <form id="addNoteForm" onSubmit={this._submitForm}>
                    <div className="modal-content">
                        <div className="row noMarginBottom">
                            <div className="col s12">
                                <h4><Trans>notes.addNoteTitle</Trans></h4>
                            </div>
                        </div>
                        {/* ERROR MESSAGE */}
                        <div className={this.state.showError}>
                            <div id="card-alert" className="card red">
                                <div className="card-content white-text">
                                    <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <textarea id="body"
                                          type="text"
                                          name="body"
                                          value={this.state.body}
                                          onChange={this._changeHandler}
                                          className="materialize-textarea autogrow"
                                          required data-msg={ i18n.t('errors.required_field') } >
                                </textarea>
                                <label htmlFor="textarea1"><Trans>formFields.note</Trans></label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col s12">
                                <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>generalActions.add</Trans></button>
                                <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                    <CircleLoader/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default AddNote;