import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

class EditScheduleHeader extends React.Component {
    constructor(props) {
        super(props);

        //IS THE SIDENAV ALREADY INITIALIZED
        this.state = {
            duration: 0
        };

        this._changeHandler = this._changeHandler.bind(this);
        this._updateFields = this._updateFields.bind(this);
        this._nrOfWeeksChange = this._nrOfWeeksChange.bind(this);
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _nrOfWeeksChange(event) {
        const target = event.target;
        const value = target.value;

        if (typeof this.props.onNrOfWeeksChange === 'function') {
            this.props.onNrOfWeeksChange(value);
        }
    }

    _updateFields() {
        $('.schemeHeader select').material_select();
        Materialize.updateTextFields();
    }

    componentDidMount() {
        this.setState({
            duration: this.props.nrOfWeeks,
        }, function() {
            this._updateFields();
            $('.schemeHeader select').on('change', this._changeHandler);
            $('.schemeHeader select#duration').on('change', this._nrOfWeeksChange);
        }.bind(this));
    }

    render() {
        return (
            <div className="schemeHeader">
                <div className="row">
                    <div className="col s12 m4">
                        <div id="logo-container" className="brand-logo"><img src="/images/logo_grijs.svg" className="lightGreyHeader" /></div>
                    </div>
                    <div className="col s6 m4">
                        <div className="input-field">
                            <select
                                id="duration"
                                name="duration"
                                value={this.state.duration}
                                onChange={this._changeHandler}>
                                <option value="3">3 { i18n.t('formFields.weeks') }</option>
                                <option value="4">4 { i18n.t('formFields.weeks') }</option>
                                <option value="5">5 { i18n.t('formFields.weeks') }</option>
                                <option value="6">6 { i18n.t('formFields.weeks') }</option>
                                <option value="7">7 { i18n.t('formFields.weeks') }</option>
                                <option value="8">8 { i18n.t('formFields.weeks') }</option>
                                <option value="9">9 { i18n.t('formFields.weeks') }</option>
                                <option value="10">10 { i18n.t('formFields.weeks') }</option>
                            </select>
                            <label htmlFor="duration"><Trans>formFields.durationWeeks</Trans></label>
                        </div>
                    </div>
                    <div className="col s6 m4">
                        <div className="input-field">
                            { this.props.goal ?
                                <div>
                                    <input disabled value={this.props.goal.name} id="disabled" type="text"/>
                                    <label htmlFor="disabled"><Trans>trainingSchedule.goal</Trans></label>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditScheduleHeader;