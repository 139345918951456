import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { UserContext } from 'contexts.jsx';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import ImageCropper from 'components/components/imageCropper/imageCropper.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';

export default class ProfileEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstName: '',
            lastName: '',
            initials: '',
            gender: '',
            birthdate: '',
            serverBirthdate: '',

            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'};

        this.picker = null;

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);
        this._changePassword = this._changePassword.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _setDate (setDate) {
        if(setDate.select) {
            this.setState({ serverBirthdate: new Date(setDate.select).toLocalISOString() });
            this.setState({ birthdate: new Date(setDate.select).toInversedShortDateString() });
        }
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _setStartValues (result) {
        let setDate;
        if(result.birthDate) {
            setDate = new Date(result.birthDate);
        }

        this.setState({
            firstName: result.firstName?result.firstName:'',
            lastName: result.lastName?result.lastName:'',
            initials: result.initials?result.initials:'',
            // eslint-disable-next-line eqeqeq
            gender: result.gender?(result.gender==0?"0":result.gender):'',
            birthdate: result.birthDate?new Date(setDate.getTime()).toInversedShortDateString():''
        });
        setTimeout(function() {
            if (this.unmounted) return;

            Materialize.updateTextFields();

            if(result.birthDate) {
                this.picker.set('select', new Date(result.birthDate));
            }
        }.bind(this), 500);
    }

    componentDidMount() {
        let picker = $('#profileForm .datepicker').pickadate({
            selectYears: 70,
            selectMonths: true,
            today: '',
            format: 'dd-mm-yyyy',
            max: new Date(),
            closeOnSelect: true,
            onSet: function(timeSet) {
                this._setDate(timeSet);
            }.bind(this)
        });
        this.picker = picker.pickadate('picker');

        fmfApiClient.getUserProfile(this.cancelSource.token)
            .then(result => {
                this._setStartValues(result);
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });

        $("#profileForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            rules: {
                password: {
                    required: true,
                    minlength: 6,
                    pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/
                },
                checkPassword: {
                    equalTo: "#password"
                }
            },
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
        this.unmounted = true;
    }

    _changePassword() {
        $('#changePassword').modal();
        $('#changePassword').modal('open');
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        // eslint-disable-next-line eqeqeq
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            username: $('#username')[0].value,
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        let profile = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            initials: this.state.initials,
            gender: this.state.gender,
            birthDate: this.state.serverBirthdate
        };

        fmfApiClient.updateUserProfile(profile)
            .then(result => {
                if (this.unmounted) return;
                this.setState({
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: ''
                });
                Materialize.toast(i18n.t('changeProfile.successText'), window.toastDuration);
            }, error => {
                if (this.unmounted) return;
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    render() {
        const { currentUser } = this.context;
        return (
            <div className="editContainer">
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col s12 m9 l7">
                            <form id="profileForm" noValidate onSubmit={this._submitForm}>
                                <div className="row noMarginBottom">
                                    <div className="col s12">
                                        <h4 className="smallGreyTitle"><Trans>changeProfile.modalTitle</Trans></h4>
                                        <br />
                                        {/* ERROR MESSAGE */}
                                        <div className={this.state.showError}>
                                            <div id="card-alert" className="card red">
                                                <div className="card-content white-text">
                                                    <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 m5">
                                        {currentUser &&
                                            <ImageCropper
                                                preview={currentUser.profile.imageUrl}
                                                itemId={currentUser.identityToken.user_id}
                                                onChange={this._resetImage}
                                                onSave={(cancelToken, fileName, fileData) =>
                                                    fmfApiClient.createUserProfileImage(fileName, fileData)
                                                }
                                            />
                                        }
                                    </div>
                                    <div className="col s12 m7 input-field">
                                        <div className="row">
                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="initials" type="text"
                                                    name="initials"
                                                    value={this.state.initials}
                                                    onChange={this._changeHandler}
                                                />
                                                <label htmlFor="initials" >{ i18n.t('formFields.initials') }</label>
                                            </div>
                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="firstName" type="text"
                                                    name="firstName"
                                                    value={this.state.firstName}
                                                    onChange={this._changeHandler}
                                                    required data-msg={ i18n.t('errors.required_field') } />
                                                <label htmlFor="firstName" >{ i18n.t('formFields.firstname') }</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='input-field col s12'>
                                                <input
                                                    id="lastName" type="text"
                                                    name="lastName"
                                                    value={this.state.lastName}
                                                    onChange={this._changeHandler}
                                                    data-msg={ i18n.t('errors.required_field') }
                                                    required
                                                />
                                                <label htmlFor="lastName">{ i18n.t('formFields.lastname') }</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12 m6 input-field">
                                                <select id="gender"
                                                        name="gender"
                                                        className="browser-default"
                                                        value={this.state.gender}
                                                        onChange={this._changeHandler}
                                                >
                                                    <option value="" disabled>{ i18n.t('formFields.chooseGender') }</option>
                                                    <option value="0">{ i18n.t('formFields.male') }</option>
                                                    <option value="1">{ i18n.t('formFields.female') }</option>
                                                </select>
                                            </div>
                                            <div className="col s12 m6 input-field">
                                                <input type="text"
                                                       id="birthdate"
                                                       name="birthdate"
                                                       value={this.state.birthdate}
                                                       onChange={this._changeHandler}
                                                       className="datepicker" />
                                                <label htmlFor="birthdate">{ i18n.t('formFields.birthdate') }</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12 right-align">
                                                <a onClick={this._changePassword} className="waves-effect waves-light grey btn noShadow"><Trans>sidenav.changePassword</Trans></a>
                                                <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>generalActions.save</Trans></button>
                                                <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                                    <CircleLoader/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


ProfileEdit.contextType = UserContext