import React from 'react';
import fmfApiClient from 'utils/fmf-api-client.js';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import Slider from 'react-slick';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import ExerciseCard from 'components/components/trainingSchedule/exerciseCard.jsx';
import ScheduleCard from 'components/components/trainingSchedule/scheduleCard.jsx';
import ExerciseInfo from 'components/components/trainingSchedule/exerciseInfo.jsx';
import Pagination from 'components/components/pagination/pagination.jsx';
import image from 'utils/image.js';

let timeout = null;

class ExerciseOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: '',
            equipment: [],
            region: [],
            query: '',
            types: [],
            equipments: [],
            regions: [],
            exercises: [],
            templates: [],
            customTemplates: [],
            pageSize: 24,
            nrOfPages: 0,
            currentPage: 0,
            exerciseId: 0,
            goal: this.props.goal,
            isGoalLoading: this.props.isGoalLoading,
            showLoader: true,
            showReset: 'hide'
        }

        this._changeHandler = this._changeHandler.bind(this);
        this._triggerSearch = this._triggerSearch.bind(this);
        this._addExercise = this._addExercise.bind(this);
        this._setSearch = this.debounce(this._setSearch, 100);
        this._getFilters = this._getFilters.bind(this);
        this._setCurrentPage = this._setCurrentPage.bind(this);
        this._showExerciseInfo = this._showExerciseInfo.bind(this);
        this._resetFilters = this._resetFilters.bind(this);
        this._addSchedule = this._addSchedule.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    addCustomTemplate(template) {
        this.setState({customTemplates: [...this.state.customTemplates, template]});
    }

    _changeHandler (event) {
        const target = event.target;
        const name = target.name;

        let newValue = $(event.target).val();
        // eslint-disable-next-line eqeqeq
        if(newValue == null) {
            newValue = [];
        }

        this.setState({
            currentPage: 0,
            [name]: newValue
            }, function() {
                // eslint-disable-next-line eqeqeq
                if(this.state.type == 1) {
                    this._setCardioOptions();
                } else {
                    this._unsetCardioOptions();
                    this._getExercises();
                }
            }.bind(this));

    }

    _setCardioOptions() {
        this.setState({
                equipment: [],
                region: []
            },
            function() {
                $('#equipment, #region').attr('disabled', 'disabled');
                $('select').material_select();
                this._getExercises();
            });
    }

    _unsetCardioOptions() {
        $('#equipment, #region').prop('disabled', false);
        $('select').material_select();
    }

    _changeFilters() {
        // eslint-disable-next-line eqeqeq
        if(this.state.query.length > 0 || this.state.type != '' || this.state.equipment.length > 0 || this.state.region.length > 0) {
            this.setState({
                showReset: 'show'
            });
        } else {
            this.setState({
                showReset: 'hide'
            });
        }
    }

    _resetFilters() {
        $('#query').val('');

        this.setState({
            showReset: 'hide',
            query: '',
            type: [],
            equipment: [],
            region: []
        }, function() {
            this._unsetCardioOptions();
            $('select').material_select();
            this._getExercises();
        }.bind(this));
    }

    _updateFields() {
        $('.exercisesOverviewContainer select').material_select();
        Materialize.updateTextFields();
    }

    _getFilters() {
        fmfApiClient.getExerciseFilters(this.cancelSource.token)
            .then(result => {
                this.setState({
                    types: result.exerciseTypes,
                    equipments: result.equipmentFilters,
                    regions: result.regionFilters,
                });
                this._updateFields();
            });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.goal && nextProps.goal !== this.props.goal) {
            this.setState({
                    goal: nextProps.goal,
                    isGoalLoading: nextProps.isGoalLoading
                }, function() {
                    this._getExercises();
                }.bind(this));
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _getExercises() {
        this.setState({
            showLoader: true
        });

        this._changeFilters();

        let type = this.state.type;
        // eslint-disable-next-line eqeqeq
        if(type == '') {
            type = [];
        }

        let goals = [];
        // eslint-disable-next-line eqeqeq
        if(this.state.goal != null) {
            goals = [this.state.goal.id];
        }

        if(!this.props.hideTemplates && !this.state.isGoalLoading) {
            fmfApiClient.getTrainingTemplates(
                this.cancelSource.token,
                this.state.query,
                goals,
                type,
                this.state.equipment,
                this.state.region,
                0,
                100
            ).then(result => {
                this.setState({
                    templates: result.items,
                });
            });

            fmfApiClient.getCustomTrainingTemplates(
                this.cancelSource.token,
                this.state.query,
                goals,
                type,
                this.state.equipment,
                this.state.region,
                0,
                100
            ).then(result => {
                this.setState({
                    customTemplates: result.items,
                });
            });
        }

        fmfApiClient.getExercises(
            this.cancelSource.token,
            this.state.query,
            type,
            this.state.equipment,
            this.state.region,
            this.state.currentPage,
            this.state.pageSize,
            image.resize(327, 327)
        ).then(result => {
            this.setState({
                exercises: result.items,
                nrOfPages: result.totalPages,
                currentPage: result.page,
                showLoader: false
            });
        });
    }

    _triggerSearch (event) {
        event.persist();

        clearTimeout(timeout);
        timeout = setTimeout(function () {
            this._setSearch(event);
        }.bind(this), 500);
    }

    _setSearch(event) {
        const target = event.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({
            currentPage: 0,
            [name]: value
            }, function() {
                this._getExercises();
            }.bind(this));
    }

    debounce(func, wait, immediate) {
        let timeout;
        return function() {
            let context = this, args = arguments;
            let later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    _addExercise(exercise) {
        if (typeof this.props.onAddExercise === 'function') {
            this.props.onAddExercise(exercise);
        }
    }

    _addSchedule(template) {
        if (typeof this.props.onAddExercises === 'function') {
            this.props.onAddExercises(template.exercises);
        }
    }

    _setCurrentPage(newPageNr) {
        this.setState({
            currentPage: newPageNr
        },function() {
            this._getExercises();
        }.bind(this));
    }

    UNSAFE_componentWillMount() {
        this._getFilters();
        this._getExercises();
    }

    componentDidMount() {
        this._updateFields();
        $('.exercisesOverviewContainer select').on('change', this._changeHandler);
    }

    _showExerciseInfo(exerciseId) {
        this.setState({
            exerciseId: exerciseId
        }, function() {
            $('#exerciseInfo').modal('open');
        });
    }

    render() {
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <div className="exercisesOverviewContainer">
                <div className="row">
                    <div className="col s12 m4 no-padding">
                        <div className="row">
                            <div className="input-field col s12">
                                <input id="query" type="text"
                                       name="query"
                                       onChange={this._triggerSearch}
                                       onKeyUp={this._triggerSearch} />
                                <label htmlFor="query"><Trans>formFields.searchExercise</Trans></label>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m8 no-padding right-align">
                        <div className="row">
                            <div className="input-field col s12 m4">
                                <select
                                    id="type"
                                    name="type"
                                    value={this.state.type}
                                    onChange={this._changeHandler}>
                                    <option value=''>{ i18n.t('formFields.chooseType') }</option>
                                    { this.state.types.map((option) => (
                                        <option key={option.id} value={option.id}>{ option.name }</option>
                                    )) }
                                </select>
                                <label htmlFor="type"><Trans>formFields.type</Trans></label>
                            </div>
                            <div className="input-field col s12 m4">
                                <select
                                    id="equipment"
                                    name="equipment"
                                    multiple
                                    value={this.state.equipment}
                                    onChange={this._changeHandler}>
                                    <option value='' disabled>{ i18n.t('formFields.chooseEquipment') }</option>
                                    { this.state.equipments.map((option) => (
                                        <option key={option.id} value={option.id}>{ option.name }</option>
                                    )) }
                                </select>
                                <label htmlFor="equipment"><Trans>formFields.equipment</Trans></label>
                            </div>
                            <div className="input-field col s12 m4">
                                <select
                                    id="region"
                                    name="region"
                                    multiple
                                    value={this.state.region}
                                    onChange={this._changeHandler}>
                                    <option value='' disabled>{ i18n.t('formFields.chooseRegion') }</option>
                                    { this.state.regions.map((option) => (
                                        <option key={option.id} value={option.id}>{ option.name }</option>
                                    )) }
                                </select>
                                <label htmlFor="region"><Trans>formFields.region</Trans></label>
                            </div>
                        </div>
                        <button className={ this.state.showReset + " btn resetFilters"} onClick={this._resetFilters}>{ i18n.t('trainingSchedule.resetFilters') }</button>
                    </div>
                </div>
                {/* eslint-disable-next-line eqeqeq */}
                { this.state.showLoader == false ?
                    <div>
                        { this.state.templates.length > 0 || this.state.customTemplates.length > 0?
                        <div>
                            <div className="row noMarginBottom">
                                <div className="col s12">
                                    <h5><Trans>trainingSchedule.trainingTemplates</Trans></h5>
                                </div>
                            </div>
                            <div className="exercisesContainer">
                                <Slider {...settings}>
                                    { this.state.templates.concat(this.state.customTemplates).map((template) => (
                                        <div className="slide" key={template.id}>
                                            <ScheduleCard  template={template}
                                                      onAddSchedule={this._addSchedule} />
                                        </div>
                                    )) }
                                </Slider>
                            </div>
                        </div>
                        : null }
                        <div className="row noMarginBottom">
                            <div className="col s12">
                                <h5><Trans>trainingSchedule.exercises</Trans></h5>
                            </div>
                        </div>
                        { this.state.exercises.length > 0 ?
                            <div className="exercisesContainer">
                                <div className="row">
                                    { this.state.exercises.map((exercise) => (
                                        <ExerciseCard key={exercise.id} exercise={exercise}
                                                      currentClient={this.props.currentClient}
                                                      onAddExercise={this._addExercise}
                                                      onShowExercise={this._showExerciseInfo}/>
                                    )) }
                                </div>
                                <div className="row">
                                    <div className="col s12 center-align">
                                        <Pagination currentPage={this.state.currentPage} pages={this.state.nrOfPages}
                                                    onPageChange={this._setCurrentPage}/>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="exercisesContainer center-align">
                                <Trans>trainingSchedule.noResult</Trans>
                            </div>
                            }
                    </div>
                    :
                    <FPLoader />
                }
                <ExerciseInfo exerciseId={this.state.exerciseId} currentClient={this.props.currentClient} />
            </div>
        )
    }
}

export default ExerciseOverview;