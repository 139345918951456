import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { UserContext } from 'contexts.jsx';
import history from 'history.js';
import ChangeLicense from 'components/components/changeLicense';
import { hasLicenseExpired, hasTryOutLicense } from 'utils/licenseUtils';

export default function LicenseExpired() {
    const { currentUser } = useContext(UserContext);
    if (!hasLicenseExpired(currentUser)) {
        history.push('/dashboard');
        return null;
    }

    const currentUserHasTryOutLicense = hasTryOutLicense(currentUser);
    const titleKey = 'subscriptionPage.' + (currentUserHasTryOutLicense ? 'licenseExpiredTrialTitle' : 'licenseExpiredSubscriptionTitle');
    return (
        <ChangeLicense
            title={<Trans>{titleKey}</Trans>}
            subtitle={<Trans>subscriptionPage.licenseExpiredText</Trans>}
        />
    )
}
