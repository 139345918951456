import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

class TrainingTemplateCard extends React.Component {
    constructor(props) {
        super(props);

        this._addExercise = this._addExercise.bind(this);
        this._showExercise = this._showExercise.bind(this);
    }

    _addExercise() {
        if (typeof this.props.onAddExercise === 'function') {
            this.props.onAddExercise(this.props.exercise);
        }
    }

    _showExercise() {
        if (typeof this.props.onShowExercise === 'function') {
            this.props.onShowExercise(this.props.exercise.id);
        }
    }

    render() {
        return (
            <div className="card exercise">
                <div className="card-image">
                    <div className="trainingTemplateImageContainer" style={{backgroundImage: "url(/images/TrainingTemplate.jpg)"}}></div>
                </div>
                <div className="addAction">
                    <Link to={ (this.props.cms ? '/cms/training-templates/edit/' : '/mijn-templates/') + this.props.template.id }
                    className="btn-floating btn-large waves-effect waves-light">
                        <i className="material-icons">edit</i>
                    </Link>
                </div>
                <div className="cardInfo">
                    <span className="card-title">{ this.props.template.name }</span>
                    <span className="extraInfo">{ this.props.template.goalName }</span>
                </div>
                <div className="card-action">
                    <a>{this.props.template.exercises.length} { this.props.template.exercises.length > 1 ?
                        <Trans>trainingSchedule.exercises</Trans>:<Trans>trainingSchedule.exercise</Trans>}
                    </a>
                </div>
            </div>
        )
    }
}

export default TrainingTemplateCard;