import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { UserContext } from 'contexts.jsx';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';

export default class DailyAdviceOverView extends React.Component {
    _isUserTypeClient() {
       const user = this.context.currentUser;
       const userType = user && user.identityToken && user.identityToken.user_type;
       return userType === 'Client';
   }

    _onClickDay() {
        if (this.props.showDayDetail) {
            this.props.showDayDetail(this.props.dailyAdvice, this.props.dayInWeek, this.props.dateFormatted);
        }
    }

    _onClickMeal(dailyAdviceMeal) {
        if (this.props.showMealDetail) {
            this.props.showMealDetail(dailyAdviceMeal.meal, dailyAdviceMeal);
        }
    }

    _onClickSubstituteMeal(substituteMeal, substitutedMeal) {
        if (this.props.showMealDetail) {
            const dailyAdviceMeal = this.props.dailyAdvice.dailyAdviceMeals.find(dam => dam.id === substituteMeal.dailyAdviceMealId);
            this.props.showMealDetail(substituteMeal.substituteMeal, dailyAdviceMeal, substituteMeal, substitutedMeal.meal);
        }
    }

    _findSubstituteForMeal(dailyAdviceMeal) {
        return this.props.mealSubstitutes.find(ms => ms.dailyAdviceMealId === dailyAdviceMeal.id);
    }

    // Necessary until dto of daily advice contains totals which incorporates substitutions, for a daily advice.
    _getTotalsForDailyAdvice() {
        let kcal = 0;
        let totalCarbohydrates = 0;
        let totalProtein = 0;
        let totalFat = 0;
        let totalFibers = 0;
        let totalSodium = 0;

        this.props.dailyAdvice.dailyAdviceMeals.forEach(dailyAdviceMeal => {
            let meal;

            const substituteMeal = this._findSubstituteForMeal(dailyAdviceMeal);
            if (!substituteMeal) {
                meal = dailyAdviceMeal.meal;
            } else {
                meal = substituteMeal.substituteMeal;
            }

            kcal += meal.kcal;
            totalCarbohydrates += meal.totalCarbohydrates;
            totalProtein += meal.totalProtein;
            totalFat += meal.totalFat;

            // Necessary until dto of meal includes totals for fibers and sodium.
            meal.mealNutritions.forEach(mealNutrition => {
                if (mealNutrition.nutrition.totalDietaryFiber) {
                    let helper = parseFloat(mealNutrition.nutrition.totalDietaryFiber);
                    if (!isNaN(helper)) {
                        totalFibers += helper;
                    }
                }

                if (mealNutrition.nutrition.sodium) {
                    let helper = parseFloat(mealNutrition.nutrition.sodium);
                    if (!isNaN(helper)) {
                        totalSodium += helper;
                    }
                }
            });
        });

        return {
            kcal: Math.floor(kcal),
            totalCarbohydrates: Math.floor(totalCarbohydrates),
            totalProtein: Math.floor(totalProtein),
            totalFat: Math.floor(totalFat),
            totalFibers: Math.floor(totalFibers),
            totalSodium: Math.floor(totalSodium),
        };
    }

    _imageFileName() {
        // eslint-disable-next-line eqeqeq
        if (this.props.dailyAdvice.imageUrl && this.props.dailyAdvice.imageUrl != "") {
            return 'url(' + this.props.dailyAdvice.imageUrl + ')';
        }
        return 'transparent';
    }

    _renderDinnerRecipe() {
        const dinnerTimeSlot = 5;
        let dinner = this.props.dailyAdvice.dailyAdviceMeals.find(dam => dam.timeSlot === dinnerTimeSlot);
        if(!dinner) {
            return null;
        }

        dinner = this._findSubstituteForMeal(dinner) || dinner;
        // HACK: subsitute daily advice meal has no meal property, only a subsituteMeal property.
        //       Since we want to use the same logic, we copy this property to the meal property (when set).
        dinner.meal = dinner.substituteMeal || dinner.meal;

        if (!dinner.meal.recipe) {
            return null;
        }

        const meal = dinner.meal;
        return (
            <div className="row recipe-row">
                <div className="col s12">
                    <p><strong>{ meal.name }</strong></p>
                    {/* Recipe is currently one big blob of formatted text. For now we at least html-ify the line breaks, so they work. */}
                    {/* REASON: We're actually removing control characters, so in this case its valid. */}
                    {/* eslint-disable-next-line no-control-regex */}
                    <p dangerouslySetInnerHTML={{__html: meal.recipe.replace(new RegExp('\r?\n','g'), '<br>')}}></p>
                </div>
            </div>
        );
    }

    render() {
        let totalNutritionValues;
        if (this.props.dailyAdvice) {
            totalNutritionValues = this._getTotalsForDailyAdvice();
        }

        return (
            <div className="nutrition-week-day-detail">
                {!this.props.dailyAdvice ?
                    <FPLoader fixed="fixed" /> :
                    <React.Fragment>
                        <div className="row nutrition-week-day-row">
                            <div className="col s12 l4 nutrition-week-day-recipe-image">
                                <figure className="recipe-image-container">
                                    <span className="recipe-image"
                                        style={{backgroundImage:this._imageFileName()}}>
                                    </span>
                                </figure>
                            </div>
                            <div className="col s12 l8">
                                <div className="cardHeader">
                                    <div>
                                        <div className="nutrition-week-day-title" onClick={() => this._onClickDay()}>
                                            <h5>{i18n.t('nutritionSchedule.' + this.props.dayInWeek)}</h5>
                                        </div>
                                        <span className="dailyAdvice-title">{this.props.dateFormatted}</span>
                                    </div>
                                    <div className="buttonContainer">
                                        <a className="btn grey cursor noShadow" onClick={() => this._onClickDay()}>
                                            <i className="material-icons">info_outline</i>
                                        </a>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 m7 nutrition-week-day-col-divide">
                                        {this.props.dailyAdvice.dailyAdviceMeals.map((dailyAdviceMeal, index) => {
                                            const substituteMeal = this._findSubstituteForMeal(dailyAdviceMeal);

                                            return (
                                                <div key={this.props.dayInWeek + "-dailyAdviceMeal" + index}
                                                    className="nutrition-week-day-meal">
                                                    {!substituteMeal ?
                                                        <div className={"clearfix row"}>
                                                            <span className="col s8 meal-title" onClick={() => this._onClickMeal(dailyAdviceMeal)}>
                                                                {dailyAdviceMeal.meal.name}
                                                            </span>
                                                            <span className="col s4 right meal-value">
                                                                {Math.floor(dailyAdviceMeal.meal.kcal)} <Trans>nutritionSchedule.kcal</Trans>
                                                            </span>
                                                        </div> :
                                                        <div className="clearfix row substituteMealRow">
                                                            <span className="col s8 meal-title orange-text"
                                                                onClick={() => this._onClickSubstituteMeal(substituteMeal, dailyAdviceMeal)}>
                                                                {substituteMeal.substituteMeal.name}
                                                            </span>
                                                            <span className="col s4 right meal-value">
                                                                {Math.floor(substituteMeal.substituteMeal.kcal)} <Trans>nutritionSchedule.kcal</Trans>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="col s12 m5 nutrition-week-day-nutritiontotals nutrition-week-day-col-divide">
                                        <div>
                                            <div className="clearfix row"><span className="col s12"><span className="meal-title">{i18n.t('nutritionSchedule.values.kcal')}</span><span className="right meal-value">{totalNutritionValues.kcal} <Trans>nutritionSchedule.kcal</Trans></span></span></div>
                                            <div className="clearfix row"><span className="col s12"><span className="meal-title">{i18n.t('nutritionSchedule.values.carbohydrates')}</span><span className="right meal-value">{Math.floor(totalNutritionValues.totalCarbohydrates)} g</span></span></div>
                                            <div className="clearfix row"><span className="col s12"><span className="meal-title">{i18n.t('nutritionSchedule.values.protein')}</span><span className="right meal-value">{Math.floor(totalNutritionValues.totalProtein)} g</span></span></div>
                                            <div className="clearfix row"><span className="col s12"><span className="meal-title">{i18n.t('nutritionSchedule.values.fat')}</span><span className="right meal-value">{Math.floor(totalNutritionValues.totalFat)} g</span></span></div>
                                            <div className="clearfix row"><span className="col s12"><span className="meal-title">{i18n.t('nutritionSchedule.values.fibers')}</span><span className="right meal-value">{Math.floor(totalNutritionValues.totalFibers)} g</span></span></div>
                                            {/*
                                            Our current sodium count is on the high side. This will be fixed later, but in the meantime we hide the sodium info.
                                            <div className="clearfix row"><span className="col s12"><span className="meal-title">{i18n.t('nutritionSchedule.values.sodium')}</span><span className="right meal-value ">{Math.floor(totalNutritionValues.totalSodium)} mg</span></span></div>
                                            */}
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix row total-row">
                                    <div className="col s12 m7">
                                        <span className="right">
                                            <strong><Trans>nutritionSchedule.total</Trans> {totalNutritionValues.kcal} <Trans>nutritionSchedule.kcal</Trans></strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.printMode && this._renderDinnerRecipe()}
                    </React.Fragment>
                }
            </div>
        );
    }
}

DailyAdviceOverView.contextType = UserContext