import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import image from 'utils/image.js';

class ExerciseInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            exercise: null
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        $('#exerciseInfo').modal();
        if (this.props.exerciseId) {
            this._getExercise();
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.exerciseId !== prevProps.exerciseId) {
            this._getExercise();
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _getExercise() {
        this.setState({
            exercise: null
        });

        fmfApiClient.getExercise(this.cancelSource.token, this.props.exerciseId, image.resize(466, 700))
            .then(result => {
                this.setState({
                    exercise: result
                });
                this._activateSlider();
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error.toString()), window.toastDuration);
                this._close();
            });
    }

    _activateSlider() {
        $('#exerciseInfo .exerciseSlider').slick({
            dots: true,
            customPaging: (slider, i) => {
                return $('<button type="button" />').html('<span class="step">' + i18n.t('trainingSchedule.step') + '</span> ' + (i + 1));
            },
        });
    }

    _getExerciseTags() {
        let exerciseTags = [];
        exerciseTags = exerciseTags.concat(this.state.exercise.equipment, this.state.exercise.regions);

        return exerciseTags.join(' | ');
    }

    _close() {
        $(".modal-overlay").trigger("click");
    }

    _getExerciseImage(images) {
        let currentGender = 0;
        // eslint-disable-next-line eqeqeq
        if(this.props.currentClient != null) {
            currentGender = this.props.currentClient.gender;
        }
        let backgroundImage = null;

        if(images.length > 0){
            backgroundImage = images[0].url;
            for(let i = 0; i < images.length; i ++) {
                let imageObject = images[i];
                // eslint-disable-next-line eqeqeq
                if(imageObject.gender == currentGender) {
                    backgroundImage = imageObject.url;
                }
            }
        }

        return backgroundImage;
    }

    render() {
        return (
            <div id="exerciseInfo" className="modal wide">
                {!this.state.exercise ?
                    <FPLoader /> :
                    <div className="exerciseInfo">
                        <div className="sliderContainer">
                            <div className="exerciseSlider">
                                    {this.state.exercise.steps.map((step, index) => (
                                        <div className="slide" key={index}>
                                            <img src={this._getExerciseImage(step.images)} />
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className="infoContainer">
                            <h3>
                                {this.state.exercise.name}<br />
                                <small><span className="extraInfo">{this._getExerciseTags()}</span></small>
                            </h3>
                            {this.state.exercise.steps.map((step, index) => (
                                <div className="stepDescription" key={'step'+index}>
                                    <h6><Trans>trainingSchedule.step</Trans> { step.index }</h6>
                                    <p key={index}>{step.description}</p>
                                </div>
                            ))}
                            <a className="closeButton show-on-small" onClick={() => this._close()}><i className="material-icons">close</i></a>
                        </div>
                        <a className="closeButton hide-on-small-only" onClick={() => this._close()}><i className="material-icons">close</i></a>
                    </div>
                }
            </div>
        )
    }
}

export default ExerciseInfo;