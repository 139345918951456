import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import UpgradeState from 'components/components/upgradeState'

export default function UpgradeFinished() {
    return (
        <UpgradeState>
            <h4><Trans>subscriptionPage.accountChangeDoneTitle</Trans></h4>
            <p><Trans>subscriptionPage.accountChangeDoneText</Trans></p>
            <Link to="/dashboard" className="btn">
                <Trans>generalActions.backToDashboard</Trans>
            </Link>
        </UpgradeState>
    );
}