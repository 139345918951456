import debugKey from './debugKey'
import { Event, Trace, Exception } from "@microsoft/applicationinsights-common";

export default function(envelope) {
    if (envelope.iKey !== debugKey) {
        return;
    }

    // Left: ApplicationInsights-JS/Web - Right: ApplicationInsights-node.js
    const baseType = envelope.baseType || envelope.data.baseType;
    const baseData = envelope.baseData || envelope.data.baseData;

    if (baseType === Event.dataType) {
        console.log('AppInsights Event: ' + baseData.name)
    }

    if (baseType === Trace.dataType) {
        console.log('AppInsights Trace: ' + baseData.message);
    }

    if (baseType === Exception.dataType) {
        if (baseData.exceptions) {
            baseData.exceptions.forEach(ex => {
                let stack = 'AppInsights Exception: ' + ex.message;
                ex.parsedStack.forEach(s => {
                    stack += '\n' + s.method + " " + s.assembly + " [" + s.fileName + "]";
                });
                console.error(stack);
            });
        }
    }

    return false;
}