import React from 'react';
import moment from 'moment';

export default class ScheduleCard extends React.Component {
    _renderStartEndDate(schedule) {
        let startDate = moment(schedule.startDate).format('D MMMM \'YY');
        let endDate = moment(startDate,'D MMMM \'YY');
        endDate = endDate.add(schedule.weeks, 'weeks').format('D MMMM \'YY');
        return startDate + " - " + endDate;
    }

    _renderLastEdited(schedule) {
        let n = new Date();
        n.setDate(n.getDate()-1);
        let lastEditedMoment = moment(schedule.lastEditedAt);
        if(lastEditedMoment.isBefore(n)) {
            return lastEditedMoment.format('dddd D MMMM YYYY');
        } else {
            return lastEditedMoment.fromNow();
        }
    }

    render() {
        const handleCardClick = this.props.handleCardClick;
        const schedule = this.props.schedule;
        const icon = this.props.icon;
        const title = this.props.title;

        return (
            <div className="card customer schedule" onClick={handleCardClick}>
                <div className="card-image">
                    <div className="cardIcon">
                        <i className="material-icons large">{icon}</i>
                    </div>
                    <span className="card-title">{title}</span>
                    <span className="card-description">
                        {this.props.description}&nbsp;
                    </span>
                    <span className="extraInfo">{this._renderStartEndDate(schedule)}</span>
                </div>
                <div className="card-action">
                    <div className="row">
                        <div className="col s12 center-align">
                            { this._renderLastEdited(schedule) }
                        </div>
                    </div>
                </div>
            </div>
    );
 }
}