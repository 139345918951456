import IntakeCalculator from 'utils/nutrition/intakeCalculator.js';

const intakeCalculator = new IntakeCalculator();

/**
 * Encapsulates IntakeCalculator to make it easier to use with FMF resources.
 */
// TODO: Rename to HealthCalculator or something more logical then IntakeCalculator.
export default class FmfIntakeCalculator {
    calculateFatPercentage(client, measurement) {
        if (measurement.skinFoldMeasurementMethod > 0 &&
            !this.canFatPercentageBeCalculatedFromSkinFoldMeasurement(client)) {
            return;
        }

        let result;
        switch (measurement.skinFoldMeasurementMethod) {
            case 0:
                result = measurement.fatPercentage
                break;
            case 1:
                result = this.calculateFatPercentageWith4PointsSkinFold(client, measurement);
                break;
            case 2:
                result = this.calculateFatPercentageWith7PointsSkinFold(client, measurement);
                break;
        }
        return parseInt(result);
    }

    canFatPercentageBeCalculatedFromSkinFoldMeasurement(client) {
        return this.isValidBirthDate(client.birthDate) && this.isValidGender(client.gender);
    }

    isValidBirthDate(birthDate) {
        return birthDate && new Date(birthDate);
    }

    isValidGender(gender) {
        return gender === 1 || gender === 2;
    }

    calculateFatPercentageWith4PointsSkinFold(client, measurement) {
        const c = client;
        const m = measurement;
        return intakeCalculator.calculateFatPercentageWith4PointsSkinFold(
            c.birthDate, c.gender,
            m.bicepsSkinFold, m.tricepsSkinFold, m.subscapularSkinFold, m.iliacCrestSkinFold
        );
    }

    calculateFatPercentageWith7PointsSkinFold(client, measurement) {
        const c = client;
        const m = measurement
        return intakeCalculator.calculateFatPercentageWith7PointsSkinFold(
            c.birthDate, c.gender,
            m.chestSkinFold, m.tricepsSkinFold, m.subscapularSkinFold, m.iliacCrestSkinFold,
            m.abdominalSkinFold, m.thighSkinFold, m.axillarySkinFold
        );
    }

    calculateDailyKcalTarget(currentWeight, currentFatPercentage, activityLevel, targetWeight) {
        const lbm = intakeCalculator.calculateLBM(currentWeight, currentFatPercentage);
        const bmr = intakeCalculator.calculateBMR(lbm);
        const maintenance = intakeCalculator.calculateMaintenance(activityLevel, bmr);
        const adviceKcalDaily = intakeCalculator.calculateDailyKcalTarget(maintenance, currentWeight, targetWeight);

        return {
            maintenance: maintenance,
            adviceKcalDaily: adviceKcalDaily
        };
    }
}