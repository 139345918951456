import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import accounting from 'accounting';
import history from 'history.js';
import fmfApiClient from 'utils/fmf-api-client.js';

export default class InvoiceForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayName: '',
            organizationName: '',
            postcode: '',
            houseNumber: '',
            street: '',
            city: '',
            country: 'Nederland',
            phoneNumber: '',
            email: '',
            organizationContactName: '',
            vatin: '',
            website: '',

            hasError: false,
            errorMessage: '',
            isLoading: true,
            isSubmitting: false,
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        fmfApiClient.getOrganization(this.cancelSource.token)
            .then(result => {
                this._setStartValues(result);
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            }).finally(() => {
                this.setState({isLoading: false}, () => Materialize.updateTextFields());
            });
        this._setFormValidation();
    }

    componentDidUpdate() {
        this._setFormValidation();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
        this.unmounted = true;
    }

    _isUpgrade() {
        return (this.props.upgradeLicense.priceExclVat > this.props.currentLicense.priceExclVat);
    }

    _setFormValidation() {
        $("#invoiceForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: (error, element) => {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: () => {
                this._onSubmit();
            }
        });
    }

    _changeHandler(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    _setStartValues(result) {
        this.setState({
            displayName: result.displayName ? result.displayName: '',
            organizationName: result.organizationName ? result.organizationName: '',
            postcode: result.postcode ? result.postcode: '',
            houseNumber: result.houseNumber ? result.houseNumber: '',
            street: result.street ? result.street: '',
            city: result.city ? result.city: '',
            phoneNumber: result.phoneNumber ? result.phoneNumber: '',
            email: result.email ? result.email: '',
            organizationContactName: result.organizationContactName ? result.organizationContactName: '',
            vatin: result.vatin ? result.vatin: '',
            website: result.website ? result.website: ''
        });
    }

    _onSubmit () {
        this.setState({
            isSubmitting: true,
            hasError: false,
            errorMessage: '',
        });

        let account = {
            displayName: this.state.displayName,
            organizationName: this.state.organizationName,
            postcode: this.state.postcode,
            houseNumber: this.state.houseNumber,
            street: this.state.street,
            country: this.state.country,
            city: this.state.city,
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
            organizationContactName: this.state.organizationContactName,
            vatin: this.state.vatin,
            website: this.state.website
        };

        fmfApiClient.updateOrganization(account)
            .then(result => {
                fmfApiClient.changeLicense(null, this.props.upgradeLicense.id)
                    .then(result => {
                        if (result) {
                            window.location = result;
                        }
                        else {
                            fmfApiClient.onChangeLicenseComplete()
                                .then(result => {
                                    history.push('/profile/upgrade-afgerond');
                                }, error => {
                                    if (this.unmounted) return;
                                    this.setState({
                                        errorMessage: i18n.t('errors.' + error.error),
                                        hasError: true,
                                        isSubmitting: false
                                    });
                                });
                        }
                    }, error => {
                        if (this.unmounted) return;
                        this.setState({
                            errorMessage: i18n.t('subscriptionPage.errors.' + error.error)
                                || i18n.t('errors.general_error'),
                            hasError: true,
                            isSubmitting: false
                        });
                    });
            }, error => {
                if (this.unmounted) return;
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    hasError: true,
                    isSubmitting: false
                });
            });
    }

    _cancel(event) {
        event.preventDefault();
        this.props.onCancelled();
    }

    render() {
        return (
            <div className="container inlineModal" id="invoiceFormContainer">
                <div className="row">
                    <div className="col s12 m8 offset-m2 l6 offset-l3">
                        <div className="modalContentContainer">
                            {!this.state.isLoading &&
                                <React.Fragment>
                                    <div className="row">
                                        <div className="col s12 center-align">
                                            { !this._isUpgrade() ?
                                                <div>
                                                    <h4>
                                                        {i18n.t('subscriptionPage.downgradeTo', {upgradeName: this.props.upgradeLicense.name})}<br />
                                                        <small><Trans>subscriptionPage.paymentFormSubtitle</Trans></small>
                                                    </h4>
                                                    <div id="card-alert" className="card red">
                                                        <div className="card-content white-text">
                                                            <Trans>subscriptionPage.accountUserDowngradeMessage</Trans>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <h4>
                                                    {i18n.t('subscriptionPage.upgradeTo', {upgradeName: this.props.upgradeLicense.name})}<br />
                                                    <small><Trans>subscriptionPage.paymentFormSubtitle</Trans></small>
                                                </h4>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col s12">
                                            <h4 className="smallGreyTitle"><Trans>subscriptionPage.paymentMethod</Trans></h4>
                                            <div className="card bordered infoCard">
                                                <div className="card-content noMinHeight">
                                                    { this.props.upgradeLicense.priceExclVat > 0 ?
                                                        <div>
                                                            <span className="card-title">
                                                                iDeal <img src="/images/ideal-icon.png" width="32" className="inlineImage"/>
                                                            </span>
                                                            <span className="card-subtitle">
                                                                <Trans>subscriptionPage.paymentMethodDescriptionMonthlyDirectDebit</Trans>
                                                            </span>
                                                        </div>:
                                                        null
                                                    }
                                                    <h6>
                                                        <strong>
                                                            <Trans>subscriptionPage.newPrice</Trans>&nbsp;
                                                            <small>(<Trans>subscriptionPage.fromNextDirectDebit</Trans>)</small>
                                                        </strong>
                                                    </h6>
                                                    <div className="row noMarginBottom">
                                                        <div className="col s12">
                                                            <span className="upgradePrice">
                                                                <span className="valuta">€</span>
                                                                <span className="price">
                                                                    {this.props.upgradeLicense ?
                                                                        /* eslint-disable-next-line eqeqeq */
                                                                        this.props.upgradeLicense.priceExclVat == 0 ?
                                                                        '0,-' :
                                                                        this.props.upgradeLicense.priceExclVat + ',-' : ''
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className="variableName">
                                                                &nbsp;
                                                                {this.props.upgradeLicense ? (
                                                                    /* eslint-disable-next-line eqeqeq */
                                                                    this.props.upgradeLicense.priceExclVat == 0 ?
                                                                        null :
                                                                        <small>
                                                                            {accounting.formatMoney(this.props.upgradeLicense.priceInclVat)}
                                                                            &nbsp;incl.&nbsp;
                                                                            {this.props.upgradeLicense.vatPercentage}
                                                                            % BTW
                                                                        </small>
                                                                    ) :
                                                                    ''
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form id="invoiceForm" onSubmit={e => e.preventDefault()}>
                                        <div className="row noMarginBottom">
                                            <div className="col s12">
                                                <h4 className="smallGreyTitle"><Trans>upgradeToPayment.modalTitle</Trans></h4>
                                                <br />
                                                {/* ERROR MESSAGE */}
                                                <div className={this.state.hasError ? '' : 'hide'}>
                                                    <div id="card-alert" className="card red">
                                                        <div className="card-content white-text">
                                                            <p dangerouslySetInnerHTML={this._setErrorMessageForInnerHTML()}></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="organizationName" type="text"
                                                    name="organizationName"
                                                    value={this.state.organizationName}
                                                    onChange={e => this._changeHandler(e)}
                                                    required data-msg={ i18n.t('errors.required_field') }
                                                />
                                                <label htmlFor="organizationName" >{ i18n.t('formFields.company') }</label>
                                            </div>
                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="organizationContactName" type="text"
                                                    name="organizationContactName"
                                                    value={this.state.organizationContactName}
                                                    onChange={e => this._changeHandler(e)}
                                                />
                                                <label htmlFor="organizationContactName" >{ i18n.t('formFields.organizationContactName') }</label>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="postcode" type="text"
                                                    name="postcode"
                                                    value={this.state.postcode}
                                                    onChange={e => this._changeHandler(e)}
                                                    required data-msg={ i18n.t('errors.required_field') }
                                                />
                                                <label htmlFor="postcode" >{ i18n.t('formFields.postcode') }</label>
                                            </div>

                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="houseNumber" type="text"
                                                    name="houseNumber"
                                                    value={this.state.houseNumber}
                                                    onChange={e => this._changeHandler(e)}
                                                    required data-msg={ i18n.t('errors.required_field') }
                                                />
                                                <label htmlFor="houseNumber" >{ i18n.t('formFields.houseNumber') }</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="street" type="text"
                                                    name="street"
                                                    value={this.state.street}
                                                    onChange={e => this._changeHandler(e)}
                                                    required data-msg={ i18n.t('errors.required_field') }
                                                />
                                                <label htmlFor="street" >{ i18n.t('formFields.street') }</label>
                                            </div>

                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="city" type="text"
                                                    name="city"
                                                    value={this.state.city}
                                                    onChange={e => this._changeHandler(e)}
                                                    required data-msg={ i18n.t('errors.required_field') }
                                                />
                                                <label htmlFor="city" >{ i18n.t('formFields.city') }</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="phoneNumber" type="text"
                                                    name="phoneNumber"
                                                    value={this.state.phoneNumber}
                                                    onChange={e => this._changeHandler(e)}
                                                    required data-msg={ i18n.t('errors.required_field') }
                                                />
                                                <label htmlFor="phoneNumber" >{ i18n.t('formFields.phoneNumber') }</label>
                                            </div>

                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="email" type="email"
                                                    name="email"
                                                    value={this.state.email}
                                                    onChange={e => this._changeHandler(e)}
                                                    required data-msg={ i18n.t('errors.required_field') }
                                                    className="validate"
                                                />
                                                <label htmlFor="email" >{ i18n.t('formFields.organizationEmail') }</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className='input-field col s12 m6'>
                                                <input
                                                    id="vatin" type="text"
                                                    name="vatin"
                                                    value={this.state.vatin}
                                                    onChange={e => this._changeHandler(e)}
                                                />
                                                <label htmlFor="vatin">{ i18n.t('formFields.vatin') }</label>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col s12 right-align">
                                                <a className="btn grey noShadow" onClick={(e) => this._cancel(e)}><Trans>generalActions.cancel</Trans></a>
                                                <button disabled={this.state.isLoading || this.state.isSubmitting ? 'disabled' : ''}
                                                    className="waves-effect waves-light btn noShadow" type="submit">
                                                    { !this._isUpgrade() ?
                                                        <Trans>subscriptionPage.confirmDowngrade</Trans> :
                                                        <Trans>subscriptionPage.confirmUpgrade</Trans>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}