import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import moment from 'moment';
import fmfApiClient from 'utils/fmf-api-client.js';
import history from 'history.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import GoalModalPart from './goalModalPart';

class AddTrainingSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nrOfWeeks: '',
            duration: '',
            startDate: moment().format('DD-MM-YYYY'),
            serverStartDate: new Date().toLocalISOString(),

            goal: this.props.currentGoal,

            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'
        };

        this.picker = null;

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);
        this._onGoalChanging = this._onGoalChanging.bind(this);
        this._onGoalChange = this._onGoalChange.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentGoal !== this.props.currentGoal) {
            this.setState({
                goal: nextProps.currentGoal
            });
        }
    }

    componentDidMount() {
        $(ReactDOM.findDOMNode(this.datePickerContainer)).pickadate({
            selectYears: 70,
            today: i18n.t('trainingSchedule.today'),
            selectMonths: true,
            container: $('#page'),
            format: 'dd-mm-yyyy',
            closeOnSelect: true,
            onSet: function(timeSet) {
                this._setDate(timeSet);
            }.bind(this)
        });

        $("#addTrainingScheduleForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _setDate(setDate) {
        if(setDate.select) {
            this.setState({
                serverStartDate: new Date(setDate.select).toLocalISOString(),
                startDate: new Date(setDate.select).toInversedShortDateString()
            });
        }
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        // Hack:
        // We can't pass complex objects to the `addTrainingSchedule` route, because they cannot be described in an url.
        // The `AddTrainingScheduleLayout` component however picks the current client goal by default.
        // This means that if we set the current client goal to the goal in the training schedule, we can basically pass
        // the goal variables to the add training schedule route.
        fmfApiClient.updateClientGoal(this.cancelSource.token, this.props.clientId, this.state.goal)
            .then(result => {
                $(".modal-overlay").trigger("click");
                history.push('/clienten/' + this.props.clientId + '/trainingsschema/toevoegen/' + this.state.duration + '/' + this.state.startDate);
            }, error => {
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error.toString()),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    _onGoalChanging() {
        this.setState({
            buttonDisabled: 'disabled',
            showLoading: ''
        });
    }

    _onGoalChange(goal) {
        this.setState({
            buttonDisabled: '',
            showLoading: 'hide',
            goal: goal
        });
    }

    _onError(errorMessage) {
        this.setState({
            errorMessage: errorMessage,
            buttonDisabled: '',
            showLoading: 'hide',
            showError: ''
        });
    }

    render() {
        return (
            <div id="addTrainingSchedule" className="modal medium">
                <form id="addTrainingScheduleForm" noValidate onSubmit={this._submitForm}>
                    <div className="modal-content">
                        <div className="row noMarginBottom">
                            <div className="col s12">
                                <h4><Trans>trainingSchedule.addModalTitle</Trans></h4>
                            </div>
                        </div>
                        <div className="row noMarginBottom">
                            <div className="col s6 input-field">
                                <input type="text"
                                    id="trainingScheduleStartDate"
                                    name="startDate"
                                    value={this.state.startDate}
                                    onChange={this._changeHandler}
                                    required data-msg={ i18n.t('errors.required_field') }
                                    className="datepicker"
                                    ref={datePicker => { this.datePickerContainer = datePicker }} />
                                <label htmlFor="trainingScheduleStartDate">{ i18n.t('formFields.startDate') }</label>
                            </div>
                            <div className="col s6 input-field">
                                <select id="duration"
                                        name="duration"
                                        className="browser-default"
                                        value={this.state.duration}
                                        required data-msg={ i18n.t('errors.required_field') }
                                        onChange={this._changeHandler}>
                                    <option value="" disabled>{ i18n.t('formFields.durationWeeks') }</option>
                                    <option value="3">3 { i18n.t('formFields.weeks') }</option>
                                    <option value="4">4 { i18n.t('formFields.weeks') }</option>
                                    <option value="5">5 { i18n.t('formFields.weeks') }</option>
                                    <option value="6">6 { i18n.t('formFields.weeks') }</option>
                                    <option value="7">7 { i18n.t('formFields.weeks') }</option>
                                    <option value="8">8 { i18n.t('formFields.weeks') }</option>
                                    <option value="9">9 { i18n.t('formFields.weeks') }</option>
                                    <option value="10">10 { i18n.t('formFields.weeks') }</option>
                                </select>
                            </div>
                        </div>

                        <div className="row">
                            <GoalModalPart currentGoal={this.props.currentGoal} onGoalChanging={this._onGoalChanging}
                                onGoalChange={this._onGoalChange} onError={this._onError} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col s12">
                                <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>generalActions.create</Trans></button>
                                <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}} >
                                    <CircleLoader/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default AddTrainingSchedule;