import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import moment from 'moment';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';

export default class NoteCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            edit: false,
            showLoading: 'hide',
            formInitialized: false
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};

        if (!props.note) return newState;
        if (state.initialBody !== props.note.body) {
            newState = {
                ...newState,
                body: props.note.body,
                initialBody: props.note.body
            };
        }

        return newState;
    }

    componentDidUpdate() {
        // eslint-disable-next-line eqeqeq
        if(this.state.edit == true && this.state.formInitialized == false) {
            this.setState({
                formInitialized: true
            });

            $('textarea.autogrow').autogrow();

            $("#noteForm" + this.props.note.id).validate({
                errorClass: 'invalid',
                validClass: 'valid',
                errorElement: 'span',
                errorPlacement: (error, element) => {
                    // eslint-disable-next-line eqeqeq
                    if (element.attr("type") == "checkbox" ) {
                        element.parent('.input-field').append(error);
                    } else {
                        error.insertAfter(element);
                    }
                },
                submitHandler: () => {
                    this._onSubmit();
                }
            });
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _isUserTypeClient() {
        const user = this.context.currentUser;
        const userType = user && user.identityToken && user.identityToken.user_type;
        return userType === 'Client';
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _refreshNoteList() {
        if (typeof this.props.onRefresh === 'function') {
            this.props.onRefresh();
        }
    }

    _deleteNote() {
        if(window.confirm(i18n.t('notes.confirmNoteDelete'))) {
            fmfApiClient.deleteClientNote(this.cancelSource.token, this.props.clientId, this.props.note.id)
                .then(result => {
                    this._refreshNoteList();
                }, error => {
                    Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                });
        }
    }

    _editNote() {
        // eslint-disable-next-line eqeqeq
        if(this.state.edit == false) {
            this.setState({
                edit: true
            });
        }
    }

    _cancelEdit() {
        // eslint-disable-next-line eqeqeq
        if(this.state.edit == true) {
            this.setState({
                edit: false,
                formInitialized: false,
                body: this.state.initialBody
            });
        }
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            buttonDisabled: 'disabled',
            showLoading: '',
        });

        let note = {
            body: this.state.body
        };

        fmfApiClient.updateClientNote(this.cancelSource.token, this.props.clientId, this.props.note.id, note)
            .then(result => {
                this.setState({
                    buttonDisabled: '',
                    formInitialized: false,
                    showLoading: 'hide',
                    edit: false
                });
                this.props.onRefresh();
                Materialize.toast(i18n.t('notes.addNoteSucces'), window.toastDuration);
            }, error => {
                this.setState({
                    edit: false,
                    formInitialized: false,
                    body: this.state.initialBody,
                    showLoading: 'hide'
                });
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });
    }

    render() {
        let n = new Date();
        let lastEdited = '';
        n.setDate(n.getDate()-1);
        let lastEditedMoment = moment(this.props.note.lastEditedAt);
        if(lastEditedMoment.isBefore(n)) {
            lastEdited = lastEditedMoment.format('dddd D MMMM YYYY');
        } else {
            lastEdited = lastEditedMoment.fromNow();
        }

        return (
            <div className="card white note">
                <div className="card-content">
                    <div className="row">
                        <div className="col s1">
                            <i className="material-icons tiny card-icon">question_answer</i>
                        </div>
                        <div className="col s11">
                            <div className="notesDetails">
                                <span className="date">{lastEdited}</span>
                                {!this._isUserTypeClient() &&
                                    <span className="itemTools">
                                        <a className="cursor" onClick={() => this._editNote()} ><i className="material-icons">create</i></a>
                                        <a className="cursor" onClick={() => this._deleteNote()} ><i className="material-icons">delete</i></a>
                                    </span>
                                }
                            </div>
                            {this.state.edit ?
                                <form id={"noteForm" + this.props.note.id} noValidate onSubmit={this._submitForm}>
                                    <textarea id="body"
                                            type="text"
                                            name="body"
                                            value={this.state.body}
                                            onChange={(e) => this._changeHandler(e)}
                                            className="materialize-textarea autogrow"
                                            required data-msg={ i18n.t('errors.required_field') } >
                                    </textarea>
                                    <div className="row">
                                        <div className="col s12">
                                            <button disabled={this.state.buttonDisabled} onClick={() => this._cancelEdit()}
                                            className="waves-effect waves-light btn grey noShadow" type="button">
                                                <Trans>generalActions.cancel</Trans>
                                            </button>
                                            <button disabled={this.state.buttonDisabled}
                                                className="waves-effect waves-light btn noShadow" type="submit">
                                                <Trans>generalActions.save</Trans>
                                            </button>
                                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}>
                                                <CircleLoader/>
                                            </div>
                                        </div>
                                    </div>
                                </form> :
                                <p>{this.state.body}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NoteCard.contextType = UserContext