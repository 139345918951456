// We use many async callbacks. This creates the problem of having an incomplete
// stack trace, since it only includes the callback path, and not the original caller.
// For example: with the stack trace from the callback we can't determine which method called the fmf api client.
//
// To solve this, we save the call stack earlier on, and apply it to the callback error object.
export function fixAsyncStack() {
    const temporaryErrorName = 'TEMPORARY_NAME';
    const originalCallStack = new Error('TEMPORARY_NAME').stack;
    return (err) => {
        err.stack = originalCallStack && originalCallStack.replace(temporaryErrorName, err.message);
    }
}
