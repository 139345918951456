import React from 'react';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import GoalModalPart from './goalModalPart';

export default class AddGoal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._onGoalChanging = this._onGoalChanging.bind(this);
        this._onGoalChange = this._onGoalChange.bind(this);
        this._onError = this._onError.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        $("#addGoalForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                window.event.preventDefault();
                this._onSubmit();
            }.bind(this)
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        const currentGoal = nextProps.currentGoal;
        if(currentGoal !== this.state.goal){
            this.setState({
                goal: currentGoal
            })
            Materialize.updateTextFields();
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        // eslint-disable-next-line eqeqeq
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        fmfApiClient.updateClientGoal(this.cancelSource.token, this.props.clientId, this.state.goal)
            .then(result => {
                this.setState({
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: ''
                });

                $(".modal-overlay").trigger("click");
                Materialize.toast(i18n.t('addGoal.successText'), window.toastDuration);
                if (typeof this.props.onChange === 'function') {
                    this.props.onChange();
                }
            }, error => {
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error.toString()),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    _setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    _onError(errorMessage) {
        this.setState({
            errorMessage: errorMessage,
            buttonDisabled: '',
            showLoading: 'hide',
            showError: ''
        });
    }

    _onGoalChanging() {
        this.setState({
            buttonDisabled: 'disabled',
            showLoading: ''
        });
    }

    _onGoalChange(goal) {
        this.setState({
            buttonDisabled: '',
            showLoading: 'hide',
            goal: goal
        });
    }

    render() {
        return (
            <div id="addGoal" className="modal medium">
                <form id="addGoalForm" noValidate onSubmit={this._submitForm}>
                <div className="modal-content">
                    <div className="row noMarginBottom">
                        <div className="col s12">
                            <h4><Trans>addGoal.modalTitle</Trans></h4>
                            {/* ERROR MESSAGE */}
                            <div className={this.state.showError}>
                                <div id="card-alert" className="card red">
                                    <div className="card-content white-text">
                                        <p dangerouslySetInnerHTML={this._setErrorMessageForInnerHTML()}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <GoalModalPart currentGoal={this.props.currentGoal} onGoalChanging={this._onGoalChanging}
                        onGoalChange={this._onGoalChange} onError={this._onError}
                    />
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12">
                            <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>generalActions.save</Trans></button>
                            <button className="waves-effect waves-light btn noShadow grey modal-close" type="button"><Trans>generalActions.cancel</Trans></button>
                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                <CircleLoader/>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}