import React from 'react';
import { UserContext } from 'contexts.jsx';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import UpgradeBlock from './upgradeBlock.jsx';
import InvoiceForm from './InvoiceForm.jsx';

export default class ChangeLicense extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            licenses: [],
            currentLicense: null,
            upgradeLicense: null,
            showInvoiceForm: false,

            isLoading: true,
            isSelectingLicense: false
        };

        this.isLoaded_getLicenses = false;
        this.isLoaded_getOrganizationLicense = false;

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _checkIfLoaded() {
        if (this.isLoaded_getLicenses && this.isLoaded_getOrganizationLicense) {
            this.setState({isLoading: false});
        }
    }

    componentDidMount() {
        fmfApiClient.getLicenses(this.cancelSource.token)
            .then(result => {
                this.setState({licenses: result}, () => {
                    this.isLoaded_getLicenses = true;
                    this._checkIfLoaded();
                });
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });

        fmfApiClient.getOrganizationLicense(this.cancelSource.token)
            .then(result => {
                this.setState({currentLicense: result}, () => {
                    this.isLoaded_getOrganizationLicense = true;
                    this._checkIfLoaded();
                });
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });
    }

    _unlinkControlPlusIfRequired(licenseId, callback) {
        fmfApiClient.isControlPlusUnlinkRequired(this.cancelSource.token, licenseId)
            .then(result => {
                if (result) {
                    if (window.confirm(i18n.t('controlPlus.linkDisabledOnLicenseChange'))) {
                        this._unlinkControlPlus(callback)
                    }
                } else {
                    callback();
                }
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });
    }

    _unlinkControlPlus(callback) {
        fmfApiClient.controlPlusUnlink(this.cancelSource.token)
            .then(result => {
                callback();
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });
    }

    _selectUpgradeLicense(license) {
        this.setState({isSelectingLicense: true})
        const licenseId = license.id;
        this._unlinkControlPlusIfRequired(licenseId, () => {
            this.setState({
                upgradeLicense: license,
                showInvoiceForm: true
            }, () => this.setState({isSelectingLicense: false}));
        });
    }

    _cancelUpgrade() {
        this.setState({
            showInvoiceForm: false,
            isSelectingLicense: false
        });
    }

    render() {
        return (
            <div id="page" className={this.state.showInvoiceForm ? 'invoice' : ''}>
                <div className="container" id="pricesContainer">
                    <div className="row">
                        <div className="col s12 center-align white-text">
                            <h3>
                                {this.props.title}<br />
                                <small>{this.props.subtitle}</small>
                            </h3>
                        </div>
                    </div>
                    <div className="row upgradeBlock-container">
                        {!this.state.isLoading &&
                            this.state.licenses.map((license) => (
                                <UpgradeBlock
                                    key={license.id.toString()}
                                    license={license}
                                    currentLicense={this.state.currentLicense}
                                    onLicenseSelected={(license) => this._selectUpgradeLicense(license)}
                                    isEnabled={!this.state.isSelectingLicense}
                                />
                            ))
                        }
                    </div>
                    <div className="row">
                        <div className="col s12 center-align">
                            <Trans>subscriptionPage.paymentInformation</Trans>
                        </div>
                    </div>
                </div>

                {!this.state.isLoading && this.state.showInvoiceForm &&
                    <InvoiceForm
                        currentLicense={this.state.currentLicense}
                        upgradeLicense={this.state.upgradeLicense}
                        onCancelled={() => this._cancelUpgrade()}
                    />
                }
            </div>
        )
    }
}

ChangeLicense.contextType = UserContext