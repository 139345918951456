import React from 'react';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: props.email,
            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._changeUsername = this._changeUsername.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    changeUsername (username) {
        this.setState({
            username: username,
        });
    }

    _changeUsername (event) {
        this.setState({
            username: event.target.value,
        });
    }

    _onSubmit (event) {
        event.preventDefault();
        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        fmfApiClient.sendResetPasswordMail(this.cancelSource.token, this.state.username)
            .then(result => {
                this.setState({
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: ''
                });
            }, error => {
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    render() {
        return (
            <div id="forgotPassword" className="modal">
                <form id="forgotPasswordForm" onSubmit={this._onSubmit}>
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12">
                            <h4><Trans>forgotPass.modalTitle</Trans></h4>
                            <p><Trans>forgotPass.modalText</Trans></p>
                            {/* ERROR MESSAGE */}
                            <div className={this.state.showError}>
                                <div id="card-alert" className="card red">
                                    <div className="card-content white-text">
                                        <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                    </div>
                                </div>
                            </div>
                            {/* SUCCES MESSAGE */}
                            <div className={this.state.showSuccess}>
                                <div id="card-success" className="card primary">
                                    <div className="card-content">
                                        <p><Trans>forgotPass.successText</Trans></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 input-field">
                            <input
                                id="username" type="email"
                                value={this.state.username}
                                onChange={this._changeUsername}
                                className="validate" />
                            <label htmlFor="username" data-error={ i18n.t('errors.no_valid_email') }>{ i18n.t('formFields.email') }</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12">
                            <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>forgotPass.modalButton</Trans></button>
                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                <CircleLoader/>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}

export default ForgotPassword;