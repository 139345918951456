import React from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from 'contexts.jsx';
import { Trans } from 'react-i18next';

export default class Sidenav extends React.Component {
    constructor(props) {
        super(props);

        this._changePassword = this._changePassword.bind(this);
    }

    componentDidMount() {
        $(".button-collapse").sideNav({
            edge: 'right',
            closeOnClick: true,
            menuWidth: 300,
            draggable: true
        });
    }

    _isUserTypeClient(user) {
        const userType = user && user.identityToken && user.identityToken.user_type;
        return userType === 'Client';
    }

    _changePassword() {
        $('#changePassword').modal();
        $('#changePassword').modal('open');
    }

    hasFeature(feature){
        const { currentUser } = this.context;
        return currentUser &&
            currentUser.identityToken.features &&
            currentUser.identityToken.features.indexOf(feature) !== -1;
    }

    render() {
        const { currentUser } = this.context;
        const isUserTypeClient = this._isUserTypeClient(currentUser);
        const hasControlPlusLink = currentUser.identityToken.hasOwnProperty('control_plus_link');

        return (
            <ul id="slide-out" className="side-nav">
                <li>
                    <div className="userView">
                        <div className="background">
                            <img src="/images/profileheader.jpg"/>
                        </div>
                        { currentUser ? <img className="circle" src={currentUser.profile.imageUrl}/> : ''}
                        <span className="white-text name">{currentUser ? currentUser.identityToken.fullname : ''}</span>
                        <span className="white-text email">{currentUser ? currentUser.identityToken.username : ''}</span>
                    </div>
                </li>
                { this.hasFeature("Cms") ?
                <ul>
                    <li><Link to="/cms"><Trans>sidenav.cms</Trans></Link></li>
                    <li><div className="divider"></div></li>
                </ul>
                : '' }
                { !isUserTypeClient ?
                    <ul>
                        <li><Link to="/dashboard"><Trans>sidenav.dashboard</Trans></Link></li>
                        <li><Link to="/mijn-templates"><Trans>sidenav.myTemplates</Trans></Link></li>
                        <li><div className="divider"></div></li>
                        <li><Link to="/profile"><Trans>sidenav.profileSettings</Trans></Link></li>
                        <li><Link to="/profile/styling"><Trans>sidenav.styling</Trans></Link></li>
                        <li><Link to="/profile/users"><Trans>sidenav.users</Trans></Link></li>

                        {!hasControlPlusLink && (
                            <>
                                <li><Link to="/profile/account"><Trans>sidenav.accountSettings</Trans></Link></li>
                                <li><Link to="/profile/abonnement"><Trans>sidenav.subscription</Trans></Link></li>
                            </>
                        )}

                        <li><div className="divider"></div></li>
                    </ul>
                : null }
                <ul>
                    <li><a className="cursor" onClick={this._changePassword}><Trans>sidenav.changePassword</Trans></a></li>
                    <li><Link to="/uitloggen"><Trans>sidenav.logout</Trans></Link></li>
                </ul>
            </ul>
        )
    }
}

Sidenav.contextType = UserContext