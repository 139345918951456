export class MergeShoppingList {
    mergeShoppingListFromNutritionScheduleWeek(nutritionSchedule, week) {
        let mealNutritions = this._readMealNutritionsFromSchedule(nutritionSchedule, week);
        return this._mergeShoppingListFromMealNutritions(mealNutritions);
    }

    mergeShoppingListFromDailyAdvicePerMeal(mealSubstitutes, weeklyAdviceId, dailyAdvice) {
        let meals = this._readMealsFromDailyAdvice(mealSubstitutes, weeklyAdviceId, dailyAdvice);
        return this._groupByMealName(meals);
    }

    mergeShoppingListFromDailyAdvicePerGroupName(mealSubstitutes, weeklyAdviceId, dailyAdvice) {
        let mealNutritions = this._readMealNutritionsFromDailyAdvice(mealSubstitutes, weeklyAdviceId, dailyAdvice);
        return this._mergeShoppingListFromMealNutritions(mealNutritions);
    }

    _mergeShoppingListFromMealNutritions(nutritions) {
        let mergedNutritions = this._mergeMealNutritionsBy(nutritions, mealNutrition => mealNutrition.nutrition.shoppingListName);
        mergedNutritions = this._filterByNullOf(mergedNutritions, mealNutrition => mealNutrition.nutrition.shoppingListName);
        let shoppingList = this._groupBy(mergedNutritions, mealNutrition => mealNutrition.nutrition.nutritionGroupName);
        shoppingList = this._orderByGroupAndBy(shoppingList, mealNutrition => mealNutrition.nutrition.shoppingListName);
        return shoppingList;
    }

    _readMealNutritionsFromSchedule(nutritionSchedule, week) {
        let mealNutritions = [];
        let weeklyAdvice = nutritionSchedule.weeklyAdvices[week - 1];

        weeklyAdvice.dailyAdvices.forEach(dailyAdvice =>
            mealNutritions.push(...this._readMealNutritionsFromDailyAdvice(
                nutritionSchedule.mealSubstitutes,
                weeklyAdvice.id,
                dailyAdvice
            ))
        );

        return mealNutritions;
    }

    _readMealNutritionsFromDailyAdvice(mealSubstitutes, weeklyAdviceId, dailyAdvice) {
        let mealNutritions = [];
        dailyAdvice.dailyAdviceMeals.forEach(dailyAdviceMeal =>
            mealNutritions.push(...this._readMealNutritionsFromDailyAdviceMeal(mealSubstitutes, weeklyAdviceId, dailyAdviceMeal))
        );
        return mealNutritions;
    }

    _readMealsFromDailyAdvice(mealSubstitutes, weeklyAdviceId, dailyAdvice) {
        let mealNutritions = [];
        dailyAdvice.dailyAdviceMeals.forEach(dailyAdviceMeal =>
            mealNutritions.push(this._readMealFromDailyAdviceMeal(mealSubstitutes, weeklyAdviceId, dailyAdviceMeal))
        );
        return mealNutritions;
    }

    _readMealNutritionsFromDailyAdviceMeal(mealSubstitutes, weeklyAdviceId, dailyAdviceMeal) {
        return this._readMealFromDailyAdviceMeal(mealSubstitutes, weeklyAdviceId, dailyAdviceMeal)
            .mealNutritions;
    }

    _readMealFromDailyAdviceMeal(mealSubstitutes, weeklyAdviceId, dailyAdviceMeal) {
        const mealSubstitute = mealSubstitutes.find(mealSubstitute =>
            // eslint-disable-next-line eqeqeq
            mealSubstitute.dailyAdviceMealId == dailyAdviceMeal.id  && mealSubstitute.weeklyAdviceId == weeklyAdviceId);

        if (mealSubstitute) {
            return mealSubstitute.substituteMeal;
        }

        return dailyAdviceMeal.meal;
    }

    _filterByNullOf(mealNutritions, nameGetter) {
        return mealNutritions.filter(mealNutrition => nameGetter(mealNutrition))
    }

    _mergeMealNutritionsBy(mealNutritions, nameGetter) {
        let mergedMealNutritions = [];

        mealNutritions.forEach(mealNutrition => {
            const existingMealNutrition = mergedMealNutritions.find(existingMealNutrition =>
                nameGetter(existingMealNutrition) === nameGetter(mealNutrition)
            );

            if (existingMealNutrition) {
                existingMealNutrition.quantity += mealNutrition.quantity;
            } else {
                // We make a (shallow) copy, because we might update the quantity.
                // Without a copy, the original meal nutrition will also update
                // its quantity.
                mergedMealNutritions.push(Object.assign({}, mealNutrition));
            }
        });

        return mergedMealNutritions;
    }

    _groupByMealName(meals) {
        return meals.map(meal => [meal.name, meal.mealNutritions]);
    }

    _orderByGroupAndBy(shoppingList, nameGetter) {
        return shoppingList
            .sort((a, b) => this._compare(a[0].toLowerCase(), b[0].toLowerCase()))
            .map(item => [
                item[0],
                item[1].sort((a, b) => this._compare(nameGetter(a).toLowerCase(), nameGetter(b).toLowerCase()))
            ]);
    }

    _compare(a,b) {
        if (a < b)
            return -1;
        if (a > b)
            return 1;
        return 0;
    }

    _groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return Array.from(map);
    }
}