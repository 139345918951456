import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import arrayMove from 'array-move';
import moment from 'moment';
import history from 'history.js';
import fmfApiClient from 'utils/fmf-api-client.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import ListContainer from 'components/components/sortableList/listContainer.jsx';
import { SortableContainer } from 'react-sortable-hoc';

class ScheduleExercises extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: null,
            goal: null,
            exercises: [],
            showLoading: 'hide',
            buttonDisabled: ''
        };

        this._changeHandler = this._changeHandler.bind(this);
        this._openDatePicker = this._openDatePicker.bind(this);
        this._cancel = this._cancel.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
        this._deleteExercise = this._deleteExercise.bind(this);
        this._setOneRepetitionMax = this._setOneRepetitionMax.bind(this);
        this._saveSchedule = this._saveSchedule.bind(this);
        this.saveAsTemplate = this.saveAsTemplate.bind(this);
        this._openSaveAsTemplate = this._openSaveAsTemplate.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    UNSAFE_componentWillMount() {
        let startDate = moment(this.props.startDate,'DD-MM-YYYY');
        this.setState({
            startDate: startDate,
            exercises: this.props.exercises,
            goal: this.props.goal
        });
    }

    _setDate (setDate) {
        if(setDate.select) {
            this.setState({
                startDate: moment(setDate.select)
            });
        }
    }

    componentDidMount() {
        let picker = $('#startDate').pickadate({
            selectYears: 70,
            container: $('.splitcolumnPage'),
            today: i18n.t('trainingSchedule.today'),
            selectMonths: true,
            format: 'dd-mm-yyyy',
            closeOnSelect: true,
            onSet: function(timeSet) {
                this._setDate(timeSet);
            }.bind(this)
        });
        this.picker = picker.pickadate('picker');
        $('.helpLink .tooltipped').tooltip({delay: 200});
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _openDatePicker(event) {
        event.stopPropagation();
        event.preventDefault();

        this.picker.open();
    }

    _cancel() {
        if(window.confirm(i18n.t('trainingSchedule.cancelAdd'))){
            if(this.props.trainingScheduleId) {
                history.push('/clienten/' + this.props.client.id + '/trainingsschema/' + this.props.trainingScheduleId);
            } else {
                history.push('/clienten/' + this.props.client.id);
            }
        }
    }

    addExercise(exercise) {
        let exerciseArray = [];
        exerciseArray = this.state.exercises;

        let exerciseObject = {
            exercise: exercise,
            oneRepetitionMax: 0
        }

        exerciseArray.push(exerciseObject);
        this.setState({
            exercises: exerciseArray
        });
    }

    addExercises(exercises) {
        let exerciseArray = [];
        exerciseArray = this.state.exercises;

        Object.keys(exercises).forEach(function(key,index) {
            let exercise = exercises[index];
            let exerciseObject = {
                exercise: exercise,
                oneRepetitionMax: 0
            }
            exerciseArray.push(exerciseObject);
        });

        this.setState({
            exercises: exerciseArray
        });
    }

    onSortEnd({oldIndex, newIndex}) {
        this.setState({
            exercises: arrayMove(this.state.exercises, oldIndex, newIndex),
        });
    }

    _deleteExercise(index) {
        let exerciseArray = [];
        exerciseArray = this.state.exercises;

        exerciseArray.splice(index, 1);

        this.setState({
            exercises: exerciseArray
        });
    }

    _setOneRepetitionMax(index, oneRepetitionMax) {
        let exerciseArray = [];
        exerciseArray = this.state.exercises;

        let touchedExercise = this.state.exercises[index];

        if(touchedExercise != null) {

            touchedExercise.oneRepetitionMax = oneRepetitionMax;
            exerciseArray[index] = touchedExercise;

            this.setState({
                exercises: exerciseArray
            });
        }
    }

    _saveSchedule() {
        if(this.state.exercises.length > 0) {
            this.setState({
                showLoading: '',
                buttonDisabled: 'disabled'
            });

            let editedExercises = [];

            Object.keys(this.state.exercises).forEach(function(key,index) {
                let newExercise = {};
                let exercise = this.state.exercises[index];
                newExercise.id = exercise.id;
                newExercise.exerciseId = exercise.exercise.id;
                if(exercise.oneRepetitionMax > 0) {
                    newExercise.oneRepetitionMax = exercise.oneRepetitionMax;
                }
                editedExercises.push(newExercise);
            }.bind(this));

            let trainingSchedule = {
                startDate: this.state.startDate.add(2, 'hours').toISOString(),
                weeks: this.props.nrOfWeeks,
                scheduleExercises: editedExercises
            }

            let response;
            let messageId;

            if (!this.props.trainingScheduleId) {
                response = fmfApiClient.createClientTrainingSchedule(this.cancelSource.token, this.props.client.id, trainingSchedule);
                messageId = 'trainingScheduleAdded';
            } else {
                response = fmfApiClient.updateClientTrainingSchedule(this.cancelSource.token, this.props.client.id, this.props.trainingScheduleId, trainingSchedule);
                messageId = 'trainingScheduleAdded';
            }

            response.then(result => {
                Materialize.toast(i18n.t('trainingSchedule.' + messageId), window.toastDuration);
                history.push('/clienten/' + this.props.client.id + '/trainingsschema/' + result.id);
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error.toString()), window.toastDuration);
                this.setState({
                    showLoading: 'hide',
                    buttonDisabled: ''
                });
            })
        }
    }

    _openSaveAsTemplate() {
        if(this.state.exercises.length > 0 && this.props.goal != null) {
            $('#setTemplateName').modal();
            $('#setTemplateName').modal('open');
        } else {
            alert(i18n.t('trainingSchedule.addExercisesAndGoal'));
        }
    }

    saveAsTemplate(title, comment) {
        if(this.state.exercises.length > 0 && this.props.goal != null) {
            this.setState({
                showLoading: '',
                buttonDisabled: 'disabled'
            });

            let templateExerciseIds = [];

            Object.keys(this.state.exercises).forEach(function(key,index) {
                let exercise = this.state.exercises[index].exercise;
                templateExerciseIds.push(exercise.id);
            }.bind(this));

            let trainingScheduleTemplate = {
                name: title,
                comment: comment,
                goalId: (this.props.goal != null?this.props.goal.id:null),
                exercises: templateExerciseIds
            }

            fmfApiClient.createCustomTrainingTemplate(this.cancelSource.token, trainingScheduleTemplate)
                .then(result => {
                    Materialize.toast(i18n.t('trainingSchedule.saveAsTemplateSuccesful'), window.toastDuration);
                    $(".modal-overlay").trigger("click");
                    this.props.onTemplateSaved(result);
                }).finally(() => {
                    this.setState({
                        showLoading: 'hide',
                        buttonDisabled: ''
                    });
                });
        } else {
            alert(i18n.t('trainingSchedule.addExercisesAndGoal'));
        }
    }

    _openMobileExercises(event) {
        event.preventDefault();

        let target = event.currentTarget;
        let sideContainer = $(target).parents('.greyContainer');
        sideContainer.addClass('open');
    }

    _closeMobileExercises(event) {
        event.preventDefault();

        let target = event.currentTarget;
        let sideContainer = $(target).parents('.greyContainer');
        sideContainer.removeClass('open');
    }

    render() {
        const SortableList = SortableContainer(ListContainer, {withRef: true});

        let startDate = this.state.startDate.format('D MMMM \'YY');
        let endDate = moment(startDate,'D MMMM \'YY');
        endDate = endDate.add(this.props.nrOfWeeks, 'weeks').format('D MMMM \'YY');

        return (
            <div className="scheduleExerciseContainer">
                <div className="row noMarginBottom">
                    <div className="col s12">
                        <div className="hide-on-small-only">
                            <img className="profilePic" src={ this.props.client.imageUrl } />
                            <h6 className="profileName">{ this.props.client.name }</h6>
                        </div>
                        <div className="row noMarginBottom hide-on-med-and-up">
                            <div className="col s4">
                                <img className="profilePic" src={ this.props.client.imageUrl } />
                            </div>
                            <div className="col s8 right-align">
                                <a className="cursor menuLink view" onClick={this._openMobileExercises}><Trans>trainingSchedule.viewExercises</Trans> <i className="material-icons">toc</i></a>
                                <a className="cursor menuLink close" onClick={this._closeMobileExercises}><i className="material-icons">close</i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row noMarginBottom">
                    <div className="col s12">
                        <hr />
                    </div>
                </div>
                <div className="row">
                    <div className="col s12">
                        <div className="schemeInfo">
                            <h5><Trans>trainingSchedule.trainingschedule</Trans> {this.props.nrOfWeeks} <Trans>formFields.weeks</Trans></h5>
                            <span className="schemeDate"><a className="cursor" onClick={this._openDatePicker}>{ startDate }</a> - { endDate }</span>
                            <input type="hidden" id="startDate" value={this.state.startDate.format('DD-MM-YYYY')} onChange={this._changeHandler} />
                        </div>
                        <div className="helpLink">
                            <a className="info tooltipped afterSwitch" data-position="bottom" data-tooltip={ i18n.t('trainingSchedule.1rmInfo') }>Hoe werkt 1RM in FitMasterFreddy?</a>
                        </div>
                        <div className="exerciseListContainer">
                            { this.state.exercises.length > 0
                                ?
                                <SortableList ref={(instance) => {this.SortableList = instance;}} onSetOneRepetitionMax={this._setOneRepetitionMax} onDeleteExercise={this._deleteExercise} items={this.state.exercises} onSortEnd={this.onSortEnd} lockAxis="y" helperClass="fmfSortable" useDragHandle={true} />
                                :
                                <ul className="exercisesList">
                                    <li>
                                        <div className="exerciseListItem empty">
                                            <div className="itemTitle">
                                                <Trans>trainingSchedule.addExerciseToStart</Trans>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            }
                            { this.state.exercises.length > 0 ?
                            <a className="btn" disabled={this.state.buttonDisabled} onClick={this._saveSchedule}><Trans>generalActions.save</Trans></a>
                                : null }
                            <a disabled={this.state.buttonDisabled} onClick={this._cancel} className="btn grey"><Trans>generalActions.cancel</Trans></a>
                            <br />
                            <br />
                            { this.state.exercises.length > 0 ?
                            <a disabled={this.state.buttonDisabled} onClick={this._openSaveAsTemplate} className="btn outline"><Trans>generalActions.saveAsTemplate</Trans></a>
                                : null }
                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                <CircleLoader/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ScheduleExercises;

ScheduleExercises.propTypes = {
    client            : PropTypes.object.isRequired,
    exercises       : PropTypes.array.isRequired
};