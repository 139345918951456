import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';

export default class ConnectClient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            client: this.props.client,
            showError: 'hide',
            errorMessage: '',
            isLoading: false
        };

        this._connectClient = this._connectClient.bind(this);
        this._disconnectClient = this._disconnectClient.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        if(nextProps.client !== this.state.client){
            this.setState({
                client: nextProps.client,
                showError: 'hide',
                errorMessage: ''
            });
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _connectClient () {
        this.setState({isLoading: true});
        fmfApiClient.connectClient(this.cancelSource.token, this.state.client.id)
            .then(result => {
                this.setState({
                    isLoading: false,
                    showError: 'hide',
                    errorMessage: ''
                });
                this.props.onChange();
                Materialize.toast(i18n.t('connectClient.clientConnectedSuccess'), window.toastDuration);
                $(".modal-overlay").trigger("click");
            }, error => {
                this.setState({
                    isLoading: false,
                    showError: '',
                    errorMessage: error &&
                        // eslint-disable-next-line eqeqeq
                        (error.error == "username_not_available" ?
                        i18n.t('errors.username_not_available_plain') :
                        i18n.t('errors.' + error.error.toString())),
                });
            });
    }

    _disconnectClient () {
        this.setState({isLoading: true});
        fmfApiClient.disconnectClient(this.cancelSource.token, this.state.client.id)
            .then(result => {
                this.setState({
                    isLoading: false,
                    showError: 'hide'
                });
                this.props.onChange();
                Materialize.toast(i18n.t('connectClient.clientDisconnectedSuccess'), window.toastDuration);
                $(".modal-overlay").trigger("click");
            }, error => {
                this.setState({
                    isLoading: false,
                    showError: '',
                    errorMessage: error && i18n.t('errors.' + error.error.toString()),
                });
            });
    }

    render() {
        return (
            <div id="connectClient" className="modal medium">
                <div className="modal-content">
                    <div className="row noMarginBottom">
                        <div className="col s12">
                            {!this.state.client.isConnected ?
                                <h4><Trans>connectClient.title</Trans></h4> :
                                <h4><Trans>connectClient.titleConnected</Trans></h4>
                            }
                            <div className={this.state.showError}>
                                <div id="card-alert" className="card red">
                                    <div className="card-content white-text">
                                        <p>{this.state.errorMessage}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12">
                            {!this.state.client.isConnected ?
                                <p><Trans>connectClient.disconnectedDescription</Trans></p> :
                                <p><Trans>connectClient.connectedDescription</Trans></p>
                            }
                            <p>
                                <Trans>connectClient.clientsEmail</Trans>
                                <strong>{ !this.state.client.isConnected ? this.state.client.email : this.state.client.connectedEmail }</strong>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12">
                            {!this.state.client.isConnected ?
                                <button disabled={this.state.isLoading}
                                    className="waves-effect waves-light btn noShadow"
                                    type="button" onClick={this._connectClient}>
                                    <Trans>connectClient.connect</Trans>
                                </button> :
                                <button disabled={this.state.isLoading}
                                    className="waves-effect waves-light btn noShadow"
                                    type="button" onClick={this._disconnectClient}>
                                    <Trans>connectClient.disconnect</Trans>
                                </button>
                            }
                            {this.state.isLoading && <CircleLoader/>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}