import React from 'react';
import { UserContext } from 'contexts.jsx';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import authService from 'utils/auth-service.js';

export default class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            oldPassword: '',
            password: '',
            checkPassword: '',
            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    componentDidMount() {
        $("#passwordForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            rules: {
                password: {
                    required: true,
                    minlength: 6,
                    pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/
                },
                checkPassword: {
                    equalTo: "#password"
                }
            },
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
        $('.tooltipped').tooltip();
    }

    componentWillUnmount() {
        this.unmounted = true;
    }


    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            username: $('#username')[0].value,
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        fmfApiClient.changePassword(null, this.state.username, this.state.oldPassword, this.state.password)
            .then(result => {
                $(".modal-overlay").trigger("click");
                Materialize.toast(i18n.t('changePass.successText'), window.toastDuration);
                authService.logout();
            }, error => {
                if (this.unmounted) return;
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    render() {
        const { currentUser } = this.context;
        return (
            <div id="changePassword" className="modal">
                <form id="passwordForm" noValidate onSubmit={this._submitForm}>
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12">
                            <h4><Trans>changePass.modalTitle</Trans></h4>
                            <p><Trans>changePass.modalText</Trans></p>
                            {/* ERROR MESSAGE */}
                            <div className={this.state.showError}>
                                <div id="card-alert" className="card red">
                                    <div className="card-content white-text">
                                        <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 input-field">
                            <input
                                id="oldPassword" type="password"
                                name="oldPassword"
                                value={this.state.oldPassword}
                                onChange={this._changeHandler}
                                data-msg={ i18n.t('errors.required_password') }
                                required />
                            <label htmlFor="oldPassword">{ i18n.t('formFields.oldPassword') }</label>
                        </div>
                        <div className="col s12 input-field">
                            <input
                                id="password" type="password"
                                name="password"
                                value={this.state.password}
                                onChange={this._changeHandler}
                                data-msg={ i18n.t('errors.required_password') }
                                required />
                            <label htmlFor="password">{ i18n.t('formFields.newPassword') }</label>
                            <a className="info tooltipped" data-position="bottom" data-delay="50" data-tooltip={ i18n.t('register.passwordRequirements') }><i className="material-icons">info</i></a>
                        </div>
                        <div className="col s12 input-field">
                            <input
                                id="checkPassword" type="password"
                                name="checkPassword"
                                value={this.state.checkPassword}
                                onChange={this._changeHandler}
                                required
                                data-msg={ i18n.t('errors.password_not_equal')} />
                            <label htmlFor="checkPassword">{ i18n.t('formFields.confirmPassword') }</label>
                            <input
                                id="username" type="hidden"
                                name="username"
                                value={currentUser ? currentUser.identityToken.username : ''}
                                onChange={this._changeHandler}
                                required />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12">
                            <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>changePass.button</Trans></button>
                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                <CircleLoader/>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}

ChangePassword.contextType = UserContext