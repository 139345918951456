import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18n from 'i18next';
import wNumb  from 'wnumb';
import noUiSlider from 'nouislider';

export default class GoalRangeSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start: this.props.variable.start || this.props.variable.defaultStart,
            end: this.props.variable.end || this.props.variable.defaultEnd
        };

        this._resetSlider = this._resetSlider.bind(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let nextIncrements =  nextProps.variable.increments;
        let nextStart = nextProps.variable.start || nextProps.variable.defaultStart;
        let nextEnd = nextProps.variable.end || nextProps.variable.defaultEnd;

        let currentIncrements = this.props.variable.increments;
        let currentStart = this.state.start;
        let currentEnd = this.state.end;

        // === doesn't work, so there's a type difference somewhere...
        // eslint-disable-next-line eqeqeq
        if (currentIncrements == nextIncrements &&
            // eslint-disable-next-line eqeqeq
            currentStart == nextStart &&
            // eslint-disable-next-line eqeqeq
            currentEnd == nextEnd) {
            return;
        }

        this.setState({
            start: nextStart,
            end: nextEnd
        });
    }

    componentDidMount () {
        let handlesSlider = this.sliderContainer;

        let increments =  this.props.variable.increments;
        let start = this.props.variable.start || this.props.variable.defaultStart;
        let end = this.props.variable.end || this.props.variable.defaultEnd;
        let min = this.props.variable.minimumValue;
        let max = this.props.variable.maximumValue;

        noUiSlider.create(handlesSlider, {
            connect: [false,true,false],
            direction: 'ltr',
            step: increments,
            range: {
                'min': min,
                'max': max
            },
            format: wNumb({
                decimals: 0,
                thousand: '.'
            }),
            start: [start, end]
        });

        // More info about noUiSlider events: https://refreshless.com/nouislider/events-callbacks/

        // Set is triggered when a final change has happend.
        // So not during sliding, but when the slider gets released.
        handlesSlider.noUiSlider.on('set', function(event){
            this._onChange(parseInt(event[0]), parseInt(event[1]));
        }.bind(this));

        // Update is triggered on every change. So also during sliding.
        handlesSlider.noUiSlider.on('update', function(event){
            if (this.state.start !== event[0] || this.state.end !== event[1]) {
                this.setState({
                    start: event[0],
                    end: event[1]
                });
            }
        }.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        let nextIncrements =  this.props.variable.increments;
        let nextStart = this.state.start;
        let nextEnd = this.state.end;

        let currentIncrements = prevProps.variable.increments;
        let currentStart = prevState.start;
        let currentEnd = prevState.end;

        if (currentIncrements === nextIncrements &&
            currentStart === nextStart &&
            currentEnd === nextEnd) {
            return;
        }

        this.sliderContainer.noUiSlider.updateOptions({
            step: nextIncrements,
            start: [nextStart, nextEnd]
        }, false);
    }

    _resetSlider() {
        if(window.confirm(i18n.t('addGoal.resetMessage'))) {
            let start = this.props.variable.defaultStart;
            let end = this.props.variable.defaultEnd;
            this.sliderContainer.noUiSlider.updateOptions({
                start: [start, end]
            }, false);
            this._onChange(start, end);
        }
    }

    _onChange(start, end) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(this.props.variable.id, start, end);
        }
    }

    render() {
        return (
            <div className="sliderContainer">
                <div className="row">
                    <div className="col s12 m2">
                        <span className="variableName">{this.props.variable.name}</span>
                        &nbsp;
                        <a onClick={this._resetSlider} className="restoreButton cursor" title={i18n.t('addGoal.resetTitle')}>
                            <i className="material-icons">restore</i>
                        </a>
                    </div>
                    <div className="col s12 m10">
                        <div className="row">
                            <div className="col s2 right-align">
                                <span>
                                    <span>{this.state.start}</span>
                                </span>
                            </div>
                            <div className="col s8">
                                <div className="sliderTrackContainer">
                                    <div className="goalSlider" ref={slider => { this.sliderContainer = slider }} />
                                </div>
                            </div>
                            <div className="col s2 left-align">
                                <span>
                                    <span>{this.state.end}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

GoalRangeSlider.propTypes = {
    variable: PropTypes.object.isRequired,
};