import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { UserContext } from 'contexts.jsx';
import {MergeShoppingList} from 'utils/nutrition/mergeShoppingList.js';

const mergeShoppingList = new MergeShoppingList();
export default class DayDetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            viewType: 'perMeal' // perMeal, perGroup
        }
    }

    componentDidMount() {
        $('#dayDetailInfo').modal();
    }

    _close() {
        $(".modal-overlay").trigger("click");
    }

    _imageFileName() {
        // eslint-disable-next-line eqeqeq
        if (this.props.dailyAdvice && this.props.dailyAdvice.imageUrl != "") {
            return 'url('+this.props.dailyAdvice.imageUrl+')';
        } else {
            return 'transparent';
        }
    }

    _nutritions() {
        const substitutes = this.props.nutritionSchedule.mealSubstitutes;
        const weeklyAdviceId = this.props.nutritionSchedule.weeklyAdvices[this.props.week - 1].id;
        const dailyAdvice = this.props.dailyAdvice;

        let groupedNutritions;
        if (this.state.viewType === 'perMeal') {
            groupedNutritions = mergeShoppingList.mergeShoppingListFromDailyAdvicePerMeal(
                substitutes, weeklyAdviceId, dailyAdvice
            );
        } else { // perGroup
            groupedNutritions = mergeShoppingList.mergeShoppingListFromDailyAdvicePerGroupName(
                substitutes, weeklyAdviceId, dailyAdvice
            );
        }

        return (
            <div id="mealDetailNutritions-body" className="mealDetailnfo-body">
                {groupedNutritions.map((nutritionsGroup, groupIndex) => (
                    <div key={"nutrition-group-" + groupIndex}>
                        <div className="row">
                            <div className="col cat">
                                {nutritionsGroup[0]}
                            </div>
                        </div>
                        {nutritionsGroup[1].map((mealNutrition, nutritionIndex) => {
                            const nutrition = mealNutrition.nutrition;
                            return (
                                <div key={"nutrition-" + nutritionIndex} className="row">
                                    <span className="col s3">{mealNutrition.quantity} {mealNutrition.quantity > 1 ? nutrition.unitNamePlural : nutrition.unitName}</span>
                                    <span className="col s7">{this.state.viewType === 'perMeal' ? nutrition.name : nutrition.shoppingListName}</span>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div id="dayDetailInfo" className="modal wide">
                {this.props.dailyAdvice !== null &&
                    <div className="mealDetailnfo">
                        <div className="row recipe-image-row">
                            <div className="col l4 s12 recipe-image-col">
                                <figure className="recipe-image-container">
                                    <span className="recipe-image"
                                        style={{backgroundImage:this._imageFileName()}}>
                                    </span>
                                </figure>
                            </div>
                            <div className="col l8 s12 mealDetailinfo-container">
                                <div className="mealDetailnfo-header">
                                    <h2>{i18n.t('nutritionSchedule.' + this.props.dayInWeek)}</h2>
                                    <div className="userInfo variableName">{this.props.dateFormatted}</div>
                                    <div className="switch">
                                        <label>
                                            <Trans>nutritionSchedule.perMeal</Trans>
                                            <input type="checkbox"
                                                name="viewType"
                                                // perMeal, perGroup
                                                checked={this.state.viewType === 'perGroup' ? true : false}
                                                onChange={e => this.setState({viewType: e.target.checked ? 'perGroup' : 'perMeal'})}
                                            />
                                            <span className="lever"></span>
                                            <Trans>nutritionSchedule.perGroup</Trans>
                                        </label>
                                    </div>
                                </div>
                                <div className="mealDetail-body-container">
                                    {this._nutritions()}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <a className="closeButton" onClick={this._close}><i className="material-icons">close</i></a>
            </div>
        )
    }
}

DayDetailView.contextType = UserContext
