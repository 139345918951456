import { withRouter } from "react-router-dom";

export default withRouter(({location}) => {
    // Because this is a SPA, which loads pages dynamically, gtag cannot
    // determine when a new page navigation has occurred.
    // So we track the pageview here manually.
    // See: https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
    //
    // TODO: Load tracking ID via settings. See comment in `head.html`.
    window.gtag('config', 'UA-45277310-1', {'page_path': location.pathname});
    return null;
});