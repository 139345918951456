import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import moment from 'moment';

import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';
import history from 'history.js';
import authService from 'utils/auth-service.js';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import ExerciseDetailCard from 'components/components/trainingSchedule/exerciseDetailCard.jsx';
import ExerciseInfo from 'components/components/trainingSchedule/exerciseInfo.jsx';
import image from 'utils/image.js';
import SetTemplateName from 'components/forms/modals/setTemplateName.jsx';

export default class trainingSchedule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            trainingSchedule: null,
            currentClient: null,
            exerciseId: 0
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillMount() {
        this._getClient();
        this._getTrainingSchedule();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _isUserTypeClient() {
        const user = this.context.currentUser;
        const userType = user && user.identityToken && user.identityToken.user_type;
        return userType === 'Client';
    }

    _getClient() {
        fmfApiClient.getClient(this.cancelSource.token, this.props.clientId, image.resize(160, 160))
            .then(result => {
                this.setState({
                    currentClient: result
                });
            }
        );
    }

    _getTrainingSchedule() {
        fmfApiClient.getClientTrainingSchedule(this.cancelSource.token, this.props.clientId, this.props.trainingScheduleId, image.resize(201, 302))
            .then(result => {
                this.setState({
                    trainingSchedule: result
                });
            }, error => {
                if (error && error.error === 'client_inactive') {
                    // Client has been set to inactive by trainer...
                    if(!this._isUserTypeClient()) {
                        // ...redirect to client dashboard.
                        history.push('/clienten/' + this.state.currentClient.id);
                    } else {
                        // ...logout the client user.
                        authService.logout();
                    }
                } else if (error && error.error === 404) {
                    // Schedule has been deleted, so redirect to client dashboard.
                    history.push('/clienten/' + this.state.currentClient.id);
                } else {
                    return Promise.reject(error);
                }
            });
    }

    _getTrainingScheduleDates() {
        let startDate = moment(this.state.trainingSchedule.startDate).format('D MMMM \'YY');
        let endDate = moment(startDate, 'D MMMM \'YY');
        endDate = endDate.add(this.state.trainingSchedule.weeks, 'weeks').format('D MMMM \'YY');

        return startDate + ' - ' + endDate ;
    }

    _showExerciseInfo(exerciseId) {
        this.setState({
            exerciseId: exerciseId
        }, () => {
            $('#exerciseInfo').modal('open');
        });
    }

    _editTrainingSchedule(event) {
        event.preventDefault();
        history.push('/clienten/' + this.state.currentClient.id + '/trainingsschema/' + this.state.trainingSchedule.id + '/bewerken');
    }

    _deleteTrainingSchedule() {
        if(window.confirm(i18n.t('trainingSchedule.deleteTrainingScheduleConfirmation'))) {
            fmfApiClient.deleteClientTrainingSchedule(this.cancelSource.token, this.props.clientId, this.props.trainingScheduleId)
                .then(result => {
                    Materialize.toast(i18n.t('trainingSchedule.trainingScheduleDeleted'), window.toastDuration);
                    history.push('/clienten/' + this.state.currentClient.id);
                }, error => {
                    Materialize.toast(i18n.t('errors.' + error.error.toString()), window.toastDuration);
                });
        }
    }

    _goToClient(event) {
        event.preventDefault();
        history.push('/clienten/' + this.state.currentClient.id);
    }

    _print(event) {
        event.preventDefault();
        history.push('/clienten/' + this.state.currentClient.id + '/trainingsschema/' + this.props.trainingScheduleId + '/print');
    }

    _openSaveAsTemplate() {
        // eslint-disable-next-line eqeqeq
        if(this.state.trainingSchedule.scheduleExercises.length > 0 && this.state.trainingSchedule.goal != null) {
            $('#setTemplateName').modal();
            $('#setTemplateName').modal('open');
        } else {
            alert(i18n.t('trainingSchedule.addExercisesAndGoal'));
        }
    }

    _saveAsTemplate(title, comment) {
        // eslint-disable-next-line eqeqeq
        if(this.state.trainingSchedule.scheduleExercises.length > 0 && this.state.trainingSchedule.goal != null) {
            this.setState({
                showLoading: '',
                buttonDisabled: 'disabled'
            });

            let templateExerciseIds = [];

            Object.keys(this.state.trainingSchedule.scheduleExercises).forEach((key,index) => {
                let exercise = this.state.trainingSchedule.scheduleExercises[index].exercise;
                templateExerciseIds.push(exercise.id);
            });

            let trainingScheduleTemplate = {
                name: title,
                comment: comment,
                // eslint-disable-next-line eqeqeq
                goalId: (this.state.trainingSchedule.goal != null?this.state.trainingSchedule.goal.id:null),
                exercises: templateExerciseIds
            }

            fmfApiClient.createCustomTrainingTemplate(this.cancelSource.token, trainingScheduleTemplate)
                .then(result => {
                    Materialize.toast(i18n.t('trainingSchedule.saveAsTemplateSuccesful'), window.toastDuration);
                    $(".modal-overlay").trigger("click");
                }).finally(() => {
                    this.setState({
                        showLoading: 'hide',
                        buttonDisabled: ''
                    });
                });
        } else {
            alert(i18n.t('trainingSchedule.addExercisesAndGoal'));
        }

    }

    render() {
        const currentClient = this.state.currentClient;

        return (
            <div id="page" className="trainingScheduleDetail">
                {!this.state.currentClient || !this.state.trainingSchedule ?
                    <FPLoader fixed="fixed" /> :
                    <div>
                        <div className="pageheader trainingSchedule schedule">
                            <div className="container">
                                <div className="row">
                                    <div className="col s12 m8">
                                        <div className="row noMarginBottom">
                                            <div className="col s12 m1">
                                                {currentClient ?
                                                    <a onClick={e => this._goToClient(e)} className="cursor">
                                                        <img className="profilePic" src={currentClient.imageUrl}/>
                                                    </a> :
                                                    ''
                                                }
                                            </div>
                                            <div className="col s12 m10">
                                                <h2><Trans>trainingSchedule.trainingschedule</Trans> {this.state.trainingSchedule.weeks} <Trans>formFields.weeks</Trans></h2>
                                                <div className="userInfo variableName">{this._getTrainingScheduleDates()}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12 m4">
                                        {this.state.trainingSchedule.goal &&
                                            <div className="goalContainer hide-on-small-only">
                                                <h2>{this.state.trainingSchedule.goal.shortName}</h2>
                                                {/* eslint-disable-next-line eqeqeq */}
                                                {this.state.trainingSchedule.goal.comment != '' &&
                                                    <span className="variableName">{this.state.trainingSchedule.goal.comment}</span>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="actions">
                                {!this._isUserTypeClient() &&
                                    <React.Fragment>
                                        <a onClick={() => this._deleteTrainingSchedule()}
                                            className="btn-floating waves-effect waves-light small grey lighten-4">
                                            <i className="material-icons">delete</i>
                                        </a>
                                        <a onClick={e => this._editTrainingSchedule(e)}
                                            className="btn-floating waves-effect waves-light small grey lighten-4">
                                                <i className="material-icons">mode_edit</i>
                                            </a>
                                        <a onClick={() => this._openSaveAsTemplate()} title={i18n.t('generalActions.saveAsTemplate')}
                                            className="btn-floating waves-effect waves-light small grey lighten-4">
                                            <i className="material-icons">content_paste</i>
                                        </a>
                                    </React.Fragment>
                                }
                                <a onClick={e => this._print(e)} className="btn-floating waves-effect waves-light small grey lighten-4">
                                    <i className="material-icons">print</i>
                                </a>
                            </div>
                            <div className="row">
                                <div className="col s12 m12 l11 xl8">
                                    {this.state.trainingSchedule.scheduleExercises.map((exercise) => (
                                        <ExerciseDetailCard key={exercise.id} exercise={exercise}
                                            trainingSchedule={this.state.trainingSchedule}
                                            currentClient={this.state.currentClient}
                                            onShowExercise={(id) => this._showExerciseInfo(id)}
                                        />
                                    ))}
                                </div>
                                <div className="col s12 m12 l12 xl4">
                                </div>
                            </div>
                        </div>
                        <ExerciseInfo exerciseId={this.state.exerciseId} currentClient={this.state.currentClient} />
                    </div>
                }
                <SetTemplateName onSetTemplateName={(title, comment) => this._saveAsTemplate(title, comment)}/>
            </div>
        )
    }
}

trainingSchedule.contextType = UserContext

trainingSchedule.propTypes = {
    clientId              : PropTypes.string.isRequired,
    trainingScheduleId  : PropTypes.string.isRequired
};