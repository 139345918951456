import React from 'react';
import { Trans } from 'react-i18next';
import { UserContext } from 'contexts.jsx';
import history from 'history.js';

export default class ProfileMenu extends React.Component {
    componentDidMount() {
        $('ul.tabs').tabs();
    }

    handleTabClick(event) {
        let elmHref = $(event.currentTarget).attr('href');
        history.push(elmHref);
    }

    render() {
        const { pathname } = history.location;
        const { currentUser } = this.context;
        const hasControlPlusLink = currentUser.identityToken.hasOwnProperty('control_plus_link');

        return (
            <div className="container">
                <div className="row noMarginBottom">
                    <div className="col s12">
                        <ul className="tabs">
                            <li className="tab">
                                <a href="/profile" className={pathname === '/profile' ? "active" : ''} onClick={this.handleTabClick}>
                                    <Trans>subscriptionPage.profile</Trans>
                                </a>
                            </li>
                            <li className="tab">
                                <a href="/profile/styling" className={pathname === '/profile/styling' ? "active" : ''} onClick={this.handleTabClick}>
                                    <Trans>subscriptionPage.styling</Trans>
                                </a>
                            </li>
                            <li className="tab">
                                <a href="/profile/users" className={pathname === '/profile/users' ? "active" : ''} onClick={this.handleTabClick}>
                                    <Trans>subscriptionPage.usersTitle</Trans>
                                </a>
                            </li>

                            {!hasControlPlusLink && (
                                <>
                                    <li className="tab">
                                        <a href="/profile/account" className={pathname === '/profile/account' ? "active" : ''} onClick={this.handleTabClick}>
                                            <Trans>subscriptionPage.invoiceInfo</Trans>
                                        </a>
                                    </li>
                                    <li className="tab">
                                        <a href="/profile/abonnement" className={pathname === '/profile/abonnement' ? "active" : ''} onClick={this.handleTabClick}>
                                            <Trans>subscriptionPage.subscriptionTitle</Trans>
                                        </a>
                                    </li>
                                    <li className="tab">
                                        <a href="/profile/upgrade" onClick={this.handleTabClick}>
                                            <Trans>subscriptionPage.upgrade</Trans>
                                        </a>
                                    </li>
                                </>
                            )}

                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

ProfileMenu.contextType = UserContext