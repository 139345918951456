import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

class ActivationSuccesfull extends React.Component {

    render() {
        return (
            <div id="registerFormContainer">
                <div className="container">
                    <div className="registerSuccessNotice">
                        <div className="row">
                            <div className=" col s10 offset-s1 m8 offset-m2">
                                <br />
                                <h4><Trans>activation.successTitle</Trans></h4>
                                <p><Trans components={[<Link to='/login' />]}>activation.successText</Trans></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ActivationSuccesfull;