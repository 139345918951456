import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import history from 'history.js';

export default class CustomerCard extends Component {

    constructor(props) {
        super(props);

        this._handleCardClick = this._handleCardClick.bind(this)
    }

    _handleCardClick(event) {
        history.push('/clienten/' + this.props.client.id);
    }

    render() {
        let n = new Date();
        let lastEdited = '';
        n.setDate(n.getDate()-1);
        let lastEditedMoment = moment(this.props.client.lastEditedAt);
        if(lastEditedMoment.isBefore(n)) {
            lastEdited = lastEditedMoment.format('dddd D MMMM YYYY');
        } else {
            lastEdited = lastEditedMoment.fromNow();
        }
        return (
            <div className={(this.props.client.isActive ? '' : 'inactive ') + "card customer"} onClick={this._handleCardClick}>
                <div className="card-image">
                    <img src={this.props.client.imageUrl} className="circle" />
                    <span className="card-title">
                        {this.props.client.name}
                    <span className="extraInfo">{this.props.client.isActive ?
                        (this.props.client.goalName ? this.props.client.goalName : null)
                        : 'Inactief'}</span> </span>
                </div>
                <div className="card-action">
                    <div className="row">
                        <div className="col s12 center-align">
                            { lastEdited }
                        </div>
                    </div>
                </div>
            </div>
    );
 }
}

CustomerCard.propTypes = {
    client: PropTypes.object.isRequired,
};