import React from 'react';
import { UserContext } from 'contexts.jsx';

export default class ProfileHeader extends React.Component {
    render() {
        const { currentUser } = this.context;
        return (
            <div className="container">
                <div className="row">
                    <div className="col s12">
                        <div className="row noMarginBottom">
                            <div className="col s12 m1">
                                { currentUser ? <img className="profilePic" src={currentUser.profile.imageUrl}/> : ''}
                            </div>
                            <div className="col s12 m11">
                                <h1>{currentUser ? currentUser.identityToken.fullname : ''}</h1>
                                <div className="userInfo">
                                    <span className="infoItem setItem">{ currentUser? currentUser.identityToken.organization_displayname:'' }</span>
                                    <span className="infoItem setItem">{currentUser ? currentUser.identityToken.username : ''}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


ProfileHeader.contextType = UserContext