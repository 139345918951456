import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import ResendActivationModal from 'components/forms/modals/resendActivation.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';

class RegisterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            username: '',
            company: '',
            agreeTerms: '',
            newsletter: true,
            password: '',
            checkPassword: '',
            errorMessage: '',
            showError: 'hide',
            showSuccess: 'showForm',
            buttonDisabled: '',
            showLoading: 'hide',
            controlPlusHostname: '',
            controlPlusApiKey: ''
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);
        this._resendActivation = this._resendActivation.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    componentDidMount() {
        $("#registerForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            rules: {
                password: {
                    required: true,
                    minlength: 6,
                    pattern: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20})/
                },
                checkPassword: {
                    equalTo: "#password"
                }
            },
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
        $('.tooltipped').tooltip();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        fmfApiClient.registerAccount(
            this.cancelSource.token,
            this.state.username, this.state.password,
            this.state.firstName, this.state.lastName,
            this.state.company, this.state.newsletter,
            this.state.controlPlusHostname, this.state.controlPlusApiKey
        ).then(result => {
            this.setState({
                password: '',
                checkPassword: '',
                buttonDisabled: '',
                showLoading: 'hide',
                showError: 'hide',
                showSuccess: 'showSuccess'
            });
        }, error => {
            this.setState({
                password: '',
                checkPassword: '',
                errorMessage: i18n.t('errors.' + error.error),
                buttonDisabled: '',
                showLoading: 'hide',
                showError: ''
            });
        });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    _resendActivation() {
        $('#resendActivation').modal({
            ready: function (modal, trigger) { // Callback for Modal open. Modal and trigger parameters available.
                $('#resendActivation input#username').focus();
                this._raModal.changeUsername(this.state.username);
            }.bind(this)
        });
        $('#resendActivation').modal('open');
    }

    render() {
        return (
            <div id="registerFormContainer" className={this.state.showSuccess}>
                <div className="container">
                    <form id="registerForm" noValidate onSubmit={this._submitForm}>
                        <div className="loginForm">
                            <div className="row">
                                <div className=" col s10 offset-s1 m8 offset-m2">

                                    {/* ERROR MESSAGE */}
                                    <div className={this.state.showError}>
                                        <div className="row">
                                            <div className='col s12'>
                                                <div id="card-alert" className="card red">
                                                    <div className="card-content white-text">
                                                        <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="firstName" type="text"
                                                name="firstName"
                                                value={this.state.firstName}
                                                onChange={this._changeHandler}
                                                required data-msg={ i18n.t('errors.required_field') } />
                                            <label htmlFor="firstName" >{ i18n.t('formFields.firstname') }</label>
                                        </div>
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="lastName" type="text"
                                                name="lastName"
                                                value={this.state.lastName}
                                                onChange={this._changeHandler}
                                                data-msg={ i18n.t('errors.required_field') }
                                                required
                                                />
                                            <label htmlFor="lastName">{ i18n.t('formFields.lastname') }</label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="username" type="email"
                                                name="username"
                                                value={this.state.username}
                                                onChange={this._changeHandler}
                                                data-msg={ i18n.t('errors.no_valid_email') }
                                                required
                                                />
                                            <label htmlFor="username">{ i18n.t('formFields.email') }</label>
                                        </div>
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="company" type="text"
                                                name="company"
                                                value={this.state.company}
                                                onChange={this._changeHandler}
                                                />
                                            <label htmlFor="company">{ i18n.t('formFields.company') + i18n.t('formFields.optional') }</label>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="password" type="password"
                                                name="password"
                                                onChange={this._changeHandler}
                                                value={this.state.password}
                                                data-msg={ i18n.t('errors.required_password') }
                                                required
                                            />
                                            <label htmlFor="password">{ i18n.t('formFields.password') }</label>
                                            <a className="info tooltipped" data-position="bottom" data-delay="50" data-tooltip={ i18n.t('register.passwordRequirements') }><i className="material-icons">info</i></a>
                                        </div>
                                        <div className='input-field col s12 m6'>
                                            <input
                                                id="checkPassword" type="password"
                                                name="checkPassword"
                                                onChange={this._changeHandler}
                                                value={this.state.checkPassword}
                                                required
                                                data-msg={ i18n.t('errors.password_not_equal') }
                                            />
                                            <label htmlFor="checkPassword">{ i18n.t('formFields.confirmPassword') }</label>
                                        </div>
                                    </div>

                                    {this.props.controlPlus &&
                                        <ControlPlusInputs onInputChanged={this._changeHandler} />
                                    }

                                    <div className="row">
                                        <div className='input-field col s12'>
                                            <input type="checkbox"
                                                id="agreeTerms"
                                                name="agreeTerms"
                                                checked={this.state.agreeTerms}
                                                onChange={this._changeHandler}
                                                className="filled-in" required
                                                data-msg={ i18n.t('errors.required_field') } />
                                            <label htmlFor="agreeTerms">
                                                <Trans components={[<a href='https://fitmasterfreddy-public.imgix.net/Algemene-Voorwaarden-FitMasterFreddy-Software.pdf' target='_blank' rel='noopener noreferrer' />]}>
                                                    register.generalTerms
                                                </Trans>
                                            </label>
                                        </div>
                                        <div className='input-field col s12'>
                                            <input type="checkbox"
                                                id="newsletter"
                                                name="newsletter"
                                                checked={this.state.newsletter}
                                                onChange={this._changeHandler}
                                                className="filled-in" />
                                            <label htmlFor="newsletter"><Trans>register.newsletter</Trans></label>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className='col s12 m6'>
                                            <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit"><Trans>register.submit</Trans></button>
                                            <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                                <CircleLoader/>
                                            </div>

                                            <p><Trans components={[<Link to='/login' />]}>register.alreadyGotAAcount</Trans></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <RegisterSuccessNotice
                        onResendActivation={this._resendActivation}
                        successTextKey={!this.props.controlPlus ? 'register.successText' : 'register.successTextControlPlus'} />
                </div>
                <ResendActivationModal ref={(raModal) => { this._raModal = raModal; }}  email={this.state.username} />
            </div>
        );
    }
}

const RegisterSuccessNotice = ({successTextKey, onResendActivation}) => (
    <div className="registerSuccessNotice">
        <div className="row">
            <div className=" col s10 offset-s1 m8 offset-m2">
                <br />
                <h4><Trans>register.successTitle</Trans></h4>
                <p><Trans>{successTextKey}</Trans></p>
                <p><Trans components={[<Link to='/login' />]}>register.successButton</Trans></p>

                <p><Trans>register.resendActivationText</Trans>
                <br /><a className="cursor" onClick={onResendActivation}><Trans>register.resendActivationTrigger</Trans></a></p>
            </div>
        </div>
    </div>
);

const ControlPlusInputs = ({onInputChanged}) => (
    <div className="row">
        <div className='input-field col s12 m6'>
            <input
                id="controlPlusHostname" type="text"
                name="controlPlusHostname"
                onChange={onInputChanged}
                data-msg={i18n.t('errors.required_field')}
                required
            />
            <label htmlFor="controlPlusHostname">{i18n.t('controlPlus.controlPlusHostname')}</label>
            <a className="info tooltipped" data-position="bottom" data-delay="50" data-tooltip={i18n.t('controlPlus.controlPlusHostnameTooltip')}>
                <i className="material-icons">info</i>
            </a>
        </div>
        <div className='input-field col s12 m6'>
            <input
                id="controlPlusApiKey" type="text"
                name="controlPlusApiKey"
                onChange={onInputChanged}
                data-msg={i18n.t('errors.required_field')}
                required
            />
            <label htmlFor="controlPlusApiKey">{i18n.t('controlPlus.controlPlusApiKey')}</label>
            <a className="info tooltipped" data-position="bottom" data-delay="50" data-tooltip={i18n.t('controlPlus.controlPlusApiKeyTooltip')}>
                <i className="material-icons">info</i>
            </a>
        </div>
    </div>
);

export default RegisterForm;
