import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { UserContext } from 'contexts.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import Pagination from 'components/components/pagination/pagination.jsx';
import AddUser from './addUser.jsx';

export default class Users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            nrOfPages: 0,
            currentPage: 0,
            pageSize: 10
        };

        this.getUsers = this.getUsers.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this._setCurrentPage = this._setCurrentPage.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();

        this.getUsers();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _setCurrentPage(newPageNr) {
        this.setState({
            currentPage: newPageNr
        },function() {
            this.getUsers();
        }.bind(this));
    }

    getUsers() {
        fmfApiClient.getUsers(this.cancelSource.token, 1, this.state.currentPage, this.state.pageSize)
            .then(result => {
                this.setState({
                    users: result.items,
                    nrOfPages: result.totalPages,
                    currentPage: result.page
                });
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
            });
    }

    addUser(){
        $('#addUser').modal();
        $('#addUser').modal('open');
    }

    deleteUser(id) {
        if(window.confirm(i18n.t('subscriptionPage.users.confirmDeleteUser'))){
            fmfApiClient.deleteUser(this.cancelSource.token, id)
                .then(result => {
                    Materialize.toast(i18n.t('subscriptionPage.users.userDeleted'), window.toastDuration);
                    this.setState({
                        currentPage: this.state.users.length > 1 ? this.state.currentPage : this.state.currentPage - 1
                    },function() {
                        this.getUsers();
                    });
                }, error => {
                    Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                });
        }
    }

    hasFeature(feature){
        return this.context.currentUser &&
            this.context.currentUser.identityToken.features &&
            this.context.currentUser.identityToken.features.indexOf(feature) !== -1;
    }

    _handleUserAdded() {
        this.getUsers();
    }

    render() {
        const { currentUser } = this.context;
        return (
            <div className="subscriptionContainer">
                <div className="container">
                    <div className="actions">
                        <a onClick={this.addUser} className="btn-floating btn-large waves-effect waves-light">
                            <i className="material-icons">add</i>
                        </a>
                    </div>
                    <h4 className="smallGreyTitle"><Trans>subscriptionPage.usersTitle</Trans></h4>
                    <br />
                    { this.state.users && this.state.users.length > 0 ?
                    <div>
                        <div className="row">
                            <table className="responsive-table striped">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th><Trans>subscriptionPage.users.firstName</Trans></th>
                                        <th><Trans>subscriptionPage.users.lastName</Trans></th>
                                        <th><Trans>subscriptionPage.users.email</Trans></th>
                                        <th><Trans>subscriptionPage.users.createdAt</Trans></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                { this.state.users.map((user) => (
                                    <tr key={user.id}>
                                        <td><img className="userOverviewImg" src={ user.imageUrl } /></td>
                                        <td>{ user.firstName }</td>
                                        <td>{ user.lastName }</td>
                                        <td>{ user.userName }</td>
                                        <td>{ moment(user.createdAt).format('D-M-YYYY') }</td>
                                        <td>
                                            { currentUser._id !== user.id.toString() && !user.isMainUser ?
                                            <a type='button' className="btn outline alert"
                                                onClick={() => this.deleteUser(user.id)}>
                                                <Trans>subscriptionPage.users.delete</Trans>
                                            </a>
                                            : null }
                                        </td>
                                    </tr>
                                )) }
                                </tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div className="col s12 center-align">
                                <Pagination currentPage={this.state.currentPage}
                                    pages={this.state.nrOfPages} onPageChange={this._setCurrentPage}/>
                            </div>
                        </div>
                    </div>
                    :
                    <FPLoader fixed="relative" />
                    }
                </div>
                <AddUser onUserAdded={() => this._handleUserAdded()} />
            </div>
        )
    }
}


Users.contextType = UserContext