import React from 'react';
import ItemContainer from 'components/components/sortableList/itemContainer.jsx';
import {SortableElement} from 'react-sortable-hoc';

class ListContainer extends React.Component {

    constructor(props) {
        super(props);

        this._deleteExercise = this._deleteExercise.bind(this);
        this._setOneRepetitionMax = this._setOneRepetitionMax.bind(this);
    }


    _deleteExercise(index) {
        if (typeof this.props.onDeleteExercise === 'function') {
            this.props.onDeleteExercise(index);
        }
    }

    _setOneRepetitionMax(index, oneRepetitionMax) {
        if (typeof this.props.onSetOneRepetitionMax === 'function') {
            this.props.onSetOneRepetitionMax(index, oneRepetitionMax);
        }
    }

    render() {
        const SortableItem = SortableElement(ItemContainer, {withRef: true});

        return(
            <ul>
                {this.props.items.map((value, index) => (
                    <SortableItem ref={(instance) => {this.item = instance;}} key={`item-${index}`} index={index} itemIndex={index} onSetOneRepetitionMax={this._setOneRepetitionMax} onDeleteExercise={this._deleteExercise} exercise={value} />
                ))}
            </ul>
        );

    }
}

export default ListContainer;