import React, { Component } from 'react';

export default class circleLoader extends Component {
    render() {
        return (
            <div className={"preloader-wrapper small" + (!this.props.disabled ? " active" : "")}>
                <div className="spinner-layer">
                    <div className="circle-clipper left">
                        <div className="circle"></div>
                    </div><div className="gap-patch">
                    <div className="circle"></div>
                </div><div className="circle-clipper right">
                    <div className="circle"></div>
                </div>
                </div>
            </div>
        )
    }
}