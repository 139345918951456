import React from 'react';
import { Trans } from 'react-i18next';

class ExerciseCard extends React.Component {
    constructor(props) {
        super(props);

        this._addExercise = this._addExercise.bind(this);
        this._showExercise = this._showExercise.bind(this);
    }

    _getExerciseImage() {
        let currentGender = 0;
        // eslint-disable-next-line eqeqeq
        if(this.props.currentClient != null) {
            currentGender = this.props.currentClient.gender;
        }
        let backgroundImage = null;
        let images = this.props.exercise.imageUrls;

        if(images.length > 0){
            backgroundImage = images[0].url;
            for(let i = 0; i < images.length; i ++) {
                let imageObject = images[i];
                // eslint-disable-next-line eqeqeq
                if(imageObject.gender == currentGender) {
                    backgroundImage = imageObject.url;
                }
            }
        }

        return {backgroundImage: 'url('+ backgroundImage + ')'};
    }

    _getExerciseTags() {
        let exerciseTags = [];
        exerciseTags = exerciseTags.concat(this.props.exercise.equipment, this.props.exercise.regions);

        return exerciseTags.join(' | ');
    }

    _addExercise() {
        if (typeof this.props.onAddExercise === 'function') {
            this.props.onAddExercise(this.props.exercise);
        }
    }

    _showExercise() {
        if (typeof this.props.onShowExercise === 'function') {
            this.props.onShowExercise(this.props.exercise.id);
        }
    }

    render() {
        return (
            <div className="col s12 m6 l4">
                <div className="card exercise">
                    <div className="card-image">
                        <div className="exerciseImageContainer" style={this._getExerciseImage()}></div>
                    </div>
                    <div className="addAction">
                        <a onClick={this._addExercise} className="btn-floating btn-large waves-effect waves-light">
                            <i className="material-icons">add</i>
                        </a>
                    </div>
                    <div className="cardInfo">
                        <span className="card-title">{ this.props.exercise.name }</span>
                        <span className="extraInfo">{ this._getExerciseTags() }</span>
                    </div>
                    <div className="card-action">
                        <a className="cursor" onClick={this._showExercise}><Trans>trainingSchedule.moreInfo</Trans></a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExerciseCard;