import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';

export default class MealDetailView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            meal: props.meal,
            activeTab: "nutritions"
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        // eslint-disable-next-line eqeqeq
        if(this.state.meal != nextProps.meal) {
            this.setState({
                meal: nextProps.meal,
                activeTab: "nutritions"
            });
        }
    }

    componentDidMount() {
        $('#mealDetailInfo').modal();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _viewMealDetailNutritions(event) {
        event.preventDefault();
        this.setState({activeTab:"nutritions"});
        return false;
    }

    _viewMealDetailRecipe(event) {
        event.preventDefault();
        this.setState({activeTab:"recipe"});
        return false;
    }

    _close() {
        $(".modal-overlay").trigger("click");
    }

    _substituteMeal() {
        this.props.showMealSubstitutions(this.state.meal, this.props.dailyAdviceMeal);
    }

    _clearSubstitution() {
        fmfApiClient.removeSubstituteMeal(this.cancelSource.token, this.props.clientId, this.props.nutritionScheduleId, this.props.substituteMeal.id)
            .then(result => {
                this.props.refreshNutrititonSchedule();
            }, error => {
                Materialize.toast(i18n.t('errors.' + error.error.toString()), window.toastDuration);
            }).finally(() => {
                this._close();
            });
    }

    _imageFileName() {
        if (this.state.meal.imageUrl) {
            return 'url(' + this.state.meal.imageUrl + ')';
        }
        return 'transparent';
    }

    render() {
        let recipe;
        // eslint-disable-next-line eqeqeq
        if (this.state.meal != null) {
            if (this.state.meal.recipe) {
                recipe = (
                    <div>
                        <p>
                            {this.state.meal.recipe.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                            })}
                        </p>
                    </div>
                );
            }
        }
        return (
            <div id="mealDetailInfo" className="modal wide">
                {this.state.meal &&
                    <div className="mealDetailnfo" key={"mealdetail" + this.state.meal.id}>
                        <div className="row recipe-image-row">
                            <div className="col l4 s12 recipe-image-col">
                                <figure className="recipe-image-container">
                                    <span className="recipe-image"
                                        style={{backgroundImage: this._imageFileName()}}>
                                    </span>
                                </figure>
                            </div>
                            <div className="col l8 s12 mealDetailinfo-container">
                                <div className="mealDetailnfo-header">
                                    <h2>{this.state.meal.name}</h2>
                                    <div className="userInfo variableName">{this.state.meal.mealTypeName}</div>
                                    {this.props.substitutedMeal &&
                                        <div className="card orange">
                                            <div className="card-content white-text">
                                                <i className="material-icons">swap_horiz</i>
                                                <span>{i18n.t('nutritionSchedule.replacementFor')} '{this.props.substitutedMeal.name}'.</span>
                                                <a href="#" onClick={() => this._clearSubstitution()}>{i18n.t('nutritionSchedule.resetSubstitution')}</a>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="tabs-container">
                                    <ul className="tabs">
                                        <li className="tab">
                                            {/* eslint-disable-next-line eqeqeq */}
                                            <a className={this.state.activeTab == "nutritions" ? "active" : ""}
                                                onClick={e => this._viewMealDetailNutritions(e)}>
                                                <Trans>nutritionSchedule.ingredients</Trans>
                                            </a>
                                        </li>
                                        {this.state.meal && this.state.meal.recipe &&
                                            <li className="tab">
                                                {/* eslint-disable-next-line eqeqeq */}
                                                <a className={this.state.activeTab == "recipe" ? "active" : ""}
                                                    onClick={e => this._viewMealDetailRecipe(e)}>
                                                    <Trans>nutritionSchedule.recipe</Trans>
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                    <div className="tabs-underline"></div>
                                </div>
                                <div className="mealDetail-body-container">
                                    <div id="mealDetailNutritions-body"
                                        // eslint-disable-next-line eqeqeq
                                        className={this.state.activeTab == "nutritions" ? "mealDetailnfo-body" : "mealDetailnfo-body hidden"}>
                                        {this.state.meal.mealNutritions.map((mealNutrition,index) => (
                                            <div key={"mealNutritions" + index} className="row">
                                                <span className="col s3">
                                                    {mealNutrition.quantity}
                                                    &nbsp;
                                                    {mealNutrition.quantity > 1 ? mealNutrition.nutrition.unitNamePlural : mealNutrition.nutrition.unitName}
                                                </span>
                                                <span className="col s7">{mealNutrition.nutrition.name}</span>
                                                <span className="col s2 right-align">{mealNutrition.kcal} kcal</span>
                                            </div>
                                        ))}
                                    </div>
                                    {this.state.meal && this.state.meal.recipe  &&
                                        <div id="mealDetailRecept-body"
                                            // eslint-disable-next-line eqeqeq
                                            className={this.state.activeTab == "recipe" ? "mealDetailnfo-body" : "mealDetailnfo-body hidden"}>
                                            {recipe}
                                        </div>
                                    }
                                </div>
                                <div className="mealDetail-footer-container">
                                    <button onClick={() => this._substituteMeal()} className="waves-effect waves-light btn noShadow">
                                        <i className="material-icons">swap_horiz</i>
                                        <Trans>nutritionSchedule.checkSubstitutions</Trans>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <a className="closeButton" onClick={this._close}><i className="material-icons">close</i></a>
            </div>
        )
    }
}

MealDetailView.contextType = UserContext
