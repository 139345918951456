import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';

export default class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            firstName: '',
            lastName: '',

            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    componentDidMount() {
        $("#addUserForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._onSubmit();
            }.bind(this)
        });
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _onSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        let user = {
            userName: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName
        };

        //SET empty string values to null
        Object.keys(user).forEach(function(key,index) {
            if(user[key] === '') {
                user[key] = null;
            }
        });

        fmfApiClient.createUser(this.cancelSource.token, user)
            .then(result => {
                this.setState({
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: ''
                });

                $(".modal-overlay").trigger("click");
                Materialize.toast(i18n.t('subscriptionPage.users.addUser.successText'), window.toastDuration);
                this.props.onUserAdded();
            }, error => {
                this.setState({
                    errorMessage: error.error === 'username_not_available' ?
                        i18n.t('subscriptionPage.users.addUser.usernameNotAvailable') :
                        i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    render() {
        return (
            <div id="addUser" className="modal">
                <form id="addUserForm" noValidate onSubmit={this._submitForm}>
                <div className="modal-content">
                    <div className="row">
                        <div className="col s12">
                            <h4><Trans>subscriptionPage.users.addUser.modalTitle</Trans></h4>
                            <p>{ i18n.t('subscriptionPage.users.addUser.description') }</p>
                            {/* ERROR MESSAGE */}
                            <div className={this.state.showError}>
                                <div id="card-alert" className="card red">
                                    <div className="card-content white-text">
                                        <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='input-field col s12'>
                            <input id="email" type="email"
                                name="email"
                                value={this.state.email}
                                onChange={this._changeHandler}
                                data-msg={ i18n.t('errors.no_valid_email') }
                                required />
                            <label htmlFor="firstName" >{ i18n.t('formFields.email') }</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className='input-field col s12'>
                            <input id="firstName" type="text"
                                name="firstName"
                                value={this.state.firstName}
                                onChange={this._changeHandler}
                                data-msg={ i18n.t('errors.required_field') }
                                required />
                            <label htmlFor="firstName" >{ i18n.t('formFields.firstname') }</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className='input-field col s12'>
                            <input id="lastName" type="text"
                                name="lastName"
                                value={this.state.lastName}
                                onChange={this._changeHandler}
                                data-msg={ i18n.t('errors.required_field') }
                                required />
                            <label htmlFor="lastName">{ i18n.t('formFields.lastname') }</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <div className="col s12">
                            <button type="submit"
                                disabled={this.state.buttonDisabled}
                                className="waves-effect waves-light btn noShadow">
                                <Trans>subscriptionPage.users.addUser.button</Trans>
                            </button>
                            <div className={this.state.showLoading}
                                style={{margin: '0 0 0 16px', display: 'inline-block','position':'absolute'}}  >
                                <CircleLoader/>
                            </div>
                        </div>
                    </div>
                </div>
                </form>
            </div>
        );
    }
}