import React from 'react';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import ImageCropper from 'components/components/imageCropper/imageCropper.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';

export default class EditClient extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: 0,
            firstName: '',
            lastName: '',
            gender: '',
            phoneNumber: '',
            email: '',
            connectedEmail: '',
            birthdate: '',
            serverBirthdate: '',
            titel: '',
            initials: '',
            postcode: '',
            houseNumber: '',
            street: '',
            city: '',
            country: '',
            length: '',
            phoneNumberAlt: '',
            imageUrl: '',
            previewImageUrl: '',
            isActive: true,
            isConnected: false,

            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            canAddClient: true,
            showLoading: 'hide'
        };

        this.picker = null;

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);
        this._resetImage = this._resetImage.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _setDate (setDate) {
        if(setDate.select) {
            this.setState({ serverBirthdate: new Date(setDate.select).toLocalISOString() });
            this.setState({ birthdate: new Date(setDate.select).toInversedShortDateString() });
        }
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'length') {
            // Only allow input that is a whole number (a number without a decimal).
            const wholeNumberRegex = /^[0-9\b]+$/;
            if (value !== '' && !wholeNumberRegex.test(value)) {
                return;
             }
        }

        this.setState({[name]: value});
    }

    componentDidMount() {
        let picker = $('#editClientForm .datepicker').pickadate({
            selectYears: 70,
            today: '',
            selectMonths: true,
            format: 'dd-mm-yyyy',
            max: new Date(),
            closeOnSelect: true,
            onSet: function(timeSet) {
                this._setDate(timeSet);
            }.bind(this)
        });
        this.picker = picker.pickadate('picker');


        $("#editClientForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function() {
                this._onSubmit();
            }.bind(this)
        });
        $('#editClient .tooltipped').tooltip({delay: 50});
    }

    componentDidUpdate(){
        $('#editClient .tooltipped').tooltip({delay: 50});
    }

    componentWillUnmount() {
        this.unmounted = true;
        this.cancelSource.cancel();
    }

    resetState () {
        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide',
            showSuccess: 'hide'
        });
    }

    _resetImage (imageUrl) {
        if (typeof this.props.onChange === 'function') {
            this.props.onChange();
        }
    }


    _onSubmit () {
        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        if (this.props.controlPlusLink) {
            $(".modal-overlay").trigger("click");
            return false;
        }

        let client = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            connectedEmail: this.state.connectedEmail,
            phoneNumber: this.state.phoneNumber,
            gender: this.state.gender,
            birthDate: this.state.serverBirthdate,
            titel: this.state.titel,
            initials: this.state.initials,
            postcode: this.state.postcode,
            houseNumber: this.state.houseNumber,
            street: this.state.street,
            city: this.state.city,
            country: this.state.country,
            length: this.state.length,
            phoneNumberAlt: this.state.phoneNumberAlt,
            isActive: this.state.isActive
        };

        //SET empty string values to null
        Object.keys(client).forEach(function(key,index) {
            if(client[key] === '') {
                if(key === 'gender'){
                    client[key] = 0;
                } else {
                    client[key] = null;
                }
            }
        });

        fmfApiClient.updateClient(this.cancelSource.token, this.props.clientId, client)
            .then(result => {
                this.setState({
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: 'hide',
                    showSuccess: ''
                });

                if (typeof this.props.onChange === 'function') {
                    this.props.onChange();
                }

                $(".modal-overlay").trigger("click");
                Materialize.toast(i18n.t('editClient.successText'), window.toastDuration);
            }, error => {
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error.toString()),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });

        return false;
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    _submitForm(event) {
        event.preventDefault();
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        let client = nextProps.client;
        if(client){
            Object.keys(client).forEach(function(key,index) {

                if (client[key]) {
                    this.setState({ [key]: client[key] });
                } else {
                    this.setState({ [key]: "" });
                }

            }.bind(this));

            setTimeout(function() {
                if (this.unmounted) return;

                Materialize.updateTextFields();

                if(client.birthDate) {
                    this.picker.set('select', new Date(client.birthDate));
                }
            }.bind(this), 100);
        }
    }

    render() {
        return (
            <div id="editClient" className="modal wide">
                <form id="editClientForm" noValidate onSubmit={this._submitForm}>
                    <div className="modal-content">
                        <div className="row">
                            <div className="col s12">
                                <h4><Trans>editClient.modalTitle</Trans> { this.state.firstName }</h4>
                                {/* ERROR MESSAGE */}
                                <div className={this.state.showError}>
                                    <div id="card-alert" className="card red">
                                        <div className="card-content white-text">
                                            <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.props.controlPlusLink ?
                                    <div id="card-alert" className="card">
                                        <div className="card-content">
                                            <p>{i18n.t('controlPlus.clientEditDisabled')}</p>
                                        </div>
                                    </div> :
                                    null
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m5">
                                {this.state.imageUrl &&
                                    <ImageCropper
                                        preview={this.state.imageUrl}
                                        itemId={this.state.id}
                                        onChange={this._resetImage}
                                        controlPlusLink={this.props.controlPlusLink}
                                        onSave={(cancelToken, fileName, fileData) =>
                                            fmfApiClient.createClientImage(cancelToken, this.state.id, fileName, fileData)
                                        }
                                    />
                                }
                                <br />
                                <br />
                            </div>
                            <div className="col s12 m7 image-inputs-col">
                                <div className="row">
                                    <div className="input-field col s6 m3">
                                        <input
                                            id="initials" type="text"
                                            name="initials"
                                            value={this.state.initials}
                                            onChange={this._changeHandler}
                                            disabled={this.props.controlPlusLink}
                                        />
                                        <label htmlFor="initials" >{ i18n.t('formFields.initials') }</label>
                                    </div>
                                    <div className="input-field col s6 m3">
                                        <input
                                            id="titel" type="text"
                                            name="titel"
                                            value={this.state.titel}
                                            onChange={this._changeHandler}
                                            disabled={this.props.controlPlusLink}
                                        />
                                        <label htmlFor="titel" >{ i18n.t('formFields.titel') }</label>
                                    </div>
                                    <div className="col s12 m6">
                                        <label htmlFor="gender">
                                            <span>Geslacht</span>
                                        </label>
                                        <select id="gender"
                                                name="gender"
                                                className="browser-default"
                                                value={this.state.gender}
                                                onChange={this._changeHandler}
                                                disabled={this.props.controlPlusLink}>
                                            <option value="" disabled>{ i18n.t('formFields.chooseGender') }</option>
                                            <option value="1">{ i18n.t('formFields.male') }</option>
                                            <option value="2">{ i18n.t('formFields.female') }</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <input
                                            id="firstName" type="text"
                                            name="firstName"
                                            value={this.state.firstName}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                            disabled={this.props.controlPlusLink}
                                        />
                                        <label htmlFor="firstName" >{ i18n.t('formFields.firstname') }</label>
                                    </div>
                                    <div className="input-field col s12 m6">
                                        <input
                                            id="lastName" type="text"
                                            name="lastName"
                                            value={this.state.lastName}
                                            onChange={this._changeHandler}
                                            required data-msg={ i18n.t('errors.required_field') }
                                            disabled={this.props.controlPlusLink}
                                            />
                                        <label htmlFor="lastName" >{ i18n.t('formFields.lastname') }</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <input
                                            id="email" type="email"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this._changeHandler}
                                            data-msg={ i18n.t('errors.no_valid_email') }
                                            disabled={this.props.controlPlusLink}
                                            />
                                        <label htmlFor="email" >{ i18n.t('formFields.email') }</label>
                                    </div>
                                    <div className="input-field col s12 m6">
                                        <input
                                            id="phoneNumber" type="text"
                                            name="phoneNumber"
                                            value={this.state.phoneNumber}
                                            onChange={this._changeHandler}
                                            data-msg={ i18n.t('errors.no_valid_email') }
                                            disabled={this.props.controlPlusLink}
                                            />
                                        <label htmlFor="phoneNumber" >{ i18n.t('formFields.phoneNumber') }</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col s12 m6 input-field">
                                        <input type="text"
                                            id="birthdate"
                                            name="birthdate"
                                            value={this.state.birthdate}
                                            onChange={this._changeHandler}
                                            className="datepicker"
                                            disabled={this.props.controlPlusLink}
                                            />
                                        <label htmlFor="birthdate" className="active">{ i18n.t('formFields.birthdate') }</label>
                                    </div>
                                    <div className="input-field col s12 m6">
                                        <input
                                            id="length"
                                            type="text"
                                            name="length"
                                            value={this.state.length}
                                            onChange={this._changeHandler}
                                        />
                                        <span className="suffixInput">cm</span>
                                        <label htmlFor="length"><Trans>formFields.length</Trans></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12 m6">
                                <input
                                    id="street" type="text"
                                    name="street"
                                    value={this.state.street}
                                    onChange={this._changeHandler}
                                    disabled={this.props.controlPlusLink}
                                     />
                                <label htmlFor="street" >{ i18n.t('formFields.street') }</label>
                            </div>
                            <div className="input-field col s12 m6">
                                <input
                                    id="houseNumber" type="text"
                                    name="houseNumber"
                                    value={this.state.houseNumber}
                                    onChange={this._changeHandler}
                                    disabled={this.props.controlPlusLink}
                                     />
                                <label htmlFor="houseNumber" >{ i18n.t('formFields.houseNumber') }</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12 m6">
                                <input
                                    id="postcode" type="text"
                                    name="postcode"
                                    value={this.state.postcode}
                                    onChange={this._changeHandler}
                                    disabled={this.props.controlPlusLink}
                                     />
                                <label htmlFor="postcode" >{ i18n.t('formFields.postcode') }</label>
                            </div>
                            <div className="input-field col s12 m6">
                                <input
                                    id="city" type="text"
                                    name="city"
                                    value={this.state.city}
                                    onChange={this._changeHandler}
                                    disabled={this.props.controlPlusLink}
                                     />
                                <label htmlFor="city" >{ i18n.t('formFields.city') }</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12 m6">
                                <input
                                    id="country" type="text"
                                    name="country"
                                    value={this.state.country}
                                    onChange={this._changeHandler}
                                    disabled={this.props.controlPlusLink}
                                     />
                                <label htmlFor="country" >{ i18n.t('formFields.country') }</label>
                            </div>
                            {this.state.isConnected ?
                            <div className="input-field col s12 m6">
                                <input
                                    id="connectedEmail" type="text"
                                    name="connectedEmail"
                                    value={this.state.connectedEmail}
                                    disabled />
                                <a className="info tooltipped" data-position="bottom" data-delay="50"
                                    data-tooltip={ i18n.t('editClient.client_connected_email_description') }>
                                    <i className="material-icons">info</i>
                                </a>
                                <label htmlFor="connectedEmail">{ i18n.t('formFields.connectedEmail') }</label>
                            </div>
                            : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col s12 m6">
                                <div className="switch">
                                    <label>
                                        <strong>Cliënt:</strong>&nbsp;
                                        Inactief
                                        <input type="checkbox"
                                            name="isActive"
                                            checked={this.state.isActive}
                                            onChange={this._changeHandler}

                                        />
                                            <span className="lever"></span>
                                            Actief

                                    </label>
                                    &nbsp; &nbsp;
                                    <a className="info tooltipped afterSwitch" data-position="bottom" data-delay="50" data-tooltip={ i18n.t('editClient.inactiveClient') }><i className="material-icons">info</i></a>
                                </div>
                            </div>
                            <div className="col s12 m6">
                                <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit">
                                    {
                                        this.props.controlPlusLink ?
                                        <Trans>editClient.cancelButton</Trans> :
                                        <Trans>editClient.button</Trans>
                                    }
                                </button>
                                <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                    <CircleLoader/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}