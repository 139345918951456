import React from 'react';
import fmfApiClient from 'utils/fmf-api-client.js';

import TrainingTemplateHeader from 'components/components/trainingTemplates/trainingTemplateHeader.jsx';
import ExerciseOverview from 'components/components/trainingSchedule/exerciseOverview.jsx';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import TrainingTemplateExercises from "./trainingTemplateExercises";

class EditTrainingTemplateLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            goalId: null,
            exercises: [],
            trainingSchedule: null,
            trainingTemplateId: null
        };

        this._addExercise = this._addExercise.bind(this);
        this.nameChange = this.nameChange.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillMount() {
        this.setState({trainingTemplateId: this.props.trainingTemplateId});

        if(this.props.trainingTemplateId) {
            this.getTrainingTemplate(this.props.trainingTemplateId);
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    getTrainingTemplate(trainingTemplateId) {
        const endpoint = this.props.cms ? 'getTrainingTemplate' : 'getCustomTrainingTemplate';
        fmfApiClient[endpoint](this.cancelSource.token, trainingTemplateId)
            .then(result => {
                this.setState({
                    trainingSchedule: result,
                    exercises: result.scheduleExercises,
                    goalId: result.goalId
                });
            });
    }

    _addExercise(exercise) {
        this.exerciseList.addExercise(exercise);
    }

    nameChange(name, goal) {
        this.exerciseList.setNameAndGoal(name, goal);
    }

    render() {
        return (
            <div className="splitcolumnPage">
                { this.state.trainingSchedule == null && !this.props.cms && this.props.addTrainingSchedule ?
                <FPLoader fixed="fixed" />
                :
                <div>
                    <div className="row noMarginBottom">
                        <div className="col s12 m7 l8 xl9">
                            <TrainingTemplateHeader ref={instance => {this.header = instance;}}
                                template={this.state.trainingSchedule} onNameChange={this.nameChange} />
                            <ExerciseOverview onAddExercise={this._addExercise}
                                hideTemplates={true} currentClient={this.state.currentClient} />
                        </div>
                        <div className="col s12 m5 l4 xl3 greyContainer" id="sidebar">
                            <TrainingTemplateExercises cms={this.props.cms} addTrainingTemplate={this.props.addTrainingTemplate}
                                ref={instance => {this.exerciseList = instance;}}
                                template={this.state.trainingSchedule} goal={this.state.goalId}
                                exercises={this.state.trainingSchedule ? this.state.trainingSchedule.exercises : []} />
                        </div>
                    </div>
                </div>
                }
            </div>
        )
    }
}

export default EditTrainingTemplateLayout;