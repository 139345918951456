import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import moment from 'moment';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import fmfApiClient from 'utils/fmf-api-client.js';

class AddMeasurement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this._resetLoadAndErrorState(),
            ...this._deriveStateFromProps({}, props)
        };

        this._onSubmit = this._onSubmit.bind(this);
        this._changeHandler = this._changeHandler.bind(this);
        this._selectChangeHandler = this._selectChangeHandler.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const newState = this._deriveStateFromProps(this.props, nextProps);
        if (newState) {
            this.setState({
                ...this._resetLoadAndErrorState(),
                ...newState
            }, Materialize.updateTextFields);
        }
    }

    componentDidMount() {
        $("#addMeasurementForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function() {
                this._onSubmit();
            }.bind(this)
        });
        $('#addMeasurement').modal();
    }

    componentDidUpdate(_, prevState) {
        if (this.state.skinFoldMeasurementMethod !== prevState.skinFoldMeasurementMethod) {
            // This fixes overlapping textfield headers on skinfold measurement inputs.
            Materialize.updateTextFields();
        }
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    openModal() {
        $('#addMeasurement').modal('open');
    }

    _resetLoadAndErrorState() {
        return {
            errorMessage: '',
            showError: 'hide',
            buttonDisabled: '',
            showSuccess: 'hide',
            showLoading: 'hide'
        };
    }

    _emptyMeasurement() {
        return {
            currentWeight: '',
            currentLength: '',
            bicepsSkinFold: '',
            tricepsSkinFold: '',
            subscapularSkinFold: '',
            iliacCrestSkinFold: '',
            chestSkinFold: '',
            abdominalSkinFold: '',
            thighSkinFold: '',
            axillarySkinFold: '',
            fatPercentage: '',
            skinFoldMeasurementMethod: ''
        };
    }

    _deriveStateFromProps(props, nextProps) {
        if (props.existingMeasurement !== nextProps.existingMeasurement) {
            let state = this.state || {};

            if (!nextProps.existingMeasurement) {
                state = {
                    ...state,
                    ...this._emptyMeasurement()
                }
            } else {
                const m = nextProps.existingMeasurement;

                // 0 is a valid value, but because of JS shenanigans it's the same as !var.
                let newSkinFoldMeasurementMethod = m.skinFoldMeasurementMethod;
                if (newSkinFoldMeasurementMethod !== 0 && !newSkinFoldMeasurementMethod)
                    newSkinFoldMeasurementMethod = '';

                state = {
                    ...state,
                    currentWeight: m.currentWeight || '',
                    currentLength: m.currentLength || '',
                    bicepsSkinFold: m.bicepsSkinFold || '',
                    tricepsSkinFold: m.tricepsSkinFold || '',
                    subscapularSkinFold: m.subscapularSkinFold || '',
                    iliacCrestSkinFold: m.iliacCrestSkinFold || '',
                    chestSkinFold: m.chestSkinFold || '',
                    abdominalSkinFold: m.abdominalSkinFold || '',
                    thighSkinFold: m.thighSkinFold || '',
                    axillarySkinFold: m.axillarySkinFold || '',
                    fatPercentage: m.fatPercentage || '',
                    skinFoldMeasurementMethod: newSkinFoldMeasurementMethod
                };
            }

            return state;
        }
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _selectChangeHandler (event) {
        const target = event.target;
        const value = parseInt(target.value); // <select> inputs always its their value as a string.
        const name = target.name;

        this.setState({ [name]: value });
    }

    _onSubmit () {
        // eslint-disable-next-line eqeqeq
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        // If no data is entered for measurements.. then don't store empty record
        // eslint-disable-next-line eqeqeq
        if (this.state.currentWeight == '' && this.state.currentLength == '' &&
            (this.state.skinFoldMeasurementMethod === '' ||
            // eslint-disable-next-line eqeqeq
            (this.state.skinFoldMeasurementMethod === 0 && this.state.fatPercentage == ''))) {
            $("#addMeasurement").modal('close');
            return;
        }

        this.setState({
            errorMessage: '',
            buttonDisabled: 'disabled',
            showLoading: '',
            showError: 'hide'
        });

        const measurement = {
            // eslint-disable-next-line eqeqeq
            "fatPercentage": this.state.skinFoldMeasurementMethod === 0 && this.state.fatPercentage != '' ? this.state.fatPercentage : null,
            "bicepsSkinFold": this.state.skinFoldMeasurementMethod === 1 ? this.state.bicepsSkinFold:null,
            "tricepsSkinFold": (this.state.skinFoldMeasurementMethod === 1 || this.state.skinFoldMeasurementMethod === 2) ? this.state.tricepsSkinFold : null,
            "subscapularSkinFold": (this.state.skinFoldMeasurementMethod === 1 ||this.state.skinFoldMeasurementMethod === 2) ? this.state.subscapularSkinFold : null,
            "iliacCrestSkinFold": (this.state.skinFoldMeasurementMethod === 1 || this.state.skinFoldMeasurementMethod === 2) ? this.state.iliacCrestSkinFold : null,
            "chestSkinFold":this.state.skinFoldMeasurementMethod === 2 ? this.state.chestSkinFold : null,
            "abdominalSkinFold":this.state.skinFoldMeasurementMethod === 2 ? this.state.abdominalSkinFold : null,
            "thighSkinFold":this.state.skinFoldMeasurementMethod === 2 ? this.state.thighSkinFold : null,
            "axillarySkinFold":this.state.skinFoldMeasurementMethod === 2 ? this.state.axillarySkinFold : null,
            "skinFoldMeasurementMethod":this.state.skinFoldMeasurementMethod,
            // eslint-disable-next-line eqeqeq
            "currentWeight": (this.state.currentWeight != '' && this.state.currentWeight > 0) ? this.state.currentWeight : null,
            // eslint-disable-next-line eqeqeq
            "currentLength": (this.state.currentLength != '' && this.state.currentLength > 0) ? this.state.currentLength : null,
            "WaistSize": null,
            "ChestSize": null,
            "HipSize": null,
            "UpperArmLeft": null,
            "UpperArmRight": null,
            "UpperLegLeft": null,
            "UpperLegRight": null
        };

        let response;
        if (!this.props.existingMeasurement) {
            response = fmfApiClient.createClientMeasurement(this.cancelSource.token, this.props.clientId, measurement);
        } else {
            response = fmfApiClient.updateClientMeasurement(this.cancelSource.token, this.props.clientId, this.props.existingMeasurement.id, measurement);
        }
        response.then(result => {
            this.setState({
                ...this._emptyMeasurement(),
                buttonDisabled: '',
                showLoading: 'hide',
                showError: 'hide',
                showSuccess: ''
            });

            if (typeof this.props.onChange === 'function') {
                this.props.onChange();
            }

            $("#addMeasurement").modal('close');
            Materialize.toast(i18n.t('measurements.addMeasurementSucces'), window.toastDuration);
        }, error => {
            this.setState({
                errorMessage: i18n.t('errors.' + error.error),
                buttonDisabled: '',
                showLoading: 'hide',
                showError: ''
            });
        });
    }

    setErrorMessageForInnerHTML() {
        return {__html: this.state.errorMessage};
    }

    _submitForm(event) {
        event.preventDefault();
    }

    _renderTitle() {
        if (!this.props.existingMeasurement)
            return <h4><Trans>measurements.addMeasurementTitle</Trans></h4>
        else
            return <h4><Trans>measurements.editMeasurementTitle</Trans></h4>;
    }

    _renderDate(measurement) {
        if (!measurement) {
            return;
        }

        let date = moment(measurement.createdAt);
        let dateFormatted = date.format('D MMMM \'YY');

        return (
            <div className="row">
                <div className="col s12">
                    <span className="variableName">{dateFormatted}</span>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div id="addMeasurement" className="modal">
                <form id="addMeasurementForm" onSubmit={this._submitForm}>
                    <div className="modal-content">
                        <div className="row noMarginBottom">
                            <div className="col s12">
                                { this._renderTitle() }
                            </div>
                        </div>
                        { this._renderDate(this.props.existingMeasurement) }
                        {/* ERROR MESSAGE */}
                        <div className={this.state.showError}>
                            <div id="card-alert" className="card red">
                                <div className="card-content white-text">
                                    <p dangerouslySetInnerHTML={this.setErrorMessageForInnerHTML()}></p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12 m5">
                                <div>
                                    <input id="currentWeight"
                                        type="text"
                                        name="currentWeight"
                                        value={this.state.currentWeight}
                                        onChange={this._changeHandler}
                                    />
                                    <span className="suffixInput">kg</span>
                                    <label htmlFor="currentWeight"><Trans>formFields.currentWeight</Trans></label>
                                </div>
                            </div>
                            <div className='col s12 m7'>
                                <label htmlFor="skinFoldMeasurementMethod" className="skinFoldMeasurementMethod">
                                    <Trans>measurements.skinFoldMeasurementMethod</Trans>
                                </label>
                                <select id="skinFoldMeasurementMethod"
                                    name="skinFoldMeasurementMethod"
                                    className="browser-default"
                                    value={this.state.skinFoldMeasurementMethod}
                                    onChange={this._selectChangeHandler}
                                >
                                    <option value="" disabled>{ i18n.t('measurements.skinFoldMeasurementMethodPlaceholder') }</option>
                                    <option value={0}>{ i18n.t('measurements.noSkinFoldMeasurementMethod') }</option>
                                    <option value={1}>{ i18n.t('measurements.4points') }</option>
                                    <option value={2}>{ i18n.t('measurements.7points') }</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            {this.state.skinFoldMeasurementMethod === 0 &&
                                <div className='col'>
                                    <div className="row">
                                        <div className='input-field col s12'>
                                            <input id="fatPercentage" type="text"
                                                name="fatPercentage"
                                                maxLength="4"
                                                value={this.state.fatPercentage}
                                                onChange={this._changeHandler}
                                            />
                                            <span className="suffixInput">%</span>
                                            <label htmlFor="fatPercentage"><Trans>measurements.fatPercentage</Trans></label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.skinFoldMeasurementMethod === 1 &&
                                <div className='col'>
                                    <div className="row">
                                        <div className='input-field col s6'>
                                            <input id="bicepsSkinFold" type="text"
                                                name="bicepsSkinFold"
                                                maxLength="4"
                                                value={this.state.bicepsSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="bicepsSkinFold"><Trans>measurements.bicepsSkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="tricepsSkinFold" type="text"
                                                name="tricepsSkinFold"
                                                maxLength="4"
                                                value={this.state.tricepsSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="tricepsSkinFold"><Trans>measurements.tricepsSkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="subscapularSkinFold" type="text"
                                                name="subscapularSkinFold"
                                                maxLength="4"
                                                value={this.state.subscapularSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="subscapularSkinFold"><Trans>measurements.subscapularSkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="iliacCrestSkinFold" type="text"
                                                name="iliacCrestSkinFold"
                                                maxLength="4"
                                                value={this.state.iliacCrestSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="iliacCrestSkinFold"><Trans>measurements.iliacCrestSkinFold</Trans></label>
                                        </div>
                                    </div>
                                </div>
                            }
                            {this.state.skinFoldMeasurementMethod === 2 &&
                                <div className='col'>
                                    <div className="row s12">
                                        <div className='input-field col s6'>
                                            <input id="chestSkinFold" type="text"
                                                name="chestSkinFold"
                                                maxLength="4"
                                                value={this.state.chestSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="chestSkinFold"><Trans>measurements.chestSkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="abdominalSkinFold" type="text"
                                                name="abdominalSkinFold"
                                                maxLength="4"
                                                value={this.state.abdominalSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="abdominalSkinFold"><Trans>measurements.abdominalSkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="thighSkinFold" type="text"
                                                name="thighSkinFold"
                                                maxLength="4"
                                                value={this.state.thighSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="thighSkinFold"><Trans>measurements.thighSkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="axillarySkinFold" type="text"
                                                name="axillarySkinFold"
                                                maxLength="4"
                                                value={this.state.axillarySkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="axillarySkinFold"><Trans>measurements.axillarySkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="tricepsSkinFold" type="text"
                                                name="tricepsSkinFold"
                                                maxLength="4"
                                                value={this.state.tricepsSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="tricepsSkinFold"><Trans>measurements.tricepsSkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="subscapularSkinFold" type="text"
                                                name="subscapularSkinFold"
                                                maxLength="4"
                                                value={this.state.subscapularSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="subscapularSkinFold"><Trans>measurements.subscapularSkinFold</Trans></label>
                                        </div>
                                        <div className='input-field col s6'>
                                            <input id="iliacCrestSkinFold" type="text"
                                                name="iliacCrestSkinFold"
                                                maxLength="4"
                                                value={this.state.iliacCrestSkinFold}
                                                onChange={this._changeHandler}
                                                required
                                                data-msg={ i18n.t('errors.required_field') }
                                            />
                                            <span className="suffixInput">mm</span>
                                            <label htmlFor="iliacCrestSkinFold"><Trans>measurements.iliacCrestSkinFold</Trans></label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col s12">
                                <button disabled={this.state.buttonDisabled} className="waves-effect waves-light btn noShadow" type="submit">
                                    {
                                        !this.props.existingMeasurement ?
                                        <Trans>generalActions.add</Trans> :
                                        <Trans>generalActions.edit</Trans>
                                    }
                                </button>
                                <div className={this.state.showLoading} style={{margin: '0 0 0 16px',display: 'inline-block','position':'absolute'}}  >
                                    <CircleLoader/>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default AddMeasurement;