import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { ai } from 'utils/appInsights';
import moment from 'moment';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';
import history from 'history.js';
import authService from 'utils/auth-service.js';

import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import image from 'utils/image.js';

import { MergeShoppingList } from 'utils/nutrition/mergeShoppingList.js';

import DailyAdviceOverView from './dailyAdviceOverView.jsx';

import DayDetailView from 'components/components/nutritionSchedule/dayDetailView.jsx';
import MealDetailView from 'components/components/nutritionSchedule/mealDetailView.jsx';
import MealSubstitutionView from 'components/components/nutritionSchedule/mealSubstitutionView.jsx';
import AddNutritionScheduleWeeksModal from 'components/forms/modals/addNutritionSchedule.jsx';
import CalculateKcalDailyModal from 'components/forms/modals/calculateKcalDaily.jsx';
import AddMeasurementModal from 'components/forms/modals/addMeasurement.jsx';

const mergeShoppingList = new MergeShoppingList();
export default class NutritionSchedule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            nutritionSchedule: null,
            client: null,
            clickedDailyAdvice: null,
            clickedDailyAdviceDayInWeek: null,
            clickedDailyAdviceDateFormatted: null,
            clickedMealDetail: null,
            clickedDailyAdviceMeal: null,
            clickedMealSubstituteMeal: null,
            clickedMealSubstitutedMeal: null
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    componentDidMount() {
        const isUserTypeClient = this._isUserTypeClient();
        this._getClient(isUserTypeClient);
        this._getNutritionSchedule(isUserTypeClient);
        this._getMeasurements(isUserTypeClient);
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _calculateWeek() {
        return this.props.week || (this.state.nutritionSchedule && this.state.nutritionSchedule.currentWeek) || 1;
    }

    _isUserTypeClient() {
        const user = this.context.currentUser;
        const userType = user && user.identityToken && user.identityToken.user_type;
        return userType === 'Client';
    }

    _getClient(isUserTypeClient) {
        fmfApiClient.getClient(this.cancelSource.token, this.props.clientId, image.resize(160, 160))
            .then(result => {
                this.setState({client: result});
            }, error => {
                this._handleCariphyErrors(error, isUserTypeClient);
            });
    }

    _getNutritionSchedule(isUserTypeClient) {
        fmfApiClient.getClientNutritionSchedule(this.cancelSource.token, this.props.clientId, this.props.nutritionScheduleId)
            .then(result => {
                this.setState({
                    nutritionSchedule: result
                }, () => {
                    this.forceUpdate();
                });
            }, error => {
                this._handleCariphyErrors(error, isUserTypeClient);
            });
    }

    _getMeasurements(isUserTypeClient) {
        fmfApiClient.getClientMeasurements(this.cancelSource.token, this.props.clientId)
            .then(result => {
                this.setState({
                    measurements: result.items
                });
            }, error => {
                this._handleCariphyErrors(error, isUserTypeClient);
            });
    }

    _deleteNutritionSchedule(isUserTypeClient) {
        if(window.confirm(i18n.t('nutritionSchedule.deleteConfirmation'))) {
            fmfApiClient.deleteClientNutritionSchedule(this.cancelSource.token, this.props.clientId, this.props.nutritionScheduleId)
                .then(result => {
                    Materialize.toast(i18n.t('nutritionSchedule.nutritionScheduleDeleted'), window.toastDuration);
                    history.push('/clienten/' + this.props.clientId);
                }, error => {
                    this._handleCariphyErrors(error, isUserTypeClient);
                });
        }
    }

    _handleCariphyErrors(error, isUserTypeClient) {
        if (error.error === 'client_inactive' || error.error === 404) {
            // Client has been set to inactive by trainer...
            if(!isUserTypeClient) {
                // ...redirect to client dashboard.
                history.push('/clienten/' + this.props.clientId);
            } else {
                // ...logout the client user.
                authService.logout();
            }
        } else {
            Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
        }
    }

    _handleKcalCalculation(adviceKcalDaily) {
        this.setState({adviceKcalDaily: adviceKcalDaily});
    }

    _handleAddWeeks(nutritionSchedule) {
        this.setState({nutritionSchedule: nutritionSchedule});
    }

    _refreshNutrititonSchedule(isUserTypeClient) {
        this._getNutritionSchedule(isUserTypeClient);
    }

    _getNutritionScheduleDates() {
        let startDate = moment(this.state.nutritionSchedule.startDate).format('D MMMM \'YY');
        let endDate = moment(startDate, 'D MMMM \'YY');
        endDate = endDate.add(this.state.nutritionSchedule.weeks, 'weeks').add('-1','days').format('D MMMM \'YY');

        return startDate + ' - ' + endDate ;
    }

    _getNutritionScheduleDatesForWeek(weekno) {
        let startDate = moment(this.state.nutritionSchedule.startDate);
        let endDate = moment(startDate);
        endDate = endDate.add(weekno+1, 'weeks').add('-1','days').format('D MMMM \'YY');

        startDate = startDate.add(weekno, 'weeks').format('D MMMM \'YY');

        return startDate + ' - ' + endDate ;
    }

    _viewNutritionScheduleForWeek(week) {
        history.push('/clienten/' + this.props.clientId + '/voedingsschema/' + this.props.nutritionScheduleId + '?week=' + week);
    }

    _goToClient(event) {
        event.preventDefault();
        history.push('/clienten/' + this.props.clientId);
    }

    _showDayDetail(dailyAdvice, dayInWeek, dateFormatted) {
        this.setState({
            clickedDailyAdvice: dailyAdvice,
            clickedDailyAdviceDayInWeek: dayInWeek,
            clickedDailyAdviceDateFormatted: dateFormatted
        }, () => {
            $("#dayDetailInfo").modal('open');
        });
    }

    _showMealDetail(meal, dailyAdviceMeal, substituteMeal, substitutedMeal) {
        this.setState({
            clickedMealDetail: meal,
            clickedDailyAdviceMeal: dailyAdviceMeal,
            clickedMealSubstituteMeal: substituteMeal,
            clickedMealSubstitutedMeal: substitutedMeal
        }, () => {
            $("#mealDetailInfo").modal('open');
        });
    }

    _showMealSubstitutions(meal, dailyAdviceMeal) {
        this.setState({
            clickedMealDetail: meal,
            clickedDailyAdviceMeal: dailyAdviceMeal
        }, () => {
            $("#mealSubstitutions").modal('open');
        });
    }

    _print(event, week) {
        event.preventDefault();
        history.push('/clienten/' + this.props.clientId + '/voedingsschema/' + this.props.nutritionScheduleId + '/print?week=' + week);
    }

    _shoppingList(shoppingList, week) {
        return (
            <div>
                <h5><Trans>nutritionSchedule.shoppingList</Trans> <Trans>nutritionSchedule.week</Trans> {week}</h5>
                <div className="shoppinglist-container">
                    {shoppingList.map((shoppingListCat, catIndex) => {
                        return shoppingListCat && shoppingListCat[1] && shoppingListCat[1].length > 0 && (
                            <div className="cat-row" key={"shoppinglistitem-" + catIndex}>
                                <div className="row shoppingListItem-row">
                                    <div className="col s12 cat">
                                        {shoppingListCat[0]}
                                    </div>
                                </div>
                                {shoppingListCat[1].map((mealNutrition, index) => {
                                    const nutrition = mealNutrition.nutrition;
                                    return (
                                        <div className="row shoppingListItem-row" key={"shoppinglistitem-" + catIndex + "-" + index}>
                                            <div className="col s8">
                                                {nutrition.shoppingListName}
                                            </div>
                                            <div className="col s4">
                                                {mealNutrition.quantity}&nbsp;{mealNutrition.quantity > 1 ? nutrition.unitNamePlural : nutrition.unitName}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    _dailyAdvice(dayInWeek, dailyAdvice, dayInWeekNumber, week) {
        let startDate = moment(this.state.nutritionSchedule.startDate);
        let startDateFormatted = startDate.add(dayInWeekNumber - 1,'day').add(week - 1, 'weeks').format('D MMMM \'YY');
        const weeklyAdviceId = this.state.nutritionSchedule.weeklyAdvices[week - 1].id;
        const ids =  dailyAdvice.dailyAdviceMeals.map((dailyAdviceMeal) => dailyAdviceMeal.id);
        const mealSubstitutesForThisDailyAdvice = this.state.nutritionSchedule.mealSubstitutes.filter(
            // eslint-disable-next-line eqeqeq
            (o) => (ids.indexOf(o.dailyAdviceMealId) >= 0) && o.weeklyAdviceId == weeklyAdviceId);

        return <DailyAdviceOverView key={"DailyAdviceOverView" + dailyAdvice.id}
            mealSubstitutes={mealSubstitutesForThisDailyAdvice} dailyAdvice={dailyAdvice}
            dayInWeek={dayInWeek}
            dateFormatted={startDateFormatted}
            showDayDetail={(dailyAdvice, dayInWeek, dateFormatted) => this._showDayDetail(dailyAdvice, dayInWeek, dateFormatted)}
            showMealDetail={(meal, dailyAdviceMeal, substituteMeal, substitutedMeal) => this._showMealDetail(meal, dailyAdviceMeal, substituteMeal, substitutedMeal)}
        />
    }

    _weekDetailAdvice(week) {
        let dayAdvices = this.state.nutritionSchedule.weeklyAdvices[week - 1].dailyAdvices;

        // Because of a database corruption issue it could happen that an incorrect week was generated.
        // To be save, we track it here:
        const nutritionScheduleId = this.state.nutritionSchedule.id;
        const emptyDays = [];
        if (!dayAdvices[0]) emptyDays.push(1);
        if (!dayAdvices[1]) emptyDays.push(2);
        if (!dayAdvices[2]) emptyDays.push(3);
        if (!dayAdvices[3]) emptyDays.push(4);
        if (!dayAdvices[4]) emptyDays.push(5);
        if (!dayAdvices[5]) emptyDays.push(6);
        if (!dayAdvices[6]) emptyDays.push(7);
        if (emptyDays.length > 0) {
            const emptyDaysError = new Error('Nutrition schedule with id `' + nutritionScheduleId +
            '` contained the following undefined day of the weeks (1-based): `' + emptyDays.join(', ') + '`.');
            ai.appInsights.trackException({exception: emptyDaysError});
        }

        return (
            <div>
                {dayAdvices[0] && this._dailyAdvice("monday", dayAdvices[0], 1, week)}
                {dayAdvices[1] && this._dailyAdvice("tuesday", dayAdvices[1], 2, week)}
                {dayAdvices[2] && this._dailyAdvice("wednesday", dayAdvices[2], 3, week)}
                {dayAdvices[3] && this._dailyAdvice("thursday", dayAdvices[3], 4, week)}
                {dayAdvices[4] && this._dailyAdvice("friday", dayAdvices[4], 5, week)}
                {dayAdvices[5] && this._dailyAdvice("saturday", dayAdvices[5], 6, week)}
                {dayAdvices[6] && this._dailyAdvice("sunday", dayAdvices[6], 7, week)}
            </div>
        );
    }

    _getWeeksNavigator(isUserTypeClient, week) {
        let weeksNavElement = [];

        for (let scheduleWeek = 1; scheduleWeek <= this.state.nutritionSchedule.weeks; scheduleWeek++) {
            weeksNavElement.push(
                <li key={"weektab-" + scheduleWeek} className="tab">
                    {/* eslint-disable-next-line eqeqeq */}
                    <a className={week == (scheduleWeek) ? "active" : ""}
                        onClick={(e) => { e.preventDefault(); this._viewNutritionScheduleForWeek(scheduleWeek); }}>
                        Week {scheduleWeek}
                    </a>
                </li>
            );
        }

        const maxWeeks = (this.context.currentUser.license.maxWeeksPerNutritionSchedule || 10)
            - ((this.props.nutritionSchedule && this.props.nutritionSchedule.weeks) || 0);

        if (!isUserTypeClient && this.state.nutritionSchedule.weeks < maxWeeks) {
            weeksNavElement.push(
                <li key={"weektab-99"} className="tab">
                    <a onClick={(e) => { e.preventDefault(); this.addNutritionScheduleWeeks.openModal(); }}>
                        <div className="btn-floating">
                            <i className="material-icons">add</i>
                        </div>
                    </a>
                </li>
            );
        }

        return <ul className="tabs">{weeksNavElement}</ul>
    }

    render() {
        const isUserTypeClient = this._isUserTypeClient();
        const week = this._calculateWeek()

        let shoppingList;
        let mealSubstitutesForThisWeeklyAdvice;
        if (this.state.nutritionSchedule) {
            shoppingList = mergeShoppingList.mergeShoppingListFromNutritionScheduleWeek(this.state.nutritionSchedule, week);

            const weeklyAdviceId = this.state.nutritionSchedule.weeklyAdvices[week - 1].id;
            mealSubstitutesForThisWeeklyAdvice = this.state.nutritionSchedule.mealSubstitutes.filter(
                // eslint-disable-next-line eqeqeq
                (o) => o.weeklyAdviceId == weeklyAdviceId
            );
        }

        return (
            <div id="page" className="NutritionSchedule">
            {!this.state.client || !this.state.nutritionSchedule || !this.state.measurements ?
                <FPLoader fixed="fixed" /> :
                <div>
                    <div className="pageheader nutritionSchedule schedule">
                        <div className="container">
                            <div className="row">
                                <div className="col s12 m8">
                                    <div className="row noMarginBottom">
                                        <div className="col s12 m1">
                                            <a onClick={(e) => this._goToClient(e)} className="cursor">
                                                <img className="profilePic" src={this.state.client.imageUrl}/>
                                            </a>
                                        </div>
                                        <div className="col s12 m10">
                                            <h2>Voedingsschema {this.state.nutritionSchedule.weeks} weken</h2>
                                            <div className="userInfo variableName">{this._getNutritionScheduleDates()}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row week-navigator-row">
                                <div className="col s12">{this._getWeeksNavigator(isUserTypeClient, week)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="actions">
                            {!this._isUserTypeClient() &&
                                <React.Fragment>
                                    <a onClick={() => this._deleteNutritionSchedule(this._isUserTypeClient)}
                                        className="btn-floating waves-effect waves-light small grey lighten-4">
                                        <i className="material-icons">delete</i>
                                    </a>
                                </React.Fragment>
                            }
                            <a onClick={(e) => this._print(e, week)} className="btn-floating waves-effect waves-light small grey lighten-4">
                                <i className="material-icons">print</i>
                            </a>
                        </div>
                        <div className="row">
                            <div className="col s12 m12 l11 xl8">
                                <div className="userInfo variableName">{this._getNutritionScheduleDatesForWeek(week - 1)}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={"col s12 m12 l8 " + (mealSubstitutesForThisWeeklyAdvice.length > 0 ? "containsMealSubstitutes" : "")}>
                                {this._weekDetailAdvice(week)}
                            </div>
                            <div className="col s12 m12 l4 shoppinglist">
                                {this._shoppingList(shoppingList, week)}
                            </div>
                        </div>
                    </div>

                    {/* MODALS  */}
                    <DayDetailView nutritionSchedule={this.state.nutritionSchedule}
                        week={week}
                        dailyAdvice={this.state.clickedDailyAdvice}
                        dayInWeek={this.state.clickedDailyAdviceDayInWeek}
                        dateFormatted={this.state.clickedDailyAdviceDateFormatted}
                    />
                    <MealDetailView meal={this.state.clickedMealDetail}
                        dailyAdviceMeal={this.state.clickedDailyAdviceMeal}
                        substituteMeal={this.state.clickedMealSubstituteMeal}
                        substitutedMeal={this.state.clickedMealSubstitutedMeal}
                        nutritionScheduleId={this.props.nutritionScheduleId}
                        clientId={this.props.clientId}
                        showMealSubstitutions={(meal, dailyAdviceMeal) => this._showMealSubstitutions(meal, dailyAdviceMeal)}
                        refreshNutrititonSchedule={() => this._refreshNutrititonSchedule(isUserTypeClient)}
                    />
                    <MealSubstitutionView meal={this.state.clickedMealDetail}
                        dailyAdviceMeal={this.state.clickedDailyAdviceMeal}
                        weeklyAdviceId={this.state.nutritionSchedule.weeklyAdvices[week - 1].id}
                        nutritionScheduleId={this.props.nutritionScheduleId}
                        clientId={this.props.clientId}
                        refreshNutrititonSchedule={() => this._refreshNutrititonSchedule(isUserTypeClient)}
                    />
                    {!isUserTypeClient && (
                        <React.Fragment>
                            <AddNutritionScheduleWeeksModal
                                ref={addNutritionScheduleWeeks => this.addNutritionScheduleWeeks = addNutritionScheduleWeeks}
                                clientId={this.props.clientId}
                                nutritionSchedule={this.state.nutritionSchedule}
                                adviceKcalDaily={this.state.adviceKcalDaily}
                                onSubmit={(nutritionSchedule) => this._handleAddWeeks(nutritionSchedule)}
                                currentUser={this.context.currentUser}
                            />
                            <CalculateKcalDailyModal
                                ref={calculateKcalDaily => this.calculateKcalDaily = calculateKcalDaily}
                                client={this.state.client}
                                measurements={this.state.measurements}
                                onChange={(adviceKcalDaily) => this._handleKcalCalculation(adviceKcalDaily)}
                                onAddMeasurement={() => this.addMeasurementModal.openModal()}
                                onMeasurementAdded={() => this._getMeasurements(isUserTypeClient)}
                            />
                            <AddMeasurementModal
                                ref={addMeasurementModal => this.addMeasurementModal = addMeasurementModal}
                                isReadOnly={false}
                                existingMeasurement={null}
                                clientId={this.props.clientId}
                                onChange={() => this._getMeasurements(isUserTypeClient)}
                            />
                        </React.Fragment>
                    )}
                </div>
            }
            </div>
        )
    }
}

NutritionSchedule.contextType = UserContext

NutritionSchedule.propTypes = {
    clientId             : PropTypes.string.isRequired,
    nutritionScheduleId  : PropTypes.string.isRequired,
    week                 : PropTypes.string
};