import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import {SortableHandle} from 'react-sortable-hoc';

const DragHandle = SortableHandle(() => <span><i className="small material-icons">drag_handle</i></span>);

class ExerciseListItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            oneRepetitionMax: 0,
            oneRepetitionMaxFormValue: 0,
            exercise: null
        };

        this._deleteExercise = this._deleteExercise.bind(this);
        this._changeHandler = this._changeHandler.bind(this);
        this._openOneRepetitionMaxSetter = this._openOneRepetitionMaxSetter.bind(this);
        this._closeOneRepetitionMaxSetter = this._closeOneRepetitionMaxSetter.bind(this);
        this._setOneRepetitionMax = this._setOneRepetitionMax.bind(this);
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _deleteExercise() {
        if (typeof this.props.onDeleteExercise === 'function') {
            this.props.onDeleteExercise(this.props.itemIndex);
        }
    }

    _openOneRepetitionMaxSetter(event) {
        event.preventDefault();
        this.setState({
            oneRepetitionMaxFormValue: this.state.oneRepetitionMax
        });
        let target = event.currentTarget;
        let infoContainer = $(target).parents('.infoSetting');
        infoContainer.addClass('openSetter');

        let setterInput = infoContainer.find('input[name=oneRepetitionMaxFormValue]');
        // eslint-disable-next-line eqeqeq
        if(setterInput.length == 1) {
            setterInput.focus();
        }
    }

    UNSAFE_componentWillMount() {
        this.setState({
            oneRepetitionMax: this.props.exercise.oneRepetitionMax,
            oneRepetitionMaxFormValue: this.props.exercise.oneRepetitionMax,
            // eslint-disable-next-line eqeqeq
            exercise: typeof this.props.exercise.exercise == 'undefined'?this.props.exercise:this.props.exercise.exercise
        });
    }

    componentDidMount() {
        $('.exerciseListItem .tooltipped').tooltip({delay: 1500});
    }

    _closeOneRepetitionMaxSetter(event) {
        event.preventDefault();
        let target = event.currentTarget;
        let infoContainer = $(target).parents('.infoSetting');
        infoContainer.removeClass('openSetter');
    }

    _setOneRepetitionMax(event) {
        event.preventDefault();

        let target = event.currentTarget;
        let infoContainer = $(target).parents('.infoSetting');
        infoContainer.removeClass('openSetter');

        this.setState({
            oneRepetitionMax: this.state.oneRepetitionMaxFormValue
        }, function() {
            if (typeof this.props.onSetOneRepetitionMax === 'function') {
                this.props.onSetOneRepetitionMax(this.props.itemIndex, this.state.oneRepetitionMax);
            }
        }.bind(this));
    }

    render() {
        return (
            <div className="exerciseListItem">
                <div className="dragIcon">
                    <DragHandle />

                </div>
                <div className="itemTitle">
                    { this.state.exercise.name }
                </div>
                { this.state.exercise.supportsOneRepetitionMax ?
                    <div className="infoSetting">
                        <span className="infoName"><Trans>trainingSchedule.1RM</Trans></span>
                        {/* eslint-disable-next-line eqeqeq */}
                        <span className="infoValue" onClick={this._openOneRepetitionMaxSetter}>{ this.state.oneRepetitionMax == null ? 0 : this.state.oneRepetitionMax }</span>
                        <div className="oneRMFormContainer">
                            <button type="button" className="waves-effect waves-light btn noShadow grey" onClick={this._closeOneRepetitionMaxSetter}><i className="material-icons">close</i></button>
                            <span className="infoName"><Trans>trainingSchedule.1RM</Trans></span>
                            <input type="text"
                                   id="oneRepetitionMaxFormValue"
                                   name="oneRepetitionMaxFormValue"
                                   value={this.state.oneRepetitionMaxFormValue}
                                   onChange={this._changeHandler} />
                            <button type="button" className="waves-effect waves-light btn noShadow" onClick={this._setOneRepetitionMax}><i className="material-icons">done</i></button>
                        </div>
                    </div>
                    : null
                }

                <a className="deleteButton" onClick={this._deleteExercise}><i className="tiny material-icons">close</i></a>
            </div>
        )
    }
}

export default ExerciseListItem;

ExerciseListItem.propTypes = {
    exercise            : PropTypes.object.isRequired
};