import React from 'react';

import PageItem from 'components/components/pagination/pageItem.jsx';

class Pagination extends React.Component {
    constructor(props) {
        super(props);

        this._setCurrentPage = this._setCurrentPage.bind(this)
    }

    _setCurrentPage(newPageNr) {
        if (typeof this.props.onPageChange === 'function') {
            this.props.onPageChange(newPageNr);
        }
    }

    _getPages() {
        let pages = [];

        for (let i=0; i < this.props.pages; i++) {
            if(i < 2 || (i > this.props.currentPage - 3 && i < this.props.currentPage + 3) || i > this.props.pages - 3){
                // eslint-disable-next-line eqeqeq
                pages.push(<PageItem key={i} onPageChange={this._setCurrentPage} pageNr={i} isActive={this.props.currentPage == i ? true : false} />);
            }
            else{
                // eslint-disable-next-line eqeqeq
                if(pages[pages.length - 1].type != "i"){
                    pages.push(<i key={i} className="ellipsis">...</i>);
                }
            }
        }

        return pages;
    }

    _getBackArrow() {
        // eslint-disable-next-line eqeqeq
        if(this.props.currentPage == 0){
            return (<li className="disabled">
                <a className="cursor">
                    <i className="material-icons">chevron_left</i>
                </a>
            </li>);
        } else {
            return (<li>
                <a onClick={this._setCurrentPage.bind(this, (this.props.currentPage - 1))} className="cursor">
                    <i className="material-icons">chevron_left</i>
                </a>
            </li>);
        }
    }

    _getNextArrow() {
        // eslint-disable-next-line eqeqeq
        if(this.props.currentPage == (this.props.pages - 1)){
            return (<li className="disabled">
                <a className="cursor">
                    <i className="material-icons">chevron_right</i>
                </a>
            </li>);
        } else {
            return (<li>
                <a onClick={this._setCurrentPage.bind(this, (this.props.currentPage + 1))} className="cursor">
                    <i className="material-icons">chevron_right</i>
                </a>
            </li>);
        }
    }

    render() {
        return (
            <ul className="pagination">
                { this._getBackArrow() }
                { this._getPages() }
                { this._getNextArrow() }
            </ul>
        )
    }
}

export default Pagination;