import moment from 'moment';

export function hasLicenseExpired(user) {
    const licenseValidUntilDate = moment(user.identityToken.license_valid_until_date);
    const nowDate = moment().local().startOf('day');

    if (licenseValidUntilDate.diff(nowDate) < 0) {
        return true;
    }

    return false;
}

export function pathCanBeAccessedWithExpiredLicense(path) {
    return path.startsWith('/licentie-verlopen') || path.startsWith('/profile/upgrade');
}

export function hasTryOutLicense(user) {
    return user.license.priceExclVat === 0;
}