import moment from 'moment';

// TODO: Rename to HealthCalculator or something more logical then IntakeCalculator.
export default class IntakeCalculator {
    calculateBMI(weight, length) {
        weight = parseFloat(weight);
        length = parseFloat(length);

        // eslint-disable-next-line eqeqeq
        if (weight != "" && weight != "" &&
            !isNaN(weight) && !isNaN(length) &&
            (weight > 0) && (length > 0))
        {
            return (weight / ((length/100) * (length/100))).toFixed(0);
        }
        return "0";
    }

    calculateFatPercentageWith4PointsSkinFold(birthDate, gender,
        bicepsSkinFold, tricepsSkinFold, subscapularSkinFold, iliacCrestSkinFold
    ) {
        bicepsSkinFold = parseInt(bicepsSkinFold);
        tricepsSkinFold = parseInt(tricepsSkinFold);
        subscapularSkinFold = parseInt(subscapularSkinFold);
        iliacCrestSkinFold = parseInt(iliacCrestSkinFold);

        if ((isNaN(bicepsSkinFold) || bicepsSkinFold === 0) &&
            (isNaN(tricepsSkinFold) || tricepsSkinFold === 0) &&
            (isNaN(subscapularSkinFold) || subscapularSkinFold === 0) &&
            (isNaN(iliacCrestSkinFold) || iliacCrestSkinFold === 0)
        ) {
            return "0";
        }

        // eslint-disable-next-line eqeqeq
        if (birthDate == null) {
            return "0"
        }

        // eslint-disable-next-line eqeqeq
        if (gender == null) {
            return "0"
        }

        const age = this._calculateAge(new Date(birthDate))
        const bodyDensity = this._calculateBodyDensityWith4PointsSkinFold(gender, age,
            bicepsSkinFold, tricepsSkinFold, subscapularSkinFold, iliacCrestSkinFold);

        return this._calculateFatPercentage(bodyDensity);
    }

    calculateFatPercentageWith7PointsSkinFold(birthDate, gender,
        chestSkinFold, tricepsSkinFold, subscapularSkinFold, iliacCrestSkinFold, abdominalSkinFold, thighSkinFold, axillarySkinFold
    ) {
        chestSkinFold = parseInt(chestSkinFold);
        tricepsSkinFold = parseInt(tricepsSkinFold);
        subscapularSkinFold = parseInt(subscapularSkinFold);
        iliacCrestSkinFold = parseInt(iliacCrestSkinFold);
        abdominalSkinFold = parseInt(abdominalSkinFold);
        thighSkinFold = parseInt(thighSkinFold);
        axillarySkinFold = parseInt(axillarySkinFold);

        if ((isNaN(chestSkinFold) || chestSkinFold === 0) &&
            (isNaN(tricepsSkinFold) || tricepsSkinFold === 0) &&
            (isNaN(subscapularSkinFold) || subscapularSkinFold === 0) &&
            (isNaN(iliacCrestSkinFold) || iliacCrestSkinFold === 0) &&
            (isNaN(abdominalSkinFold) || abdominalSkinFold === 0) &&
            (isNaN(thighSkinFold) || thighSkinFold === 0) &&
            (isNaN(axillarySkinFold) || axillarySkinFold === 0)
        ) {
            return "0";
        }

        // eslint-disable-next-line eqeqeq
        if (birthDate == null) {
            return "0"
        }

        // eslint-disable-next-line eqeqeq
        if (gender == null) {
            return "0"
        }

        const age = this._calculateAge(new Date(birthDate));
        const bodyDensity = this._calculateBodyDensityWith7PointsSkinFold(gender, age,
            chestSkinFold, tricepsSkinFold, subscapularSkinFold, iliacCrestSkinFold, abdominalSkinFold, thighSkinFold, axillarySkinFold);

        return this._calculateFatPercentage(bodyDensity);
    }

    calculateLBM(weight, fatPercentage) {
        weight = parseFloat(weight);
        fatPercentage = parseFloat(fatPercentage);

        if (isNaN(fatPercentage) ) {
            return "0"
        }

        if (isNaN(weight) ) {
            return "0"
        }

        if ((fatPercentage > 0) && (weight > 0)) {
            return ( weight - (weight * fatPercentage/100) ).toFixed(1)
        } else {
            return "0.0";
        }
    }

    calculateBMR(lbm) {
        lbm = parseFloat(lbm);

        if (isNaN(lbm)) {
            return "0"
        }

        if ((lbm > 0)) {
            return this._calculateBmrWithLbm(lbm);
        } else {
            return "0.00"
        }
    }

    calculateMaintenance(activityLevel, bmr) {
        activityLevel = parseInt(activityLevel);
        bmr = parseFloat(bmr);

        if (isNaN(bmr)) {
            return "0"
        }
        if (isNaN(activityLevel)) {
            return "0"
        }

        if (bmr > 0 && activityLevel > 0) {
            return this._calculateMaintenance(activityLevel, bmr);
        } else {
            return "0.00"
        }
    }

    calculateDailyKcalTarget(maintenance, currentWeight, targetWeight) {
        maintenance = parseFloat(maintenance);
        currentWeight = parseFloat(currentWeight);
        targetWeight = parseFloat(targetWeight);

        if (isNaN(maintenance) || maintenance <= 0) {
            return "0";
        }
        if (isNaN(currentWeight) || currentWeight <= 0) {
            return "0";
        }
        if (isNaN(targetWeight) || targetWeight <= 0) {
            return "0";
        }

        return this._calculateDailyKcalTarget(maintenance, currentWeight, targetWeight).toFixed(0);
    }

    _calculateAge(birthday) { // birthday is a date
        return moment().diff(birthday, 'years');
    }

    // Calculates the body density with `Durnin & Womersley` equation.
    _calculateBodyDensityWith4PointsSkinFold(gender, age, bicepsSkinFold, tricepsSkinFold, subscapularSkinFold, iliacCrestSkinFold) {
        let bodyDensity = 0;
        let sumSkinFolds = bicepsSkinFold + tricepsSkinFold + subscapularSkinFold + iliacCrestSkinFold;

        // gender : 1 = man 2 = woman
        // eslint-disable-next-line eqeqeq
        if (gender == 1) {
            if (age < 17) {
                bodyDensity = 1.1533 - (0.0643 * Math.log10(sumSkinFolds));
            } else if (age >= 17 && age < 19) {
                bodyDensity = 1.1620 - (0.0630 * Math.log10(sumSkinFolds));
            } else if (age >= 20 && age < 29) {
                bodyDensity = 1.1631 - (0.0632 * Math.log10(sumSkinFolds));
            } else if (age >= 29 && age < 39) {
                bodyDensity = 1.1422 - (0.0544 * Math.log10(sumSkinFolds));
            } else if (age >= 39 && age < 49) {
                bodyDensity = 1.1620 - (0.0700 * Math.log10(sumSkinFolds));
            } else if (age >= 49) {
                bodyDensity = 1.1715 - (0.0779 * Math.log10(sumSkinFolds));
            }
        } else {
            if (age < 17) {
                bodyDensity = 1.1369 - (0.0598 * Math.log10(sumSkinFolds))
            } else if (age >= 17 && age < 19) {
                bodyDensity = 1.1549 - (0.0678 * Math.log10(sumSkinFolds))
            } else if (age >= 20 && age < 29) {
                bodyDensity = 1.1599 - (0.0717 * Math.log10(sumSkinFolds))
            } else if (age >= 29 && age < 39) {
                bodyDensity = 1.1423 - (0.0632 * Math.log10(sumSkinFolds))
            } else if (age >= 39 && age < 49) {
                bodyDensity = 1.1333 - (0.0612 * Math.log10(sumSkinFolds))
            } else if (age >= 49) {
                bodyDensity = 1.1339 - (0.0645 * Math.log10(sumSkinFolds))
            }
        }

        return bodyDensity;
    }

    // Calculates the body density with `Jackson & Pollock` equation.
    _calculateBodyDensityWith7PointsSkinFold(gender, age,
        chestSkinFold, tricepsSkinFold, subscapularSkinFold, iliacCrestSkinFold, abdominalSkinFold, thighSkinFold, axillarySkinFold) {
        let bodyDensity = 0;
        let sumSkinFolds = chestSkinFold + tricepsSkinFold + subscapularSkinFold + iliacCrestSkinFold + abdominalSkinFold + thighSkinFold + axillarySkinFold;

        // gender : 1 = man 2 = woman
        // eslint-disable-next-line eqeqeq
        if (gender == 1) {
            bodyDensity = 1.112 - (0.00043499 * sumSkinFolds) + (0.00000055 * sumSkinFolds * sumSkinFolds) - (0.00028826 * age);
        } else {
            bodyDensity = 1.097 - (0.00046971 * sumSkinFolds) + (0.00000056 * sumSkinFolds * sumSkinFolds) - (0.00012828 * age);
        }

        return bodyDensity;
    }

    // Calculates the body's fat percentage with the `Siri` equation.
    _calculateFatPercentage(bodyDensity) {
        return ((495 / bodyDensity) - 450).toFixed(2);
    }

    // Calculates the body's basal metabolic rate with the `Katch-McArdle` equation.
    _calculateBmrWithLbm(lbm) {
        return (370 + (21.6 * lbm)).toFixed(2);
    }

    // Multiplies the bmr (body's basal metabolic) with factor based on how active the person lives.
    // This creates an indication of a person's maintenance calories (no improvements and no decline).
    // Cannot find a name that belongs to this equation...
    _calculateMaintenance(activityLevel, bmr) {
        let activityFactor = 1;
        switch (activityLevel) {
            case 1:
                activityFactor = 1.2;
                break;
            case 2:
                activityFactor = 1.375;
                break;
            case 3:
                activityFactor = 1.55;
                break;
            case 4:
                activityFactor = 1.725;
                break;
            case 5:
                activityFactor = 1.9;
                break;
            default:
                activityFactor = 1.2;
                break;
        }

        return (bmr * activityFactor).toFixed(2);
    }

    // Determines if a user wants to maintain, lose or gain weight and
    // and changes the maintenance calories based on this.
    _calculateDailyKcalTarget(maintenance, currentWeight, targetWeight) {
        let kcalTarget;

        // eslint-disable-next-line eqeqeq
        if (currentWeight == targetWeight) {
            kcalTarget = maintenance;
        } else if (currentWeight > targetWeight) {
            kcalTarget = maintenance - 500;
        } else if (currentWeight < targetWeight) {
            kcalTarget = maintenance + 500;
        }

        return kcalTarget;
    }
}