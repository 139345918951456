import React from 'react';
import ExerciseListItem from 'components/components/trainingSchedule/exerciseListItem.jsx';

class ItemContainer extends React.Component {

    constructor(props) {
        super(props);

        this._deleteExercise = this._deleteExercise.bind(this);
        this._setOneRepetitionMax = this._setOneRepetitionMax.bind(this);
    }


    _deleteExercise(index) {
        if (typeof this.props.onDeleteExercise === 'function') {
            this.props.onDeleteExercise(index);
        }
    }

    _setOneRepetitionMax(index, oneRepetitionMax) {
        if (typeof this.props.onSetOneRepetitionMax === 'function') {
            this.props.onSetOneRepetitionMax(index, oneRepetitionMax);
        }
    }

    render() {

        return(
            <li>
                <ExerciseListItem itemIndex={this.props.itemIndex} onSetOneRepetitionMax={this._setOneRepetitionMax} onDeleteExercise={this._deleteExercise} exercise={this.props.exercise} />
            </li>
        );

    }
}

export default ItemContainer;