import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'utils/jquery-global.js';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'nouislider/distribute/nouislider.css';
import 'lib/meteor-materialize-scss/dist/js/materialize.js';
import 'lib/autogrow.js';
import 'lib/outdatedBrowser.js';

import 'moment/locale/nl';
import * as moment from 'moment';

import React from 'react';
import i18n from 'i18next';
import i18nBackendXHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { ai } from 'utils/appInsights';
import { render } from 'react-dom';
import accounting from 'accounting';

import history from 'history.js';
import authService from 'utils/auth-service.js';
import settings, { loadSettings } from 'utils/settings.js';
import { ThemeProvider } from 'contexts';

import 'index.scss';

import Outdated from 'outdated.jsx';
import App from 'app.jsx';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx'

let i18nInitialized = false;
let userSessionLoaded = false;
let appSettingsLoaded = false;

// Polyfill for `toBlob` function on Canvas.
// The API is supported on almost every modern browser, except for Edge.
// See: https://stackoverflow.com/a/47487073/5473409
if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function(callback, type, quality) {
            const canvas = this;
            setTimeout(function() {
                let binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
                len = binStr.length,
                arr = new Uint8Array(len);

                for (let i = 0; i < len; i++) {
                    arr[i] = binStr.charCodeAt(i);
                }

                callback(new Blob([arr], {type: type || 'image/png'}));
            });
        }
    });
 }

const StartupLoader = () => (
    <div style={{width: '33%', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        <ThemeProvider>
            <FPLoader />
        </ThemeProvider>
    </div>
)

const tryRenderApp = () => {
    render(<Outdated />, document.getElementById('outdated-target'));

    if (i18nInitialized && userSessionLoaded && appSettingsLoaded) {
        render(<App />, document.getElementById('render-target'));
    } else {
        render(<StartupLoader />, document.getElementById('render-target'));
    }
};
tryRenderApp();

i18n.use(i18nBackendXHR)
    .use(initReactI18next)
    .init({
        lng: 'nl',
        fallbackLng: 'nl',
        ns: 'common',
        defaultNS: 'common',

        interpolation: {
            // Not needed for React, according to the docs: https://react.i18next.com/latest/i18next-instance
            escapeValue: false
        },

        // Log exception on missing key.
        // For backwards compatibility reasons we don't throw the exception yet.
        // The old i18n toolset simply didn't write any output if the key was missing.
        // So for now, simply log the exception in the browser and in AppInsights.
        saveMissing: true,
        missingKeyHandler: (lng, ns, key, fallbackValue) => {
            const error = new Error('Missing translation key: namespace: `' + ns + '`, key: `' + key + '`');
            console.error(error);
            ai.appInsights.trackException({exception: error});
        },
        parseMissingKeyHandler: () => "" // Return empty string on missing key, to mimic old behaviour.
    });
i18n.on('initialized', () => {
    i18nInitialized = true;
    tryRenderApp();
});

moment.locale('nl');

accounting.settings = {
    currency: {
        symbol: "€",   // default currency symbol is '$'
        format: "%s %v", // controls output: %s = symbol, %v = value/number (can be object: see below)
        decimal: ",",  // decimal point separator
        thousand: ".",  // thousands separator
        precision : 2   // decimal places
    },
    number: {
        precision: 0,  // default precision on numbers is 0
        thousand: ".",
        decimal: ","
    }
}

window.toastDuration = 5000;

// ADD TRANSLATION FOR DATEPICKER
$(function() {
    jQuery.extend(jQuery.fn.pickadate.defaults, {
        monthsFull: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        monthsShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        weekdaysFull: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        weekdaysShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        today: 'nu',
        clear: 'wis',
        close: 'Kies',
        firstDay: 1,
        format: 'dddd d mmmm yyyy',
        formatSubmit: 'yyyy/mm/dd'
    });
});

// Date extension methods.
(function() {
    function pad(n) { return (n < 10) ? '0' + n : n;}
    // Returns a string representing the given date in the ISO 8601 format according to local time.
    // eslint-disable-next-line no-extend-native
    Date.prototype.toLocalISOString = function() {
        return this.getFullYear() +
            '-' + pad(this.getMonth() + 1) +
            '-' + pad(this.getDate()) +
            'T' + pad(this.getHours()) +
            ':' + pad(this.getMinutes()) +
            ':' + pad(this.getSeconds()) +
            '.' + (this.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
            'Z';
    };
    // Returns a string representing the given date in the inversed short date format according to local time (dd-MM-yyyy).
    // eslint-disable-next-line no-extend-native
    Date.prototype.toInversedShortDateString = function() {
        return pad(this.getDate()) +
            '-' + pad(this.getMonth() + 1) +
            '-' + this.getFullYear();
    };
}());

new outdatedBrowser({
    lowerThan: "borderImage"
});

const initializeAppInsights = () => {
    ai.initialize(settings.APP_INSIGHTS_INSTRUMENTATION_KEY, history);
}

const initializeAuthService = () => {
    // Make sure current user session is loaded, before we render the app.
    authService.onInit().then(() => {
        userSessionLoaded = true;
        tryRenderApp();
    });
    authService.onUserLoggedOut(() => {
        if (history.location.pathname !== '/uitloggen') {
            history.push('/login#' + history.location.pathname + history.location.search);
        }
    });
}

loadSettings().then(() => {
    appSettingsLoaded = true;

    // These services depend on initialized settings.
    initializeAppInsights();
    initializeAuthService();

    tryRenderApp();
});