import React from 'react';
import fmfApiClient from 'utils/fmf-api-client.js';
import i18n from 'i18next';
import { Trans } from 'react-i18next';

class TrainingTemplateHeader extends React.Component {
    constructor(props) {
        super(props);

        //IS THE SIDENAV ALREADY INITIALIZED
        this.state = {
            goalId: '',
            templateName: ''
        };

        this._changeHandler = this._changeHandler.bind(this);
        this._updateFields = this._updateFields.bind(this);
        this._getGoals = this._getGoals.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _changeHandler (event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value },
            function() {
                if (typeof this.props.onNameChange === 'function') {
                    this.props.onNameChange(this.state.templateName, this.state.goalId);
                }
            }.bind(this));
    }


    _updateFields() {
        $('.schemeHeader select').material_select();
        Materialize.updateTextFields();
    }

    _getGoals () {
        fmfApiClient.getGoals(this.cancelSource.token)
            .then(result => {
                let arrOpts = [];
                arrOpts.push(<option key="0" value="">{ i18n.t('addGoal.chooseGoal') }</option>);
                for (let k = 0; k < result.length; k++) {
                    arrOpts.push(<option key={result[k].id} value={result[k].id}>{result[k].name}</option>);
                }
                this.setState({
                    goalOpts: arrOpts
                });
            }, error => {
                this.setState({
                    errorMessage: i18n.t('errors.' + error.error),
                    buttonDisabled: '',
                    showLoading: 'hide',
                    showError: ''
                });
            });
    }

    componentDidMount() {
        this._getGoals();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            goalId: (nextProps.template && nextProps.template.goalId != null ? nextProps.template.goalId:''),
            templateName: (nextProps.template && nextProps.template.name != null ? nextProps.template.name:''),
        }, function() {
            this._updateFields();
        }.bind(this));
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    render() {
        return (
            <div className="schemeHeader">
                <div className="row">
                    <div className="col s12 m4">
                        <div id="logo-container" className="brand-logo"><img src="/images/logo_grijs.svg" className="lightGreyHeader" /></div>
                    </div>
                    <div className="col s6 m4">
                        <div className="input-field">
                            <div>
                                <input
                                    value={this.state.templateName}
                                    id="templateName"
                                    type="text"
                                    name="templateName"
                                    onChange={this._changeHandler}
                                    data-msg={ i18n.t('errors.required_field') }
                                    required
                                />
                                <label htmlFor="templateName"><Trans>formFields.templateName</Trans></label>
                            </div>
                        </div>
                    </div>
                    <div className="col s6 m4">
                        <div className="input-field">
                            <select id="goalId"
                                    name="goalId"
                                    className="browser-default"
                                    value={this.state.goalId}
                                    onChange={this._changeHandler}
                                    data-msg={ i18n.t('errors.required_field') }
                                    required
                            >
                                {this.state.goalOpts}
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default TrainingTemplateHeader;