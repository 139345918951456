import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import fmfApiClient from 'utils/fmf-api-client.js';
import { ai } from 'utils/appInsights';

export const ControlPlusClientProxy = ({controlPlusId}) => {
    const [redirectTo, setRedirectTo] = useState(null);
    useEffect(() => {
        const cancelSource = fmfApiClient.createCancelSource();
        fmfApiClient.getClientIdByControlPlusId(cancelSource.token, controlPlusId)
            .then(clientId => {
                setRedirectTo("/clienten/" + clientId);
            })
            .catch(error => {
                ai.appInsights.trackException({exception: error});
                setRedirectTo("/dashboard");
            })
        return () => cancelSource.cancel();
    }, [controlPlusId])

    if (!redirectTo) return null;
    return <Redirect to={redirectTo} />
}