import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import { UserContext } from 'contexts.jsx';
import history from 'history.js';
import authService from 'utils/auth-service.js';

import EditClientModal from 'components/forms/modals/editClient.jsx';
import ConnectClientModal from 'components/forms/modals/connectClient.jsx';
import AddGoalModal from 'components/forms/modals/addGoal.jsx';
import AddNoteModal from 'components/forms/modals/addNote.jsx';
import AddMeasurementModal from 'components/forms/modals/addMeasurement.jsx';
import AddTrainingScheduleModal from 'components/forms/modals/addTrainingSchedule.jsx';
import AddNutritionScheduleModal from 'components/forms/modals/addNutritionSchedule.jsx';
import CalculateKcalDaily from 'components/forms/modals/calculateKcalDaily.jsx';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import AddClientErrorModal from 'components/forms/modals/maxClientsError.jsx';
import AddItemErrorModal from 'components/forms/modals/addItemError.jsx';
import AddTrainingScheduleError from 'components/forms/modals/addTrainingScheduleError.jsx';
import AddNutritionScheduleErrorMax from 'components/forms/modals/addNutritionScheduleErrorMax.jsx';
import NoteCard from 'components/components/noteList/noteCard.jsx';
import MeasurementCard from 'components/components/measurementList/measurementCard.jsx';
import TrainingScheduleCard from 'components/components/scheduleCards/trainingScheduleCard.jsx';
import NutritionScheduleCard from 'components/components/scheduleCards/nutritionScheduleCard.jsx';
import image from 'utils/image.js';

import IntakeCalculator from 'utils/nutrition/fmfIntakeCalculator';

const intakeCalculator = new IntakeCalculator();

export default class ClientDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            client: {isActive: true, isConnected: false},
            currentGoal: null,
            loadingClient: true,
            loadingNotes: true,
            loadingMeasurements: true,
            loadingTrainingSchedules: true,
            loadingNutritionSchedules: true,
            notes: null,
            measurements: null,
            trainingSchedules: null,
            trainingScheduleCount: null,
            nutritionSchedules: null,
            nutritionSchedulesCount: null,
            trainingAndNutritionSchedules: null,
            currentMeasurement: null
        };

        this._compare = this._compare.bind(this);
        this._getClient = this._getClient.bind(this);
        this._editClient = this._editClient.bind(this);
        this._addGoal = this._addGoal.bind(this);
        this._getGoal = this._getGoal.bind(this);
        this._addNote = this._addNote.bind(this);
        this._getNotes = this._getNotes.bind(this);
        this._getMeasurements = this._getMeasurements.bind(this);
        this._addTrainingSchedule = this._addTrainingSchedule.bind(this);
        this._addNutritionSchedule = this._addNutritionSchedule.bind(this);
        this._addMeasurement = this._addMeasurement.bind(this);
        this._connectClient = this._connectClient.bind(this);
        this._showMeasurement = this._showMeasurement.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    _isUserTypeClient() {
        const user = this.context.currentUser;
        const userType = user && user.identityToken && user.identityToken.user_type;
        return userType === 'Client';
    }

    _compare(a,b) {
        // Sort by startdate descending.
        if (a.date < b.date) {
            return 1;
        }
        if (a.date > b.date) {
            return -1;
        }
        return 0;
    }

    _getClient() {
        if(this.props.clientId) {
            fmfApiClient.getClient(this.cancelSource.token, this.props.clientId, image.resize(300, 300))
                .then(result => {
                    this.setState({
                        client: result,
                        currentGoal: result.goal ? result.goal : null,
                        loadingClient: false
                    });
                }, error => {
                    if (error.error === 404) {
                        history.push('/dashboard');
                        Materialize.toast(i18n.t('clientDashboard.clientDeleted'), window.toastDuration);
                        return;
                    }
                    Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                });
        }
    }

    _getTrainingSchedules() {
        if(this.props.clientId) {
            fmfApiClient.getClientTrainingSchedules(this.cancelSource.token, this.props.clientId)
                .then(result => {
                    let schedules = [];
                    if (this.state.trainingAndNutritionSchedules != null) {
                        schedules = this.state.trainingAndNutritionSchedules;
                    }
                    result.items.forEach((trainingSchedule) => {
                        schedules.push({
                            date: trainingSchedule.startDate,
                            schedule: 'training',
                            trainingSchedule: trainingSchedule
                        })
                    })
                    schedules.sort(this._compare);

                    this.setState({
                        trainingSchedules: result.items,
                        trainingScheduleCount: result.totalCount,
                        trainingAndNutritionSchedules: schedules,
                        loadingTrainingSchedules: false
                    });
                }, error => {
                    if(error.error !== 'client_inactive'){
                        Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                    }
                    // eslint-disable-next-line eqeqeq
                    if(error.error == 'client_inactive' && this._isUserTypeClient()){
                        // Client has been set to inactive by trainer, logout the client user.
                        authService.logout();
                    }
                    this.setState({
                        loadingTrainingSchedules: false
                    });
                });
        }
    }

    _getNutritionSchedules() {
        if(this.props.clientId) {
            fmfApiClient.getClientNutritionSchedules(this.cancelSource.token, this.props.clientId)
                .then(result => {
                    let schedules = [];
                    if (this.state.trainingAndNutritionSchedules != null) {
                        schedules = this.state.trainingAndNutritionSchedules;
                    }
                    result.items.forEach((nutritionSchedule) => {
                        schedules.push({
                            date: nutritionSchedule.startDate,
                            schedule: 'nutrition',
                            nutritionSchedule:nutritionSchedule
                        })
                    })
                    schedules.sort(this._compare);

                    this.setState({
                        nutritionSchedules: result.items,
                        nutritionScheduleCount: result.totalCount,
                        trainingAndNutritionSchedules: schedules,
                        loadingNutritionSchedules: false
                    });
                }, error => {
                    if(error.error !== 'client_inactive'){
                        Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                    }
                    // eslint-disable-next-line eqeqeq
                    if(error.error == 'client_inactive' && this._isUserTypeClient()){
                        // Client has been set to inactive by trainer, logout the client user.
                        authService.logout();
                    }
                    this.setState({
                        loadingNutritionSchedules: false
                    });
                });
        }
    }

    _getNotes() {
        fmfApiClient.getClientNotes(this.cancelSource.token, this.props.clientId)
            .then(result => {
                this.setState({
                    notes: result.items,
                    loadingNotes: false
                });
            }, error => {
                if(error.error !== 'client_inactive'){
                    Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                }
                // eslint-disable-next-line eqeqeq
                if(error.error == 'client_inactive' && this._isUserTypeClient()){
                    // Client has been set to inactive by trainer, logout the client user.
                    authService.logout();
                }
                this.setState({
                    loadingNotes: false
                });
            });
    }

    componentDidMount() {
        $('.clientDashboard .modal-trigger').modal();

        this._getClient();
        this._getNotes();
        this._getTrainingSchedules();
        this._getMeasurements();
        this._getNutritionSchedules();

        this._activeTooltips();
    }

    componentDidUpdate() {
        this._activeTooltips();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    _activeTooltips() {
        $('.clientDashboard .tooltipped').tooltip({delay: 50});
    }

    _editClient() {
        $('#editClient').modal();
        $('#editClient').modal('open');
    }

    _connectClient() {
        $('#connectClient').modal();
        $('#connectClient').modal('open');
    }

    _addGoal() {
        $('#addGoal').modal();
        $('#addGoal').modal('open');
    }

    _getGoal() {
        fmfApiClient.getClientGoal(this.cancelSource.token, this.props.clientId)
            .then(result => {
                this.setState({
                    currentGoal: result
                });
            });
    }

    _addNote() {
        if(this.state.client) {
            // eslint-disable-next-line eqeqeq
            if (this.state.client.isActive == true) {
                $('#addNote').modal();
                $('#addNote').modal('open');
            } else {
                $('#addItemError').modal();
                $('#addItemError').modal('open');
            }
        }
    }

    _getMeasurements() {
        fmfApiClient.getClientMeasurements(this.cancelSource.token, this.props.clientId)
            .then(result => {
                this.setState({
                    measurements: result.items,
                    loadingMeasurements: false
                });
            }, error => {
                if(error.error !== 'client_inactive'){
                    Materialize.toast(i18n.t('errors.' + error.error), window.toastDuration);
                }
                // eslint-disable-next-line eqeqeq
                if(error.error == 'client_inactive' && this._isUserTypeClient()){
                    // Client has been set to inactive by trainer, logout the client user.
                    authService.logout();
                }
                this.setState({
                    loadingMeasurements: false
                });
            });
    }

    _addMeasurement() {
        if(this.state.client) {
            // eslint-disable-next-line eqeqeq
            if (this.state.client.isActive == true) {
                this.setState({
                    currentMeasurement: null
                }, function () {
                    $('#addMeasurement').modal();
                    $('#addMeasurement').modal('open');
                })
            } else {
                $('#addItemError').modal();
                $('#addItemError').modal('open');
            }
        }
    }

    _addTrainingSchedule() {
        if(this.state.client) {
            // eslint-disable-next-line eqeqeq
            if (this.state.client.isActive == true) {
                const maxTrainingSchedulesPerClient = this.context.currentUser &&
                    this.context.currentUser.license.maxTrainingSchedulesPerClient;
                if(this.state.trainingScheduleCount && maxTrainingSchedulesPerClient &&
                    this.state.trainingScheduleCount >= maxTrainingSchedulesPerClient) {
                    $('#addTrainingScheduleError').modal();
                    $('#addTrainingScheduleError').modal('open');
                } else {
                    $('#addTrainingSchedule').modal();
                    $('#addTrainingSchedule').modal('open');
                }
            } else {
                $('#addItemError').modal();
                $('#addItemError').modal('open');
            }
        }
    }

    _addNutritionSchedule() {
        if(this.state.client) {
            // eslint-disable-next-line eqeqeq
            if (this.state.client.isActive == true) {
                const maxNutritionSchedulesPerClient = this.context.currentUser &&
                    this.context.currentUser.license.maxNutritionSchedulesPerClient;

                if(this.state.nutritionScheduleCount && maxNutritionSchedulesPerClient &&
                    this.state.nutritionScheduleCount >= maxNutritionSchedulesPerClient) {
                    $('#addNutritionScheduleErrorMax').modal();
                    $('#addNutritionScheduleErrorMax').modal('open');
                } else {
                    $('#addNutritionSchedule').modal();
                    $('#addNutritionSchedule').modal('open');
                }
            } else {
                $('#addItemError').modal();
                $('#addItemError').modal('open');
            }
        }
    }

    _showMeasurement(measurement) {
        if (this._isUserTypeClient()) {
            return;
        }

        if(this.state.client) {
            // eslint-disable-next-line eqeqeq
            if (this.state.client.isActive == true) {
                this.setState({
                    currentMeasurement: measurement
                }, function () {
                    $('#addMeasurement').modal();
                    $('#addMeasurement').modal('open');
                })
            } else {
                $('#addItemError').modal();
                $('#addItemError').modal('open');
            }
        }
    }

    _handleKcalCalculation(adviceKcalDaily) {
        this.setState({adviceKcalDaily: adviceKcalDaily});
    }

    _handleAddedNutritionSchedule(nutritionSchedule) {
        Materialize.toast(i18n.t('nutritionSchedule.nutritionScheduleAdded'), window.toastDuration);
        history.push('/clienten/' + this.props.clientId + '/voedingsschema/' + nutritionSchedule.id);
    }

    _renderGoal() {
        if (this.state.currentGoal != null) {
            return (
                <div className="goalContainer">
                    <h2>
                        {this.state.currentGoal.shortName}
                        {!this._isUserTypeClient() &&
                        <a className="cursor variableNameLink" onClick={this._addGoal}><i className="material-icons">create</i></a>}
                    </h2>
                    {this.state.currentGoal.comment && <span className="variableName">{this.state.currentGoal.comment}</span>}
                </div>
            );
        } else {
            if (this._isUserTypeClient()) {
                return null
            }

            return (
                <div>
                    <h2 className="inactive"><Trans>clientDashboard.noTarget</Trans></h2>
                    <a className="cursor" onClick={this._addGoal}>
                        <Trans>clientDashboard.setTarget</Trans>
                    </a>
                </div>
            );
        }
    }

    _renderFatPercentageWarning(measurements) {
        if (!measurements ||
            !measurements.find(m => m.skinFoldMeasurementMethod > 0) || // contains no measurement with skinfold measurement method.
            intakeCalculator.canFatPercentageBeCalculatedFromSkinFoldMeasurement(this.state.client)) {
            return;
        }

        let tooltipTitle;
        let tooltipText;
        if (!this._isUserTypeClient()) {
            tooltipTitle = i18n.t('measurements.noSkinFoldMeasurementPossibleTitle');
            tooltipText = i18n.t('measurements.noSkinFoldMeasurementPossibleTooltip', {clientFirstName: this.state.client.firstName});
        }
        else {
            tooltipTitle = i18n.t('measurements.noSkinFoldMeasurementPossibleTitleClient');
            tooltipText = i18n.t('measurements.noSkinFoldMeasurementPossibleTooltipClient')
        }

        return (
            <a id="measurementNotPossibleTooltip" className="tooltipped" data-html data-tooltip={tooltipText}>
                <span>{tooltipTitle}</span>
            </a>
        );
    }

    render() {
        const { currentUser } = this.context;
        const client = this.state.client;
        let cssClassNutritionTrainingBlock = "col s12 m6 l3";
        if (this.state.trainingAndNutritionSchedules) {
            if ((this.state.trainingAndNutritionSchedules.length + 1) % 4 === 0) {
                cssClassNutritionTrainingBlock += " last-4-col";
            }
            if ((this.state.trainingAndNutritionSchedules.length + 1) % 2 === 0) {
                cssClassNutritionTrainingBlock += " last-2-col";
            }
        }

        // Control Plus basic license doesn't include nutrition schedules, so don't render them.
        const shouldRenderNutritionSchedules = currentUser.license.maxNutritionSchedulesPerClient !== 0;

        return (
            <div id="page" className="clientDashboard">
                <div className="pageheader">
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m12 l8">
                                {/* eslint-disable-next-line eqeqeq */}
                                <div className={(client && client.isActive == true ? '' : 'inactive ') + "row noMarginBottom"}>
                                    <div className="col s12 m2">
                                        { client ? <img className="profilePic" src={client.imageUrl}/> : ''}
                                    </div>
                                    <div className="col s12 m10">
                                        <h1 className="clientName">
                                            { client ? client.name:'' }
                                            { !this._isUserTypeClient() && this.state.client.isConnected ?
                                            <i className="material-icons">link</i>
                                            : null }
                                        </h1>
                                        {/* eslint-disable-next-line eqeqeq */}
                                        { client && client.isActive == true ?
                                            <div className="userInfo">
                                                { client && client.email ?
                                                    <a href={"mailto:" + client.email }
                                                       className="infoItem setItem">{ client.email }</a> :
                                                    !this._isUserTypeClient() &&
                                                    // eslint-disable-next-line eqeqeq
                                                    (this.context.currentUser == null || !this.context.currentUser.identityToken.control_plus_link) ?
                                                        <a onClick={this._editClient} className="infoItem">
                                                            <Trans>clientDashboard.setEmail</Trans>
                                                        </a> :
                                                        null}
                                                { client && client.phoneNumber ?
                                                    <a href={"tel:" + client.phoneNumber }
                                                       className="infoItem setItem">{ client.phoneNumber }</a> :
                                                    !this._isUserTypeClient() &&
                                                    // eslint-disable-next-line eqeqeq
                                                    (this.context.currentUser == null || !this.context.currentUser.identityToken.control_plus_link) ?
                                                        <a onClick={this._editClient} className="infoItem">
                                                            <Trans>clientDashboard.setPhone</Trans>
                                                        </a> :
                                                        null }
                                            </div> :
                                            <div className="userInfo">
                                                <Trans>clientDashboard.inActive</Trans>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m6 l4">
                                {/* eslint-disable-next-line eqeqeq */}
                                { client && client.isActive == true ?
                                <div>
                                    { this._renderGoal() }
                                </div>
                                : null }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container dashboardScheduleContainer">
                    { this.state.loadingClient ||
                        this.loadingNotes ||
                        this.loadingMeasurements ||
                        this.state.loadingTrainingSchedules ||
                        this.state.loadingNutritionSchedules ?
                    <FPLoader /> :
                    <div className="customers">
                        { !this._isUserTypeClient() ?
                        <div className="actions">
                            <div className="fixed-action-btn click-to-toggle">
                                <a className="btn-floating btn-large">
                                    <i className="material-icons">add</i>
                                </a>
                                <ul>
                                    <li>
                                        <a className="subicon" onClick={this._addTrainingSchedule}>
                                            <span className="btn-floating">
                                                <i className="material-icons">assignment</i>
                                            </span>
                                        </a>
                                    </li>
                                    {shouldRenderNutritionSchedules &&
                                        <li>
                                            <a className="subicon" onClick={this._addNutritionSchedule}>
                                                <span className="btn-floating">
                                                    <i className="material-icons">local_dining</i>
                                                </span>
                                            </a>
                                        </li>
                                    }
                                    <li>
                                        <a className="subicon" onClick={this._addMeasurement}>
                                            <span className="btn-floating">
                                                <i className="material-icons">insert_chart</i>
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="subicon" onClick={this._addNote}>
                                            <span className="btn-floating">
                                                <i className="material-icons">question_answer</i>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <a onClick={this._editClient} className="btn-floating waves-effect waves-light small grey lighten-4">
                                <i className="material-icons">mode_edit</i>
                            </a>
                            <a onClick={this._connectClient} className="btn-floating waves-effect waves-light small grey lighten-4">
                                <i className="material-icons">link</i>
                            </a>
                        </div>
                        : null }
                        { client && client.isActive ?
                        <div>
                            <div className="row">
                                { this.state.trainingAndNutritionSchedules ? this.state.trainingAndNutritionSchedules.map(
                                    // eslint-disable-next-line eqeqeq
                                    (schedule) => (schedule.schedule == 'training' ?
                                        <div className="col s12 m6 l3" key={'ctrainingSchedule' + schedule.trainingSchedule.id}>
                                            <TrainingScheduleCard clientId={this.props.clientId}
                                                trainingSchedule={schedule.trainingSchedule}
                                            />
                                        </div> :
                                        <div className="col s12 m6 l3" key={'cnutritionSchedule' + schedule.nutritionSchedule.id}>
                                            <NutritionScheduleCard clientId={this.props.clientId}
                                                nutritionSchedule={schedule.nutritionSchedule}
                                            />
                                        </div>
                                    ), this
                                ) : null }
                                { !this._isUserTypeClient() ?
                                    <div className="col s12 m6 l3">
                                        <a onClick={this._addTrainingSchedule} className="addLink">
                                            <span className="btn-floating btn-large"><i className="material-icons">add</i></span>
                                            <span className="caption">Trainingsschema</span>
                                        </a>
                                    </div> :
                                    null
                                }
                                { this._isUserTypeClient() &&
                                    // eslint-disable-next-line eqeqeq
                                    (this.state.trainingSchedules == null || this.state.trainingSchedules.length === 0) ?
                                    <div className="col s12 m6 l3">
                                        <a className="addLink-disabled">
                                            <i className="floating material-icons">assignment</i>
                                            <span className="caption">Geen trainingsschema's</span>
                                        </a>
                                    </div> :
                                    null
                                }
                                { !this._isUserTypeClient() && shouldRenderNutritionSchedules ?
                                    <div className={cssClassNutritionTrainingBlock}>
                                        <a onClick={this._addNutritionSchedule} className="addLink">
                                            <span className="btn-floating btn-large"><i className="material-icons">add</i></span>
                                            <span className="caption">Voedingsschema</span>
                                        </a>
                                    </div> :
                                    null
                                }
                                { this._isUserTypeClient() && shouldRenderNutritionSchedules &&
                                    // eslint-disable-next-line eqeqeq
                                    (this.state.nutritionSchedules == null || this.state.nutritionSchedules.length === 0) ?
                                    <div className="col s12 m6 l3">
                                        <a className="addLink-disabled">
                                            <i className="floating icon icon-nutrition-icon-black" />
                                            <span className="caption">Geen voedingsschema's </span>
                                        </a>
                                    </div> :
                                    null
                                }
                            </div>

                            <br />
                            <hr />
                            <br />
                            <div className="row">
                                { client && client.isActive ?
                                <div className="col s12 m12 l6">
                                    <h5>
                                        <i className="material-icons titleiconcomment">insert_chart</i> <Trans>measurements.measurements</Trans>
                                        {this._renderFatPercentageWarning(this.state.measurements)}
                                    </h5>
                                    {!this._isUserTypeClient() && (
                                        <a onClick={this._addMeasurement} className="addLink horizontal">
                                            <span className="btn-floating"><i className="material-icons">add</i></span>
                                            <span className="caption">Meting toevoegen</span>
                                        </a>
                                    )}
                                    {/* eslint-disable-next-line eqeqeq */}
                                    { this._isUserTypeClient() && (this.state.measurements == null || this.state.measurements.length === 0) ?
                                        <a className="addLink-disabled horizontal">
                                            <span className="caption">Geen metingen</span>
                                        </a> :
                                        null }
                                    <div>
                                        { this.state.measurements && this.state.measurements.map((measurement) => (
                                            <MeasurementCard key={measurement.id}
                                                measurement={measurement}
                                                client={this.state.client}
                                                onRefresh={this._getMeasurements}
                                                onClick={this._showMeasurement}
                                                clickable={!this._isUserTypeClient()}
                                            />
                                        )) }
                                    </div>
                                </div>
                                : null }

                                { client && client.isActive ?
                                <div className="col s12 m12 l6">
                                    <h5><i className="material-icons titleiconcomment">question_answer</i> <Trans>notes.notes</Trans></h5>
                                    { !this._isUserTypeClient() ?
                                        <a onClick={this._addNote} className="addLink horizontal">
                                            <span className="btn-floating"><i className="material-icons">add</i></span>
                                            <span className="caption">Notitie toevoegen</span>
                                        </a> :
                                        null }
                                    {/* eslint-disable-next-line eqeqeq */}
                                    { this._isUserTypeClient() && (this.state.notes == null || this.state.notes.length === 0) ?
                                        <a className="addLink-disabled horizontal">
                                            <span className="caption">Geen notities</span>
                                        </a> :
                                        null }
                                    { this.state.notes ?
                                    <div>
                                        { this.state.notes.map((note) => (
                                            <NoteCard key={note.id}
                                                note={note}
                                                clientId={this.props.clientId}
                                                onRefresh={this._getNotes} />
                                        )) }
                                    </div>
                                    : null }
                                </div>
                                : null }
                            </div>
                        </div>
                        : <div><Trans>errors.client_inactive</Trans></div> }
                    </div>
                }
                </div>

                {/* Modals */}
                {!this._isUserTypeClient() && (
                    <React.Fragment>
                        <EditClientModal clientId={this.props.clientId} client={this.state.client} onChange={this._getClient}
                            controlPlusLink={this.context.currentUser && this.context.currentUser.identityToken.control_plus_link} />
                        <ConnectClientModal client={this.state.client} onChange={this._getClient} />
                        <AddClientErrorModal />
                        <AddItemErrorModal />
                        <AddTrainingScheduleError />
                        <AddNutritionScheduleErrorMax />
                        <AddGoalModal clientId={this.props.clientId} onChange={this._getGoal} currentGoal={this.state.currentGoal} />
                        <AddNoteModal clientId={this.props.clientId} onChange={this._getNotes} />
                        <AddTrainingScheduleModal clientId={this.props.clientId} currentGoal={this.state.currentGoal} />
                        <AddNutritionScheduleModal clientId={this.props.clientId} adviceKcalDaily={this.state.adviceKcalDaily}
                            onSubmit={this._handleAddedNutritionSchedule.bind(this)}
                            currentUser={this.context.currentUser} />
                        <CalculateKcalDaily client={this.state.client} measurements={this.state.measurements}
                            onChange={this._handleKcalCalculation.bind(this)}
                            onAddMeasurement={this._addMeasurement.bind(this)}
                            onMeasurementAdded={this._getMeasurements.bind(this)} />
                        <AddMeasurementModal existingMeasurement={this.state.currentMeasurement}
                            clientId={this.props.clientId}
                            onChange={this._getMeasurements} />
                    </React.Fragment>
                )}
            </div>
        )
    }
}

ClientDashboard.contextType = UserContext