import React from 'react';
import Navbar from 'components/components/navbar/navbar.jsx';

export const UpgradeLayout = ({children}) => (
    <div className="upgradePage">
        <div className="loginHeader">
            <Navbar transparent="true"/>
        </div>
        {children}
    </div>
);