import React from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import moment from 'moment';
import fmfApiClient from 'utils/fmf-api-client.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';

export default class AddNutritionSchedule extends React.Component {
    constructor(props) {
        super(props);
        const propState = this._deriveStateFromProps({}, props);

        this.state = {
            isBusy: true,
            showError: false,
            errorMessage: '',

            adviceLines: [],

            ...this._calculateDates(moment().day(1).toDate()),
            adviceLineId: '',
            adviceKcalDaily: '',
            duration: 1,

            ...propState,
        };

        this._changeHandler = this._changeHandler.bind(this);
        this._handleSubmit = this._handleSubmit.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const propState = this._deriveStateFromProps(this.props, nextProps);
        if (propState) this.setState(propState);
    }

    _deriveStateFromProps(props, nextProps) {
        let hasNewState = false;
        let newState = {}

        const getLatestAdviceLineId = (nutritionSchedule) => {
            if (!nutritionSchedule || !nutritionSchedule.weeklyAdvices) return;

            const weeklyAdvices = nutritionSchedule.weeklyAdvices
            const latestWeeklyAdvice = weeklyAdvices[weeklyAdvices.length - 1];
            return latestWeeklyAdvice.adviceLineId;
        }

        if (props.nutritionSchedule !== nextProps.nutritionSchedule && nextProps.nutritionSchedule) {
            const schedule = props.nutritionSchedule || {};
            const nextSchedule = nextProps.nutritionSchedule;
            if (schedule.startDate !== nextSchedule.startDate) {
                newState = {
                    ...newState,
                    ...this._calculateDates(new Date(nextSchedule.startDate))
                };
                hasNewState = true;
            }
            const nextLatestAdviceLineId = getLatestAdviceLineId(nextSchedule);
            if (getLatestAdviceLineId(schedule) !== nextLatestAdviceLineId) {
                newState = {
                    ...newState,
                    adviceLineId: nextLatestAdviceLineId
                };
                hasNewState = true;
            }
        }
        if (props.adviceKcalDaily !== nextProps.adviceKcalDaily) {
            newState = {
                ...newState,
                adviceKcalDaily: this._normalizeAdviceKcalDaily(nextProps.adviceKcalDaily)
            };
            hasNewState = true;
        }

        if (hasNewState) return newState;
    }

    componentDidMount() {
        $(ReactDOM.findDOMNode(this.datePickerContainer)).pickadate({
            selectYears: 70,
            today: i18n.t('trainingSchedule.today'),
            selectMonths: true,
            container: $('#page'),
            format: 'dd-mm-yyyy',
            closeOnSelect: true,
            disable: [
                2,3,4,5,6,7
            ],
            onSet: function(timeSet) {
                if(timeSet.select) {
                    this.setState({
                        ...this._calculateDates(new Date(timeSet.select))
                    });
                }
            }.bind(this)
        });
        Materialize.updateTextFields(); // Update date input field to have no label+value collision.

        $('#addNutritionSchedule').modal();
        $("#addNutritionScheduleForm").validate({
            errorClass: 'invalid',
            validClass: 'valid',
            errorElement: 'span',
            errorPlacement: function(error, element) {
                // eslint-disable-next-line eqeqeq
                if (element.attr("type") == "checkbox" ) {
                    element.parent('.input-field').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {
                this._handleSubmit();
            }.bind(this)
        });

        this._loadAdviceLines();
    }

    componentWillUnmount(){
        this.cancelSource.cancel();
    }

    openModal() {
        $('#addNutritionSchedule').modal('open');
    }

    _normalizeAdviceKcalDaily(adviceKcalDaily) {
        return adviceKcalDaily || '';
    }

    _calculateDates(date) {
        return {
            serverStartDate: date.toLocalISOString(),
            startDate: date.toInversedShortDateString()
        }
    }

    _changeHandler(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    _handleFormSubmit(event) {
        event.preventDefault();
    }

    _openCalculateKcalDaily() {
        $('#calculateKcalDaily').modal();
        $('#calculateKcalDaily').modal('open');
    }

    _loadAdviceLines() {
        fmfApiClient.getAdviceLines(this.cancelSource.token)
            .then(result => {
                this.setState({
                    isBusy: false,
                    adviceLines: result,
                    adviceLineId: this.state.adviceLineId || result[0].id
                })
            }, error => {
                this.setState({
                    isBusy: false,
                    showError: true,
                    errorMessage: i18n.t('errors.' + error.error.toString())
                })
            });
    }

    _handleSubmit () {
        if(typeof window.event != 'undefined') {
            window.event.preventDefault();
        }

        this.setState({
            isBusy: true,
            showError: false,
            errorMessage: ''
        });

        const stopLoading = () => {
            this.setState({
                isBusy: false
            });
        }

        const handleError = (error) => {
            stopLoading();
            this.setState({
                showError: true,
                errorMessage: error.error === "no_more_advices" ?
                    i18n.t('errors.no_nutritionadvice_for_kcalgroup') :
                    i18n.t('errors.general_error')
            });
        }

        const validateKcalAndCreateNutritionSchedule = () => {
            fmfApiClient.kcalGroupExists(this.cancelSource.token, this.state.adviceLineId, this.state.adviceKcalDaily)
                .then(result => {
                    if (result) {
                        createOrUpdateNutritionSchedule();
                    } else {
                        useNearestKcalIfUserAgrees();
                    }
                }, error => {
                    handleError(error)
                });
        }

        const useNearestKcalIfUserAgrees = () => {
            fmfApiClient.getKcalGroupNearest(this.cancelSource.token, this.state.adviceLineId, this.state.adviceKcalDaily)
                .then(result => {
                    askForKcalOverride(result);
                }, error => {
                    handleError(error);
                });
        }

        const askForKcalOverride = (nearestKcal) => {
            if (window.confirm(i18n.t('nutritionSchedule.useNearest', {nearestKcal: nearestKcal}))) {
                this.setState({adviceKcalDaily: nearestKcal}, () => {
                    createOrUpdateNutritionSchedule();
                });
            } else {
                stopLoading();
            }
        }

        const createOrUpdateNutritionSchedule = () => {
            !this.props.nutritionSchedule ? createNutritionSchedule() : updateNutritionSchedule()
        };

        const createNutritionSchedule = () => {
            let nutritionSchedule = {
                startDate: this.state.serverStartDate,
                initialWeeks: getAddWeeks()
            };

            fmfApiClient.createClientNutritionSchedule(this.cancelSource.token, this.props.clientId, nutritionSchedule)
                .then(result => {
                    $('#addNutritionSchedule').modal('close');
                    this.props.onSubmit(result);
                }, error => {
                    handleError(error);
                    stopLoading();
                });
        };

        const updateNutritionSchedule = () => {
            fmfApiClient.updateClientNutritionSchedule(this.cancelSource.token, this.props.clientId, this.props.nutritionSchedule.id, getAddWeeks())
                .then(result => {
                    $('#addNutritionSchedule').modal('close');
                    this.props.onSubmit(result);
                }, error => {
                    handleError(error);
                }).finally(() => {
                    stopLoading();
                });
        };

        const getAddWeeks = () => {
            return {
                adviceLineId: this.state.adviceLineId,
                weeks: parseInt(this.state.duration),
                kcal: this.state.adviceKcalDaily
            };
        }

        validateKcalAndCreateNutritionSchedule();
    }

    _renderDurationOptions() {
        const maxWeeks = (this.props.currentUser.license.maxWeeksPerNutritionSchedule || 10)
            - ((this.props.nutritionSchedule && this.props.nutritionSchedule.weeks) || 0);

        const options = [];
        for (let i = 1; i <= maxWeeks; i++) {
            // eslint-disable-next-line eqeqeq
            const i18nKey = i == 1 ? 'formFields.week' : 'formFields.weeks';
            options.push(
                <option key={i} value={i}>{i} { i18n.t(i18nKey) }</option>
            );
        }

        return options;
    }

    render() {
        return (
            <div id="addNutritionSchedule" className="modal medium">
                <form id="addNutritionScheduleForm" noValidate onSubmit={this._handleFormSubmit}>
                    <div className="modal-content">
                        <div className="row noMarginBottom">
                            <div className="col s12">
                                {!this.props.nutritionSchedule ?
                                    <h4><Trans>nutritionSchedule.addModalTitle</Trans></h4> :
                                    <h4><Trans>nutritionSchedule.addWeeksModalTitle</Trans></h4>
                                }
                                <div className={!this.state.showError && 'hide'}>
                                    <div id="card-alert" className="card red">
                                        <div className="card-content white-text">
                                            <p>{this.state.errorMessage}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row noMarginBottom">
                            <div className="col s12 m6 input-field">
                                <input type="text"
                                    id="nutritionScheduleStartDate"
                                    name="startDate"
                                    value={this.state.startDate}
                                    required data-msg={ i18n.t('errors.required_field') }
                                    onChange={this._changeHandler}
                                    className="datepicker"
                                    ref={datePicker => { this.datePickerContainer = datePicker }}
                                    disabled={!this.props.nutritionSchedule ? false : true}
                                />
                                <label htmlFor="nutritionScheduleStartDate">{ i18n.t('formFields.startDate') }</label>
                            </div>
                            <div className="col s12 m6">
                                <label htmlFor="adviceLineId">
                                    <Trans>nutritionSchedule.adviceLine</Trans>
                                </label>
                                <select id="adviceLineId"
                                    name="adviceLineId"
                                    className="browser-default"
                                    value={this.state.adviceLineId}
                                    onChange={this._changeHandler}
                                >
                                {this.state.adviceLines.map((adviceLine) => (
                                    <option value={adviceLine.id} key={adviceLine.id}>{adviceLine.name}</option>
                                ))}
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m6 adviceKcalDailyContainer">
                                <div className="input-field">
                                    <label htmlFor="adviceKcalDaily"><Trans>nutritionSchedule.adviceKcalDaily</Trans></label>
                                    <input
                                        id="adviceKcalDaily" type="number"
                                        name="adviceKcalDaily"
                                        value={this.state.adviceKcalDaily}
                                        onChange={this._changeHandler}
                                        required data-msg={ i18n.t('errors.required_field') }
                                    />
                                    <span className="suffixInput">kcal</span>
                                </div>
                                <a className="waves-effect waves-light btn noShadow"
                                    disabled={this.state.isBusy}
                                    onClick={this._openCalculateKcalDaily}
                                >
                                    <Trans>nutritionSchedule.calculate</Trans>
                                </a>
                            </div>
                            <div className="col s12 m6">
                                <label htmlFor="nutritionScheduleDuration"><Trans>nutritionSchedule.durationWeeksMeasurement</Trans></label>
                                <select id="nutritionScheduleDuration"
                                        name="duration"
                                        className="browser-default"
                                        value={this.state.duration}
                                        onChange={this._changeHandler}
                                        required data-msg={i18n.t('errors.required_field')}>
                                    {this._renderDurationOptions()}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col s12">
                                <button disabled={this.state.isBusy} className="waves-effect waves-light btn noShadow" type="submit"
                                    style={{marginLeft: '16px'}}>
                                    <Trans>generalActions.add</Trans>
                                </button>
                                <div style={{marginLeft: '16px', display: 'inline-block', position:'absolute'}} >
                                    <CircleLoader disabled={!this.state.isBusy} />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}