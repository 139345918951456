import axios from 'axios';
import { ai } from 'utils/appInsights';
import { fixAsyncStack } from 'utils/errorLogging.js';
import history from 'history.js';
import qs from 'qs';
import { hasLicenseExpired } from 'utils/licenseUtils';
import authService from 'utils/auth-service.js';
import settings from 'utils/settings.js';

const timeout = 30000;
const methods = {
    delete: 'delete',
    get: 'get',
    head: 'head',
    post: 'post',
    put: 'put'
};

const tokenRefreshSubscriptions = [];
let tokenIsRefreshing = false;

const applyTokenRefreshInterceptor = httpClient => {
    // TODO: Add request interceptor which checks the token's `expires_in` property, to refresh the token proactively.
    httpClient.interceptors.response.use(
        response => response,
        error => {
            if (!error.response || error.response.status !== 401) {
                return Promise.reject(error);
            }

            const config = error.config;
            const retryRequestAfterTokenRefresh = new Promise(resolve => {
                tokenRefreshSubscriptions.push(
                    accessToken => {
                        config.headers['Authorization'] = 'Bearer ' + accessToken;
                        resolve(httpClient.request(config));
                    }
                );
            });

            if (!tokenIsRefreshing) {
                tokenIsRefreshing = true;

                // Try to get a new access token.
                const refreshToken = authService.getRefreshToken();
                authService.loginWithRefreshToken(refreshToken)
                    .then(response => {
                        tokenIsRefreshing = false;

                        // Retry all queued up calls with the fresh access token.
                        const accessToken = authService.getAccessToken();
                        while (tokenRefreshSubscriptions.length > 0) {
                            const subscription = tokenRefreshSubscriptions.shift();
                            subscription(accessToken);
                        }
                    }, refreshError => {
                        tokenIsRefreshing = false;

                        // Refresh token was not valid, logout the client.
                        tokenRefreshSubscriptions.length = 0;
                        authService.logout();
                    });
            }

            return retryRequestAfterTokenRefresh;
        }
    );
};

const applyLicenseExpiredInterceptor = httpClient => {
    httpClient.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 403) {
                const user = authService.getUserSession();
                if (user && hasLicenseExpired(user)) {
                    history.push('/licentie-verlopen');

                    // Don't invoke callbacks.
                    return new Promise(() => {});
                }
            }
            return Promise.reject(error);
        }
    );
};

const applyHeadTransformInterceptor = httpClient => {
    httpClient.interceptors.response.use(
        response => {
            if (response.config.method === methods.head) {
                response.data = true;
            }
            return Promise.resolve(response);
        },
        error => {
            if (error.config && error.config.method === methods.head
            && error.response && error.response.status === 404) {
                return false;
            }
            return Promise.reject(error);
        }
    );
};

const applyCancelledRequestInterceptor = httpClient => {
    return httpClient.interceptors.response.use(
        response => response,
        error => {
            if (axios.isCancel(error)) {
                // Don't invoke callbacks.
                return new Promise(() => {});
            }
            return Promise.reject(error);
        }
    );
}

const httpClient = axios.create();
applyTokenRefreshInterceptor(httpClient);
applyLicenseExpiredInterceptor(httpClient);
applyHeadTransformInterceptor(httpClient);
applyCancelledRequestInterceptor(httpClient);

// Returns a promise that:
// - on completion contains the response body as a plain js object;
// - on rejection contains an the following object:
// {
//      error : string,
//      reason : string || {
//          [key]: array[string].
//          ...
//      },
//      status : number
// }
const callApi = (requestConfig) => {
    const stackFixer = fixAsyncStack();
    return httpClient.request(requestConfig)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            return handleHttpError(error, stackFixer);
        })
}

const callApiJson = (httpMethod, relativeUrl, cancelToken, queryParams, data) => {
    // Request is by default configured for json.
    const requestConfig = getApiBaseRequestConfig(httpMethod, relativeUrl, cancelToken, queryParams, data);
    return callApi(requestConfig);
}

const callApiFileUpload = (relativeUrl, cancelToken, queryParams, fileData, fileName) => {
    const formData = new FormData();
    formData.append('file', fileData, fileName);

    const requestConfig = getApiBaseRequestConfig(methods.post, relativeUrl, cancelToken, queryParams, formData);
    requestConfig.headers['Content-Type'] = 'multipart/form-data';

    return callApi(requestConfig);
}

const callApiOAuth = (relativeUrl, cancelToken, data) => {
    const formData = new URLSearchParams();
    for (let key in data) {
        formData.append(key, data[key]);
    }
    formData.append('client_id', settings.FMF_API_CLIENT_ID);

    const requestConfig = getBaseRequestConfig(methods.post, 'oauth2/' + relativeUrl, cancelToken, {}, formData);
    requestConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded';

    return callApi(requestConfig);
}

const getApiBaseRequestConfig = (httpMethod, relativeUrl, cancelToken, queryParams, data) => {
    const requestConfig = getBaseRequestConfig(httpMethod, relativeUrl, cancelToken, queryParams, data);
    requestConfig.headers['Authorization'] = 'Bearer ' + authService.getAccessToken();

    return requestConfig;
}

const getBaseRequestConfig = (httpMethod, relativeUrl, cancelToken, queryParams, data) => {
    const requestConfig = {
        baseURL: settings.FMF_API_URL + '/',
        timeout: timeout,
        headers: {},
        url: relativeUrl,
        method: httpMethod,
        cancelToken: cancelToken,
        params: queryParams,
        paramsSerializer: params => {
            return qs.stringify(params)
        },
        data: data
    };
    return requestConfig;
}

const handleHttpError = (error, stackFixer) => {
    let errorCode = 'general_error';
    let reason = '';
    let status = null;
    let shouldTrackError = true;

    if(error.response) {
        // See FMF API Swagger documentation about `Error responses`.
        status = error.response.status;
        const data = error.response.data;

        if(error.response.data && error.response.data.error){
            // If `error` property has been set,
            // pass error to client, so it can be handled.
            //
            // TODO: Make function smarter, by detecting if
            // error value is for a client or user error.
            // Only pass error to client if it's a user error,
            // else pass a general_error.
            errorCode = error.response.data.error;
            reason = error.response.data.error_description;
            shouldTrackError = false;
        } else if (status === 400) {
            // 400 without an error property indicates a client
            // error (API implementation error client-side).
            // This can't be handled client-side,
            // so pass general_error.
            reason = data;
        } else if (status === 401) {
            // 401 should be handeled by
            // refreshing to access token. At this
            // stage this should have been done by
            // the proper interceptor.
            reason = data;
        } else if (status === 403) {
            // 403 responses indicate that the user
            // has no right for API call.
            // The only case which we can handle is for an
            // expired license. Which should have be taken
            // care of at this point by the proper interceptor.
            reason = data;
        } else if (status === 404) {
            // 404 is now sometimes used for flow handling and
            // when a resource is deleted that's already open.
            // So we need to pass the status code to the caller.
            //
            // In the feature:
            // - For flow handling we should probably
            // send a HTTP HEAD request, instead of relying on a 404.
            // - For deleted resources we should probably make a global
            // rule that redirects the user back to the dashboard.
            errorCode = status;
            reason = data;
            shouldTrackError = false;
        } else if (400 <= status && status <= 499) {
            // Other 4xx responses can't be handled client-side,
            // so pass general_error.
            reason = data;
        } else if (500 <= status && status <= 599) {
            // 5xx responses indicate a server error.
            // This can't be handled client-side,
            // so pass general_error.
            reason = data;
        }
    } else {
        reason = "No response";
    }

    let errorMessage = errorCode;
    if (status) errorMessage += " (" + status + ")";
    errorMessage += " - " + JSON.stringify(reason);

    const err = new Error(errorMessage);
    err.error = errorCode;
    err.reason = reason;
    err.status = status;

    // Fix up async stacktraces.
    // Without this, we wouldn't know which method made the request.
    stackFixer(err);
    stackFixer(error);

    if (shouldTrackError) {
        trackError(err);
        trackError(error);
    }

    return Promise.reject(err);
}

const trackError = (error) => {
    ai.appInsights.trackException({exception: error});
}

function createCancelSource() {
    return axios.CancelToken.source();
}

const fmfApiClient = {
    createCancelSource: createCancelSource,

    // OAuth2
    getTokenByUserCredentials : (username, password) =>
        callApiOAuth('token', null, {username: username, password: password, grant_type: 'password'}),
    getTokenByRefreshToken : (refreshToken) =>
        callApiOAuth('token', null, {refresh_token: refreshToken, grant_type: 'refresh_token'}),
    getTokenByCode : (code) =>
        callApiOAuth('token', null, {code: code, grant_type: 'authorization_code'}),
    registerAccount : (cancelToken, username, password, firstname, lastname, organization, mailsubscription, controlPlusHostname, controlPlusApiKey) =>
        callApiOAuth('register', cancelToken, {username, password, firstname, lastname, organization, mailsubscription, controlPlusHostname, controlPlusApiKey}),
    confirmEmail : (cancelToken, username, token) =>
        callApiOAuth('confirm', cancelToken, {username: username, token: token}),
    resendConfirmationMail : (cancelToken, username) =>
        callApiOAuth('resend', cancelToken, {username: username}),
    sendResetPasswordMail : (cancelToken, username) =>
        callApiOAuth('resetpasswordmail', cancelToken, {username: username}),
    resetPassword : (cancelToken, username, token, password) =>
        callApiOAuth('resetpassword', cancelToken, {username: username, token: token, password: password}),
    changePassword : (cancelToken, username, oldpassword, password) =>
        callApiOAuth('changepassword', cancelToken, {username: username, oldpassword: oldpassword, newpassword: password}),

    // Clients
    getClients: (cancelToken, query, page, pageSize, imgParams) => {
        const requestConfig = getApiBaseRequestConfig(methods.get, 'Clients', cancelToken, { query, page, pageSize, ...imgParams });
        requestConfig.timeout = 600000; // 10 min, because of a possible delay caused by Control Plus synchronization.
        return callApi(requestConfig);
    },
    getClient: (cancelToken, id, imgParams) =>
        callApiJson(methods.get, 'Clients/' + id, cancelToken, imgParams),
    countClients: (cancelToken, onlyActive) =>
        callApiJson(methods.get, 'Clients/Count', cancelToken, { onlyActive }),
    createClient: (cancelToken, client, imgParams) =>
        callApiJson(methods.post, 'Clients', cancelToken, imgParams, client),
    updateClient: (cancelToken, id, client, imgParams) =>
        callApiJson(methods.put, 'Clients/' + id, cancelToken, imgParams, client),
    createClientImage: (cancelToken, id, fileName, fileData, imgParams) =>
        callApiFileUpload('Clients/' + id + '/image', cancelToken, imgParams, fileData, fileName),
    deleteClientImage: (cancelToken, id, client) =>
        callApiJson(methods.delete, 'Clients/' + id + '/image', cancelToken, {}, client),
    connectClient: (cancelToken, id) =>
        callApiJson(methods.post, 'Clients/' + id + '/connect', cancelToken),
    disconnectClient: (cancelToken, id) =>
        callApiJson(methods.post, 'Clients/' + id + '/disconnect', cancelToken),

    getClientIdByControlPlusId: (cancelToken, controlPlusId) =>
        callApiJson(methods.get, 'Clients/ControlPlus/' + controlPlusId, cancelToken),

    // UserProfile
    getUserProfile: (cancelToken, imgParams) =>
        callApiJson(methods.get, 'UserProfile', cancelToken, imgParams),
    updateUserProfile: (profile, imgParams) =>
        callApiJson(methods.put, 'UserProfile', null, imgParams, profile)
            .then(userProfile =>
                Promise.all([
                    fmfApiClient.getUserProfile(),
                    fmfApiClient.getOrganization()
                ]).then(values => {
                    const [userProfile, organization] = values;
                    const userSession = authService.getUserSession();

                    Object.assign(userSession.profile, userProfile);
                    userSession.identityToken.fullname = userProfile.name;
                    Object.assign(userSession.organization, organization);
                    userSession.identityToken.organization_displayname = organization.displayName;

                    authService.setUserSession(userSession);

                    return userProfile;
                })
            ),
    createUserProfileImage: (fileName, fileData, imgParams) =>
        callApiFileUpload('UserProfile/image', null, imgParams, fileData, fileName)
            .then(image => {
                return fmfApiClient.getUserProfile().then(userProfile => {
                    const userSession = authService.getUserSession();
                    Object.assign(userSession.profile, userProfile);
                    authService.setUserSession(userSession);

                    return image;
                })
            }),
    deleteUserProfileImage: () =>
        callApiJson(methods.delete, 'UserProfile/image', null)
            .then(image => {
                fmfApiClient.getUserProfile().then(userProfile => {
                    const userSession = authService.getUserSession();
                    Object.assign(userSession.profile, userProfile);
                    authService.setUserSession(userSession);

                    return image;
                })
            }),

    // Organization
    getOrganization: (cancelToken) =>
        callApiJson(methods.get, 'Organization', cancelToken),
    updateOrganization: profile =>
        callApiJson(methods.put, 'Organization', null, {}, profile)
            .then(organization => {
                fmfApiClient.getOrganization().then(organization => {
                    const userSession = authService.getUserSession();
                    Object.assign(userSession.organization, organization);
                    userSession.identityToken.organization_displayname = organization.displayName;
                    authService.setUserSession(userSession);

                    return organization;
                });
            }),
    getOrganizationLicense: (cancelToken) =>
        callApiJson(methods.get, 'Organization/License', cancelToken),
    setStyling: (cancelToken, primaryColorHexRgb, fileName, fileData) => {
        const formData = new FormData();
        if (primaryColorHexRgb) formData.append('primaryColorHexRgb', primaryColorHexRgb);
        if (fileData) formData.append('logoImageFile', fileData, fileName);

        const requestConfig = getApiBaseRequestConfig(methods.put, '/Organization/Styling', cancelToken, null, formData);
        requestConfig.headers['Content-Type'] = 'multipart/form-data';

        return callApi(requestConfig).then(result => {
            // Setting style invalidates the access token, so refresh it proactively.
            authService.loginWithRefreshToken(authService.getRefreshToken());
            return result;
        });
    },
    changeLicense: (cancelToken, licenseId) =>
        callApiJson(methods.put, 'Organization/License/' + licenseId, cancelToken),
    onChangeLicenseComplete: (cancelToken) =>
        // When the license is changed the current access token gets revoked.
        // Due to bug #5196 the revoked token is refreshed for every api call individually,
        // instead of blocking feature calls while the refresh is in progress.
        // Due to this we call 1 random API endpoint after the license changed,
        // so the access token only gets refreshed 1 time.
        callApiJson(methods.get, 'Organization/License', cancelToken),
    isPaymentRequired: (cancelToken, licenseId) =>
        callApiJson(methods.get, 'Organization/License/' + licenseId + '/FirstPaymentRequired', cancelToken),

    // Licenses
    getLicenses: (cancelToken) =>
        callApiJson(methods.get, 'Licenses', cancelToken),
    getLicenseById: (cancelToken, id) =>
        callApiJson(methods.get, 'Licenses/' + id, cancelToken),

    // Invoices
    getInvoices: (cancelToken) =>
        callApiJson(methods.get, 'Invoices', cancelToken),
    getInvoiceByInvoiceNumber: (cancelToken, invoiceNumber) =>
        callApiJson(methods.get, 'Invoices/' + invoiceNumber, cancelToken),

    // Payments
    getPayment: (cancelToken, id) =>
        callApiJson(methods.get, 'Payments/' + id, cancelToken),

    // Exercises
    getExerciseFilters: (cancelToken) =>
        callApiJson(methods.get, 'Exercises/Filters', cancelToken),
    getExercises: (cancelToken, query, exerciseTypeIds, equipmentIds, regionIds, page, pageSize, imgParams) =>
        callApiJson(methods.get, 'Exercises', cancelToken, { query, exerciseTypeIds, equipmentIds, regionIds, page, pageSize, ...imgParams }),
    getExercise: (cancelToken, id, imgParams) =>
        callApiJson(methods.get, 'Exercises/' + id, cancelToken, imgParams),

    // Training Schedules
    getClientTrainingSchedules: (cancelToken, clientId) =>
        callApiJson(methods.get, 'Clients/' + clientId + '/TrainingSchedules', cancelToken),
    getClientTrainingSchedule: (cancelToken, clientId, id, imgParams) =>
        callApiJson(methods.get, 'Clients/' + clientId + '/TrainingSchedules/' + id, cancelToken, imgParams),
    createClientTrainingSchedule: (cancelToken, clientId, schedule) =>
        callApiJson(methods.post, 'Clients/' + clientId + '/TrainingSchedules', cancelToken, {}, schedule),
    updateClientTrainingSchedule: (cancelToken, clientId, id, schedule) =>
        callApiJson(methods.put, 'Clients/' + clientId + '/TrainingSchedules/' + id, cancelToken, {}, schedule),
    deleteClientTrainingSchedule: (cancelToken, clientId, id) =>
        callApiJson(methods.delete, 'Clients/' + clientId + '/TrainingSchedules/' + id, cancelToken),
    updateClientTrainingScheduleExercise: (cancelToken, clientId, scheduleId, id, exercise) =>
        callApiJson(methods.put, 'Clients/' + clientId + '/TrainingSchedules/' + scheduleId + '/' + id, cancelToken, {}, exercise),
    updateClientTrainingScheduleExerciseOneRepetitionMax: (cancelToken, clientId, scheduleId, id, oneRepetitionMax) =>
        callApiJson(methods.put, 'Clients/' + clientId + '/TrainingSchedules/' + scheduleId + '/' + id + '/' + oneRepetitionMax, cancelToken),

    // Training Templates
    getTrainingTemplates: (cancelToken, query, goalIds, exerciseTypeIds, equipmentIds, regionIds, page, pageSize) =>
        callApiJson(methods.get, 'TrainingTemplates', cancelToken, { query, goalIds, exerciseTypeIds, equipmentIds, regionIds, page, pageSize }),
    getTrainingTemplate: (cancelToken, id) =>
        callApiJson(methods.get, 'TrainingTemplates/' + id, cancelToken),
    createTrainingTemplate: (cancelToken, schedule) =>
        callApiJson(methods.post, 'TrainingTemplates', cancelToken, {}, schedule),
    updateTrainingTemplate: (cancelToken, id, schedule) =>
        callApiJson(methods.put, 'TrainingTemplates/' + id, cancelToken, {}, schedule),
    deleteTrainingTemplate: (cancelToken, id) =>
        callApiJson(methods.delete, 'TrainingTemplates/' + id, cancelToken),

    // Custom Training Templates
    getCustomTrainingTemplates: (cancelToken, query, goalIds, exerciseTypeIds, equipmentIds, regionIds, page, pageSize) =>
        callApiJson(methods.get, 'CustomTrainingTemplates', cancelToken, { query, goalIds, exerciseTypeIds, equipmentIds, regionIds, page, pageSize }),
    getCustomTrainingTemplate: (cancelToken, id) =>
        callApiJson(methods.get, 'CustomTrainingTemplates/' + id, cancelToken),
    createCustomTrainingTemplate: (cancelToken, schedule) =>
        callApiJson(methods.post, 'CustomTrainingTemplates', cancelToken, {}, schedule),
    updateCustomTrainingTemplate: (cancelToken, id, schedule) =>
        callApiJson(methods.put, 'CustomTrainingTemplates/' + id, cancelToken, {}, schedule),
    deleteCustomTrainingTemplate: (cancelToken, id) =>
        callApiJson(methods.delete, 'CustomTrainingTemplates/' + id, cancelToken),

    // Goals
    getGoals: (cancelToken) =>
        callApiJson(methods.get, 'Goals', cancelToken),
    getGoal: (cancelToken, id) =>
        callApiJson(methods.get, 'Goals/' + id, cancelToken),
    getClientGoal: (cancelToken, clientId) =>
        callApiJson(methods.get, 'Clients/' + clientId + '/Goal', cancelToken),
    updateClientGoal: (cancelToken, clientId, goal) =>
        callApiJson(methods.put, 'Clients/' + clientId + '/Goal', cancelToken, {}, goal),
    deleteClientGoal: (cancelToken, clientId) =>
        callApiJson(methods.delete, 'Clients/' + clientId + '/Goal', cancelToken),

    // Notes
    getClientNotes: (cancelToken, clientId) =>
        callApiJson(methods.get, 'Clients/' + clientId + '/Notes', cancelToken),
    getClientNote: (cancelToken, clientId, noteId) =>
        callApiJson(methods.get, 'Clients/' + clientId + '/Notes/' + noteId, cancelToken),
    createClientNote: (cancelToken, clientId, note) =>
        callApiJson(methods.post, 'Clients/' + clientId + '/Notes', cancelToken, {}, note),
    updateClientNote: (cancelToken, clientId, noteId, note) =>
        callApiJson(methods.put, 'Clients/' + clientId + '/Notes/' + noteId, cancelToken, {}, note),
    deleteClientNote: (cancelToken, clientId, noteId) =>
        callApiJson(methods.delete, 'Clients/' + clientId + '/Notes/' + noteId, cancelToken),

    // Measurements
    getClientMeasurements: (cancelToken, clientId) =>
        callApiJson(methods.get, 'Clients/' + clientId + '/Measurements', cancelToken),
    createClientMeasurement: (cancelToken, clientId, measurement) =>
        callApiJson(methods.post, 'Clients/' + clientId + '/Measurements', cancelToken, {}, measurement),
    updateClientMeasurement: (cancelToken, clientId, measurementId, measurement) =>
        callApiJson(methods.put, 'Clients/' + clientId + '/Measurements/' + measurementId, cancelToken, {}, measurement),
    deleteClientMeasurement: (cancelToken, clientId, measurementId) =>
        callApiJson(methods.delete, 'Clients/' + clientId + '/Measurements/' + measurementId, cancelToken),

    // Nutrition Schedules
    getClientNutritionSchedules: (cancelToken, clientId) =>
        callApiJson(methods.get, 'Clients/' + clientId + '/NutritionSchedules', cancelToken),
    getClientNutritionSchedule: (cancelToken, clientId, scheduleId) =>
        callApiJson(methods.get, 'Clients/' + clientId + '/NutritionSchedules/' + scheduleId, cancelToken),
    createClientNutritionSchedule: (cancelToken, clientId, nutritionSchedule) =>
        callApiJson(methods.post, 'Clients/' + clientId + '/NutritionSchedules', cancelToken, {}, nutritionSchedule),
    updateClientNutritionSchedule: (cancelToken, clientId, scheduleId, addWeeks) =>
        callApiJson(methods.put, 'Clients/' + clientId + '/NutritionSchedules/' + scheduleId, cancelToken, {}, addWeeks),
    deleteClientNutritionSchedule: (cancelToken, clientId, scheduleId) =>
        callApiJson(methods.delete, 'Clients/' + clientId + '/NutritionSchedules/' + scheduleId, cancelToken),

    getSubstitionMeals: (cancelToken, query, kcal, mealTypeId) =>
        callApiJson(methods.get, 'SubstituteMeals', cancelToken, { query: query, kcal: kcal, mealTypeId: mealTypeId }),
    substituteMeal: (cancelToken, clientId, scheduleId, subsitution) =>
        callApiJson(methods.post, 'Clients/' + clientId + '/NutritionSchedules/' + scheduleId + '/Substitutions', cancelToken, {}, subsitution),
    removeSubstituteMeal: (cancelToken, clientId, scheduleId, substitutionId) =>
        callApiJson(methods.delete, 'Clients/' + clientId + '/NutritionSchedules/' + scheduleId + '/Substitutions/' + substitutionId, cancelToken),

    getAdviceLines: (cancelToken) =>
        callApiJson(methods.get, 'AdviceLine', cancelToken),
    kcalGroupExists: (cancelToken, adviceLineId, kcal) =>
        callApiJson(methods.head, 'AdviceLines/' + adviceLineId + '/kcal/' + kcal, cancelToken),
    getKcalGroupNearest: (cancelToken, adviceLineId, kcal) =>
        callApiJson(methods.get, 'AdviceLines/' + adviceLineId + '/nearestKcal/' + kcal, cancelToken),

    // Users
    getUsers: (cancelToken, userType, page, pageSize) =>
        callApiJson(methods.get, 'Users', cancelToken, { page, pageSize }),
    getUser: (cancelToken, id) =>
        callApiJson(methods.get, 'Users/' + id, cancelToken),
    createUser: (cancelToken, user) =>
        callApiJson(methods.post, 'Users', cancelToken, {}, user),
    deleteUser: (cancelToken, id) =>
        callApiJson(methods.delete, 'Users/' + id, cancelToken),

    // ControlPlus
    controlPlusLink: (cancelToken, hostName, apiKey) =>
        callApiJson(methods.put, 'ControlPlus/Link', cancelToken, { hostName, apiKey }),
    controlPlusUnlink: (cancelToken) =>
        callApiJson(methods.put, 'ControlPlus/Unlink', cancelToken),
    controlPlusMigrateClients: (cancelToken) =>
        callApiJson(methods.put, 'ControlPlus/MigrateClients', cancelToken),
    controlPlusMigrateClientsVerify: (cancelToken) =>
        callApiJson(methods.get, 'ControlPlus/MigrateClients/Verify', cancelToken),
    isControlPlusUnlinkRequired: (cancelToken, licenseId) =>
        callApiJson(methods.get, 'Organization/License/' + licenseId + '/RequiresControlPlusUnlink', cancelToken),
}

export default fmfApiClient;