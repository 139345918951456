import React, { useContext } from 'react';
import i18n from 'i18next';
import { ThemeContext } from 'contexts';

export const LoginLayout = ({match, children}) => {
    const { logoUrl } = useContext(ThemeContext);

    let introTitle = null;
    let intro = null;

    switch(match.path) {
        case '/login':
            introTitle = i18n.t('login.introTitle');
            intro = i18n.t('login.intro');
            break;
        case '/registreer':
            introTitle = i18n.t('register.introTitle');
            intro = i18n.t('register.intro');
            break;
        case '/registreer/voltooid':
        case '/registreer/mislukt':
            introTitle = i18n.t('activation.introTitle');
            intro = i18n.t('activation.intro');
            break;
        case '/resetPass':
            introTitle = i18n.t('forgotPass.introTitle');
            intro = i18n.t('forgotPass.intro');
            break;
        case '/setPass':
            introTitle = i18n.t('setPass.introTitle');
            intro = i18n.t('setPass.intro');
            break;
        default:
            introTitle = i18n.t('login.introTitle');
            intro = i18n.t('login.intro');
            break;
    }

    return (
        <div>
            <div className="loginHeader">
                <div className="container">
                    <div className="loginLogoContainer">
                        <div className="row">
                            <div className="col s10 offset-s1 m8 offset-m2">
                                <img src={logoUrl} className="loginLogo"/>
                                <h4>{introTitle}</h4>
                                <p>{intro}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};