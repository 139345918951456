import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import fmfApiClient from 'utils/fmf-api-client.js';
import CircleLoader from 'components/components/circleLoader/circleLoader.jsx';
import IntakeCalculator from 'utils/nutrition/fmfIntakeCalculator.js';
import uniqueId from 'lodash.uniqueid';
import { isNumber } from 'util';
import LatestNutritionPicker from 'utils/latestNutritionPicker';

const intakeCalculator = new IntakeCalculator();
const latestNutritionPicker = new LatestNutritionPicker();

export default class CalculateKcalDaily extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ...this._calculateCurrentAndInitial(this.props.measurements, this.props.client),
            targetWeight: '',
            targetFatPercentage: '',
            activityLevel: '',
            isBusy: false
        };

        this.state = {
            ...this.state,
            ...this._calculateAdvice()
        };

        this.cancelSource = fmfApiClient.createCancelSource();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.client === this.props.client && nextProps.measurements === this.props.measurement) {
            return;
        }

        const callback = () => {
            Materialize.updateTextFields();
            this.setState(this._calculateAdvice());
        };

        this.setState(this._calculateCurrentAndInitial(nextProps.measurements, nextProps.client), callback);
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    openModal() {
        $('#calculateKcalDaily').modal('open');
    }

    _calculateCurrentAndInitial(measurements, client) {
        let initialWeight = '';
        let initialFatPercentage = '';
        let currentWeight = '';
        let currentFatPercentage = '';

        const lmWeight = latestNutritionPicker.getLatestMeasurementWithWeightOrNull(measurements);
        currentWeight = (lmWeight && lmWeight.currentWeight) || '';

        const lmFatPerc = latestNutritionPicker.getLatestMeasurementWithFatPercentageOrNull(measurements);
        if (lmFatPerc && client) {
            currentFatPercentage = intakeCalculator.calculateFatPercentage(client, lmFatPerc) || '';
        }

        initialWeight = currentWeight;
        initialFatPercentage = currentFatPercentage;

        return {
            initialWeight,
            initialFatPercentage,
            currentWeight,
            currentFatPercentage
        };
    }

    _calculateAdvice() {
        const s = this.state;
        return intakeCalculator.calculateDailyKcalTarget(
            s.currentWeight, s.currentFatPercentage, s.activityLevel, s.targetWeight
        );
    }

    _handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({[name]: value}, () => this.setState(this._calculateAdvice()));
    }

    _handleSubmit(event) {
        event.preventDefault();
        this._addMeasurementIfChanged(() => {
            this.props.onChange(this.state.adviceKcalDaily);
            this.props.onMeasurementAdded();
            $("#calculateKcalDaily").modal('close');
        });
    }

    _addMeasurementIfChanged(callback) {
        // Don't add measurement if input is invalid.
        if ((isNaN(this.state.currentWeight) || this.state.currentWeight <= 0) ||
            (isNaN(this.state.currentFatPercentage) || this.state.currentFatPercentage <= 0)) {
            callback();
            return;
        }

        // Don't add measurement if input is not changed from initial input.
        if (this.state.initialWeight === this.state.currentWeight &&
            this.state.initialFatPercentage === this.state.currentFatPercentage) {
            callback();
            return;
        }

        this.setState({isBusy: true});

        const measurement = {
            "currentWeight": this.state.currentWeight,
            "fatPercentage": this.state.currentFatPercentage,
            "skinFoldMeasurementMethod": 0
        };

        fmfApiClient.createClientMeasurement(
            this.cancelSource.token, this.props.client.id, measurement)
                .then(result => {
                    this.setState({isBusy: false}, callback)
                }, error => {
                    Materialize.toast(i18n.t('errors.general_error'), window.toastDuration);
                    this.setState({isBusy: false});
                });
    }

    render() {
        return (
            <div id="calculateKcalDaily" className="modal medium">
                <form id="calculateKcalDailyForm" noValidate onSubmit={this._handleSubmit.bind(this)}>
                    <div className="modal-content">
                        <div className="row calculateKcalHeader">
                            <div className="col s12">
                                <h4><Trans>dailyAdvice.title</Trans></h4>
                            </div>
                            <div className="col s12 subtext">
                                <Trans>dailyAdvice.addMeasurementTip</Trans>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s5"><strong><Trans>dailyAdvice.current</Trans></strong></div>
                            <div className="col s5 offset-s2"><strong><Trans>dailyAdvice.target</Trans></strong></div>

                            <MeasurementProgress currentName="currentWeight" currentValue={this.state.currentWeight}
                                targetName="targetWeight" targetValue={this.state.targetWeight}
                                suffix="kg"
                                label={<Trans>formFields.currentWeight</Trans>}
                                onChange={this._handleChange.bind(this)} />

                            <MeasurementItem name="currentFatPercentage" value={this.state.currentFatPercentage}
                                suffix="%"
                                label={<Trans>formFields.currentFatPercentage</Trans>}
                                onChange={this._handleChange.bind(this)} />

                            <ActivitySelector
                                value={this.state.activityLevel}
                                onChange={this._handleChange.bind(this)} />
                        </div>

                        <div className="row divider" />

                        <div className="calculateKcalFooter">
                            <DailyKcalAdvice adviceKcalDaily={this.state.adviceKcalDaily} maintenance={this.state.maintenance} />
                            <div className="medium-filler" />
                            <CircleLoader disabled={!this.state.isBusy} />

                            <div className="buttonContainer">
                                <a className="waves-effect waves-light btn noShadow"
                                    disabled={this.state.isBusy}
                                    onClick={this._handleAddMeasurement.bind(this)}>
                                    <Trans>dailyAdvice.addMeasurement</Trans>
                                </a>
                                <div className="small-filler" />
                                <button className="waves-effect waves-light btn noShadow"
                                    disabled={this.state.isBusy}
                                    type="submit">
                                    <Trans>dailyAdvice.useAdvice</Trans>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    _handleAddMeasurement(event) {
        event.preventDefault();
        this.props.onAddMeasurement();
    }
}

function MeasurementProgress(props) {
    return (
        <div className="col s12 valign-wrapper" style={{padding: 0}}>
            <MeasurementItem name={props.currentName} value={props.currentValue} onChange={props.onChange} suffix={props.suffix} label={props.label} />
            <div className="col s2 center-align"><i className="material-icons">arrow_forward</i></div>
            <MeasurementItem name={props.targetName} value={props.targetValue} onChange={props.onChange} suffix={props.suffix} label={props.label} />
        </div>
    );
}

function MeasurementItem(props) {
    const id = uniqueId();
    return (
        <div className="col s5 input-field">
            <div>
                <input id={id} type="text" name={props.name} value={props.value} onChange={props.onChange} />
                <span className="suffixInput">{props.suffix}</span>
                <label htmlFor={id}>{props.label}</label>
            </div>
        </div>
    );
}

function ActivitySelector(props) {
    const id = uniqueId();
    return (
        <div className='col s12'>
            <label htmlFor={id} className="activityLevel">
                <Trans>dailyAdvice.activityLevel</Trans>
            </label>
            <select id={id}
                name="activityLevel"
                className="browser-default"
                value={props.value}
                data-msg={ i18n.t('errors.required_field') }
                onChange={props.onChange}>
                <option value="" disabled>{ i18n.t('dailyAdvice.activityLevelPlaceholder') }</option>
                <option value="1">{ i18n.t('dailyAdvice.activity.sedentary') }</option>
                <option value="2">{ i18n.t('dailyAdvice.activity.light') }</option>
                <option value="3">{ i18n.t('dailyAdvice.activity.moderate') }</option>
                <option value="4">{ i18n.t('dailyAdvice.activity.heavy') }</option>
                <option value="5">{ i18n.t('dailyAdvice.activity.athlete') }</option>
            </select>
        </div>
    );
}

function DailyKcalAdvice(props) {
    return (
        <div>
            <div>
                <strong><Trans>dailyAdvice.adviceKcalDaily</Trans></strong>
                <Tooltip text="dailyAdvice.infoAdvice" />
            </div>
            <div style={{fontSize: '1.4rem'}}>
                <KcalAdvice kcal={props.adviceKcalDaily} />
            </div>
            <div style={{fontSize: '14px'}}>
                <span><Trans>dailyAdvice.maintenance</Trans>: </span>
                <KcalAdvice kcal={props.maintenance} />
                <Tooltip text="dailyAdvice.infoMaintenance" />
            </div>
        </div>
    );
}

function Tooltip(props) {
    return (
        <a className="info tooltipped afterSwitch"
            style={{marginLeft: '2px'}}
            data-position="bottom" data-tooltip={ i18n.t(props.text) }>
            <i className="material-icons">info</i>
        </a>
    );
}

function KcalAdvice(props) {
    const kcal = parseFloat(props.kcal);
    return (
        <span>
            <span>{isNumber(kcal) && Math.floor(kcal)} kcal</span>
            &nbsp;
            <span>({isNumber(kcal) && Math.floor(kcal * 4.184)} kJ)</span>
        </span>
    );
}