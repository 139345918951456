import React from 'react';
import { Trans } from 'react-i18next';

class ScheduleCard extends React.Component {
    constructor(props) {
        super(props);

        this._addSchedule = this._addSchedule.bind(this);
    }

    _addSchedule() {
        if (typeof this.props.onAddSchedule === 'function') {
            this.props.onAddSchedule(this.props.template);
        }
    }

    render() {
        return (
            <div className="col s12">
                <div className="card exercise">
                    <div className="card-image">
                        <div className="trainingTemplateImageContainer" style={{backgroundImage: "url(/images/TrainingTemplate.jpg)"}}></div>
                    </div>
                    <div className="addAction">
                        <a onClick={this._addSchedule} className="btn-floating btn-large waves-effect waves-light"><i
                            className="material-icons">add</i></a>
                    </div>
                    <div className="cardInfo">
                            <span className="card-title">
                                { this.props.template.name }
                            </span>
                        <span className="extraInfo">{ this.props.template.goalName }</span>
                    </div>
                    <div className="card-action">
                        <a > {this.props.template.exercises.length} { this.props.template.exercises.length > 1 ? <Trans>trainingSchedule.exercises</Trans>:<Trans>trainingSchedule.exercise</Trans>}
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default ScheduleCard;