import React from 'react';
import Router from './router.jsx';
import { UserProvider, ThemeProvider } from './contexts.jsx';

export default function App() {
    return (
        <UserProvider>
            <ThemeProvider>
                <Router />
            </ThemeProvider>
        </UserProvider>
    );
}