import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import ResendActivationModal from 'components/forms/modals/resendActivation.jsx';

class ActivationError extends React.Component {
    constructor(props) {
        super(props);
        this._resendActivation = this._resendActivation.bind(this);
    }

    _resendActivation() {
        $('#resendActivation').modal();
        $('#resendActivation').modal('open');
    }

    render() {
        return (
            <div id="activationErrorContainer">
                <div className="container">
                    <div className="registerSuccessNotice">
                        <div className="row">
                            <div className=" col s10 offset-s1 m8 offset-m2">
                                <br />
                                <h4><Trans>activation.errorTitle</Trans></h4>
                                <p><Trans>activation.errorText</Trans></p>
                                <p><a className="cursor" onClick={this._resendActivation}><Trans>activation.resendActivationTrigger</Trans></a></p>
                                <p>
                                    <Trans components={[<Link to='/registreer' />]}>login.noAccount</Trans><br />
                                    <Trans components={[<Link to='/login' />]}>register.alreadyGotAAcount</Trans>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ResendActivationModal />
            </div>
        );
    }
}

export default ActivationError;