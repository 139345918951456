import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

import fmfApiClient from 'utils/fmf-api-client.js';
import AddScheduleHeader from 'components/components/trainingSchedule/editScheduleHeader.jsx';
import ExerciseOverview from 'components/components/trainingSchedule/exerciseOverview.jsx';
import FPLoader from 'components/components/fullPageLoader/fpLoader.jsx';
import ScheduleExercises from 'components/components/trainingSchedule/scheduleExercises.jsx';
import SetTemplateName from 'components/forms/modals/setTemplateName.jsx';
import image from 'utils/image.js';

class EditTrainingScheduleLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            client: null,
            currentGoal: null,
            nrOfWeeks: 0,
            trainingScheduleId: this.props.trainingScheduleId,
            exercises: [],
            trainingSchedule: null,
            startDate: null
        };

        this._addExercise = this._addExercise.bind(this);
        this._addExercises = this._addExercises.bind(this);
        this._handleTemplateSaved = this._handleTemplateSaved.bind(this);
        this._setTemplateName = this._setTemplateName.bind(this);
        this._setNrOfWeeks = this._setNrOfWeeks.bind(this);

        this.cancelSource = fmfApiClient.createCancelSource();

        this.getClient();
        this.getTrainingSchedule();
    }

    componentWillUnmount() {
        this.cancelSource.cancel();
    }

    getClient() {
        fmfApiClient.getClient(this.cancelSource.token, this.props.clientId, image.resize(160, 160))
            .then(result => {
                this.setState({
                    client: result
                });
            });
    }

    getTrainingSchedule() {
        fmfApiClient.getClientTrainingSchedule(this.cancelSource.token, this.props.clientId, this.state.trainingScheduleId)
            .then(result => {
                let startDate = moment(result.startDate);
                this.setState({
                    trainingSchedule: result,
                    nrOfWeeks: result.weeks,
                    exercises: result.scheduleExercises,
                    startDate: startDate.format('DD-MM-YYYY'),
                    currentGoal: result.goal
                });
            });
    }

    _setNrOfWeeks(nrOfWeeks) {
        this.setState({
            nrOfWeeks: nrOfWeeks
        });
    }

    _addExercise(exercise) {
        this.exerciseList.addExercise(exercise);
    }

    _setTemplateName(title, comment) {
        this.exerciseList.saveAsTemplate(title, comment);
    }

    _addExercises(exercises) {
        this.exerciseList.addExercises(exercises);
    }

    _handleTemplateSaved(template) {
        this.exerciseOverview.addCustomTemplate(template);
    }

    render() {
        return (
            <div className="splitcolumnPage">
                {this.state.client == null || this.state.trainingSchedule == null ?
                    <FPLoader fixed="fixed" /> :
                    <div>
                        <div className="row noMarginBottom">
                            <div className="col s12 m7 l8 xl9">
                                <AddScheduleHeader ref={instance => {this.header = instance;}}
                                    nrOfWeeks={this.state.nrOfWeeks}
                                    goal={this.state.currentGoal}
                                    onNrOfWeeksChange={this._setNrOfWeeks} />
                                <ExerciseOverview ref={instance => {this.exerciseOverview = instance}}
                                    onAddExercise={this._addExercise}
                                    onAddExercises={this._addExercises}
                                    goal={this.state.currentGoal}
                                    currentClient={this.state.client} />
                            </div>
                            <div className="col s12 m5 l4 xl3 greyContainer" id="sidebar">
                                <ScheduleExercises ref={instance => {this.exerciseList = instance;}}
                                    client={this.state.client}
                                    goal={this.state.currentGoal}
                                    nrOfWeeks={this.state.nrOfWeeks}
                                    exercises={this.state.exercises}
                                    startDate={this.state.startDate}
                                    trainingScheduleId={this.state.trainingSchedule.id}
                                    onTemplateSaved={this._handleTemplateSaved}
                                />
                            </div>
                        </div>
                    </div>
                }
                <SetTemplateName onSetTemplateName={this._setTemplateName}/>
            </div>
        )
    }
}

export default EditTrainingScheduleLayout;

EditTrainingScheduleLayout.propTypes = {
    clientId              : PropTypes.string.isRequired,
    trainingScheduleId    : PropTypes.string.isRequired
};